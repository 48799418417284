  function lrCalendar(trigger) {
    this.returnFocus = trigger;
    this.periodEl = parentOfClass(this.returnFocus, 'accordion-item');
    var todaysDate = new Date();
    this.daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; 
    this.monthLongList = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
    this.monthShortList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    this.dayLabels = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    this.dayWords = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	  //this.description = this.periodEl.getAttribute('data-description');
    //this.user = this.periodEl.getAttribute('data-user');
    //this.payPeriod = this.periodEl.getAttribute('data-pay-period');

    this.positionNumber = this.periodEl.getAttribute('data-position-number');
    this.payNumber = Number(this.periodEl.getAttribute('data-pay-number'));
    this.suffix = this.periodEl.getAttribute('data-suffix') ? this.periodEl.getAttribute('data-suffix') : 'NULL';
    this.seqNo = this.periodEl.getAttribute('data-seq-no') ? this.periodEl.getAttribute('data-seq-no') : 'NULL';
    //In the future, if we allow submit from the popup, we need to set data-seq-no on the submit button inside the popup based on this value;
    
    this.allowedSick = (this.periodEl.getAttribute('data-sick').toLowerCase() === 'true') ? true : false;
    this.allowedVacation = (this.periodEl.getAttribute('data-vacation').toLowerCase() === 'true') ? true : false;
    this.allowedPersonal = (this.periodEl.getAttribute('data-personal').toLowerCase() === 'true') ? true : false;
    this.allowedAlternate = (this.periodEl.getAttribute('data-alternate').toLowerCase() === 'true') ? true : false;
    //this.eligibleToReport = (this.allowedSick || this.allowedVacation || this.allowedPersonal) ? true : false;

    var startYear =(this.periodEl.getAttribute('data-start-year') != null) ? Number(this.periodEl.getAttribute('data-start-year')): '';
    var startMonth = (this.periodEl.getAttribute('data-start-month') != null) ? Number(this.periodEl.getAttribute('data-start-month')) : '';
    var startDay = (this.periodEl.getAttribute('data-start-day') != null) ? Number(this.periodEl.getAttribute('data-start-day')) : '';
    var endYear =(this.periodEl.getAttribute('data-end-year') != null) ? Number(this.periodEl.getAttribute('data-end-year')): '';
    var endMonth = (this.periodEl.getAttribute('data-end-month') != null) ? Number(this.periodEl.getAttribute('data-end-month')) : '';
    var endDay = (this.periodEl.getAttribute('data-end-day') != null) ? Number(this.periodEl.getAttribute('data-end-day')) : '';

    this.start = this.lrDateInfo(startYear, startMonth, startDay);
    this.end = this.lrDateInfo(endYear, endMonth, endDay);
  }

  lrCalendar.prototype.lrDateInfo = function(year, month, day){
      var dateInfo = {};
      dateInfo.year = year;
      dateInfo.month = month;
      dateInfo.monthShort = this.monthShortList[dateInfo.month];
      dateInfo.monthLong = this.monthLongList[dateInfo.month];
      dateInfo.day = day;
      dateInfo.jsDate = new Date(dateInfo.year, dateInfo.month, dateInfo.day);
      dateInfo.dayOfWeek = dateInfo.jsDate.getDay();

      dateInfo.monthFirstDay = new Date(dateInfo.year, dateInfo.month, 1);
      dateInfo.monthFirstDayOfWeek = dateInfo.monthFirstDay.getDay();
      dateInfo.monthLength = this.daysInMonth[dateInfo.month];
      dateInfo.prevMonthLength = ((dateInfo.month - 1) >= 0 ) ? this.daysInMonth[(dateInfo.month - 1)] : 31;

      //Make sure amount of days is correct for leap years.
      if ((dateInfo.year % 4 == 0 && dateInfo.year % 100 != 0) || dateInfo.year % 400 == 0){
          if (dateInfo.month == 1) {
              dateInfo.monthLength = 29;
          } else if (dateInfo.month == 2){
              dateInfo.prevMonthLength = 29;
          }
      }
      return dateInfo;
    }

  lrCalendar.prototype.calBtnReset = function(){
    //loop through cal btns and remove special classes
    //check against parameter to see if this btn has data
    //console.log(this.datesWithData);
    for(var i = 0; i < this.calBtns.length; i++){
      var thisBtn = this.calBtns[i];
      thisBtn.classList.remove('changed');
      thisBtn.classList.remove('error');
      thisBtn.removeAttribute('data-saved');
      for(var j = 0; j < this.datesWithData.length; j++){
        if(thisBtn.getAttribute('data-full-date') === this.datesWithData[j]){
          thisBtn.setAttribute('data-saved', '');
        }
      }
    }
    return;
  }

  lrCalendar.prototype.generateLrData = function(data){
      this.data = {};
      this.newData = {};
      this.timeEntryArray = [];
      this.datesWithData = [];
      //this.data['User'] = this.user;
     // this.newData['User'] = this.user;
      this.data['PositionNumber'] = this.positionNumber;
      this.newData['PositionNumber'] = this.positionNumber;
      //this.data['Description'] = this.description;
      //this.newData['Description'] = this.description;
      //this.data['PayPeriod'] = this.payPeriod;
      //this.newData['PayPeriod'] = this.payPeriod;

      //need to evaluate when to reset counter to 1
      var splitView = (this.start.month !== this.end.month) ? true : false;
      var lastDay = splitView ? this.start.monthLength : this.end.day;

      var counter = this.start.day;
      var month = this.start.month;
      var year = this.start.year;

      var totalSick = 0;
      var totalVacation = 0;
      var totalPersonal = 0;
      var totalAlternate = 0;

      while(counter <= lastDay){
      //console.log(counter);
      var date = this.fullDateString(year, month, counter);

      var thisSick = 0;
      var thisVacation = 0;
      var thisPersonal = 0;
      var thisAlternate = 0;
      

      for(var i = 0; i < data.length; i++){

        if(data[i]['PositionNumber'] === this.positionNumber){
          //only interested in current job
          for(var j = 0; j < data[i]['Leave_by_Job'].length; j++){
            if(data[i]['Leave_by_Job'][j]['Pay_Number'] === this.payNumber && data[i]['Leave_by_Job'][j]['Time_Entry']){
              //Matching leave by job object
              for(var k = 0; k < data[i]['Leave_by_Job'][j]['Time_Entry'].length; k++){
                if(data[i]['Leave_by_Job'][j]['Time_Entry'][k]['TIME_ENTRY_DATE'] === date){

                  var timeEntry = data[i]['Leave_by_Job'][j]['Time_Entry'][k];
                  var timeEntryType = timeEntry['EARN_LONG_DESC'].toLowerCase();
                  var timeEntryNum = Number(timeEntry['EARN_CODE_HOURS']);

                  //make sure this is truthy and not NaN, Undefined, or ''...
                  if(timeEntryNum){
                    this.datesWithData.push(timeEntry['TIME_ENTRY_DATE']);
                    if(timeEntryType.indexOf('sick') !== -1){
                      totalSick += timeEntryNum;
                      thisSick = timeEntryNum;
                    } else if(timeEntryType.indexOf('vacation') !== -1){
                      totalVacation += timeEntryNum;
                      thisVacation = timeEntryNum;                    
                    } else if(timeEntryType.indexOf('personal') !== -1){
                      totalPersonal += timeEntryNum;
                      thisPersonal = timeEntryNum;                 
                    } else if(timeEntryType.indexOf('alternate') !== -1){
                      totalAlternate += timeEntryNum;
                      thisAlternate = timeEntryNum;                 
                    } else {}
                  }

                }
              }
            }else{ 
              //no match
              continue;
            }
          }//End of loop through all leave by job objects
        }//End of if jobs match
      
    this.data[date] = {};
    this.newData[date] = {};
      if(this.allowedSick){
        this.data[date]['sick'] = thisSick;
        this.newData[date]['sick'] = thisSick;
      }
      if(this.allowedVacation){
        this.data[date]['vacation'] = thisVacation;
        this.newData[date]['vacation'] = thisVacation;
      }
      if(this.allowedPersonal){
        this.data[date]['personal'] = thisPersonal;
        this.newData[date]['personal'] = thisPersonal;
      }
      if(this.allowedAlternate){
        this.data[date]['alternate'] = thisAlternate;
        this.newData[date]['alternate'] = thisAlternate;
      }

    }//For Loop End

      counter++;

      //Only executed once when pay period overlaps into next month.
      if(splitView && (counter > Number(this.start.monthLength))){
        counter = 1;
        month = this.end.month;
        year = this.end.year;
        lastDay = this.end.day;
      }
      
    }//While Loop End



    //Use these totals to display in original piece upon closing popup
    /*
    this.total = {};
    if(this.allowedSick){
      this.total.sick = totalSick;
    }
    if(this.allowedVacation){
      this.total.vacation = totalVacation;
    }
    if(this.allowedPersonal){
      this.total.personal = totalPersonal;
    }
    if(this.allowedAlternate){
      this.total.alternate = totalAlternate;
    }
    */
    this.calBtnReset();


    //Stringify our object to use for comparisons when the user makes a new input
    //If there is a difference between lrDataString and lrNewDataString,
    //we can tell the user they have unsaved changes.
    this.dataString = JSON.stringify(this.data);
    this.newDataString = JSON.stringify(this.newData);
  }


  lrCalendar.prototype.generateDayInRange = function(year, month, day, dayOfWeek){
    var dayInRange = document.createElement('button');
    dayInRange.setAttribute('class', 'day in-month in-range');
    dayInRange.setAttribute('data-day-of-week', this.dayWords[dayOfWeek]);
    dayInRange.setAttribute('data-day', day);
    dayInRange.setAttribute('data-month',month);
    dayInRange.setAttribute('data-year', year);
    dayInRange.setAttribute('data-full-date', this.fullDateString(year, month, day));
    dayInRange.addEventListener('click', this.dateClick.bind(this));
    return dayInRange; 
  }


  lrCalendar.prototype.generateLrCalSplit = function(){
    //This generates a split month view showing a pay period which spans over two months.
    //BUFFER - date numbering starts at a week before the pay period starts PLUS any days preceeding it in the given week.

    var sameWeekBefore = this.start.dayOfWeek;
    var splitStart = this.start.day - (7 + sameWeekBefore);
    //set prevStart to true if numbering needs to begin in the month preceeding the start month
    //this is a safety just in case a pay period is long enough to span two months but its starting date
    //doesn't have enough leading dates to allow the buffer specified above.
    var prevStart;

    if(splitStart === 0){
      splitStart = this.start.prevMonthLength;
      prevStart = true;
    } else if(splitStart < 0){
      var x = Math.abs(Num(splitStart));
      splitStart = this.start.prevMonthLength - x;
      prevStart = true;
    }

    //set limit to true when end of start month is reached
    //set complete to true when final pay period date is rendered
    var limit;
    var complete;

    var lrCal = document.createElement('div');
    var dayLabelRow = document.createElement('div');
    lrCal.appendChild(dayLabelRow);
    dayLabelRow.setAttribute('class', 'grid-x');

    for (var i = 0; i < this.dayLabels.length; i++){
      var cell = document.createElement('div');
      cell.setAttribute('class', 'cell auto');
      var dayLabel = document.createElement('div');
      dayLabel.setAttribute('class', 'day day-label');
      dayLabel.textContent = this.dayLabels[i];
      cell.appendChild(dayLabel);
      dayLabelRow.appendChild(cell);
    }
    
    var counter = 0;
    var days = splitStart;
    var overflow = 1;

    for(var i = 0; i < 9; i++){

      var week = document.createElement('div');
      lrCal.appendChild(week);
      week.setAttribute('class', 'grid-x');

      for(var j = 0; j <= 6; j++){
        var cell = document.createElement('div');
        cell.setAttribute('class', 'cell auto');
        var day;

          if(i === 0){
            day = document.createElement('div');
            day.setAttribute('class', 'day in-month out-range');
            day.textContent = days++;
            if(prevStart && days >= this.start.prevMonthLength){
              days = 1;
              prevStart = false;
            }
          } else if(i === 1){
            if(counter < this.start.dayOfWeek){
              day = document.createElement('div');
              day.setAttribute('class', 'day in-month out-range');
              day.textContent = days++;
              if(prevStart && days >= this.start.prevMonthLength){
                days = 1;
                prevStart = false;
              } else if(!limit && days > this.start.monthLength){
                days = 1;
                limit = true;
              }
            } else {
              day = this.generateDayInRange(this.start.year, this.start.month, days, j);
              day.textContent = days++;
              if(!limit && days > this.start.monthLength){
                days = 1;
                limit = true;
              }
            }
            counter++;
          } else {
            if(!limit && this.start.day <= days){
              day = this.generateDayInRange(this.start.year, this.start.month, days, j);
              day.textContent = days++;
              if(prevStart && days >= this.start.prevMonthLength){
                days = 1;
                prevStart = false;
              } else if(!limit && days > this.start.monthLength){
                days = 1;
                limit = true;
              }
            }else if(limit && days <= this.end.day){
        day = this.generateDayInRange(this.end.year, this.end.month, days, j);
              day.textContent = days++;
            } else if(limit && days <= this.end.monthLength) {
              day = document.createElement('div');
              day.setAttribute('class', 'day in-month out-range');
              day.textContent = days++;
            } else {
              day = document.createElement('div');
              day.setAttribute('class', 'day out-month out-range');
              day.textContent = overflow++;
            }
        }

        cell.appendChild(day);
        week.appendChild(cell);
      }

      //print out an empty week following pay period end
      if(complete) break;
      if(limit && days > this.end.day){
        complete = true;
      }
      
    }
    return lrCal;

  }


  lrCalendar.prototype.generateLrCalSingle = function(){

    var lrCal = document.createElement('div');
    var dayLabelRow = document.createElement('div');
    lrCal.appendChild(dayLabelRow);
    dayLabelRow.setAttribute('class', 'grid-x');

    for (var i = 0; i < this.dayLabels.length; i++){
      var cell = document.createElement('div');
      cell.setAttribute('class', 'cell auto');
      var dayLabel = document.createElement('div');
      dayLabel.setAttribute('class', 'day day-label');
      dayLabel.textContent = this.dayLabels[i];
      cell.appendChild(dayLabel);
      dayLabelRow.appendChild(cell);
    }

    var counter = 0;
    var days = 1;
    var overflow = 1;

    for(var i = 0; i < 9; i++){

      var week = document.createElement('div');
      lrCal.appendChild(week);
      week.setAttribute('class', 'grid-x');

      for(var j = 0; j <= 6; j++){
        var cell = document.createElement('div');
        cell.setAttribute('class', 'cell auto');
        var day;

          if(i === 0){
            if(this.start.monthFirstDayOfWeek > counter){
              day = document.createElement('div');
              day.setAttribute('class', 'day out-month out-range');
              day.textContent = (this.start.prevMonthLength - this.start.monthFirstDayOfWeek) + counter + 1;
            } else if(this.start.day <= days && days <= this.end.day) {
        day = this.generateDayInRange(this.start.year, this.start.month, days, j);
              day.textContent = days++;
            } else {
              day = document.createElement('div');
              day.setAttribute('class', 'day in-month out-range');
              day.textContent = days++;
            }
          } else {
            if(days > this.start.monthLength){
              day = document.createElement('div');
              day.setAttribute('class', 'day out-month out-range');
              day.textContent = overflow++;
            } else if(this.start.day <= days && days <= this.end.day){
        day = this.generateDayInRange(this.end.year, this.end.month, days, j);
              day.textContent = days++;
            } else {
              day = document.createElement('div');
              day.setAttribute('class', 'day in-month out-range');
              day.textContent = days++;
            }
          }

        cell.appendChild(day);
        week.appendChild(cell);
        counter++;
      }
      if (days > this.end.monthLength) break;
    }
    return lrCal;
  } 

  lrCalendar.prototype.generateLrSave = function(){
    this.saveBtn = document.createElement('button');
    this.saveBtn.setAttribute('class', 'button disabled');
    this.saveBtn.id = 'lr-btn-save';
    this.saveBtn.setAttribute('disabled', '');
    this.saveBtn.textContent = 'Save';
    this.saveBtn.addEventListener('click', this.updateEntryHoursClick.bind(this));
    return this.saveBtn;
  }

  lrCalendar.prototype.generateLrCal = function(){
    this.calWrap = document.createElement('div');
    var monthHeading = document.createElement('h4');
    monthHeading.textContent = this.start.monthShort + ' ' + this.start.day + ' \u2013 ' + this.end.monthShort + ' ' + this.end.day;
    this.calWrap.appendChild(monthHeading);

    var monthNum = 1;
    var lrCal;

    if(this.end.month !== this.start.month){
      monthNum = 2;
    }

    if(monthNum > 1){
      lrCal = this.generateLrCalSplit();   
    } else {
      lrCal = this.generateLrCalSingle();
    }

    //Now that we have the calendar returned to lrCal, establish the button array to use for navigation
    this.calBtns = lrCal.querySelectorAll('button');

    this.calWrap.appendChild(lrCal);

    return this.calWrap;
  }

  lrCalendar.prototype.generateLrStartMessage = function(){
    this.startMessage = document.createElement('div');
      this.startMessage.id = 'lr-start-message';
      this.startMessage.textContent = 'Select a date to begin.';
      return this.startMessage;
  }

  lrCalendar.prototype.makeNavBtn = function(id, icon){
    var btn = document.createElement('button');
    btn.id = id;
    btn.classList.add('lr-nav');
    var btnSpan = document.createElement('span');
    btnSpan.classList.add(icon);
    btn.appendChild(btnSpan);
    btn.addEventListener('click', this.dateClick.bind(this));
    return btn;
  }

  lrCalendar.prototype.makeInput = function(labelText){
      var inputLine = document.createElement('div');
      inputLine.classList.add('lr-input-line');
      var label = document.createElement('label');
      label.setAttribute('for', 'lr-input-' + labelText);
      var labelTxt = labelText + " time:";
      labelTxt = labelTxt.charAt(0).toUpperCase() + labelTxt.slice(1);
      label.textContent = labelTxt;
      var input = document.createElement('input');
      input.id = "lr-input-" + labelText;
      input.setAttribute('type', 'text');
      input.addEventListener('paste', this.inputPaste.bind(this));
      input.addEventListener('keydown', this.inputKeyDown.bind(this));
      input.addEventListener('input', this.inputChange.bind(this));
      inputLine.appendChild(label);
      inputLine.appendChild(input);
      if(labelText === 'vacation'){
        input.setAttribute('data-time-type', 'vacation');
        this.inputs.vacation = input;
      }
      if(labelText === 'sick'){
        input.setAttribute('data-time-type', 'sick');
        this.inputs.sick = input;
      }
      if(labelText === 'personal'){
        input.setAttribute('data-time-type', 'personal');
        this.inputs.personal = input;
      }
      if(labelText === 'alternate'){
        input.setAttribute('data-time-type', 'alternate');
        this.inputs.alternate = input;
      }
      return inputLine;
  }

  lrCalendar.prototype.generateLrInputs = function(){
    this.inputWrap = document.createElement('div');
    this.inputWrap.id = 'lr-input-wrap';

    this.prevBtn = this.makeNavBtn('lr-prev', 'dripicons-chevron-left');
    this.inputWrap.appendChild(this.prevBtn);
    this.nextBtn = this.makeNavBtn('lr-next', 'dripicons-chevron-right');
    this.inputWrap.appendChild(this.nextBtn);

    this.inputUpdate = document.createElement('div');
    this.inputUpdate.id = "lr-input-update";

    this.inputHeading = {};
    this.inputHeading.el = document.createElement('div');
    this.inputHeading.el.id = "lr-input-heading";
    var hTag = document.createElement('h4');
    this.inputHeading.dayOfWeek = document.createElement('span');
    this.inputHeading.dayOfWeek.setAttribute('data-day-of-week', '');
    var tNode1 = document.createTextNode(', ');
    this.inputHeading.month = document.createElement('span');
    this.inputHeading.month.setAttribute('data-month', '');
    var tNode2 = document.createTextNode(' ');
    this.inputHeading.day = document.createElement('span');
    this.inputHeading.day.setAttribute('data-day', '');
    var tNode3 = document.createTextNode(', ');
    this.inputHeading.year = document.createElement('span');
    this.inputHeading.year.setAttribute('data-year', '');
    hTag.appendChild(this.inputHeading.dayOfWeek);
    hTag.appendChild(tNode1);
    hTag.appendChild(this.inputHeading.month);
    hTag.appendChild(tNode2);
    hTag.appendChild(this.inputHeading.day);
    hTag.appendChild(tNode3);
    hTag.appendChild(this.inputHeading.year);
    this.inputHeading.el.appendChild(hTag);
    this.inputUpdate.appendChild(this.inputHeading.el);

    this.inputs = {};
    this.inputs.el = document.createElement('div');
    this.inputs.el.id = "lr-inputs";


    if(this.allowedSick)
    this.inputs.el.appendChild(this.makeInput('sick'));
    if(this.allowedVacation)
    this.inputs.el.appendChild(this.makeInput('vacation'));
    if(this.allowedPersonal)
    this.inputs.el.appendChild(this.makeInput('personal'));
    if(this.allowedAlternate)
    this.inputs.el.appendChild(this.makeInput('alternate'));


  this.inputUpdate.appendChild(this.inputs.el);
  this.inputWrap.appendChild(this.inputUpdate);

  var statusWrap = document.createElement('div');
  statusWrap.id = 'lr-change-status-wrap';
  this.changeStatus = {};
  this.changeStatus.el = document.createElement('div');
  this.changeStatus.el.id = 'lr-change-status';
  this.changeStatus.icon = document.createElement('span');
  this.changeStatus.icon.id = 'lr-change-status-icon'
  this.changeStatus.icon.setAttribute('class', 'fl-warning dripicons-warning');
  this.changeStatus.text = document.createElement('span');
  this.changeStatus.text.id = 'lr-change-status-text';
  this.changeStatus.text.textContent = 'You have unsaved changes.';
  this.changeStatus.el.appendChild(this.changeStatus.icon);
  this.changeStatus.el.appendChild(this.changeStatus.text);
  statusWrap.appendChild(this.changeStatus.el);
  this.inputWrap.appendChild(statusWrap);

  //if(!this.allowedVacation && !this.allowedSick && !this.allowedPersonal && !this.allowedAlternate)
  //looks like you aren't allowed to log any time off!
  //don't need to add this though since users not eligible for time off will never be able to activate this popup area.

  //hide inputs initially so start message can display alone
    $(this.inputWrap).hide();
    return this.inputWrap;
  }


function lrReportTimeClick(e){

  e.preventDefault();
  var lrLoader = new ajaxLoader($('#node-5746'));
  var trigger = e.currentTarget ? e.currentTarget : document.body;

  lrCurrentCalObject = new lrCalendar(trigger);

    $.ajax({
    method: "GET",
    url: "/asyncloader/GetEntity/686/TRUE",
    dataType: "json"
    }).done(function (data) {

      //console.log(data);
      lrReportTimeCalendar.appendChild(lrCurrentCalObject.generateLrCal());
      lrInputArea.appendChild(lrCurrentCalObject.generateLrInputs());
      lrInputArea.appendChild(lrCurrentCalObject.generateLrStartMessage());
      lrBtnSet.appendChild(lrCurrentCalObject.generateLrSave());
      lrCurrentCalObject.generateLrData(data);

      lrLoader.remove();
      $(lrReportTimePopup).foundation('open');


    }).fail(function () {
      //WS CALL FAILED

      lrLoader.remove();
      swal2("Currently Unavailable", "Please try again later.", "error");

    });

}


//month argument is always the zero based index!
lrCalendar.prototype.fullDateString = function(year, month, day){
  year = year.toString();
  month = Number(month) + 1;
  month = month.toString();
  if (month.length === 1) month = '0' + month;
  day = day.toString();
  if (day.length === 1) day = '0' + day;
  var date = year + '-' + month + '-' + day;
  return date;
}


lrCalendar.prototype.updateInputArea = function(btn){
  var trigger = btn;
  this.current = {};
  this.current.month = Number(trigger.getAttribute('data-month'));
  this.current.monthLong = this.monthLongList[(this.current.month)];
  this.current.day = Number(trigger.getAttribute('data-day'));
  this.current.year = Number(trigger.getAttribute('data-year'));
  this.current.dayOfWeek = trigger.getAttribute('data-day-of-week');
  this.current.fullDate = this.fullDateString(this.current.year, this.current.month, this.current.day);

  this.previous = {}
  this.next = {}

  //Determine Current, Previous, and Next
  for(var i = 0; i < this.calBtns.length; i++){
    if(this.calBtns[i].getAttribute('data-full-date') === this.current.fullDate){
      //CURRENT
      this.current.el = this.calBtns[i];
      if(!this.calBtns[i].classList.contains('active'))
      this.calBtns[i].classList.add('active');
      //PREVIOUS
      if(i - 1 < 0){
      //current is the base, So PREVIOUS needs to be the last index instead
        this.previous.el = this.calBtns[this.calBtns.length - 1];
      }else{
        this.previous.el = this.calBtns[i - 1];
      }
      //NEXT
      if(i + 1 === this.calBtns.length){
      //current is the last index, So NEXT needs to be the first index instead
        this.next.el = this.calBtns[0];
      }else{
        this.next.el = this.calBtns[i + 1];
      }
      continue;
    }
    this.calBtns[i].classList.remove('active');
  }
  /*
  console.log(this.previous.el);
  console.log(this.current.el);
  console.log(this.next.el);
  */

  this.prevBtn.setAttribute('data-year', this.previous.el.getAttribute('data-year'));
  this.prevBtn.setAttribute('data-month', this.previous.el.getAttribute('data-month'));
  this.prevBtn.setAttribute('data-day', this.previous.el.getAttribute('data-day'));
  this.prevBtn.setAttribute('data-day-of-week', this.previous.el.getAttribute('data-day-of-week'));
  this.prevBtn.setAttribute('data-full-date', this.previous.el.getAttribute('data-full-date'));

  this.nextBtn.setAttribute('data-year', this.next.el.getAttribute('data-year'));
  this.nextBtn.setAttribute('data-month', this.next.el.getAttribute('data-month'));
  this.nextBtn.setAttribute('data-day', this.next.el.getAttribute('data-day'));
  this.nextBtn.setAttribute('data-day-of-week', this.next.el.getAttribute('data-day-of-week'));
  this.nextBtn.setAttribute('data-full-date', this.next.el.getAttribute('data-full-date'));

  this.inputHeading.dayOfWeek.textContent = this.current.dayOfWeek;
  this.inputHeading.month.textContent = this.current.monthLong;
  this.inputHeading.day.textContent = this.current.day;
  this.inputHeading.year.textContent = this.current.year;

  //Look through this.newData array and look for the key matching the current fulldate
  //populate the inputs with the stored values based on what is allowed
  if(this.allowedVacation){
    if(this.newData[this.current.fullDate].vacation !== this.data[this.current.fullDate].vacation){
      this.inputs.vacation.classList.add('changed');
    }else{
      this.inputs.vacation.classList.remove('changed');
    }
    this.inputs.vacation.value = (this.newData[this.current.fullDate].vacation === 0) ? '' : this.newData[this.current.fullDate].vacation;
  }
  if(this.allowedSick){
    if(this.newData[this.current.fullDate].sick !== this.data[this.current.fullDate].sick){
      this.inputs.sick.classList.add('changed');
    }else{
      this.inputs.sick.classList.remove('changed');
    }
    this.inputs.sick.value = (this.newData[this.current.fullDate].sick === 0) ? '' : this.newData[this.current.fullDate].sick;
  }
  if(this.allowedPersonal){
    if(this.newData[this.current.fullDate].personal !== this.data[this.current.fullDate].personal){
      this.inputs.personal.classList.add('changed');
    }else{
      this.inputs.personal.classList.remove('changed');
    }
    this.inputs.personal.value = (this.newData[this.current.fullDate].personal) === 0 ? '' : this.newData[this.current.fullDate].personal;
  }
  if(this.allowedAlternate){
    if(this.newData[this.current.fullDate].alternate !== this.data[this.current.fullDate].alternate){
      this.inputs.alternate.classList.add('changed');
    }else{
      this.inputs.alternate.classList.remove('changed');
    }
    this.inputs.alternate.value = (this.newData[this.current.fullDate].alternate) === 0 ? '' : this.newData[this.current.fullDate].alternate;
  }
}



lrCalendar.prototype.dateClick = function(e){
  var btn = e.currentTarget;
  var firstInput = this.inputs.first ? this.inputs.first : document.querySelector('#lr-inputs input');
  var lastChanged = this.inputs.lastChanged ? this.inputs.lastChanged : undefined;
  var firstOfValue;
  var preSave;
  
  var keyboard = (document.documentElement.getAttribute('data-whatinput') === 'keyboard') ? true : false;
  var mouse = (document.documentElement.getAttribute('data-whatinput') === 'mouse') ? true : false;
  var inCal = btn.classList.contains('day');
  var inNav = btn.classList.contains('lr-nav');

  //is start message currently in DOM?
  // If so, remove it and then introduce the inputs and navigation
  var startMessage = document.querySelector("#lr-start-message");
  if(startMessage){
    $(startMessage).fadeOut(300, function(){
      this.updateInputArea(btn);
      if(firstInput){
        var inputArray = document.querySelectorAll('#lr-inputs input');
        for(var i = 0; i < inputArray.length; i++){
          if(!firstOfValue && Number(inputArray[i].value) > 0)
            firstOfValue = inputArray[i];
          if(!preSave && inputArray[i].classList.contains('changed'))
            preSave = inputArray[i];
        }
      }       
      this.startMessage.parentNode.removeChild(this.startMessage);
      $(this.inputWrap).fadeIn(300, function(){

        if(mouse && firstOfValue){
          firstOfValue.focus();
        }else if(keyboard && firstOfValue){
          firstOfValue.focus();
        }else if(keyboard && firstInput){
          firstInput.focus();
        }else{}

      });
    }.bind(this));
  } else {
    //no start message, so we can just update the internals
  $(this.inputUpdate).fadeOut(0, function(){
    this.updateInputArea(btn);
    if(firstInput){
      var inputArray = document.querySelectorAll('#lr-inputs input');
      for(var i = 0; i < inputArray.length; i++){
        if(!firstOfValue && Number(inputArray[i].value) > 0)
          firstOfValue = inputArray[i];
        if(!preSave && inputArray[i].classList.contains('changed'))
          preSave = inputArray[i];
      }
    } 
    $(this.inputUpdate).fadeIn(0, function(){

      if(mouse && preSave){
        preSave.focus();
      }else if(mouse && lastChanged){
        lastChanged.focus();
      }else if(mouse && firstOfValue){
        firstOfValue.focus();
      }else if(keyboard && preSave && inCal){
        preSave.focus();
      }else if(keyboard && firstOfValue && inCal){
        firstOfValue.focus();
      }else if(keyboard && lastChanged && inCal){
        lastChanged.focus();
      }else if(keyboard && firstInput && inCal){
        firstInput.focus();
      } else{}

    });
  }.bind(this));    
  }
  //update date and all inputs
 
}


lrCalendar.prototype.inputKeyDown = function(e){
  var allowedKeys = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 190, 38, 40];
  var allowedFunction = [8, 9, 37, 39, 8, 45, 46]
  var charCode = (e.which) ? e.which : e.keyCode;
  if(allowedFunction.indexOf(charCode) !== -1){
    return
  }

  if(allowedKeys.indexOf(charCode) !== -1){
  //console.log(charCode);
  //console.log(allowedKeys.indexOf(charCode));

  //User entered 0-9 or a decimal point
  var input = e.currentTarget;
  var value = input.value;
  var hasDecimal = (value.indexOf('.') !== -1) ? true : null;
  var decimalAt = (value.indexOf('.') !== -1) ? value.indexOf('.') : null;

  if(charCode == 38){
    e.preventDefault();
    var tempVal = Number(input.value);
    if(!tempVal){
      tempVal = 0;
    }
    tempVal = tempVal + 1;
    tempVal = tempVal.toFixed(2);
    if(tempVal.charAt(tempVal.length - 1) === '0'){
      tempVal = tempVal.slice(0, -1); 
    }

    if(Number(tempVal) % 1 === 0){
      tempVal = Number(tempVal) / 1;
    }
    input.value = tempVal;
    this.inputChange(e);
    return;
  }
   if(charCode == 40){
    e.preventDefault();
    var tempVal = Number(input.value) - 1;
    if(tempVal >= 0){
      tempVal = tempVal.toFixed(2);
    if(tempVal.charAt(tempVal.length - 1) === '0'){
      tempVal = tempVal.slice(0, -1); 
    }

      if(Number(tempVal) % 1 === 0){
        tempVal = Number(tempVal) / 1;
      }
      input.value = tempVal;
      this.inputChange(e);
    }
    return;
  }

  if(value.length === 1 && (value.indexOf('0') !== -1) && (charCode == 48 || charCode == 96)){
  e.preventDefault();
  }
  if (charCode == 110 || charCode == 190){
    if(hasDecimal) e.preventDefault();
  } else {

    if(hasDecimal && (decimalAt + 2) === (value.length - 1) && input.selectionStart >= (value.length - 2)){
        e.preventDefault();
    }
  }

  } else{
    e.preventDefault();
  }

}


lrCalendar.prototype.createTimeEntry = function(date){
  var timeEntry = {};
  timeEntry.VacationHours = this.data[date].vacation ? this.data[date].vacation : 0;
  timeEntry.SickHours = this.data[date].sick ? this.data[date].sick : 0;
  timeEntry.PersonalHours = this.data[date].personal ? this.data[date].personal : 0;
  timeEntry.AlternateHours = this.data[date].alternate ? this.data[date].alternate : 0;

  timeEntry.SeqNo = this.seqNo;
  timeEntry.EntryDate = moment(date, "YYYY-MM-DD").format("MM/DD/YYYY").toString();
  timeEntry.PositionID = this.positionNumber;
  timeEntry.Suffix = this.suffix;

  this.timeEntryArray.push(timeEntry);

  return timeEntry;
}


lrCalendar.prototype.getTimeEntry = function(date){
  var timeEntry;

  for(var i = 0; i < this.timeEntryArray.length; i++){
    if(this.timeEntryArray[i].EntryDate === (moment(date, "YYYY-MM-DD").format("MM/DD/YYYY").toString())){
      timeEntry = this.timeEntryArray[i];
      break;
    }
  }

  return timeEntry;
}


lrCalendar.prototype.getTimeEntryIndex = function(date){
  var entryIndex;
  var entryFound;

  for(var i = 0; i < this.timeEntryArray.length; i++){
    if(this.timeEntryArray[i].EntryDate === (moment(date, "YYYY-MM-DD").format("MM/DD/YYYY").toString())){
      entryIndex = i;
      entryFound = true;
      break;
    }
  }
  if(!entryFound){
    entryIndex = -1;
  }

  return entryIndex;
}


lrCalendar.prototype.inputPaste = function(e){
  e.preventDefault();
}


lrCalendar.prototype.inputChange = function(e){
  //Store Data in NEW data object
  //Stringify this object and compare to starting object
  //if different, display message active and enable save button
  //if same and message exists, remove show message and disable save button
  //if active, hide and then remove active

  //separately, if input value is different than stored original input value
  //add changed class to day in calendar
  //if the same, make sure day doesnt have changed class

  var input = e.currentTarget;
  var newValue = Number(input.value);
  if(isNaN(newValue)) newValue = 0;
  var oldValue;
  var date = this.current.fullDate;

  //need to declare. These will be initialized later if user is eligible for that type of time.
  var oldVacation;
  var oldSick;
  var oldPersonal;
  var oldAlternate;
  var newVacation;
  var newSick;
  var newPersonal;
  var newAlternate;

  if(this.allowedVacation) oldVacation = this.data[date].vacation;
  if(this.allowedSick) oldSick = this.data[date].sick;
  if(this.allowedPersonal) oldPersonal = this.data[date].personal;
  if(this.allowedAlternate) oldAlternate = this.data[date].alternate;

  var timeType = input.getAttribute('data-time-type');

  switch (timeType) {
    case 'vacation':
    oldValue = this.data[date].vacation;
    this.newData[date].vacation = newValue;
      break;
    case 'sick':
    oldValue = this.data[date].sick;
    this.newData[date].sick = newValue;
      break;
    case 'personal':
    oldValue = this.data[date].personal;
    this.newData[date].personal = newValue;
      break;
    case 'alternate':
    oldValue = this.data[date].alternate;
    this.newData[date].alternate = newValue;
      break;
    default:
      //Not found
  }

  if(this.allowedVacation) newVacation = this.newData[date].vacation;
  if(this.allowedSick) newSick = this.newData[date].sick;
  if(this.allowedPersonal) newPersonal = this.newData[date].personal;
  if(this.allowedAlternate) newAlternate = this.newData[date].alternate;

  this.newDataString = JSON.stringify(this.newData);

  var statusActive = (this.changeStatus.el.classList.contains('active')) ? true : false;

  if(this.dataString !== this.newDataString){
  //There has been a change
  //console.log(this.newData);
    if(this.changeStatus.type !== 'unsaved'){
      this.changeStatus.type = 'unsaved';
      this.changeStatus.text.textContent = 'You have unsaved changes.';
      this.changeStatus.icon.setAttribute('class', 'fl-warning dripicons-warning');
    }
    if(!statusActive){
      this.changeStatus.el.classList.add('active');
    }
    this.saveBtn.classList.remove('disabled');
    this.saveBtn.removeAttribute('disabled');

  }else{
  //No change, or returned back to normal
    if(statusActive){
      this.changeStatus.el.classList.remove('active');
      this.saveBtn.classList.add('disabled');
      this.saveBtn.setAttribute('disabled', '');
    }else{
    }
  }

  this.inputs.lastChanged = input;

  //establish time entry. if not there yet, create it.
  var timeEntry = this.getTimeEntry(this.current.fullDate) ? this.getTimeEntry(this.current.fullDate) : this.createTimeEntry(this.current.fullDate);

  //console.log('New Value: ' + newValue);
  //console.log('Old Value: ' + oldValue);
  if(newValue !== oldValue){

    //update timeEntry to newValue
    switch (timeType) {
      case 'vacation':
        timeEntry.VacationHours = newValue;
        break;
      case 'sick':
        timeEntry.SickHours = newValue;
        break;
      case 'personal':
        timeEntry.PersonalHours = newValue;
        break;
      case 'alternate':
        timeEntry.AlternateHours = newValue;
        break;
      default:
    }
    
    //only focus on the current input type (sick/vacation/personal/alternate) and update that value to the new value.
    this.current.el.classList.add('changed');
    input.classList.add('changed');
  }else{

    //change timeEntry back to oldValue
    switch (timeType) {
      case 'vacation':
        timeEntry.VacationHours = oldValue;
        break;
      case 'sick':
        timeEntry.SickHours = oldValue;
        break;
      case 'personal':
        timeEntry.PersonalHours = oldValue;
        break;
      case 'alternate':
        timeEntry.AlternateHours = oldValue;
        break;
      default:
    }

    if(oldVacation === newVacation && oldSick === newSick && oldPersonal === newPersonal && oldAlternate === newAlternate){
      this.current.el.classList.remove('changed');
      if(this.dataString === this.newDataString) this.inputs.lastChanged = null;
      //timeEntry has been completely reverted to what is currently posted in Banner
      //Delete timeEntry from array
      this.timeEntryArray.splice(this.getTimeEntryIndex(this.current.fullDate), 1);
    } 
    input.classList.remove('changed');
  }
  //console.log(this.timeEntryArray);
}


lrCalendar.prototype.removeFromDom = function(){
  this.calWrap.parentNode.removeChild(this.calWrap);
  this.inputWrap.parentNode.removeChild(this.inputWrap);
  var startMessage = document.querySelector("#lr-start-message");
  if(startMessage) this.startMessage.parentNode.removeChild(this.startMessage);
  this.saveBtn.parentNode.removeChild(this.saveBtn);
}


//Refresh Node for a specific position & pay period
function lrUpdateNode(periodElement, callBack){
  if(lrCurrentCalObject){ 
  	//var popupLoader = new ajaxLoader($('#report-time-popup'));
  }

  var nodeLoader = new ajaxLoader($('#node-5746'));
  var positionNumber = periodElement.getAttribute('data-position-number');
  var payNumber = Number(periodElement.getAttribute('data-pay-number'));

  var seqNo;
  var payPeriod;
  var newReturnFocus;
  var status = 'Error';
  var statusInd = 'E';
  
  var statusText = periodElement.querySelector('[data-status-text]');
  var sickEl = periodElement.querySelector('[data-total-sick]');
  var vacationEl = periodElement.querySelector('[data-total-vacation]');
  var personalEl = periodElement.querySelector('[data-total-personal]');
  var alternateEl = periodElement.querySelector('[data-total-alternate]');
  var submitBtn = periodElement.querySelector('[data-submit-btn]');

  var totalSick = 0;
  var totalVacation = 0;
  var totalPersonal = 0;
  var totalAlternate = 0;
  
  //Verify everything worked with AJAX to Banner
    $.ajax({
    method: "GET",
    url: "/asyncloader/GetEntity/686/TRUE",
    dataType: "json"
    }).done(function (data) {

      //console.log(data);

      //JOB
      for(var i = 0; i < data.length; i++){
      	var thisJob = data[i];

        if(thisJob.PositionNumber === positionNumber){

          //PAY PERIOD
          for(var j = 0; j < thisJob['Leave_by_Job'].length; j++){
          	var thisPayPeriod = thisJob['Leave_by_Job'][j];

            if(thisPayPeriod.Pay_Number === payNumber){

			  payPeriod = thisPayPeriod.PayPeriod;
			  seqNo = thisPayPeriod['Time_Entry'][0].JOB_SEQNO ? thisPayPeriod['Time_Entry'][0].JOB_SEQNO.toString() : 'NULL';

			  //TIME ENTRY
              for(var k = 0; k < thisPayPeriod['Time_Entry'].length; k++){

                  var thisTimeEntry = thisPayPeriod['Time_Entry'][k];
                  var timeEntryType = thisTimeEntry['EARN_LONG_DESC'].toLowerCase();
                  var timeEntryNum = Number(thisTimeEntry['EARN_CODE_HOURS']);

                  //make sure this is truthy and not NaN, Undefined, or ''...
                  if(timeEntryNum){
                    
                    if(timeEntryType.indexOf('sick') !== -1){
                      totalSick += timeEntryNum;
                    } else if(timeEntryType.indexOf('vacation') !== -1){
                      totalVacation += timeEntryNum;                   
                    } else if(timeEntryType.indexOf('personal') !== -1){
                      totalPersonal += timeEntryNum;              
                    } else if(timeEntryType.indexOf('alternate') !== -1){
                      totalAlternate += timeEntryNum;              
                    } else {}
                  }
                  
              }//End Loop through all time entries

              status = thisPayPeriod.Status;
              statusInd = thisPayPeriod.Status_Ind;
              break;//End Loop through all pay periods. We found the right one.
            }
          }

         break;//End Loop through all jobs. We found the right one.
        }
      }

      //update the hours that are displayed in this area
      if(sickEl) sickEl.textContent = totalSick;
      if(vacationEl) vacationEl.textContent = totalVacation;
      if(personalEl) personalEl.textContent = totalPersonal;
      if(alternateEl) alternateEl.textContent = totalAlternate;

      //update the status text and icon
      statusText.textContent = status;
      periodElement.setAttribute('data-status', status);
      periodElement.setAttribute('data-status-ind', statusInd);
      periodElement.setAttribute('data-seq-no', seqNo);
      //periodElement.setAttribute('data-pay-period', payPeriod);
      //maybe assign new url to review report buttons based off of new pay period
      submitBtn.setAttribute('data-seq-no', seqNo);

      


      if(lrCurrentCalObject){
	    //If updating after final submission, reassign return focus from the popup
	    if(statusInd === 'P' || statusInd === 'C'){
		  lrCurrentCalObject.returnFocus = periodElement.querySelector('.accordion-title');
	    }
	    var popupSubmit = lrReportTimePopup.querySelector('[data-submit-btn]');
	    if(popupSubmit){ 
	    	popupSubmit.setAttribute('data-seq-no', seqNo); 
	    }
	    //lrCurrentCalObject.payPeriod = payPeriod;
	    lrCurrentCalObject.seqNo = seqNo;

        lrCurrentCalObject.generateLrData(data);
		    lrCurrentCalObject.updateInputArea(lrCurrentCalObject.current.el);
        //go ahead and update status message to success reset date classes. This must come after generateLrData and updateInputArea which are directly above.
        
        
        
        lrCurrentCalObject.saveBtn.setAttribute('disabled', '');
		lrCurrentCalObject.saveBtn.classList.add('disabled');
		lrCurrentCalObject.changeStatus.el.classList.add('active');
		lrCurrentCalObject.changeStatus.type = 'saved';
		lrCurrentCalObject.changeStatus.icon.setAttribute('class', 'fl-success dripicons-checkmark');
		lrCurrentCalObject.changeStatus.text.textContent = 'Your changes have been saved.';
		//popupLoader.remove();
		//Might need to focus on something here
      }

      nodeLoader.remove();
      if(callBack) callBack();


  }).fail(function () {
      //updating node failed, display error message for user.

      statusText.textContent = 'Error';
      periodElement.setAttribute('data-status', 'Error');
      periodElement.setAttribute('data-status-ind', 'E');
      //If the user is updating from the popup area and hit this error, the current stored returnFocus Element will not be accessible to them anymore. Reassign here.
      if(lrCurrentCalObject){
        lrCurrentCalObject.returnFocus = periodElement.querySelector('.accordion-title');
        //popupLoader.remove();
      }

      nodeLoader.remove();
      if(callBack) callBack();
  });

}





lrCalendar.prototype.updateEntryHoursClick = function(e){
  e.preventDefault();
  var clickedBtn = e.currentTarget;
  var loader = new ajaxLoader($('#report-time-popup'));
  freezeUser();

  	  //AJAX SUBMIT TIME ENTRY ARRAY
      $.ajax({
        method: "POST",
        url: "/asyncloader/DoEntity/566",
        dataType: "json",
        beforeSend: function(xhr){xhr.setRequestHeader('X-Token-Key', 'data');},
        data: {'data': this.timeEntryArray}
      }).done(function (data) {
      	//console.log(data);
        
        //AJAX done, check for post success...
        if(data.Result === 'Success'){

          lrUpdateNode(this.periodEl, function(){
            loader.remove();
            thawUser();
          }.bind(loader));

        } else{
            loader.remove();
            thawUser();
            swal2("Error", "Unable to save your changes. Please try again.", "error");
        }
        


      }.bind(this)).fail(function () {
        thawUser();
        swal2("Error", "Unable to save your changes. Please try again. Ajax Fail.", "error");
        loader.remove();
      });
      

}//END lrUpdateEntryHoursClick



function lrSubmitReportClick(e){
  e.preventDefault();
  var clickedBtn = e.currentTarget;
  var returnFocus = clickedBtn;
  var seqNo = clickedBtn.getAttribute('data-seq-no');
  if(!seqNo){
	swal2("Error", "Your leave report can not be submitted at this time.", "error");
  	return;
  }

  var loader;
  var passwordInput;

  swal2Btns({
  customClass: 'lrSubmitAlert',
  title: 'Leave Report Confirmation',
  text: 'Enter your FlashLine password to submit your leave report.',
  type: 'info',
  confirmButtonText: "Submit",
  input: 'password',
  inputPlaceholder: 'Enter your password',
  inputAttributes: {
    autocapitalize: 'off',
    autocorrect: 'off'
  },
  onBeforeOpen: function onBeforeOpen() {
    sweetAlertCustom();
    passwordInput = document.querySelector('.lrSubmitAlert input[type="password"]');
    passwordInput.removeAttribute('disabled');
  },
  showLoaderOnConfirm: true,
  preConfirm: function () {
    return new Promise(function (resolve) {
      sweetAlertUseBallFall();
      passwordInput.setAttribute('disabled', '');
      var passwordEncoded = window.btoa(passwordInput.value);
      freezeUser();

      // AJAX CHECK PASSWORD
      $.ajax({
        method: "POST",
        url: "/asyncloader/password-check",
        dataType: "json",
        beforeSend: function(xhr){xhr.setRequestHeader('X-Token-Key', 'data');},
        data: {
          "data": passwordEncoded
        }
      }).done(function (data) {

      	//console.log(data);

        //AJAX done, check for post success...
        if(data.Result === 'Success'){

          //Password verified

          //now AJAX SUBMIT LEAVE REPORT
          $.ajax({
            method: "POST",
            url: "/asyncloader/DoEntity/571",
            dataType: "json",
            beforeSend: function(xhr){xhr.setRequestHeader('X-Token-Key', 'data');},
            data: {'data': seqNo}
          }).done(function (data) {
            thawUser();
            //AJAX done, check for post success...
            if(data.Result === 'Success'){
              swal2("Success", "Your leave report has been submitted.", "success").then(function() { 
                var periodElement = parentOfClass(clickedBtn, 'accordion-item');
                returnFocus = periodElement.querySelector('a.accordion-title');
                lrUpdateNode(periodElement);
                resolve(); 
              });

            } else{
              swal2("Error", "Your leave report submission was not successful. Please try again later.", "error").then(function() { resolve(); });
            }

          }).fail(function () {
            thawUser();
            swal2("Error", "Your leave report submission was not successful. Please try again later. Ajax Fail.", "error").then(function() { resolve(); });
          });

          /*
          END of AJAX SUBMIT LEAVE REPORT
          */


        } else{
        //Password Auth 200 but did NOT return success message
          thawUser();
          swal2("Invalid Password", "Please try again.", "error").then(function() { resolve(); });
        }

      }).fail(function () {
        //Password Auth AJAX Failed
        thawUser();
        swal2("Error", "Unable to validate password. Please try again. Ajax Fail.", "error").then(function() { resolve(); });
      });

      /*
      END
      AJAX CHECK PASSWORD
      */

    });//Preconfirm Promise
  }//Closing Preconfirm function

  }).then(function() {

    //this finally executes once a resolve is hit.

    //Return Focus is only assigned if the submission was successful.
    //In that situation, the submit button that launched the module has been removed from the DOM. 
    //So we need to send focus somewhere else...
    if(returnFocus){
      returnFocus.focus();
    }

  });

}//END lrSubmitReportClick
