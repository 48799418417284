//Note: also uses some functions from urltracing.js
var steppersExist;
var stpStepper;
var stpSet;
var stpStep;
var stpHistory;

        function stepperSuspend(thisStepper){
            thisStepper.setAttribute('aria-live', '');
            thisStepper.addEventListener('keydown', disableACTIONS, true);
            thisStepper.addEventListener('click', disableACTIONS, true);
        }
        function stepperResume(thisStepper){
            thisStepper.setAttribute('aria-live', 'polite');
            thisStepper.removeEventListener('keydown', disableACTIONS, true);
            thisStepper.removeEventListener('click', disableACTIONS, true);
        }

        //This function resizes steppers when left navigation is toggled
        function stpResizeNested(ms) {
            for (var i = 0; i < stpStepper.length; i++) {

                var newHeight = stpStepper[i].querySelector('.current .inner').offsetHeight;
                stpStepper[i].style.height = newHeight + 'px';
                var flipper = parentOfClass(stpStepper[i], 'flipper');

                if (flipper){
                    stpParentFlipperResize(stpStepper[i], newHeight, ms);
                } else {
                    //Just a Stepper
                    //Closure needed to pass in accurate index
                    (function(index){
                      setTimeout(function(){
                        EventDispatcher.PackeryResize(stpStepper[index]);
                      }.bind(this), ms);
                    })(i);
                }

                

            }//Closing FOR loop
            if(flippersExist){
                //update all flippers as well
                flipperForceResize();
            }
        }


		function stpResizeSteppers(e) {

            if(e && e.detail && e.detail.stepperIgnore === 'true'){
                return;
            }

		    for (var i = 0; i < stpStepper.length; i++) {
		        stpStepper[i].style.transition = '';
		        stpStepper[i].style.height = stpStepper[i].querySelector('.current .inner').offsetHeight + 'px';
		    }
		}

        function stpParentFlipperResize(stepper, newHeight, ms) {
            var sec = Number(ms * .001);
            var ms = Number(ms);
            var flipper = parentOfClass(stepper, 'flipper');
            if (flipper){

                var activeSide;
                var stepperInActiveSide;
                if (flipper.classList.contains('flipped')) {
                    activeSide = flipper.querySelector('.flipper-back');
                    stepperInActiveSide = parentOfClass(stepper, 'flipper-back');
                } else {
                    activeSide = flipper.querySelector('.flipper-front');
                    stepperInActiveSide = parentOfClass(stepper, 'flipper-front');
                }


                var sumHeight;
                if(stepperInActiveSide) {

                  var heightElements = activeSide.querySelectorAll('[data-height="true"]');
                  var sumHeight = Number(newHeight);
                  if (heightElements.length > 0){
                      for(var i = 0; i < heightElements.length; i++){
                    	sumHeight += Number(heightElements[i].offsetHeight);
                      }
                  }
                  
                } else{
                  sumHeight = activeSide.offsetHeight;
                }//In Active Side

                if(flipper.offsetHeight !== sumHeight){
                    var savedValue = flipper.style.transition;
                    flipper.style.transition = 'height ' + sec + 's ease';
                    flipper.style.height = sumHeight + 'px';

                    setTimeout(function(){
                      if(savedValue){
                        flipper.style.transition = savedValue;
                      }
                      EventDispatcher.PackeryResize(flipper);
                    }, ms);
                }//Height Needs to Change

     
            }//Flipper

        
        }

        function stpDisableTabindex(disableStep) {
            disableStep.setAttribute('tabindex', '-1');
            var tabbableElements = disableStep.querySelectorAll('a, button, input, select, textarea, iframe, [tabindex], area, svg');
            for (var i = 0; i < tabbableElements.length; i++) {
                tabbableElements[i].setAttribute('tabindex', '-1');
            }
            disableStep.setAttribute('aria-hidden', 'true');
        }

        function stpEnableTabindex(enableStep) {
            enableStep.setAttribute('tabindex', '0');
            var tabbableElements = enableStep.querySelectorAll('a, button, input, select, textarea, iframe, [tabindex]:not(svg), area');
            for (var i = 0; i < tabbableElements.length; i++) {
                tabbableElements[i].setAttribute('tabindex', '0');
                enableStep.setAttribute('aria-hidden', 'false');
            }
        }

        function stpNextChange(oldStep, newStep) {

            var stepperNum = Number(newStep.getAttribute('data-stepper'));
            var thisStepper = stpStepper[stepperNum];

            stepperSuspend(thisStepper);
            stpDisableTabindex(oldStep);

            if (newStep.classList.contains('oops-start')){
            	thisStepper.setAttribute('data-oops', 'start');
            } else if (newStep.classList.contains('oops-end')){
            	thisStepper.setAttribute('data-oops', 'end');
            } else {
            	thisStepper.setAttribute('data-oops', '');
            }

            if (!thisStepper.getAttribute('data-oops')) {
                thisStepper.setAttribute('data-set', newStep.getAttribute('data-set'));
                thisStepper.setAttribute('data-step', newStep.getAttribute('data-step'));
            }

            if (!oldStep.classList.contains('oops-start') && newStep !== stpHistory[stepperNum][0]) {
                stpHistory[stepperNum].push(newStep);
                newStep.style.zIndex = Number(oldStep.style.zIndex) + 1;
            }

            thisStepper.style.transition = 'all .55s ease';
            var newHeight = newStep.querySelector('.inner').offsetHeight;
            thisStepper.style.height = newHeight + 'px';
            oldStep.classList.remove('current');
            //oldStep.setAttribute('aria-hidden', 'true');

            newStep.classList.add('current');
            newStep.classList.add('active');
            //newStep.setAttribute('aria-hidden', 'false');
            let hasFlipper = parentOfClass(thisStepper, 'flipper');
            if(hasFlipper) stpParentFlipperResize(thisStepper, newHeight, 550);

            setTimeout(function () {
                stpEnableTabindex(newStep);
                newStep.addEventListener('blur', tempFocus);
                //newStep.setAttribute('aria-hidden', 'false');
                stepperResume(thisStepper);
                newStep.focus();
                if(!hasFlipper) EventDispatcher.PackeryResize(newStep);
            }, 550);

        }//End of stpNextChange Function

        function stpBackChange(oldStep, newStep, popArray) {

            var stepperNum = Number(newStep.getAttribute('data-stepper'));
            var thisStepper = stpStepper[stepperNum];

            stepperSuspend(thisStepper);
            stpDisableTabindex(oldStep);

            oldStep.removeAttribute('data-pause');
            newStep.removeAttribute('data-pause');

            if (newStep.classList.contains('oops-start')){
            	thisStepper.setAttribute('data-oops', 'start');
            } else if (newStep.classList.contains('oops-end')){
            	thisStepper.setAttribute('data-oops', 'end');
            } else {
            	thisStepper.setAttribute('data-oops', '');
            }

            if (!thisStepper.getAttribute('data-oops')) {
                thisStepper.setAttribute('data-set', newStep.getAttribute('data-set'));
                thisStepper.setAttribute('data-step', newStep.getAttribute('data-step'));
            }

            thisStepper.style.transition = 'all .55s ease';
            var newHeight = newStep.querySelector('.inner').offsetHeight;
            thisStepper.style.height = newHeight + 'px';
            oldStep.classList.remove('current');
            //oldStep.setAttribute('aria-hidden', 'true');
            newStep.classList.add('current');

            if (popArray) {
                for (var i = popArray.length; i > 0; i--) {
                    var shiftStep = popArray.shift();
                    shiftStep.removeAttribute('data-pause');
                    shiftStep.classList.remove('active');
                    stpHistory[stepperNum].pop();
                }
            } else {
                oldStep.classList.remove('active');
                if (thisStepper.getAttribute('data-oops') !== 'start') {
                    stpHistory[stepperNum].pop();
                }
            }
            let hasFlipper = parentOfClass(thisStepper, 'flipper');
            if(hasFlipper) stpParentFlipperResize(thisStepper, newHeight, 550);
            
            setTimeout(function () {
                stpEnableTabindex(newStep);
                newStep.addEventListener('blur', tempFocus);
                //newStep.setAttribute('aria-hidden', 'false');
                stepperResume(thisStepper);
                newStep.focus();
                if(!hasFlipper) EventDispatcher.PackeryResize(newStep);
            }, 550);

        }//End of stpBackChange Function

        function stpBtnBackStep(e){
            e.preventDefault();
        	var thisStepper = parentOfClass(e.currentTarget, 'stepper');
        	var dataBack = e.currentTarget.getAttribute('data-back');
        	stpBackStep(thisStepper, dataBack);
        }

        function stpBackStep(thisStepper, dataBack) {
            var stepperNum = Number(thisStepper.getAttribute('data-stepper'));
            var oldStep;
            var newStep;
            var chopOff;

            if (dataBack) {
                //Found a data-back value. Let's find out if it is valid
                //Needs to be in the history stack
                //or the string 'start' as long as history has at least 1 index
                //If not valid set dataBack to null
                //If valid, make that step active and current and pop off everything following

                var validDataBack;
                var idAt;
                var markedPause;
                var pauseAt;

                for (var i = 0; i < stpHistory[stepperNum].length; i++) {
                    if (dataBack === 'start') {
                        validDataBack = true;
                    }
                    if (dataBack === stpHistory[stepperNum][i].id) {
                        idAt = i;
                        validDataBack = true;
                    }
                    if (dataBack === 'resume') {
                        markedPause = stpHistory[stepperNum][i].getAttribute('data-pause');
                        if (markedPause === 'true') {
                            validDataBack = true;
                            pauseAt = i;
                        }
                    }
                }

                if (validDataBack) {
                    if (dataBack === 'start') {
                        //Go to index 0
                        newStep = stpHistory[stepperNum][0];
                        chopOff = stpHistory[stepperNum].slice(1);
                        if (chopOff.length > 0) {
                            //Ready to pop off
                            //console.log(chopOff);
                            oldStep = chopOff[chopOff.length - 1];
                            stpBackChange(oldStep, newStep, chopOff);
                        } else {
                            //User on start already... do nothing, OR
                            //User on oops-start, just go forward to start screen
                            if (thisStepper.getAttribute('data-oops') === 'start') {
                                oldStep = thisStepper.querySelector('.oops-start');
                                stpNextChange(oldStep, newStep);
                            }
                        }
                        return;
                    } else if (dataBack === 'resume') {
                        newStep = stpHistory[stepperNum][pauseAt];
                        chopOff = stpHistory[stepperNum].slice(pauseAt + 1);
                        if (chopOff.length > 0) {
                            //Ready to pop off
                            //console.log(chopOff);
                            oldStep = chopOff[chopOff.length - 1];
                            stpBackChange(oldStep, newStep, chopOff);
                        }
                    } else {
                        //Go to the ID specified in data-back
                        newStep = stpHistory[stepperNum][idAt];
                        chopOff = stpHistory[stepperNum].slice(idAt + 1);
                        if (chopOff.length > 0) {
                            //Ready to pop off
                            //console.log(chopOff);
                            oldStep = chopOff[chopOff.length - 1];
                            stpBackChange(oldStep, newStep, chopOff);
                        }
                    }
                } else {
                    //dataBack was NOT valid
                    //set to null so we can fallback to the regular functionality in the following block\
                    dataBack = null;
                }
            }

            if (!dataBack) {

                if (stpHistory[stepperNum].length > 1) {
                    oldStep = stpHistory[stepperNum][stpHistory[stepperNum].length - 1];
                    newStep = stpHistory[stepperNum][stpHistory[stepperNum].length - 2];
                    stpBackChange(oldStep, newStep);
                } else {
                    //Trying to hit a back button on oopsStart...
                    if (thisStepper.getAttribute('data-oops') === 'start') {
                        return;
                    }
                    //This was the first step in history. Go to OopsStart
                    oldStep = stpHistory[stepperNum][0];
                    newStep = thisStepper.querySelector('.oops-start');
                    stpBackChange(oldStep, newStep);
                }

            }//!dataBack
        }// End stpBackStep Function

        function stpbtnNextStep(e){
            e.preventDefault();
        	var thisStepper = parentOfClass(e.currentTarget, 'stepper');
        	var dataNext = e.currentTarget.getAttribute('data-next');
			var dataPause = e.currentTarget.getAttribute('data-pause');
        	stpNextStep(thisStepper, dataNext, dataPause);
        }

        function stpNextStep(thisStepper, dataNext, dataPause) {

            var stepperNum = Number(thisStepper.getAttribute('data-stepper'));
            var thisStep;
            var nextStep;

            if (thisStepper.getAttribute('data-oops') === 'start') {
                thisStep = thisStepper.querySelector('.oops-start');
                nextStep = stpHistory[stepperNum][0];
                stpNextChange(thisStep, nextStep);
                return;
            }

            var eSet = Number(thisStepper.getAttribute('data-set'));
            var eStep = Number(thisStepper.getAttribute('data-step'));

            thisStep = stpStep[stepperNum][eSet][eStep];

            //allows us to dynamically mark a step to easily return to later
            if (dataPause === 'true')
                thisStep.setAttribute('data-pause', 'true');

            if (dataNext) {

                var nextStep = thisStepper.querySelector('#' + dataNext);
                if (nextStep) {
                    //Found a step in this stepper matching the data-next attribute value
                    //Let's see if this is a valid Move...
                    //Two rules for the stepper:
                    //Can't go forward to a step that you've already been to
                    //Can't go forward to a step that precedes visited steps in a given set

                    var dSet = Number(nextStep.getAttribute('data-set'));
                    var dStep = Number(nextStep.getAttribute('data-step'));

                    var flagged = null;
                    var highestVisited = 0;

                    for (var i = 0; i < stpHistory[stepperNum].length; i++) {
                        var iSet = Number(stpHistory[stepperNum][i].getAttribute('data-set'));
                        var iStep = Number(stpHistory[stepperNum][i].getAttribute('data-step'));

                        if (dataNext === stpHistory[stepperNum][i].id) {
                            flagged = true;
                        }
                        if ((dSet === iSet) && (iStep > highestVisited)) {
                            flagged = true;
                            highestVisited = iStep;
                        }

                    }//Ending loop through history

                    if (flagged && (dStep <= highestVisited)) {
                        //This is NOT a valid Move
                        dataNext = false;
                    } else {
                        //This is a valid Move
                        stpNextChange(thisStep, nextStep);
                    }

                } else {
                    //This button did contain a data-next attribute, BUT its value points to an ID which doesn't exist in this stepper
                    dataNext = false;
                }
            }//End of if (dataNext) block

            //Separate IF to provide fallback. There is a nested condition above which can change dataNext to false. In which case, we need the following to execute.
            if (!dataNext) {

                nextStep = stpStep[stepperNum][eSet][eStep + 1];

                if (nextStep) {
                    stpNextChange(thisStep, nextStep);
                } else {
                    //Trying to hit next on the OopsEnd screen...
                    if (thisStepper.getAttribute('data-oops') === 'end') {
                        return;
                    }
                    //Or - there is not another step in this set!
                    //Oops!
                    nextStep = thisStepper.querySelector('.oops-end');
                    stpNextChange(thisStep, nextStep);
                }
            }

        }// End Function

    //If you add steps to a stepper after initialization, you need to disable user action and then
    //call this function to re-number the steps. New steps have the potential to receive focus until 
    //this function runs. You should enable user actions after calling this function.
    //optional 2nd parameter resets the event listeners on the next and back buttons.
    function stpReNumberThisStepper(thisStepper, buttonReset){
    	var stepperNum = Number(thisStepper.getAttribute('data-stepper'));
    	var currentSet = Number(thisStepper.getAttribute('data-set'));
    	var currentStep =  Number(thisStepper.getAttribute('data-step'));
    	var currentEl = stpStep[stepperNum][currentSet][currentStep];

		var setExists = thisStepper.querySelector('.set');
		if (!setExists) {
			stpSet[stepperNum] = undefined;
			stpStep[stepperNum] = undefined;
			return;
		}
    	var thisSets = thisStepper.querySelectorAll('.set');
    	stpSet[stepperNum] = thisSets;
    	stpStep[stepperNum] = [];

		//Start Loop through SETS
        for (var i = 0; i < thisSets.length; i++) {
        	thisSets[i].setAttribute('data-set', i);

            var stepExists = thisStepper.querySelector('.step');
            if (!stepExists) {
                stpStep[stepperNum].push(undefined);
                continue;
            }
            var iSteps = thisSets[i].querySelectorAll('.step');
            stpStep[stepperNum].push(iSteps);

			//Start Loop through STEPS
            for (var j = 0; j < iSteps.length; j++) {
                iSteps[j].setAttribute('data-set', i);
                iSteps[j].setAttribute('data-step', j);
                if(!iSteps[j].classList.contains('current')){
                	//iSteps[j].setAttribute('aria-hidden', 'true');
                	stpDisableTabindex(iSteps[j]);
                }

                if (buttonReset === true){
					var backButton = iSteps[j].querySelector('.back');
	                if (backButton) {
	                    backButton = iSteps[j].querySelectorAll('.back');
	                    for (var k = 0; k < backButton.length; k++) {
	                        backButton[k].addEventListener('click', stpBtnBackStep);
	                    }
	                }
	                var nextButton = iSteps[j].querySelector('.next');
	                if (nextButton) {
	                    nextButton = iSteps[j].querySelectorAll('.next');
	                    for (var k = 0; k < nextButton.length; k++) {
	                        nextButton[k].addEventListener('click', stpbtnNextStep);
	                    }
	                }
                    var flipToBackBtns = iSteps[j].querySelectorAll('.flip-to-back');
                    for (var k = 0; k < flipToBackBtns.length; k++) {
                        flipToBackBtns[k].addEventListener('click', btnFlipToBack);
                    }
                    var flipToFrontBtns = iSteps[j].querySelectorAll('.flip-to-front');
                    for (var k = 0; k < flipToFrontBtns.length; k++) {
                        flipToFrontBtns[k].addEventListener('click', btnFlipToFront);
                    }
                }//Ending Button Reset
                  
            }//Closing loop through STEPS

        }//Closing loop through SETS
    	//console.log(stpSet);
    	//console.log(stpStep);

    	thisStepper.setAttribute('data-set', currentEl.getAttribute('data-set'));
        thisStepper.setAttribute('data-step', currentEl.getAttribute('data-step'));

    }//END of stpReNumberThisStepper FUNCTION

	function stpInstallSteppers() {

        steppersExist = document.querySelector('.stepper');
        if (!steppersExist) return;

        stpStepper = document.querySelectorAll('.stepper');
        stpSet = [];
        stpStep = [];
        stpHistory = [];

        for (var i = 0; i < stpStepper.length; i++) {
            stpStepper[i].setAttribute('data-stepper', i);
            stpStepper[i].addEventListener('scroll', function (e) {
                if (e.target.classList.contains('stepper') || e.target.classList.contains('set')) {
                    e.target.scrollTo(0, 0);
                }
            });
            var setExists = stpStepper[i].querySelector('.set');
            var startingHeight = null;

            var oopsStart = document.createElement('div');
            //oopsStart.setAttribute('aria-hidden', 'true');
            oopsStart.setAttribute('data-stepper', i);
            oopsStart.classList.add('oops-start');
            oopsStart.innerHTML = "<div class='inner'><img src='/sites/all/themes/ksu_foundation/images/flash-avatar.jpg' alt='' /><h3>Oops! There is Nothing Here!</h3><p>You've reached the beginning.</p><nav><button disabled class='button back dripicons-chevron-left'></button><button class='button next dripicons-chevron-right'></button><button class='button back dripicons-home' data-back='start'></button></nav></div>";

            var startBtns = oopsStart.querySelectorAll('button, a');
            for (var j = 0; j < startBtns.length; j++) {
                if (startBtns[j].classList.contains('back'))
                    startBtns[j].addEventListener('click', stpBtnBackStep);
                if (startBtns[j].classList.contains('next'))
                    startBtns[j].addEventListener('click', stpbtnNextStep);
            }

            stpDisableTabindex(oopsStart);
            stpStepper[i].insertBefore(oopsStart, stpStepper[i].firstChild);

            var oopsEnd = document.createElement('div');
            //oopsEnd.setAttribute('aria-hidden', 'true');
            oopsEnd.setAttribute('data-stepper', i);
            oopsEnd.classList.add('oops-end');
            oopsEnd.innerHTML = "<div class='inner'><img src='/sites/all/themes/ksu_foundation/images/flash-avatar.jpg' alt='' /><h3>Oops! There is Nothing Here!</h3><p>You've reached the end.</p><nav><button class='button back dripicons-chevron-left'></button><button disabled class='button next dripicons-chevron-right'></button><button class='button back dripicons-home' data-back='start'></button></nav></div>";
            var endBtns = oopsEnd.querySelectorAll('button, a');
            for (var j = 0; j < endBtns.length; j++) {
                if (endBtns[j].classList.contains('back'))
                    endBtns[j].addEventListener('click', stpBtnBackStep);
            }

            stpDisableTabindex(oopsEnd);
            stpStepper[i].appendChild(oopsEnd);

            if (!setExists) {
                console.log('This Stepper contains NO sets!!');
                stpSet.push(null);
                stpStep.push(null);

                oopsStart.innerHTML = "<div class='inner'><img src='/sites/all/themes/ksu_foundation/images/flash-avatar.jpg' alt='' /><h3>Oops! There is Nothing Here!</h3><p>Your Stepper has no steps...</div>";
                oopsStart.classList.add('current');
                stpEnableTabindex(oopsStart);
                oopsStart.setAttribute('tabindex', '-1');
                startingHeight = oopsStart.querySelector('.inner').offsetHeight;
                stpStepper[i].style.height = startingHeight + 'px';
                stpStepper[i].setAttribute('data-oops', 'start');

                //reveal stepper
                stpStepper[i].classList.add('ready');
                stpStepper[i].setAttribute('aria-hidden', 'false');
                stpStepper[i].setAttribute('aria-live', 'polite');

                //oopsStart.setAttribute('aria-hidden', 'false');

                continue;
            }

            var iSet = stpStepper[i].querySelectorAll('.set');
            stpSet.push(iSet);
            stpStep.push([]);

            var startHere = null;
            var stepExists = null;

            //Start Loop through SETS
            for (var j = 0; j < iSet.length; j++) {
                iSet[j].setAttribute('data-stepper', i);
                iSet[j].setAttribute('data-set', j);

                stepExists = iSet[j].querySelector('.step');
                if (!stepExists) {
                    stpStep[i].push(null);
                    continue;
                }

                var jStep = iSet[j].querySelectorAll('.step');
                stpStep[i].push(jStep);

                //Start Loop through STEPS
                for (var k = 0; k < jStep.length; k++) {
                    jStep[k].setAttribute('data-stepper', i);
                    jStep[k].setAttribute('data-set', j);
                    jStep[k].setAttribute('data-step', k);
                    //jStep[k].setAttribute('aria-hidden', 'true');
                    stpDisableTabindex(jStep[k]);

                    var backButton = jStep[k].querySelector('button.back');
                    if (backButton) {
                        backButton = jStep[k].querySelectorAll('button.back');
                        for (var l = 0; l < backButton.length; l++) {
                            backButton[l].addEventListener('click', stpBtnBackStep);
                        }
                    }

                    var nextButton = jStep[k].querySelector('button.next');
                    if (nextButton) {
                        nextButton = jStep[k].querySelectorAll('button.next');
                        for (var m = 0; m < nextButton.length; m++) {
                            nextButton[m].addEventListener('click', stpbtnNextStep);
                        }
                    }

                    if (!startHere && jStep[k].classList.contains('current')) {
                        startHere = jStep[k];
                    }
                }//Closing Loop through STEPS

            }//Closing Loop through SETS

            //if no step was given the current class, just start at the first step we find in this stepper
            if (!startHere && stepExists) {
                startHere = stpStepper[i].querySelector('.step');
            }

            if (stepExists) {
                startHere.style.zIndex = 2;
                startHere.classList.add('current');
                startHere.classList.add('active');

                stpHistory.push([]);
                stpHistory[i].push(startHere);
                stpStepper[i].setAttribute('data-set', startHere.getAttribute('data-set'));
                stpStepper[i].setAttribute('data-step', startHere.getAttribute('data-step'));
                startingHeight = startHere.querySelector('.inner').offsetHeight;
                stpStepper[i].style.height = startingHeight + 'px';

                //reveal stepper
                stpStepper[i].classList.add('ready');
                stpStepper[i].setAttribute('aria-hidden', 'false');

                stpEnableTabindex(startHere);
                startHere.setAttribute('tabindex', '-1');
                stpStepper[i].setAttribute('aria-live', 'polite');
                //startHere.setAttribute('aria-hidden', 'false');

            } else {
                console.log('This Stepper contains NO steps!!');

                oopsStart.innerHTML = "<div class='inner'><img src='/sites/all/themes/ksu_foundation/images/flash-avatar.jpg' alt='' /><h3>Oops! There is Nothing Here!</h3><p>Your Stepper has no steps...</div>";
                oopsStart.classList.add('current');
                startingHeight = oopsStart.querySelector('.inner').offsetHeight;
                stpStepper[i].style.height = startingHeight + 'px';
                stpStepper[i].setAttribute('data-oops', 'start');

                //reveal stepper
                stpStepper[i].classList.add('ready');
                stpStepper[i].setAttribute('aria-hidden', 'false');

                stpEnableTabindex(oopsStart);
                oopsStart.setAttribute('tabindex', '-1');
                stpStepper[i].setAttribute('aria-live', 'polite');

                //oopsStart.setAttribute('aria-hidden', 'false');

            }

            stpParentFlipperResize(stpStepper[i], startingHeight, 0);
            EventDispatcher.PackeryResize(stpStepper[i]);
            stpStepper[i].setAttribute('data-loaded', 'true');
            EventDispatcher.Custom({
                "el" : stpStepper[i],
                "event" : "StepperLoaded"
            });
            
            if(stpStepper[i].querySelector('[data-orbit]')){
                window.addEventListener('load', function(){
                    EventDispatcher.Custom({
                        "el" : window,
                        "event" : "resize"
                    }); 
                });
            }


	        }//Closing Loop through Steppers

	        //Check Main Arrays
	        //console.log(stpStepper);
	        //console.log(stpSet);
	        //console.log(stpStep);
	        //console.log(stpHistory);

	        window.addEventListener('resize', stpResizeSteppers);

    }

    document.addEventListener('DOMContentLoaded', stpInstallSteppers);
