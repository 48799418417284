class Toggler {

    constructor(id, onFunction, offFunction, clearFunction) {

        this.defaultState = 0;

        // what do we cant to go back to if we 'clear' it.
        // technically we could have always had it simply reset
        // to the 'onFunction' as the default after clearing,
        // but this is kind of better.

        this.state = this.defaultState;

        // what do we do when the state changes.
        this.id = id;
        this.woo = "";
        this.offFunction = offFunction;
        this.onFunction = onFunction;
        this.clearFunction = clearFunction

        // add the click event

        // we need to make a reference to this object so we can set back the state...
        $(id).click({me: this},
            function (event) {
                event.data.me.state = event.data.me.state == 1 ? 0 : 1;

                if (event.data.me.state == 0) {
                    if (event.data.me.onFunction != null)
                        event.data.me.onFunction();
                }
                else {
                    if (event.data.me.offFunction != null)
                        event.data.me.offFunction();
                }
            });

    }

    clear() {
        this.state = 0;
        if (this.clearFunction != null)
            this.clearFunction();
    }


}

