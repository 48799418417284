// Local Top Navigation Custom Scripts
var navMobile;
var mobileHamburger;
var mobileCloseButton;
var closeOverlay;
var navMobileLinks;
var navMobileTopLinks;
var navMobileBackButtons;
var mobileStep1;

var navMobileTabbable;
var navMobileAllDropdowns;
var navMobileAllStepInners;

function mobileCurrentTabbable(e){
    var tabbable = $('#nav-mobile .nav1PanelInner.active a, #nav-mobile .nav1PanelInner.active button, #nav-mobile .nav1PanelInner.active input, #nav-mobile .nav1PanelInner.active select, #nav-mobile .nav1PanelInner.active textarea, #nav-mobile .nav1PanelInner.active iframe, #nav-mobile .nav1PanelInner.active [tabindex], #nav-mobile .nav1PanelInner.active area').not('#nav-mobile .nav1PanelInner.active .dropdown-pane a, #nav-mobile .nav1PanelInner.active .dropdown-pane button, #nav-mobile .nav1PanelInner.active .dropdown-pane input, #nav-mobile .nav1PanelInner.active .dropdown-pane select, #nav-mobile .nav1PanelInner.active .dropdown-pane textarea, #nav-mobile .nav1PanelInner.active .dropdown-pane iframe, #nav-mobile .nav1PanelInner.active .dropdown-pane [tabindex], #nav-mobile .nav1PanelInner.active .dropdown-pane area');
    return tabbable;
}

//If user widens window, aria show active left navigation so that user can successfully tab to these menu items.
function widenFromMobile(e) {
    if (window.innerWidth >= 1024) {
        document.getElementById('site-wrapper').classList.remove('layout-transitions-off');
        ariaShowLeftNav();
        window.removeEventListener('resize', widenFromMobile);
        window.addEventListener('resize', narrowFromDesktop);
        navResizeStpFlp(550);
        profilePopDesktop();
    }
}


function mobileHamburgerClick(e) {
    if (navMobile.classList.contains('is-open')) {
        closeMobileMenu();
    } else {

        //Reset Scroll Areas
        var mobileScrollAreas = navMobile.querySelectorAll('[data-scrollarea]');
        for (var i = 0; i < mobileScrollAreas.length; i++){
                mobileScrollAreas[i].scrollTop = 0;
        }
        document.body.classList.add('mobile-menu-open');
        navMobile.classList.add('is-open');
        navMobile.setAttribute('aria-hidden', 'false');
        navMobile.setAttribute('aria-expanded', 'true');
        closeOverlay.classList.add('active');
        mobileStep1.classList.add('active');
        mobileCloseButton.setAttribute('tabindex', '0');

        var thisStepTabbable = mobileCurrentTabbable();
        for (var k = 0; k < thisStepTabbable.length; k++) {
            thisStepTabbable[k].setAttribute('tabindex', '0');
        }
    }
}

function closeMobileMenu(e) {
    if (navMobile.classList.contains('is-open')) {
        document.body.classList.remove('mobile-menu-open');
        navMobile.classList.remove('is-open');
        navMobile.setAttribute('aria-hidden', 'true');
        navMobile.setAttribute('aria-expanded', 'false');
        closeOverlay.classList.remove('active');
        mobileStep1.classList.remove('active');

        for (var i = 0; i < navMobileTabbable.length; i++) {
            navMobileTabbable[i].setAttribute('tabindex', '-1');
        }

        for (var i = 0; i < navMobileAllStepInners.length; i++) {
            navMobileAllStepInners[i].classList.remove('active');
        }
        for (var i = 0; i < navMobileAllDropdowns.length; i++) {
            navMobileAllDropdowns[i].classList.remove('is-active');

            if (navMobileAllDropdowns[i].classList.contains('is-open')) {
                navMobileAllDropdowns[i].classList.remove('is-open');
                navMobileAllDropdowns[i].setAttribute('aria-hidden', 'true');
                navMobileAllDropdowns[i].previousSibling.classList.remove('hover');
                navMobileAllDropdowns[i].previousSibling.setAttribute('aria-expanded', 'false');
            }
        }
    }
    if (navMobile.classList.contains('innersteps')) {
        navMobile.classList.remove('innersteps');
    }


    //Without this delay, we cant send focus back to hamburger due to animated visibility
    setTimeout(function () {
        mobileHamburger.focus();
    }, 100);
}

function navMobileParentClick(e) {
    e.preventDefault();
    e.stopPropagation();

    var nextTrigger = e.currentTarget;
    var thisInner = $(nextTrigger).closest(".nav1PanelInner");
    var nextBackButton;
    var thisStep;
    var thisBreadTrail;
    var thisTitle;

    if ($(thisInner).attr('id') === 'mobile-step-1') {
        thisStep = thisInner;
        thisBreadTrail = null;
        thisTitle = null;
        navMobile.classList.add('innersteps');
    } else {
        thisStep = $(thisInner).closest(".dropdown-pane");
        thisBreadTrail = document.querySelector('#nav-mobile .nav1PanelInner.active .current-trail').textContent;
        thisTitle = document.querySelector('#nav-mobile .nav1PanelInner.active .current-title').textContent;
    }

    var thisStepTabbable = mobileCurrentTabbable();

    //active class switch
    $(thisInner).removeClass('active');
    $(thisStep).removeClass('is-active');
    var nextStep = nextTrigger.parentNode.querySelector('.dropdown-pane');
    var nextInner = nextStep.querySelector('.nav1PanelInner');
    nextInner.classList.add('active');
    if (thisTitle !== null) {
        var nextBreadTrail = document.querySelector('#nav-mobile .nav1PanelInner.active .current-trail');
        nextBreadTrail.textContent = thisBreadTrail + thisTitle + " /";
    }

    var nextStepTabbable = mobileCurrentTabbable();

    nextBackButton = nextInner.querySelector('.mobile-back');


    if (!nextTrigger.classList.contains('hover')) {

        for (var i = 0; i < thisStepTabbable.length; i++) {
            thisStepTabbable[i].setAttribute('tabindex', '-1');
        }
        for (var k = 0; k < nextStepTabbable.length; k++) {
            nextStepTabbable[k].setAttribute('tabindex', '0');
        }

        nextTrigger.classList.add('hover');
        nextTrigger.setAttribute('aria-expanded', 'true');
        nextStep.classList.add('is-open');
        nextStep.classList.add('is-active');
        nextStep.setAttribute('aria-hidden', 'false');

        //Without this delay, we cant send focus to back button because the dropdowns visibility is animated
        setTimeout(function () {
            nextBackButton.focus();
        }, 100);

    }


}

function navMobileBackClick(e) {
    e.preventDefault();
    e.stopPropagation();

    //Trigger from previous step which launched current step
    var thisBack = e.currentTarget;
    var thisInner = $(thisBack).closest(".nav1PanelInner");
    var thisStep = $(thisInner).closest(".dropdown-pane");

	var prevTrigger = $(thisStep).prev();
    var prevInner = $(prevTrigger).closest(".nav1PanelInner");
    var prevStep = $(prevInner).closest(".dropdown-pane");

    var thisStepTabbable = mobileCurrentTabbable();
    for (var i = 0; i < thisStepTabbable.length; i++) {
        thisStepTabbable[i].setAttribute('tabindex', '-1');
    }

    //must add after above collection of tabbable
	$(prevStep).addClass('is-active');
	$(prevInner).addClass('active');
    if ($(prevInner).attr('id') === 'mobile-step-1') {
        navMobile.classList.remove('innersteps');
    }

    $(thisStep).removeClass('is-active');
    $(thisInner).removeClass('active');        
    $(prevTrigger).removeClass('hover');
    $(prevTrigger).attr('aria-expanded', 'false');
    $(thisStep).removeClass('is-open');
    $(thisStep).attr('aria-hidden', 'true');

    var prevStepTabbable = mobileCurrentTabbable();
    for (var i = 0; i < prevStepTabbable.length; i++) {
        prevStepTabbable[i].setAttribute('tabindex', '0');
    }

    setTimeout(function () {
       $(prevTrigger).focus();
    }, 100);
    
}


function navMobileSamePageClick(e) {
    var thisItemsHref = e.currentTarget.href;
    var hashInHref = thisItemsHref.indexOf("#");
    if (hashInHref !== -1) {
        var newHrefStart = getCleanUrl(e.currentTarget.href);
        var oldHrefStart = getCleanUrl(window.location.href);

        //Are Old and New the same? If so, close the menu so you can visually see where you scroll to.
        if (oldHrefStart === newHrefStart) {
            closeMobileMenu();
            //Empty and refill mobile topbar path
            refreshMenuPaths();
        }
    }
}


function installMobileNavListeners() {
    if (isBaseTheme('pioneer')) {
        navMobile = document.getElementById('nav-mobile');
        mobileHamburger = document.getElementById('mobile-hamburger');
        mobileCloseButton = document.getElementById('mobile-close-button');
        closeOverlay = document.getElementById('js-click-close');
        mobileStep1 = document.getElementById('mobile-step-1');

        if( mobileHamburger != null ) {
          mobileHamburger.addEventListener('click', mobileHamburgerClick);
          mobileCloseButton.addEventListener('click', closeMobileMenu);
          closeOverlay.addEventListener('click', closeMobileMenu);
        }

        navMobileTopLinks = document.querySelectorAll('#nav-mobile .nav1-ul li.expanded > a');
        navMobileLeafLinks = $('#nav-mobile .nav1-ul li:not(:has(div ul))').children('a');
        navMobileBackButtons = document.querySelectorAll('#nav-mobile .mobile-back');


        navMobileLinks = $('#nav-mobile .nav1PanelInner a');
        navMobileTabbable = $('#mobile-close-button, #nav-mobile .nav1PanelInner a, #nav-mobile .nav1PanelInner button, #nav-mobile .nav1PanelInner input, #nav-mobile .nav1PanelInner select, #nav-mobile .nav1PanelInner textarea, #nav-mobile .nav1PanelInner iframe, #nav-mobile .nav1PanelInner [tabindex], #nav-mobile .nav1PanelInner area');

        navMobileAllDropdowns = document.querySelectorAll('#mobile-step-1 .dropdown-pane');
        navMobileAllStepInners = document.querySelectorAll('#nav-mobile .nav1PanelInner');


        //Prevents nav Mobile area from scrolling horizontally.
        //This is necessary because we often send focus to submenu items while they are transitioning into view.
        if( navMobile != null ) {
          navMobile.addEventListener('scroll', function (e) {
              if (e.target === navMobile) {
                  e.target.scrollTo(0, 0);
              }
          });
        }

        for (var i = 0; i < navMobileTopLinks.length; i++) {
            navMobileTopLinks[i].addEventListener('click', navMobileParentClick);
        }

        for (var i = 0; i < navMobileLeafLinks.length; i++) {
            navMobileLeafLinks[i].addEventListener('click', navMobileSamePageClick);
        }

        for (var i = 0; i < navMobileBackButtons.length; i++) {
            navMobileBackButtons[i].addEventListener('click', navMobileBackClick);
        }

//Accessibility ESC key
        document.addEventListener('keydown', function(e){
            if ( navMobile != null && navMobile.classList.contains('is-open') && e.keyCode == 27) {
                closeMobileMenu();
            }
        });


//On load, if the viewport window starts narrow, we quickly aria hide the active desktop left navigation so that the user cannot tab to the items. We also add a listener to aria show the active menu if the user widens the viewport window.
        if (window.innerWidth < 1024) {
            if( document.getElementById('site-wrapper') != null )
              document.getElementById('site-wrapper').classList.add('layout-transitions-off');
            ariaHideLeftNav();
            window.addEventListener('resize', widenFromMobile);
            //make sure Profile Popup is in mobile nav.
        }

    }//Closing if pioneer conditional
}//Closing installListeners function

window.addEventListener('DOMContentLoaded', installMobileNavListeners);
