
function detailedWorklist(container)
{

  this.dt;

  this._rawContainer = container;

  this._container = "#" + container;

  this.loaded;

  this.init = function( container ) {

    this.initTable();
    this.dt.onDraw = function() { 
      EventDispatcher.PackeryResize($(this._container)[0]); 
      if(!this.loaded){
        EventDispatcher.NodeLoadedForTheFirstTime($(this._container)[0]); 
      }
    }.bind(this);

    //draw() called in addWorklistItemsToTable()
    //this.dt.draw();

    this.getWorklistData( this.addWorklistItemsToTable );

  }    


  this.addWorklistItemsToTable = function ( data ) {

    for( var x = 0; x < data.Result.length; x++) {
      var row = data.Result[x];

      // more was used previously -- not anymore.
      // more = "<a href='" + row.sso_key + "?dest=" + row.more_details_dest + "' target='" + row.target + "'><i class='icon-search' aria-hidden='true'></i></a></a>";
      detail = "<a href='" + row.link + "' target='" + row.target + "'>" + row.Detail + "</a></a>";
      var stat = "";

      if( row.IsOverdue != undefined )
          stat =  "<p><i class=\"dripicons-warning fl-warning\"'></i> " + row.IsOverdue + "</p>";

      if( row.IsProxy != undefined )
          stat += '<p><i class="dripicons-user-group fl-info" aria-hidden="true"></i> You are a proxy for this item</p>';

      //this.dt.addRow([ detail, row.Label, row.StartDate, stat, more],'tiny-text worklist-row', 'hey');
      this.dt.addRow([ detail, row.Label, row.StartDate, stat],'tiny-text worklist-row', 'hey');

    }  
    this.dt.draw();

  }

  this.getWorklistData = function() {

    // there should be a slightly better way to handle this.
    // i should be able to pass in the function addWorklistItemsToTable
    // and have it called like that, but its a bit much...

    $.ajax({
      url: '/asyncloader/worklist',
      success: function( data ) {
        data.Result = data;
        this.addWorklistItemsToTable( data );

      }.bind(this)

    });  

  }

  this.initTable = function() {

    // only call once.

    $('#' + this._rawContainer + "-dt").remove();

    var table = document.createElement("div");
    table.setAttribute('id', this._rawContainer + "-dt");
    $( this._container ).append( table );

    this.dt = new divTable( this._rawContainer + "-dt");

    this.dt.onSearchFails = function() { $('#worklistNoResults').show(); }
    this.dt.onSearchSuccess = function() { $('#worklistNoResults').hide(); };
    this.dt.onEmptyDataset = function() { $('#worklistNoResults').show(); }
    this.dt.onNonEmptyDataset = function() { $('#worklistNoResults').hide(); }

    this.dt._searchContainer = 'worklist-search';


    //$(this.dt._searchContainer).remove();


    this.dt._searchable = true;
    this.dt._searchContainer = 'worklist-search';

    this.dt._pagination = true;




    // add the column headers
    this.dt.addHeader({
        text: "Workflow Name",
        classes: "cell small-auto medium-2 large-4", //  header-pad-left",
        sortColumn: 0
    });

    this.dt.addHeader({
        text: "Activity",
        classes: "cell small-auto medium-2 large-3 bordered",
        sortColumn: 2
    });

    this.dt.addHeader({
        text: "Created",
        classes: "cell small-auto medium-2 large-3 bordered",
        sortColumn: 3
    });
    this.dt.addHeader({
        text: "Note",
        classes: "cell small-auto medium-2 large-2 bordered",
        sortColumn: 1
    });
/*
    this.dt.addHeader({
        text: "Details",
        classes: "cell small-4 large-1 bordered",
        sortColumn: 4
    });
*/
    this.dt._paginationRowClasses = "checklist-pagination-row small-6";
         
    this.dt.addColumnDescriptors({classes: "cell checklist-item small-12 large-4 "});
    this.dt.addColumnDescriptors({classes: "cell checklist-item small-12 large-3 "});
    this.dt.addColumnDescriptors({classes: "cell checklist-item small-12 large-3 "});
    this.dt.addColumnDescriptors({classes: "cell checklist-item small-10 large-2 "});
    //this.dt.addColumnDescriptors({classes: "cell checklist-item small-2 large-1 "});

    this.dt._stripe = true;
  }


}

/*
var wl;

window.setTimeout( function() { 

  wl = new detailedWorklist('worklist');
  wl.init();
},2000);




</script>
<div class='grid-x'>
<div class='small-6' id='worklist-search'></div>
<div class='small-6'>
<a href='#' onclick='wl.dt.copy();' class='button hollow'>Copy</a>
<a href="#" onclick='wl.dt.print("My Worklist");' class='button hollow'>Print</a>
<a href='#' onclick='wl.init();' class='button hollow'>Refresh List</a>
</div></div>
<div id='worklist'></div>

  <?php include 'panel/footer.php'; ?>

*/
