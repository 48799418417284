//Note: also uses some functions from urltracing.js
var flippersExist;
var flippers;
var flipperStorage = [];

   function navResizeStpFlp(durationMS){
       var start = Date.now();

        function animationNavChange(){
            if(steppersExist) { stpResizeNested(); }
            if(!steppersExist && flippersExist) { flipperForceResize(); }
            var millis = Date.now() - start;
                if (millis > durationMS){
                    cancelAnimationFrame(animationNavChange);
                } else {
                    requestAnimationFrame(animationNavChange);
                }
        } 
        requestAnimationFrame(animationNavChange);
    }
    
function flipperSuspend(thisFlipper){
	thisFlipper.setAttribute('aria-live', '');
	thisFlipper.addEventListener('keydown', disableACTIONS, true);
    thisFlipper.addEventListener('click', disableACTIONS, true);
}
function flipperResume(thisFlipper){
	thisFlipper.setAttribute('aria-live', 'polite');
	thisFlipper.removeEventListener('keydown', disableACTIONS, true);
    thisFlipper.removeEventListener('click', disableACTIONS, true);
}

function flipDisableTabindex(el) {
    $( el ).attr( 'tabindex', '-1' );
    $( el ).attr( 'aria-hidden', 'true' );
    var notStepperEls = $( el ).find('a, button, input, select, textarea, iframe, [tabindex], area, svg').not($( el ).find('.stepper a, .stepper button, .stepper input, .stepper select, .stepper textarea, .stepper iframe, .stepper [tabindex], .stepper area, .stepper svg'));
    var stepperEls = $( el ).find('.step.current, .step.current a, .step.current button, .step.current input, .step.current select, .step.current textarea, .step.current iframe, .step.current [tabindex], .step.current area, .step.current svg');

    notStepperEls.each(function() {
        var $this = $(this);
        $this.attr( 'tabindex', '-1' );
    });
    stepperEls.each(function() {
        var $this = $(this);
        $this.attr( 'tabindex', '-1' );
    });
}

function flipEnableTabindex(el) {
    $( el ).attr( 'tabindex', '0' );
    $( el ).attr( 'aria-hidden', 'false' );
    var notStepperEls = $( el ).find('a, button, input, select, textarea, iframe, [tabindex]:not(svg), area').not($( el ).find('.stepper a, .stepper button, .stepper input, .stepper select, .stepper textarea, .stepper iframe, .stepper [tabindex], .stepper area'));
    var stepperEls = $( el ).find('.step.current, .step.current a, .step.current button, .step.current input, .step.current select, .step.current textarea, .step.current iframe, .step.current [tabindex]:not(svg), .step.current area');

    notStepperEls.each(function() {
        var $this = $(this);
        $this.attr( 'tabindex', '0' );
    });
    stepperEls.each(function() {
        var $this = $(this);
        $this.attr( 'tabindex', '0' );
    });

}

function btnFlipToBack(e){
	e.preventDefault();
	var thisFlipper = parentOfClass(e.currentTarget, 'flipper');
	var dataSwitch = e.currentTarget.getAttribute('data-swapcontent');
	var contentSwitch;
	if (dataSwitch) {
		for (var i = 0; i < flipperStorage.length; i++){
			if (flipperStorage[i].id === dataSwitch){
				contentSwitch = flipperStorage[i];
				break;
			}
		}
		if(!contentSwitch){
			try{
				contentSwitch = document.getElementById(dataSwitch);
				contentSwitch.style.display = 'block';
			} catch(error){}
		}
	}
	if (contentSwitch){
		flipToBack(thisFlipper, contentSwitch);
	} else{
		flipToBack(thisFlipper);
	}
	
}

function flipToBack(thisFlipper, contentSwitch) {
    var oldSide = thisFlipper.querySelector('.flipper-front');
    var newSide = thisFlipper.querySelector('.flipper-back');
    var step = newSide.querySelector('.stepper .step.current');

    if (thisFlipper.classList.contains('flipped')) {
      //Do nothing
    } else {
    	flipperSuspend(thisFlipper);
    	if(contentSwitch){
    		var elementsToRemove = newSide.querySelectorAll('.flipper-content > *');
    		for (var i = 0; i < elementsToRemove.length; i++){
    			flipperStorage[flipperStorage.length] = elementsToRemove[i];
    			elementsToRemove[i].parentNode.removeChild(elementsToRemove[i]);
    		}
    		var flipperContent = newSide.querySelector('.flipper-content');
    		flipperContent.appendChild(contentSwitch);
    		var flipToFrontBtns = flipperContent.querySelectorAll('.flip-to-front');
            for (var j = 0; j < flipToFrontBtns.length; j++) {
                flipToFrontBtns[j].addEventListener('click', btnFlipToFront);
            }
    	}
    	flipDisableTabindex(oldSide);
    	thisFlipper.classList.add('flipped');
        flipperForceResize(1500, thisFlipper);

        flipEnableTabindex(newSide);
        
        setTimeout(function () {
        	newSide.addEventListener('blur', tempFocus);
            if (step) {
            	step.setAttribute('tabindex', '-1');
            }
            flipperResume(thisFlipper);
            newSide.focus();
        }, 350);


    }
}

function btnFlipToFront(e){
	e.preventDefault();
	var thisFlipper = parentOfClass(e.currentTarget, 'flipper');
	var dataSwitch = e.currentTarget.getAttribute('data-swapcontent');
	var contentSwitch;
	if (dataSwitch) {
		for (var i = 0; i < flipperStorage.length; i++){
			if (flipperStorage[i].id === dataSwitch){
				contentSwitch = flipperStorage[i];
				break;
			}
		}
		if(!contentSwitch){
			try{
				contentSwitch = document.getElementById(dataSwitch);
				contentSwitch.style.display = 'block';
			} catch(error){}
		}
	}
	if (contentSwitch){
		flipToFront(thisFlipper, contentSwitch);
	} else{
		flipToFront(thisFlipper);
	}
	
}

function flipToFront(thisFlipper, contentSwitch) {
    var oldSide = thisFlipper.querySelector('.flipper-back');
    var newSide = thisFlipper.querySelector('.flipper-front');
    var step = newSide.querySelector('.stepper .step.current');

    if (thisFlipper.classList.contains('flipped')) {
    	flipperSuspend(thisFlipper);
    	if(contentSwitch){
    		var elementsToRemove = newSide.querySelectorAll('.flipper-content > *');
    		for (var i = 0; i < elementsToRemove.length; i++){
    			flipperStorage[flipperStorage.length] = elementsToRemove[i];
    			elementsToRemove[i].parentNode.removeChild(elementsToRemove[i]);
    		}
    		var flipperContent = newSide.querySelector('.flipper-content');
    		flipperContent.appendChild(contentSwitch);
    		var flipToBackBtns = contentSwitch.querySelectorAll('.flip-to-back');
            for (var j = 0; j < flipToBackBtns.length; j++) {
                flipToBackBtns[j].addEventListener('click', btnFlipToBack);
            }
    	}
        flipDisableTabindex(oldSide);
        thisFlipper.classList.remove('flipped');
        flipperForceResize(1500, thisFlipper);
        flipEnableTabindex(newSide);

        setTimeout(function () {
        	newSide.addEventListener('blur', tempFocus);
            if (step) {
            	step.setAttribute('tabindex', '-1');
            }
            flipperResume(thisFlipper);
            newSide.focus();
        }, 350);


    } else {

    }
}

//resizes flippers on window resize event only
function flipperResize(e) {

    if(e && e.detail && e.detail.flipperIgnore === 'true'){
        return;
    }

    //console.log(e);

    for (var i = 0; i < flippers.length; i++) {
        var activeSide;
        if (flippers[i].classList.contains('flipped')) {
            activeSide = flippers[i].querySelector('.flipper-back');
        } else {
            activeSide = flippers[i].querySelector('.flipper-front');
        }

        let startHeight = flippers[i].offsetHeight;
        let endHeight = activeSide.offsetHeight;
        let change = startHeight !== endHeight;

        if(change) {
          flippers[i].style.transition = "";
          flippers[i].style.height = activeSide.offsetHeight + 'px';
          EventDispatcher.PackeryResize(flippers[i]);
        }


        var flipperLoaded = (flippers[i].getAttribute('data-loaded')) ? true : false;
        if(!flipperLoaded) {
            flippers[i].setAttribute('data-loaded', 'true');
            EventDispatcher.Custom({
                "el" : flippers[i],
                "event" : "FlipperLoaded"
            });

            if(flippers[i].querySelector('[data-orbit]')){
                window.addEventListener('load', function(){
                    EventDispatcher.Custom({
                        "el" : window,
                        "event" : "resize"
                    }); 
                });
            }

        }

    }
}

function flipperForceResize(ms, singleFlipper) {
    let myFlippers = [];
    if (singleFlipper) {
        myFlippers.push(singleFlipper);
    } else {
        myFlippers = flippers;
    }

    var ms = ms ? Number(ms) : 0;
    var sec = Number(ms * .001);

    for (var i = 0; i < myFlippers.length; i++) {

        if(sec){
            myFlippers[i].style.transition = 'height ' + sec + 's ease';
        } else {
            myFlippers[i].style.transition = '';
        }

        var activeSide;
        if (myFlippers[i].classList.contains('flipped')) {
            activeSide = myFlippers[i].querySelector('.flipper-back');
        } else {
            activeSide = myFlippers[i].querySelector('.flipper-front');
        }

        let startHeight = myFlippers[i].offsetHeight;
        let endHeight = activeSide.offsetHeight;
        let change = startHeight !== endHeight;
        //console.log('change: ' + change);

        if(change) {
          myFlippers[i].style.height = activeSide.offsetHeight + 'px';

          //Closure needed to pass in accurate index
          (function(index){
              setTimeout(function(){
                //console.log(sec);
                //console.log(index);
                EventDispatcher.PackeryResize(myFlippers[index]);
              }.bind(this), ms);
          })(i);
          
        }
    }//end loop
}


function installFlipper() {

    flippersExist = document.querySelector('.flipper');

    if (flippersExist) {
        //console.log('Flippers!');
        flippers = document.querySelectorAll('.flipper');
        var flipperStoreMe = document.querySelectorAll('[data-flipperstorage]');
        for (var i = 0; i < flipperStoreMe.length; i++) {
        	flipperStoreMe[i].removeAttribute('data-flipperstorage');
        	flipperStorage[flipperStorage.length] = flipperStoreMe[i];
        	flipperStoreMe[i].parentNode.removeChild(flipperStoreMe[i]);
        }
        for (var i = 0; i < flippers.length; i++) {
            flippers[i].addEventListener('scroll', function (e) {
                if (e.target.classList.contains('flipper') || e.target.classList.contains('flipper-object') || e.target.classList.contains('flipper-front') || e.target.classList.contains('flipper-back')) {
                    e.target.scrollTo(0, 0);
                }
            });
            $(flippers[i]).closest("article").attr("data-flipper", [i]);
            var thisArticle = document.querySelector("[data-flipper = '" + [i] + "']");
            //var thisArticlePin = thisArticle.querySelector('.button-pin');
            var thisSideA = thisArticle.querySelector('.flipper-front .flipper-content');
            //thisSideA.insertBefore(thisArticlePin, thisSideA.firstChild);

            var flipToBackBtns = flippers[i].querySelectorAll('.flip-to-back');
            for (var j = 0; j < flipToBackBtns.length; j++) {
                flipToBackBtns[j].addEventListener('click', btnFlipToBack);
            }
            var flipToFrontBtns = flippers[i].querySelectorAll('.flip-to-front');
            for (var j = 0; j < flipToFrontBtns.length; j++) {
                flipToFrontBtns[j].addEventListener('click', btnFlipToFront);
            }

            if($(flippers[i]).hasClass('flipped')){
                flipDisableTabindex($(flippers[i]).find(".flipper-front"));
            } else {
                flipDisableTabindex($(flippers[i]).find(".flipper-back"));
            }
        }
        flipperResize();
        window.addEventListener('resize', flipperResize);
        
    } else {
        //console.log('NO Flippers...');
    }

}//Ending Install Flipper

document.addEventListener('DOMContentLoaded', installFlipper);
