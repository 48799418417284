function divTable(id) {

    // this is the name of the container that will hold
    // our table.  Should be the id of a div but I suppose
    // a span or other elements would also work.

    this._id = id;


    // holds an array of objects, with the column data
    // and a tag / class associated with each row.
    // we never actually use tags here -- but its available
    // to custom filters.

    this._dataset = new Array();


    // used to define additional styles to apply
    // to a particular column.  An array of objects,
    // the order of this array corresponding to the
    // order of the columns.  Right now, it really only
    // contains a list of objects with a class property.
    // Note that this only gets applied to the actual data
    // and not the headers.  There's a different function
    // for that.


    this._columnDescriptors = new Array();

    // array of objects, populated by addColumnDescriptors( string_of_class_names );


    this._filteredRecordCount = 0;

    this._headers = new Array();

    // array of objects, holds column title and classes


    /* this us no longer necessary 
    this._myName = "";

    // this is important, at least for now.  We need to know our own name!
    // what were we instantiated as?  Thats because, until I can figure out
    // a better way, to refer to ourselves when we generate buttons for
    // pagination, sorting, etc.
    */

    this._pagination = true;

    // if you set _pageSize to a non-standard size, you should
    // also update _pageSizeSteps below.
    this._pageSize = 10;
    this._pageSizeSteps = [10,25,50,100];
    this._page = 0;
    this._paginationRowClasses = "small-5 medium-5 large-5";
    this._paginationRowClasses = "cell shrink";
    this._onPagination;

    this._pageSizeRowClasses = "small-5 medium-5 large-5 divtable-pageSize-container";
    this._pageSizeRowClasses = "cell shrink";

    this._stripe = false;


    this._copiedText = "Copied to clipboard.";

    this._entriesClasses = "small-2 medium-2 large-2";
    this._entriesClasses = "cell auto";
    this._entriesPrefix = "Showing"; // defaults to Showing x of y entries.";
    this._entriesPostfix = "total entries.";
    this._entriesSeparator = "of";
    this._entriesThrough = "through";
    this._search = "";
    // internal use. Makes it easy to refer to the search string

    this._initialized = 0;
    // that is, have we drawn the table before?  Subsequent draws
    // don't redraw the headers and such.  Maybe we need a drawAll
    // function in the future....

    this._tagFilter = null;
    // ok this is a reference to a function.  We call this function
    // and pass each row, which is expected to return back true
    // or false. If its null, it just returns back true....


    // internal use for sorting
    // this._sortLastColumnSorted;                // i think these can be combined.. 
    this._sortLastColumnSorted = -1; // I think these can be combined.. 
    this._sortDirection = 0;         // sort up / down

    // we default to Font Awesome icons, but these may easily be overridden
    this._sortAscText = '<i class="icon-sortasc" aria-hidden="true"></i>'
    this._sortDescText = '<i class="icon-sortdesc" aria-hidden="true"></i>';
    this._sortUnsortedText = '<i class="icon-sort" aria-hidden="true"></i>';

    this._pageLeftText = 'Previous';
    this._pageRightText = 'Next';

    // semi-internal -- if you like you can add one or more
    // classes to be applied to the search row.


    this._searchable = false;
    this._searchContainer = null;
    this._searchRowClasses = "";
    this._searchPlaceholder = "Search...";


    this.onSearchFails;
    this.onSearchSuccess;
    this.onPreDraw;
    this.onDraw;

    // Function definitions below:

    this.addHeader = function (descriptor) {
        // when we create a new table, we want to add a header to it
        // so we can see the column name, and append different classes
        // to it.  Classes is just a text string of classes e.g. "red underlined"
        // not an array of classes.

        this._headers.push(descriptor);
    }

    this.addRow = function (columns, classes, tags) {
        // this adds one row to the table.
        // * columns is an array of all the columns for this row
        // * classes are classes that get applied to the entire
        //   row
        // * tags is intended to hold an array of things
        //   that we can use to further filter, but not necessarily
        //   display (array of objects, or whatever...  will explain) 

        var c = new Object();

        c.data = columns;
        c.classes = classes;
        c.tags = tags;

        this._dataset.push(c);

    }


    this.addColumnDescriptors = function (descriptors) {
      console.log("info: replace references to divTables.addColumnDescriptors to addColumnDescriptor");
      this.addColumnDescriptor( descriptors );
    }

    this.addColumnDescriptor = function (descriptors) {
        // Each column might have different classes.
        // for example, we want the table data to wrap,
        // but hide the header. You can add one column descriptor,
        // or an array of classes.
        //
        // this takes an array of objects, which at this time
        // only really cares if there is an element called 'classes' 
        //
        // recent improvement: if you just supply an empty object,
        // or rather one without a 'classes' attribute, it sets it
        // to an empty string.
 
        if( Array.isArray( descriptors) ) {
            this._columnDescriptors = descriptors;
            for( var x = 0; x < this._columnDescriptors; x++) {
              if( this._columnDescriptors[x].classes == undefined )
                this._columnDescriptors[x].classes = "";
            }
        }
        else {
          if( descriptors.classes == undefined )
            descriptors.classes = "";
          this._columnDescriptors.push(descriptors);
        }
    }

    this.print = function(title) {

      var w = window.open();

      if( title != undefined )
        w.document.write( "<h2>" + title + "</h2>");

      w.document.write("<table border=1 cellpadding=5>\n");
      w.document.write("<tr>\n");
      for( var x = 0; x < this._headers.length; x++)
      {
        if( this._headers[x].printable == undefined || this._headers[x].printable == true ) 
           w.document.write("<td><b>" + this._headers[x].text + "</b></td>" );
      }
      w.document.write("</tr>");

      var filtered = new Array();

      // for each row, see if it passes the filter.
      // searchPassFail really just calls

      
      for (var x = 0; x < this._dataset.length; x++) {
          if (this.searchPassFail(this._dataset[x].data)) {
              // relies on short circuiting in case no tagFilter was specificed
              if (this._tagFilter == null || this._tagFilter(this._dataset[x].tags, x == 0))
                  filtered.push(this._dataset[x]);
          }

      }

      if (this._sortLastColumnSorted != null) {
          var temp;
          var sorted = true;

          do {

              sorted = true;

              for (var x = 0; x < filtered.length - 1; x++) {

                  if (this._sortDirection == 0 && filtered[x].data[this._sortLastColumnSorted] > filtered[x + 1].data[this._sortLastColumnSorted]) {
                      temp = filtered[x];
                      filtered[x] = filtered[x + 1];
                      filtered[x + 1] = temp;
                      sorted = false;
                  }

                  if (this._sortDirection == 1 && filtered[x].data[this._sortLastColumnSorted] < filtered[x + 1].data[this._sortLastColumnSorted]) {
                      temp = filtered[x];
                      filtered[x] = filtered[x + 1];
                      filtered[x + 1] = temp;
                      sorted = false;
                  }

              }
          } while (sorted == false);

      }


      for( x = 0; x < filtered.length; x++) {
        w.document.write("<tr>");

        for( y = 0; y < filtered[x].data.length; y++) {
          if( this._columnDescriptors[y].printable == undefined || this._columnDescriptors[y].printable == true ) 
            w.document.write("<td>" + filtered[x].data[y] + "</td>");
        }
        w.document.write("</tr>");

      }
      w.document.write("</table>");
      w.document.close();

      w.print();
      w.close();
    }

    this.copy = function( stripHTML ) {
      var headers = "";
 
      headers = this._headers.filter( function(el, i) {
          return( this._headers[i].printable == undefined || this._headers[i].printable == true ); 
        }.bind(this) );
      for( var x = 0; x < headers.length; x++)
        headers[x] = headers[x].text;

      headers = headers.join(',');

      headers = headers.replace(/<[^>]*>/g,"");
 

      var filtered = new Array();

      // for each row, see if it passes the filter.
      // searchPassFail really just calls
      for (var x = 0; x < this._dataset.length; x++) {
          if (this.searchPassFail(this._dataset[x].data)) {
              // relies on short circuiting in case no tagFilter was specificed
              if (this._tagFilter == null || this._tagFilter(this._dataset[x].tags, x == 0))
                  filtered.push(this._dataset[x]);
          }

      }

      if (this._sortLastColumnSorted != null) {
          var temp;

          var sorted = true;

          do {

              sorted = true;

              for (var x = 0; x < filtered.length - 1; x++) {

                  if (this._sortDirection == 0 && filtered[x].data[this._sortLastColumnSorted] > filtered[x + 1].data[this._sortLastColumnSorted]) {
                      temp = filtered[x];
                      filtered[x] = filtered[x + 1];
                      filtered[x + 1] = temp;
                      sorted = false;
                  }

                  if (this._sortDirection == 1 && filtered[x].data[this._sortLastColumnSorted] < filtered[x + 1].data[this._sortLastColumnSorted]) {
                      temp = filtered[x];
                      filtered[x] = filtered[x + 1];
                      filtered[x + 1] = temp;
                      sorted = false;
                  }

              }
          } while (sorted == false);

      }
     
      var body = "";
      for( x = 0; x < filtered.length; x++) {
          body += filtered[x].data.filter( 
            function( el, i) {
    return( this._columnDescriptors[i].printable == undefined || this._columnDescriptors[i].printable == true );
       
              // return( this._headers[i].printable == undefined || this._headers[i].printable == true ); 
            }.bind(this)

          ).join(",") + "\n";
      }


      if( stripHTML != false ) 
        body = body.replace( /<[^>]*>/g, '');

      el = document.createElement('textarea');
      el.value = headers + "\n" + body;
      el.style.height=1;
      el.style.width=1;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      alert( this._copiedText );
    }

    this.draw = function () {
        // Typically called when the page is first loaded,
        // after all the data has been added to the table.
        //
        // Also called after a search or filter type of op
        // is applied to the data.


        // clear out the rows container
        //$('#' + this._id + '_rows').html("");
        // ^^^ DO NOT DO THIS.  Unexpected behavior in IE

        $('#' + this._id + '_rows').empty();

        // only do these things on the first draw

        if (this._initialized == 0) {

          if( this._searchContainer != null && this._searchContainer.indexOf("#") > -1 ) {
            this._searchContainer = this._searchContainer.replace("#", "");
          }
 
          for (var x = 0; x < this._headers.length; x++) {
            if( this._headers[x].defaultSort == true )
            {
              if( this._headers[x].sortColumn != null )
                this._sortLastColumnSorted = this._headers[x].sortColumn;
              else
                this._sortLastColumnSorted = x;


              if( this._headers[x].sortDirection != null && this._headers[x].sortDirection.toLowerCase() == 'desc' )
                this._sortDirection = 0;
              else
                this._sortDirection = 1;

              if( this._headers[x].sortColumn != null )
                this.sort( this._headers[x].sortColumn, true );
              else
                this.sort( x,true  );
            }
          }

            this.appendSearch();
            this.appendHeaderRow();
            this.appendRow();
            // this just adds the div that holds the other divs

            if( this.onPreDraw != null) 
              this.onPreDraw();

            this.updateRows();

            this._initialized = 1;
        }
        else {

            if( this.onPreDraw != null) 
              this.onPreDraw();
            // show the actual rows...       
            this.updateRows();
        }

        this.appendPagination();
        if (this.onDraw != null) this.onDraw();
    }

    this.sort = function (col, skipDrawPhase) {

        // if we have already sorted on this column, we need to flip
        // the direction (asc to desc, or desc to asc)


        if (this._sortLastColumnSorted == col) {
            if (this._sortDirection == 0)
                this._sortDirection = 1;
            else
                this._sortDirection = 0;
        }
        else {
            // else, since its either first sort or we are sorting
            // a different column...
            this._sortDirection = 0;
            this._sortLastColumnSorted = col;
        }


        // for each header, set the icon to unsorted. Technically, it should
        // be every column except 'col' since we're setting it right afterwards..

        for (var x = 0; x < this._headers.length; x++) {
            $('#_' + this._id + '_sort-column-' + x).html(this._sortUnsortedText);
            $('#_' + this._id + '_sort-column-' + x).attr('aria-label', "unsorted column");
        }


        // change the marker for the selected column to desc / asc

        if (this._sortDirection == 0) {

            $('#_' + this._id + '_sort-column-' + (col)).html(this._sortDescText);
            $('#_' + this._id + '_sort-column-' + (col)).attr('aria-label', "sorted (descending)");
        }
        else {
            $('#_' + this._id + '_sort-column-' + (col)).html(this._sortAscText);
            $('#_' + this._id + '_sort-column-' + (col)).attr('aria-label', "sorted (ascending)");
          
        }
        // after sorting, re-draw.
    
        if( skipDrawPhase != true )
          this.draw();


    }

    this.appendRow = function () {
        // again, for clarification, this simply appends the row that
        // contains all the other rows.  This really doesn't do much!

        var row = document.createElement("div");
        row.id = this._id + "_rows";
        row.className = 'cell';
        $("#" + this._id).append(row);
    }

    this.appendHeaderRow = function () {
        // create a container to hold the individual column headers.
        var row = document.createElement("div");
        row.className = "grid-x divTable-header v2-area";

        for (var x = 0; x < this._headers.length; x++) {

            //   if( this._headers[x].sortable == false )


            var childdiv = document.createElement("div");
         
            if( this._headers[x].sortable ) 
              childdiv.setAttribute('tabindex', '0');

            if (this._headers[x].text == "") {
                childdiv.innerHTML = "";
            }
            else {

                var mySortColumn;

                if (this._headers[x].sortColumn != null)
                    mySortColumn = this._headers[x].sortColumn;
                else
                    mySortColumn = x;


                if( typeof this._headers[x].text  == "string" )
                  childdiv.innerHTML += this._headers[x].text;
                else {
                  childdiv.appendChild( this._headers[x].text );
                }


                if (this._headers[x].sortable != false)
                    childdiv.innerHTML += "<span id='_" + this._id + "_sort-column-" + mySortColumn + "' aria-label='unsorted column' class='divTable-header-sort-toggle'>" + this._sortUnsortedText + "</i></span>";

            }
            // see, i really hate doing this. We're appending the actual function name into the element.
            // so, when its clicked, it is exposed to the onclick event so we can call sort.

            // no no no childdiv.dataMyName = this._myName;

            // remember how we could add a header description?  They get injected here.
            childdiv.className = this._headers[x].classes + " divTable-header-row";

            // also, we want to know what column number we're sorting on.  Could I use
            // another method?  Like find what # child I am?  Sure...  but this is pretty
            // sure-fire...

            // if a sortColumn was specified, use this instead of x.  This lets us have any
            // column header sort by any other column (column 3 could sort by column 2) 
            // I did this because you might have one column header span two or more columns
            // of data

            childdiv.setAttribute('data-column-number', mySortColumn);
            // and here's that onclick event I was refering to -- make it so, when a column is clicked,
            // it calls this object and sorts by this column number.


            if( this._headers[x].cellEventHandler != null )
            {
              childdiv.addEventListener( this._headers[x].cellEventHandler.eventType, this._headers[x].cellEventHandler.eventHandler );
            }



            if (this._headers[x].sortable != false)
            {

              // so this is important stuff here. When the button is clicked, it needs to interact
              // with this object. However, it isn't in the scope of this object. How do we resolve
              // this?
              //
              // With two click events.
              //
              // the first one adds an 'active-clicked' class to itself.
              // the second one is in the scope of this object (thanks to 'this') and
              // finds the button that was clicked, grabs the column number, and sorts.

//              childdiv.addEventListener("click", function(){ this.className += " active-clicked"; } );
/*
              childdiv.addEventListener("click", function(){ 
                  if( $('.active-clicked').length == 1 ) {
                    this.sort( $('.active-clicked').data('column-number') );
                    $('.active-clicked').removeClass('active-clicked');
                  }
              }.bind(this) ); 
*/

              childdiv.addEventListener("click", function(e){ 
                this.sort( e.currentTarget.dataset.columnNumber );
              }.bind(this) ); 

              childdiv.addEventListener("keyup", function(e){
                if( e.keyCode == 13) 
                  this.sort( e.currentTarget.dataset.columnNumber );
              }.bind(this) ); 

            }

            row.appendChild(childdiv);

        }

        $("#" + this._id).append(row);

        // this could be a bit more efficient if this was moved further up when we build the column
        // the first time.
        if( this._sortLastColumnSorted != null ) {
          if (this._sortDirection == 0) 
              $('#_' + this._id + '_sort-column-' + (this._sortLastColumnSorted)).html(this._sortDescText);
          else
              $('#_' + this._id + '_sort-column-' + (this._sortLastColumnSorted)).html(this._sortAscText);
        }
    }

    this.updateRows = function () {
        // So this is kind of fun.  We have our master data in dataset,
        // and filter out everyting we want to see by copying into the
        // 'filtered' array, below.

        var filtered = new Array();

        if( this._dataset.length == 0 ) {
          if( typeof (this.onEmptyDataset ) === "function" ) {
            this.onEmptyDataset();
          }
        }
        else
        {
          if( typeof (this.onNonEmptyDataset ) === "function" ) {
            this.onNonEmptyDataset();
          }
        }
    


        // for each row, see if it passes the filter.
        // searchPassFail really just calls
        for (var x = 0; x < this._dataset.length; x++) {
            if (this.searchPassFail(this._dataset[x].data)) {
                // relies on short circuiting in case no tagFilter was specificed
                if (this._tagFilter == null || this._tagFilter(this._dataset[x].tags, x == 0))
                    filtered.push(this._dataset[x]);
            }

        }

        if (this._sortLastColumnSorted != null) {
            var temp;

            var sorted = true;

            do {

                sorted = true;

                for (var x = 0; x < filtered.length - 1; x++) {

                    if (this._sortDirection == 0 && filtered[x].data[this._sortLastColumnSorted] > filtered[x + 1].data[this._sortLastColumnSorted]) {
                        temp = filtered[x];
                        filtered[x] = filtered[x + 1];
                        filtered[x + 1] = temp;
                        sorted = false;
                    }

                    if (this._sortDirection == 1 && filtered[x].data[this._sortLastColumnSorted] < filtered[x + 1].data[this._sortLastColumnSorted]) {
                        temp = filtered[x];
                        filtered[x] = filtered[x + 1];
                        filtered[x + 1] = temp;
                        sorted = false;
                    }

                }
            } while (sorted == false);

        }


        if (filtered.length == 0 && this.onSearchFails != null)
            this.onSearchFails();
        if (filtered.length != 0 && this.onSearchSuccess != null)
            this.onSearchSuccess();

        startindex = this._page * this._pageSize;
        endindex = (this._page + 1) * this._pageSize;
        if (this._pagination == false) {
            startindex = 0;
            endindex = filtered.length;
        }

        for (var x = startindex; x < filtered.length && x < endindex; x++) {

            var row = document.createElement("div");
            for (y = 0; y < filtered[x].data.length; y++) {
                row.className = "grid-x divTable-table-row " + filtered[x].classes;

                /*if( this._rowEventHandler != null ) {
                  row.addEventListener( this._rowEventHandler.eventType, this._rowEventHandler.eventHandler );
                }*/

                if( this._stripe == true ) {
                  if( (x % 2) == 1)
                    row.className += " divtable-table-row-odd ";
                  else
                    row.className += " divtable-table-row-even";
                }
                var subrow = document.createElement("div");
                subrow.className = this._columnDescriptors[y].classes + " columns";
              
                if( this._columnDescriptors[y].cellEventHandler != null )
                {
                  subrow.addEventListener( this._columnDescriptors[y].cellEventHandler.eventType, this._columnDescriptors[y].cellEventHandler.eventHandler );
                }  
                if( typeof filtered[x].data[y] == "string" ) {
                  subrow.innerHTML += filtered[x].data[y];
                }
                else {
                  subrow.appendChild( filtered[x].data[y] );
                }
                row.appendChild( subrow); 

         //       row.innerHTML += "<div class='" + this._columnDescriptors[y].classes + " columns'>" + filtered[x].data[y] + "</div>";
            }
            $("#" + this._id).append(row);
            $('#' + this._id + "_rows").append(row);
        }
        this._filteredRecordCount = filtered.length;

    }





    this.searchPassFail = function (line) {
        if (this._search == "")
            return true;

        for (var x = 0; x < line.length; x++) {

            if( typeof line[x] != "string" )
                continue;
 
            if (line[x].toLowerCase().replace(/<[^>]*>/g).indexOf(this._search.toLowerCase()) > -1) {
                return true;
            }
        }
        return false;

    }

    this.pageBack = function () {
        if (this._page <= 0)
            return;

        $("#" + this._id + "_rows").html("");
        this._page--;
        this.draw();

        if( typeof this._onPagination === "function" )
          this._onPagination();  
    }

    this.pageForward = function () {

        if ((this._page + 1) * this._pageSize > this._dataset.length)
            return;
        $("#" + this._id + "_rows").html("");
        this._page++;
        this.draw();

        if( typeof this._onPagination === "function" )
          this._onPagination();  

    }

    this.gotoPage = function( p, doNotDraw ) {
      
        // if we are on the current page, don't do anything. 
        if( this._page == p )
          return;
 
        /*if ((this._page + 1) * this._pageSize > this._dataset.length)
            return; */
        // could probably add some error checking here.
        $("#" + this._id + "_rows").html("");

        this._page = p;

        if( doNotDraw != true )
          this.draw();

        if( typeof this._onPagination === "function" )
          this._onPagination();  
    }

    this.appendSearch = function () {
        if( $('#' + this._id + "_searchbox").length > 0)
          return;

        if (this._searchable != true)
            return;
        var row= document.createElement("div");
        row.id = this._id + "_divTable-search-container";


        var searchContainer = document.createElement("div");
        searchContainer.className = "divTable-search-container";

        var searchRow = document.createElement("div");
        searchRow.className = this._searchRowClasses;

        var searchBox = document.createElement("input");
        searchBox.type = "text";
        searchBox.className = "divTable-searchbox";
        searchBox.setAttribute('placeholder', this._searchPlaceholder);
        searchBox.setAttribute('id', this._id + "_searchbox");

        searchBox.onkeyup = function() { this.search(); }.bind(this);

        searchRow.appendChild( searchBox );
        searchContainer.appendChild( searchRow);
        row.appendChild(searchContainer);

        if (this._searchContainer != null) 
            $('#' + this._searchContainer).append(row);
        else 
            $("#" + this._id).append(row);

    }

    // used to show the total number of records in the table.
    this.appendSummary = function () {

      var summary= document.createElement("div");
      summary.className = 'divtable-summary-row summary-row-' + this._id + " " + this._entriesClasses;
      // if there are no records, we typically show "Showing 0 of 0 entries."
      // although that may be overwritten.

      if( this._filteredRecordCount == 0 ) { 
        summary.innerHTML = this._entriesPrefix + " 0 " + this._entriesSeparator + " 0 " + this._entriesPostfix;
      }
      else {

        // if it is, say, showing 10 through 20, we need to check
        // in case there is only, say, 19 entries

        // assume its a fullset (such as 10-20)
        var lastRecord = ( (this._page + 1 ) * this._pageSize );

        // but if there is really 19, make it 19.
        if( lastRecord > this._filteredRecordCount ) 
          lastRecord = this._filteredRecordCount;

        summary.innerHTML = this._entriesPrefix  + " "  +
          (this._page * this._pageSize + 1) + " " + this._entriesThrough + " " + lastRecord  + " " 
          + this._entriesSeparator + " " + this._filteredRecordCount + this._entriesPostfix;  
      }
      return( summary );

    }

    this.appendPagination = function () {
      if( this._filteredRecordCount == 0 )
      {
        if( $('#pagination-row-' + this._id).length > 0 ) {
          $('#pagination-row-' + this._id).parent().remove();
          $('.checklist-pagination-row-' + this._id).remove();
        }
        return;
      }

      // helper for generating the clickable page numbers.
      var createPaginationNumber = function(page, currentpage ) {
      
        // create container for the page number
        var pagecount = document.createElement('div');
        pagecount.className = "divtable-pageselector";

        // if the number we are generating is for our current page,
        // we need to add an additional class to it.
        if( currentpage == page ) {
          pagecount.className += " divtable-pageselector-selected";
          pagecount.innerHTML =  (page + 1); // +1 since starts at 0; 
        }
        else {

          // if its not the current page, make it an anchor tag instead.

          var p = document.createElement("a");
          p.setAttribute('tabindex', 0);
          p.innerHTML = page + 1;
          // p.href = "#"; 

          // add two events, one to add the class name so we can identify what
          // page was clicked
          p.addEventListener('click', function() { this.className += " divtable-clickedpage"; } );
          p.addEventListener('keyup', function(e) { if(e.keyCode == 13)
            this.gotoPage( parseInt( e.currentTarget.getAttribute('divtable-page-number') ) );  
          }.bind(this) );

          // and one to then goto that page.
          p.addEventListener('click', function() {
            var pn =  $('.divtable-clickedpage').attr('divtable-page-number'); 
            $(".clickedpage").removeClass("divtable-clickedpage");  
            this.gotoPage( parseInt(pn) );
            return false;     
          }.bind(this) );

          p.setAttribute('divtable-page-number', page );

          // make the number's font smaller when they get bigger.
          if( page > 9 ) p.style.fontSize = '90%';
          if( page > 99 ) p.style.fontSize = '80%';
          if( page > 999 ) p.style.fontSize = '70%';

          pagecount.appendChild(p); 

        }
        return pagecount;
      }.bind(this); 


      // we need a grid to contain both the pagination
      // and the summary of the table's data.

      // not really thrilled with this, since we use this
      // function to create the pagination AND the summary
      // but whatever. 
      var parentDiv = document.createElement("div");
      parentDiv.className = "grid-x pagination-parent";
      parentDiv.appendChild( this.appendSummary() );

      var newdiv = document.createElement("div");
      newdiv.id = 'pagination-row-' + this._id;
      newdiv.className = 'cell divTable-pagination-container ' + this._paginationRowClasses;



      // Create the back button, but only make it an anchor if
      // we are on page 2 or greater.
      var backControl;

/*
      if( this._page == 0 )
        backControl = document.createElement("span");
      else {
        backControl = document.createElement("a");
        backControl.onclick = function(e) { this.pageBack(); return false; }.bind(this);
      }

      backControl.innerHTML = this._pageLeftText;
      backControl.className = "divtable-pageback";
      newdiv.appendChild(backControl);
*/

      backControl = document.createElement("a");
      backControl.setAttribute('ariaLabel', 'previous page');
      if( this._page != 0 ) { 
        backControl.setAttribute('tabindex', 0);
        backControl.addEventListener('click', function(e) { this.pageBack(); return false; }.bind(this));
        backControl.addEventListener('keyup', function(e) { if( e.keyCode == 13)  this.pageBack(); return false; }.bind(this));

      }
      else
        backControl.onclick = function(e) { e.currentTarget.blur(); return false; }.bind(this);

      backControl.innerHTML = this._pageLeftText;
      backControl.className = "divtable-pageback";
      newdiv.appendChild(backControl);



      newdiv.appendChild(createPaginationNumber(0, this._page));

      // since the pages are, as far as we are concerned,
      // 0 1 2 3 4 5, and we want to show two pages +/-,
      // we want the spacer when we are on page 4 or greater
      // so it would then read 0 ... 2 3 [4] (obvbiously it
      // will read 1...3 4 [5] though.

      if( this._page > 3 ) {
        var spacer = document.createElement("span");
        spacer.innerHTML = "...";
        newdiv.appendChild(spacer);
      }

      // so generate the range +/- 2 pages from the current page.
      for( var x = this._page - 2; x <= this._page + 2; x++) { 
        // but there is a catch. Make sure its greater than 0 (first page is always generated)
        // and it must be less than the total page size -1 (since we will always handle the last
        // page too)
        if( x  > 0  && x < Math.ceil( this._filteredRecordCount / this._pageSize ) - 1 ) {
            newdiv.appendChild( createPaginationNumber(x, this._page) );
        }
      } 


      // likewise, if there is a gap between our range of 5 pages, and the last page,
      // add in the spacer.
      if( this._page +4 < Math.ceil(this._filteredRecordCount / this._pageSize) ) {

        var spacer = document.createElement("span");
        spacer.innerHTML = "...";
        newdiv.appendChild(spacer);

      }


      // ok, now as long as there are at least two pages, we add the last page number      
      if( this._filteredRecordCount / this._pageSize  > 1 )
        newdiv.appendChild( createPaginationNumber( Math.ceil(this._filteredRecordCount / this._pageSize) - 1, this._page ) );

/*
      // now add the 'next' button.
      if( (this._page + 1) * this._pageSize < this._filteredRecordCount) {
        var fwdControl = document.createElement("a");
        fwdControl.onclick = function(e) { this.pageForward(); return false; }.bind(this); 
        fwdControl.href = "#";
        fwdControl.innerHTML = this._pageRightText;
      }
      else {
        // but make it a span if we're at the last page.
        var fwdControl = document.createElement("span");
        fwdControl.innerHTML = this._pageRightText;
      }
*/

        var fwdControl = document.createElement("a");
        if( (this._page + 1) * this._pageSize < this._filteredRecordCount) {
          fwdControl.setAttribute('tabindex', 0);
          fwdControl.addEventListener('click', function(e) { this.pageForward(); return false; }.bind(this) );
          fwdControl.addEventListener('keyup', function(e) { if( e.keyCode == 13)  this.pageForward(); return false; }.bind(this) );
        }else {
          //fwdControl.onclick = function(e) { e.currentTarget.blur();return false; };
        } 
        fwdControl.innerHTML = this._pageRightText;



      fwdControl.className = "divtable-pageforward";
      fwdControl.setAttribute('ariaLabel', 'next page');
      newdiv.appendChild( fwdControl );


      var rowsperpage = document.createElement("select");
      rowsperpage.className = "divtable-rowsPerPage-select";

      rowsperpage.addEventListener('change', function(e) {

        var pageSize = e.currentTarget.options[ e.currentTarget.selectedIndex ].value;

        this._pageSize = pageSize;
        this._page = 0;

        this.draw();

      }.bind(this) );

      //rowsperpage.style.width = "70px";
      var showNext = false;
      for( var x = 0; x < this._pageSizeSteps.length; x++ )
      {
        var op1 = document.createElement("option");
        op1.innerHTML = this._pageSizeSteps[x];
        op1.value = this._pageSizeSteps[x];

        if( op1.value == this._pageSize )
          op1.setAttribute('selected', true);

        rowsperpage.appendChild(op1);
      }

      var pageSizeDiv = document.createElement("div");
      pageSizeDiv.className = this._pageSizeRowClasses;
 
      if( this._pageSizeSteps[0] < this._dataset.length ) {
        var preText = document.createElement("span");
        preText.innerHTML = "per page:";
        preText.style.fontSize = "90%";
        var postText = document.createElement("span");
        postText.innerHTML = "";

        pageSizeDiv.appendChild( preText );
        pageSizeDiv.appendChild( rowsperpage );
        pageSizeDiv.appendChild( postText );

      }

      // ok, if it already exists, fade it out, wait for it to
      // fade, remove it, and fade it in.

      if( $('#pagination-row-' + this._id).length > 0 ) {
        $('#pagination-row-' + this._id).fadeOut(250, function() {
          $('#pagination-row-' + this._id).parent().remove();
          $('.checklist-pagination-row-' + this._id).remove();
          newdiv.style.display = 'none';
          //parentDiv.appendChild(newdiv);
          parentDiv.insertBefore( newdiv, parentDiv.children[1] );
          $("#" + this._id).append(parentDiv);
        
          $('#pagination-row-' + this._id).fadeIn(200);
        }.bind(this));
      }
      else {
        // otherwise we're loading for the first time.
        parentDiv.appendChild(newdiv);
        $("#" + this._id).append(parentDiv);
      }
      parentDiv.appendChild( pageSizeDiv );
    }

    this.search = function (searchTerm) {
        // search is really handled all by the draw function.
        // this is just to 1.) get the search term, and also
        // to expose an external method to allow us to program-
        //  matically search.

        // ?? but what if search was just " "....
        if (searchTerm != null)
            this._search = searchTerm.trim();
        else
            this._search = $('#' + this._id + '_searchbox').val().trim();

        // go back to first page on search. 
        this._page = 0;
        this.draw();

    }

    this.tagFilter = function () {
        // this gives us a way to programmatically filter out
        // content that is not outright searchable.  That is,
        // we can attach, really any sort of content, as tags
        // to rows of data, and then filter based on that data.

        // For example, imagine you had a date field in a column
        // and attached the names of the months at tags for each
        // column (April, May, etc) -- we can then filter based
        // off the term April, etc.  That list can then be
        // generated from the tags themselves, to be supplied to
        // the filter function.


        // no filter function defined.

        if (this._tagFilter == null)
            return true;

        // for each row, pass it's tag set to the tagFilter
        // that has been provided..

        for (var x = 0; x < this._dataset.length; x++)
            this._tagFilter(this._dataset[x].tags, x == 0);

        this._page = 0;
    }

}
