class Sspr {

  constructor(options){
    this.setData(options.data);
    this.swellHandler = this.swell.bind(this);

    //START
    this.mfaPrompt();
  }


  setData(data){

  	this.data = {};
    this.data.mfa = (data && data.Mfa) ? data.Mfa : {};
    this.data.sspr = (data && data.Sspr) ? data.Sspr : {};

  }


  elementSwell(el){
    if(!el.classList.contains('swell-element')){
      el.classList.add('swell-element');
    }

    if(!el.hasAttribute('data-swell')){
      el.setAttribute('data-swell', '');
      el.addEventListener('transitionend', this.swellHandler);
      el.classList.add('swell');
    }
  }

  swell(e){
    e.currentTarget.removeEventListener('transitionend', this.swellHandler);
    e.currentTarget.classList.remove('swell');

    var outterEl = e.currentTarget;
    (function(innerEl){
      setTimeout(function(){
        innerEl.removeAttribute('data-swell');
      }.bind(this), 200);
    })(outterEl);    
    
  }


  mfaPrompt(){
    if(!this.data.mfa.showPrompt){
      // if there is no mfa prompt, then lets check sspr
      this.ssprPrompt();
      return;
    }

    //Country Codes Required to build MFA prompt
    $.getJSON( '/asyncloader/getEntity/813', (data) => {
      this.countryCodes = data;
      if(this.countryCodes) {
        this.mfa = {};
        this.mfaBuildStep1();
        this.mfaStep1();

      } else {
        console.info('No Country Code data!');
        console.info('MFA Prompt Canceled');
        this.ssprPrompt();
      }

    }).fail(() => {
      console.info('Country Code WS failed');
      console.info('MFA Prompt Canceled');
      this.ssprPrompt();
    }); 

  }//END mfaPrompt


  assignPhonePlaceholder(e) {

    if(this.mfa.step1.els.countryCodeInput.options[this.mfa.step1.els.countryCodeInput.selectedIndex].getAttribute('data-abbr') === 'US') {
        this.mfa.step1.els.phoneInput.setAttribute('placeholder', 'ex. 330-672-3000');
        this.mfa.step1.els.phoneLabelAriaSpan.textContent = ' (including area code)';
    } else {
      this.mfa.step1.els.phoneInput.setAttribute('placeholder', '');
      this.mfa.step1.els.phoneLabelAriaSpan.textContent = '';
    }

  }


  mfaBuildStep1(){
    this.mfa.moreInfoString = 'For more information, see the <a target="_blank" href="https://kentstate.freshservice.com/support/solutions/articles/19000051170">Multi-Factor Knowledge Base</a>.';
  	this.mfa.step1 = {};
    this.mfa.step1.els = {};
    this.mfa.step1.els.form = document.createElement('form');
    this.mfa.step1.els.form.addEventListener('submit', this.mfaStep1Submit.bind(this), true);

    this.mfa.step1.els.heading = 'Guarantee Your FlashLine Access!';

    this.mfa.step1.els.instructionWrap = document.createElement('div');
    this.mfa.step1.els.instructionWrap.classList = 'swal-instruction';
    this.mfa.step1.els.form.appendChild(this.mfa.step1.els.instructionWrap);
    this.mfa.step1.els.instruction = document.createElement('p');
    if(this.data.mfa.revalidate){
      this.mfa.step1.els.instruction.textContent = 'Please confirm we have your correct cell phone number for Multi-Factor Authentication (MFA). Using your phone number as an MFA option is the most effective way to avoid calling the helpdesk if you need to change your password or experience an issue with your account.';
    } else{
      this.mfa.step1.els.instruction.textContent = 'Kent State University is excited to offer the ability to add your phone number as a Multi-Factor Authentication (MFA) option. Adding your phone number is the most effective way to avoid calling the helpdesk if you need to change your password or experience an issue with your account.';
    }

    this.mfa.step1.els.instructionWrap.appendChild(this.mfa.step1.els.instruction);

    this.mfa.step1.els.phoneInputArea = document.createElement('div');
    this.mfa.step1.els.phoneInputArea.classList.add('phone-area');
    this.mfa.step1.els.countryCodeBlock = document.createElement('div');
    this.mfa.step1.els.countryCodeBlock.className = 'country-code-block swell-element';
    this.mfa.step1.els.phoneInputArea.appendChild(this.mfa.step1.els.countryCodeBlock);
    this.mfa.step1.els.phoneBlock = document.createElement('div');
    this.mfa.step1.els.phoneBlock.className = 'phone-block swell-element';
    this.mfa.step1.els.phoneInputArea.appendChild(this.mfa.step1.els.phoneBlock);    

    this.mfa.step1.els.countryCodeLabel = document.createElement('label');
    this.mfa.step1.els.countryCodeLabel.setAttribute('for', 'mfa-country-code');
    this.mfa.step1.els.countryCodeLabel.textContent = 'Country Code';
    this.mfa.step1.els.countryCodeInput = document.createElement('select');
    this.mfa.step1.els.countryCodeInput.id = 'mfa-country-code';
    this.mfa.step1.els.countryCodeInput.addEventListener('change', this.assignPhonePlaceholder.bind(this));

    let option1 = document.createElement('option');
    option1.textContent = 'Select Country Code';
    option1.setAttribute('disabled', '');
    option1.setAttribute('value', '');
    this.mfa.step1.els.countryCodeInput.appendChild(option1);

    for(key in this.countryCodes) {
      if(this.countryCodes.hasOwnProperty(key)) {
        let optionEl = document.createElement('option');
        optionEl.setAttribute('data-abbr', this.countryCodes[key].abbr);
        optionEl.setAttribute('value', this.countryCodes[key].code);
        let countryText = key;
        if(key === 'United States'){
          countryText += ' / Canada';
        } else if(key === 'Canada'){
          countryText += ' / United States';
        }
        optionEl.setAttribute('data-country', countryText);
        optionEl.textContent = countryText + ' (+' + this.countryCodes[key].code + ')';
        this.mfa.step1.els.countryCodeInput.appendChild(optionEl);
      }
    }

    //Assign selected option
    if (this.data.mfa.countryCode) {
      //console.log('A');
      let optionMatch = this.mfa.step1.els.countryCodeInput.querySelector('[value="' + this.data.mfa.countryCode + '"]');
      if(optionMatch){
        if(this.data.mfa.countryCode === '1') {
          //Default to USA instead of Canada which shares code
          this.mfa.step1.els.countryCodeInput.querySelector('[data-abbr="US"]').setAttribute('selected', '');
        } else {
          this.mfa.step1.els.countryCodeInput.querySelector('[value="' + this.data.mfa.countryCode + '"]').setAttribute('selected', '');
        }
      } else{
        //No option match
        console.info('Stored country not in our list. User must select supported country.');
        this.mfa.step1.els.countryCodeInput.querySelector('option').setAttribute('selected', '');
      }
      
    } else if(!this.data.mfa.phoneNumber) {
      //No country code and no phone number
      //console.log('B');
      this.mfa.step1.els.countryCodeInput.querySelector('[data-abbr="US"]').setAttribute('selected', '');
    } else {
      //console.log('C');
      //Phone number but no country code
      this.mfa.step1.els.countryCodeInput.querySelector('option').setAttribute('selected', '');
    }


    this.mfa.step1.els.countryCodeBlock.appendChild(this.mfa.step1.els.countryCodeLabel);
    this.mfa.step1.els.countryCodeBlock.appendChild(this.mfa.step1.els.countryCodeInput);


    this.mfa.step1.els.phoneLabel = document.createElement('label');
    this.mfa.step1.els.phoneLabel.setAttribute('for', 'mfa-phone');
    this.mfa.step1.els.phoneLabel.textContent = 'Phone Number';
    this.mfa.step1.els.phoneLabelAriaSpan = document.createElement('span');
    this.mfa.step1.els.phoneLabelAriaSpan.classList.add('aria-only');
    this.mfa.step1.els.phoneLabel.appendChild(this.mfa.step1.els.phoneLabelAriaSpan);

    this.mfa.step1.els.phoneInput = document.createElement('input');
    this.mfa.step1.els.phoneInput.setAttribute('type', 'tel');
    this.assignPhonePlaceholder();
    this.mfa.step1.els.phoneInput.id = 'mfa-phone';

    if(this.data.mfa.phoneNumber) {
      this.mfa.step1.els.phoneInput.value = this.data.mfa.phoneNumber;
    }

    this.mfa.step1.els.phoneErrorWrap = document.createElement('div');
    this.mfa.step1.els.phoneErrorWrap.className = 'fl-error';
    this.mfa.step1.els.phoneErrorWrap.setAttribute('aria-live', 'assertive');
    this.mfa.step1.els.phoneError = document.createElement('div');
    this.mfa.step1.els.phoneError.className = 'fl-form-error';
    this.mfa.step1.els.phoneError.id = 'mfa-phone-error';
    this.mfa.step1.els.phoneErrorWrap.appendChild(this.mfa.step1.els.phoneError);

    this.mfa.step1.els.phoneBlock.appendChild(this.mfa.step1.els.phoneLabel);
    this.mfa.step1.els.phoneBlock.appendChild(this.mfa.step1.els.phoneInput);
    this.mfa.step1.els.phoneBlock.appendChild(this.mfa.step1.els.phoneErrorWrap);


    this.mfa.step1.els.form.appendChild(this.mfa.step1.els.phoneInputArea);

    if(!this.data.mfa.forceDefault){
    this.mfa.step1.els.radioWrap =  document.createElement('div');
    this.mfa.step1.els.radioWrap.className = 'mfa-radio-wrap';
    this.mfa.step1.els.form.appendChild(this.mfa.step1.els.radioWrap);



    this.mfa.step1.els.radioBlock =  document.createElement('fieldset');
    this.mfa.step1.els.radioLegend =  document.createElement('legend');
    this.mfa.step1.els.radioLegend.textContent = 'Send all future verifications via:';
    this.mfa.step1.els.radioBlock.className = 'mfa-radio-block';
    this.mfa.step1.els.radio1 = document.createElement('input');
    this.mfa.step1.els.radio1.setAttribute('type', 'radio');
    this.mfa.step1.els.radio1.setAttribute('name', 'mfa-default-radio');
    this.mfa.step1.els.radio1.setAttribute('value', 'phone');
    this.mfa.step1.els.radio1.id = 'mfa-default-radio-1';
    this.mfa.step1.els.radio1.classList.add('fl-radio');
    this.mfa.step1.els.radio1.setAttribute('checked', 'checked');
    this.mfa.step1.els.radio1Label = document.createElement('label');
    this.mfa.step1.els.radio1Label.setAttribute('for', 'mfa-default-radio-1');
    this.mfa.step1.els.radio1Label.innerHTML = 'Text Message <span class="highlight">(recommended)</span>';

    this.mfa.step1.els.radioBlock.appendChild(this.mfa.step1.els.radioLegend);
    this.mfa.step1.els.radioBlock.appendChild(this.mfa.step1.els.radio1);
    this.mfa.step1.els.radioBlock.appendChild(this.mfa.step1.els.radio1Label);
    this.mfa.step1.els.radioBlock.appendChild(document.createElement('br'));

    this.mfa.step1.els.radio2 = document.createElement('input');
    this.mfa.step1.els.radio2.setAttribute('type', 'radio');
    this.mfa.step1.els.radio2.setAttribute('name', 'mfa-default-radio');
    this.mfa.step1.els.radio2.setAttribute('value', 'app');
    this.mfa.step1.els.radio2.id = 'mfa-default-radio-2';
    this.mfa.step1.els.radio2.classList.add('fl-radio');
    this.mfa.step1.els.radio2Label = document.createElement('label');
    this.mfa.step1.els.radio2Label.setAttribute('for', 'mfa-default-radio-2');
    this.mfa.step1.els.radio2Label.textContent = 'Microsoft Authenticator App';

    this.mfa.step1.els.radioBlock.appendChild(this.mfa.step1.els.radio2);
    this.mfa.step1.els.radioBlock.appendChild(this.mfa.step1.els.radio2Label);


    this.mfa.step1.els.radioWrap.appendChild(this.mfa.step1.els.radioBlock);
    }
    //END of RADIO BUTTON

    

    this.mfa.step1.els.moreInfo = document.createElement('p');
    this.mfa.step1.els.moreInfo.className = 'more-info';

    if(this.data.mfa.forceDefault){
      this.mfa.step1.els.moreInfo.innerHTML = 'The MFA App will still be available for advanced users. ' + this.mfa.moreInfoString + ' <span class="data-rates">Messaging and data rates may apply.</span>';
    } else {
      this.mfa.step1.els.moreInfo.innerHTML = this.mfa.moreInfoString + ' <span class="data-rates">Messaging and data rates may apply.</span>';
    }

    this.mfa.step1.els.form.appendChild(this.mfa.step1.els.moreInfo);


    this.mfa.step1.els.controlArea = document.createElement('div');
    this.mfa.step1.els.controlArea.classList = 'control-area';
    this.mfa.step1.els.nextButton = document.createElement('input');
    this.mfa.step1.els.nextButton.setAttribute('type', 'submit');
    this.mfa.step1.els.nextButton.setAttribute('value', 'Next');
    this.mfa.step1.els.nextButton.classList = 'button';
    this.mfa.step1.els.controlArea.appendChild(this.mfa.step1.els.nextButton);
    this.mfa.step1.els.form.appendChild(this.mfa.step1.els.controlArea);


    this.mfa.step1.els.noPhoneLinkWrap = document.createElement('div');
    this.mfa.step1.els.noPhoneLinkWrap.className = 'footer-link-wrap';
    this.mfa.step1.els.noPhoneLink = document.createElement('a');
    this.mfa.step1.els.noPhoneLink.setAttribute('role', 'button');
    this.mfa.step1.els.noPhoneLink.textContent = 'Don\'t own a cell phone?';
    this.mfa.step1.els.noPhoneLink.href = '';
    this.mfa.step1.els.noPhoneLink.addEventListener('click', (e)=>{
      e.preventDefault();
      if(this.submitting) return;
      if(!this.mfa.noPhone) {
        this.mfaBuildNoPhone();
      }
      this.mfaNoPhone();
    });


    this.mfa.step1.els.noPhoneLinkWrap.appendChild(this.mfa.step1.els.noPhoneLink);
    this.mfa.step1.els.form.appendChild(this.mfa.step1.els.noPhoneLinkWrap);

  }//END mfaBuildStep1


  mfaBuildStep2(){
  	this.mfa.step2 = {};
    this.mfa.step2.els = {};
    this.mfa.step2.els.form = document.createElement('form');
    this.mfa.step2.els.form.addEventListener('submit', this.mfaStep2Submit.bind(this), true);

    this.mfa.step2.els.heading = 'Please Enter the Code';

	  this.mfa.step2.els.instructionWrap = document.createElement('div');
    this.mfa.step2.els.instructionWrap.classList = 'swal-instruction';
    this.mfa.step2.els.instructionWrap.setAttribute('aria-live', 'assertive');
    this.mfa.step2.els.form.appendChild(this.mfa.step2.els.instructionWrap);

    this.mfa.step2.els.instruction = document.createElement('div');
    this.mfa.step2.els.instruction.innerHTML = '<p>We just sent a 6 digit code to <span class="highlight">+' +  this.mfa.step1.post.countryCode + ' ' + this.mfa.step1.post.phoneNumber + '</span>.</p>';
    this.mfa.step2.els.instructionWrap.appendChild(this.mfa.step2.els.instruction);

    //link stored. add later for text fail scenario
    this.mfa.step2.els.textFailBlock = document.createElement('p');
    this.mfa.step2.els.textFailBlock.className = 'text-fail-block';
    this.mfa.step2.els.textFailIcon = document.createElement('span');
    this.mfa.step2.els.textFailIcon.className = 'fl-error icon-exclaimcircle';
    this.mfa.step2.els.textFailIcon.setAttribute('aria-hidden', 'true');
    this.mfa.step2.els.textFailBlock.appendChild(this.mfa.step2.els.textFailIcon);
	this.mfa.step2.els.textFailLink = document.createElement('a');
    this.mfa.step2.els.textFailLink.setAttribute('role', 'button');
    this.mfa.step2.els.textFailLink.textContent = 'Didn\'t get it? Let us know.';
    this.mfa.step2.els.textFailLink.href = '';
    this.mfa.step2.els.textFailLink.addEventListener('click', (e)=>{
      e.preventDefault();
      if(this.submitting) return; //already working on it
      this.submitting = true;
      this.mfaTextFail();
    });
    this.mfa.step2.els.textFailBlock.appendChild(this.mfa.step2.els.textFailLink);

    this.mfa.step2.els.codeInputArea = document.createElement('div');
    this.mfa.step2.els.codeInputArea.classList.add('code-area');
    this.mfa.step2.els.form.appendChild(this.mfa.step2.els.codeInputArea);

    this.mfa.step2.els.codeBlock = document.createElement('div');
    this.mfa.step2.els.codeBlock.className = 'mfa-code-block swell-element';
    this.mfa.step2.els.codeInputArea.appendChild(this.mfa.step2.els.codeBlock);

    this.mfa.step2.els.codeLabel = document.createElement('label');
    this.mfa.step2.els.codeLabel.setAttribute('for', 'mfa-code');
    this.mfa.step2.els.codeLabel.textContent = 'Code';
	  this.mfa.step2.els.codeBlock.appendChild(this.mfa.step2.els.codeLabel);

    this.mfa.step2.els.codeInput = document.createElement('input');
    this.mfa.step2.els.codeInput.setAttribute('type', 'text');
    //use same function as we used on the phone number input before submitting
    this.mfa.step2.els.codeInput.id = 'mfa-code';
    this.mfa.step2.els.codeBlock.appendChild(this.mfa.step2.els.codeInput);

    this.mfa.step2.els.codeErrorWrap = document.createElement('div');
    this.mfa.step2.els.codeErrorWrap.className = 'fl-error';
    this.mfa.step2.els.codeErrorWrap.setAttribute('aria-live', 'assertive');
    this.mfa.step2.els.codeBlock.appendChild(this.mfa.step2.els.codeErrorWrap);

    this.mfa.step2.els.codeError = document.createElement('div');
    this.mfa.step2.els.codeError.className = 'fl-form-error';
    this.mfa.step2.els.codeError.id = 'mfa-code-error';
    this.mfa.step2.els.codeErrorWrap.appendChild(this.mfa.step2.els.codeError);

    this.mfa.step2.els.controlArea = document.createElement('div');
    this.mfa.step2.els.controlArea.classList = 'control-area';
    this.mfa.step2.els.form.appendChild(this.mfa.step2.els.controlArea);

    this.mfa.step2.els.backButton = document.createElement('button');
    this.mfa.step2.els.backButton.setAttribute('type', 'button');
    this.mfa.step2.els.backButton.textContent = 'Back';
    this.mfa.step2.els.backButton.classList = 'button';
    this.mfa.step2.els.backButton.addEventListener('click', (e) => {
    	//mfaStep1 takes an optional callback function as an argument.
    	e.preventDefault();
		  this.mfaStep1();
    });
    this.mfa.step2.els.controlArea.appendChild(this.mfa.step2.els.backButton);

    this.mfa.step2.els.nextButton = document.createElement('input');
    this.mfa.step2.els.nextButton.setAttribute('type', 'submit');
    this.mfa.step2.els.nextButton.setAttribute('value', 'Next');
    this.mfa.step2.els.nextButton.classList = 'button';
    this.mfa.step2.els.controlArea.appendChild(this.mfa.step2.els.nextButton);


    this.mfa.step2.els.resendCodeWrap = document.createElement('div');
    this.mfa.step2.els.resendCodeWrap.className = 'footer-link-wrap';
    this.mfa.step2.els.resendCodeLink = document.createElement('a');
    this.mfa.step2.els.resendCodeLink.setAttribute('role', 'button');
    this.mfa.step2.els.resendCodeLink.textContent = 'Resend Code';
    this.mfa.step2.els.resendCodeLink.href = '';
    this.mfa.step2.els.resendCodeLink.addEventListener('click', (e)=>{
      e.preventDefault();
      if(this.submitting) return; //already working on it
      this.submitting = true;
      if(!this.mfa.step2.resendCount) this.mfa.step2.resendCount = 0;
      this.mfa.step2.resendCount++;

      this.mfaSendCode();
    });


    this.mfa.step2.els.resendCodeWrap.appendChild(this.mfa.step2.els.resendCodeLink);
    this.mfa.step2.els.form.appendChild(this.mfa.step2.els.resendCodeWrap);





  }//END mfaBuildStep2

      
  mfaBuildNoPhone(){
    this.mfa.noPhone = {};
    this.mfa.noPhone.els = {};
    this.mfa.noPhone.els.form = document.createElement('form');
    this.mfa.noPhone.els.form.addEventListener('submit', this.mfaNoPhoneSubmit.bind(this), true);

    this.mfa.noPhone.els.heading = 'Are You Sure?';

    this.mfa.noPhone.els.instructionWrap = document.createElement('div');
    this.mfa.noPhone.els.instructionWrap.classList = 'swal-instruction';
    this.mfa.noPhone.els.form.appendChild(this.mfa.noPhone.els.instructionWrap);

    this.mfa.noPhone.els.instruction2 = document.createElement('p');
    this.mfa.noPhone.els.instruction2.textContent = 'We will only use your phone number for account-related activity, such as a password reset or a security issue with your account.';
    this.mfa.noPhone.els.instructionWrap.appendChild(this.mfa.noPhone.els.instruction2);

    //RADIOS
    this.mfa.noPhone.els.radioWrap =  document.createElement('div');
    this.mfa.noPhone.els.radioWrap.className = 'mfa-radio-wrap';
    this.mfa.noPhone.els.form.appendChild(this.mfa.noPhone.els.radioWrap);
    this.mfa.noPhone.els.radioBlock =  document.createElement('div');
    this.mfa.noPhone.els.radioBlock.className = 'mfa-radio-block';

    this.mfa.noPhone.els.radio1 = document.createElement('input');
    this.mfa.noPhone.els.radio1.setAttribute('type', 'radio');
    this.mfa.noPhone.els.radio1.setAttribute('name', 'mfa-no-phone-radio');
    this.mfa.noPhone.els.radio1.setAttribute('value', 'no-phone');
    this.mfa.noPhone.els.radio1.id = 'mfa-no-phone-radio-1';
    this.mfa.noPhone.els.radio1.classList.add('fl-radio');
    this.mfa.noPhone.els.radio1.setAttribute('checked', 'checked');
    this.mfa.noPhone.els.radio1Label = document.createElement('label');
    this.mfa.noPhone.els.radio1Label.setAttribute('for', 'mfa-no-phone-radio-1');
    this.mfa.noPhone.els.radio1Label.textContent = 'I don\'t own a cell phone';

    this.mfa.noPhone.els.radioBlock.appendChild(this.mfa.noPhone.els.radio1);
    this.mfa.noPhone.els.radioBlock.appendChild(this.mfa.noPhone.els.radio1Label);
    this.mfa.noPhone.els.radioBlock.appendChild(document.createElement('br'));

    this.mfa.noPhone.els.radio2 = document.createElement('input');
    this.mfa.noPhone.els.radio2.setAttribute('type', 'radio');
    this.mfa.noPhone.els.radio2.setAttribute('name', 'mfa-no-phone-radio');
    this.mfa.noPhone.els.radio2.setAttribute('value', 'no-provide');
    this.mfa.noPhone.els.radio2.id = 'mfa-no-phone-radio-2';
    this.mfa.noPhone.els.radio2.classList.add('fl-radio');
    this.mfa.noPhone.els.radio2Label = document.createElement('label');
    this.mfa.noPhone.els.radio2Label.setAttribute('for', 'mfa-no-phone-radio-2');
    this.mfa.noPhone.els.radio2Label.textContent = 'I don\'t want to provide my phone number';

    this.mfa.noPhone.els.radioBlock.appendChild(this.mfa.noPhone.els.radio2);
    this.mfa.noPhone.els.radioBlock.appendChild(this.mfa.noPhone.els.radio2Label);


    this.mfa.noPhone.els.radioWrap.appendChild(this.mfa.noPhone.els.radioBlock);

    //END of RADIO BUTTON

    //more info
    this.mfa.noPhone.els.moreInfo = document.createElement('p');
    this.mfa.noPhone.els.moreInfo.className = 'more-info';
    this.mfa.noPhone.els.moreInfo.innerHTML = this.mfa.moreInfoString;
    this.mfa.noPhone.els.form.appendChild(this.mfa.noPhone.els.moreInfo);
    
    //controls
    this.mfa.noPhone.els.controlArea = document.createElement('div');
    this.mfa.noPhone.els.controlArea.classList = 'control-area';
    this.mfa.noPhone.els.form.appendChild(this.mfa.noPhone.els.controlArea);
    this.mfa.noPhone.els.backButton = document.createElement('button');
    this.mfa.noPhone.els.backButton.setAttribute('type', 'button');
    this.mfa.noPhone.els.backButton.textContent = 'Back';
    this.mfa.noPhone.els.backButton.classList = 'button';
    this.mfa.noPhone.els.backButton.addEventListener('click', (e) => {
      //mfaStep1 takes an optional callback function as an argument.
      e.preventDefault();
      this.mfaStep1();
    });
    this.mfa.noPhone.els.controlArea.appendChild(this.mfa.noPhone.els.backButton);
    this.mfa.noPhone.els.nextButton = document.createElement('input');
    this.mfa.noPhone.els.nextButton.setAttribute('type', 'submit');
    this.mfa.noPhone.els.nextButton.setAttribute('value', 'Next');
    this.mfa.noPhone.els.nextButton.classList = 'button';
    this.mfa.noPhone.els.controlArea.appendChild(this.mfa.noPhone.els.nextButton);


  }//END Build No Phone


  ssprBuildStep1(){
    this.sspr.moreInfoString = 'For more information, see the <a target="_blank" href="https://kentstate.freshservice.com/support/solutions/articles/19000051465">Password Reset Knowledge Base</a>.';
    this.sspr.step1 = {};
    this.sspr.step1.els = {};
    this.sspr.step1.els.form = document.createElement('form');
    this.sspr.step1.els.form.addEventListener('submit', this.ssprStep1Submit.bind(this), true);

    this.sspr.step1.els.heading = 'Make Password Resets Easy';

    this.sspr.step1.els.instructionWrap = document.createElement('div');
    this.sspr.step1.els.instructionWrap.classList = 'swal-instruction';
    this.sspr.step1.els.form.appendChild(this.sspr.step1.els.instructionWrap);
    this.sspr.step1.els.instruction = document.createElement('p');
    if(this.data.sspr.revalidate){
      this.sspr.step1.els.instruction.textContent = 'You are seeing this prompt because we would like to confirm the alternate email address you have previously supplied us with is correct. Confirming your alternate email address will allow you to avoid calling the helpdesk in the event that you forget your password.';
    } else{
      this.sspr.step1.els.instruction.textContent = 'You are seeing this prompt because we do not have an alternate email address associated with your account. Adding your alternate email address will allow you to avoid calling the helpdesk in the event that you forget your password.';
    }
    this.sspr.step1.els.instructionWrap.appendChild(this.sspr.step1.els.instruction);

    this.sspr.step1.els.instruction2 = document.createElement('p');
    this.sspr.step1.els.instruction2.textContent = 'We will only use your alternate email address for account-related activity, such as a password reset or a security issue with your account.';
    this.sspr.step1.els.instructionWrap.appendChild(this.sspr.step1.els.instruction2);

    this.sspr.step1.els.emailInputArea = document.createElement('div');
    this.sspr.step1.els.emailInputArea.classList.add('email-area');
    this.sspr.step1.els.form.appendChild(this.sspr.step1.els.emailInputArea);

    this.sspr.step1.els.emailBlock = document.createElement('div');
    this.sspr.step1.els.emailBlock.className = 'email-block swell-element';
    this.sspr.step1.els.emailInputArea.appendChild(this.sspr.step1.els.emailBlock);

    this.sspr.step1.els.emailLabel = document.createElement('label');
    this.sspr.step1.els.emailLabel.setAttribute('for', 'sspr-email');
    this.sspr.step1.els.emailLabel.textContent = 'Alternate Email Address';
    this.sspr.step1.els.emailBlock.appendChild(this.sspr.step1.els.emailLabel);

    this.sspr.step1.els.emailInput = document.createElement('input');
    this.sspr.step1.els.emailInput.setAttribute('type', 'text');
    this.sspr.step1.els.emailInput.id = 'sspr-email';
    if(this.data.sspr.revalidate && this.data.sspr.email) this.sspr.step1.els.emailInput.value = this.data.sspr.email;
    this.sspr.step1.els.emailBlock.appendChild(this.sspr.step1.els.emailInput);

    this.sspr.step1.els.emailErrorWrap = document.createElement('div');
    this.sspr.step1.els.emailErrorWrap.className = 'fl-error';
    this.sspr.step1.els.emailErrorWrap.setAttribute('aria-live', 'assertive');
    this.sspr.step1.els.emailBlock.appendChild(this.sspr.step1.els.emailErrorWrap);

    this.sspr.step1.els.emailError = document.createElement('div');
    this.sspr.step1.els.emailError.className = 'fl-form-error';
    this.sspr.step1.els.emailError.id = 'sspr-email-error';
    this.sspr.step1.els.emailErrorWrap.appendChild(this.sspr.step1.els.emailError);

    //more info
    this.sspr.step1.els.moreInfo = document.createElement('p');
    this.sspr.step1.els.moreInfo.className = 'more-info';
    this.sspr.step1.els.moreInfo.innerHTML = this.sspr.moreInfoString;
    this.sspr.step1.els.form.appendChild(this.sspr.step1.els.moreInfo); 

    this.sspr.step1.els.controlArea = document.createElement('div');
    this.sspr.step1.els.controlArea.classList = 'control-area';
    this.sspr.step1.els.form.appendChild(this.sspr.step1.els.controlArea);

    this.sspr.step1.els.nextButton = document.createElement('input');
    this.sspr.step1.els.nextButton.setAttribute('type', 'submit');
    this.sspr.step1.els.nextButton.setAttribute('value', 'Next');
    this.sspr.step1.els.nextButton.classList = 'button';
    this.sspr.step1.els.controlArea.appendChild(this.sspr.step1.els.nextButton);

  }//END ssprBuildStep1


  mfaStep1Submit(e) {
  	//console.log('SUBMITTING FORM 1');
    e.preventDefault();//Prevent Natural Submission

    //Prevent double click submissions
    if(!this.submitting) {
      this.submitting = true;
    } else {
      //console.log('In process, cannot submit twice');
      return;
    }


    //Assign Post Values
    this.mfa.step1.post = {};
    this.mfa.step1.phoneString = this.mfa.step1.els.phoneInput.value;
    this.mfa.step1.post.phoneNumber = this.mfa.step1.phoneString.replace(/\D/g,'');
    this.mfa.step1.post.countryCode = this.mfa.step1.els.countryCodeInput.value;
    this.mfa.step1.post.setDefault = this.data.mfa.forceDefault ? true : this.mfa.step1.els.radio1.checked;

    //console.log(this.mfa.step1.post);

    let activeEl = document.activeElement;
    let returnFocus;
    
    if(!this.mfa.step1.post.countryCode){
      this.mfa.step1.els.countryCodeInput.classList.add('fl-show-user');
      this.elementSwell(this.mfa.step1.els.countryCodeBlock);
      returnFocus = (this.mfa.step1.els.countryCodeInput === activeEl) ? activeEl : returnFocus;

    }else{
      this.mfa.step1.els.countryCodeInput.classList.remove('fl-show-user');
    }

    if(!this.mfa.step1.post.phoneNumber){
      this.mfa.step1.els.phoneError.textContent = '';
      this.mfa.step1.els.phoneError.textContent = 'Error: Please enter a phone number.';
      this.mfa.step1.els.phoneInput.classList.add('fl-show-user');
      this.elementSwell(this.mfa.step1.els.phoneBlock);
      returnFocus = (this.mfa.step1.els.phoneInput === activeEl) ? activeEl : returnFocus;

    }else if(this.mfa.step1.post.phoneNumber && !this.mfa.step1.els.phoneInput.hasAttribute('data-invalid')){
      this.mfa.step1.els.phoneError.textContent = '';
      this.mfa.step1.els.phoneInput.classList.remove('fl-show-user');
    }

    if(returnFocus){
      returnFocus.focus();
    } else if(!this.mfa.step1.post.countryCode){
      this.mfa.step1.els.countryCodeInput.focus();
    } else if(!this.mfa.step1.post.phoneNumber) {
      this.mfa.step1.els.phoneInput.focus();
    }

    if(!this.mfa.step1.post.countryCode || !this.mfa.step1.post.phoneNumber){
      this.submitting = false;
      return;
    }


    let exactRepost = this.mfa.step1.postString && JSON.stringify(this.mfa.step1.post) === this.mfa.step1.postString;
    let postSuccessful = this.mfa.step1.response && this.mfa.step1.response.codeSent;

    if(exactRepost && (this.mfa.step1.response && this.mfa.step1.response.countryUnsupported)){
    	//country still unsupported
    	this.mfaUnsupportedCountrySwal();
    	return;
    } else if(exactRepost && postSuccessful){
      this.submitting = false;
      this.mfaStep2();
      return;
    } else if(exactRepost && !postSuccessful){
      this.mfa.step1.els.phoneError.textContent = '';
      this.mfa.step1.els.phoneError.textContent = this.mfa.step1.response.errorMessage ? ('Error: ' + this.mfa.step1.response.errorMessage) : 'Error: Number is not valid!';
      this.mfa.step1.els.phoneInput.classList.add('fl-show-user');
      this.mfa.step1.els.phoneInput.focus();
      this.elementSwell(this.mfa.step1.els.phoneBlock);
      this.submitting = false;
      return;
    }

    //Ready for AJAX post
	  this.mfaSendCode();


  } //END OF mfaStep1Submit
  

  mfaStep2Submit(e) {
    //console.log('SUBMITTING FORM 2');
    e.preventDefault();//Prevent Natural Submission

    //Prevent double click submissions
    if(!this.submitting) {
      this.submitting = true;
    } else {
      //console.log('In process, cannot submit twice');
      return;
    }

    //Assign Post Values
    this.mfa.step2.post = {};
    this.mfa.step2.codeString = this.mfa.step2.els.codeInput.value;
    this.mfa.step2.post.code = this.mfa.step2.codeString.replace(/\D/g,'');
    //console.log(this.mfa.step2.post);

    if(this.mfa.step2.post.code.length !== 6){
      this.loader.remove();
      this.submitting = false;
      this.mfa.step2.els.codeError.textContent = '';
      this.mfa.step2.els.codeError.textContent = 'Error: Code must be 6 digits.';
      this.elementSwell(this.mfa.step2.els.codeBlock);
      this.mfa.step2.els.codeInput.classList.add('fl-show-user');
      this.mfa.step2.els.codeInput.focus();
      return;
    }

    //stop multiple submission of same wrong code. This would eat up the users attempts.
    let exactRepost = this.mfa.step2.postString && JSON.stringify(this.mfa.step2.post) === this.mfa.step2.postString;
    let postSuccessful = this.mfa.step2.response && this.mfa.step2.response.success;

    if(exactRepost && !postSuccessful){
      this.submitting = false;
      this.mfa.step2.els.codeError.textContent = '';
      this.mfa.step2.els.codeError.textContent = this.mfa.step2.response.errorMessage ? ('Error: ' + this.mfa.step2.response.errorMessage) : 'Error: Code is not valid!';
      this.mfa.step2.els.codeInput.classList.add('fl-show-user');
      this.elementSwell(this.mfa.step2.els.codeBlock);
      this.mfa.step2.els.codeInput.focus();
      return;
    }
  
    this.loader = new ajaxLoader(document.querySelector('.sspr-swal .swal2-content'));
    //AJAX POST
    $.ajax({
      method: "POST",
      url: "/asyncloader/DoEntity/817",
      dataType: "json",
      data: this.mfa.step2.post
    }).done((data) => {
      //console.log(data);

      if(!data){
        this.abortMFA({ console: 'DoEntity/817 returned no data' });
        return;
      }else if(data.error === 'error') {
        this.abortMFA({ console: 'DoEntity/817 returned an error' });
        return;
      } 

      this.mfa.step2.postString = JSON.stringify(this.mfa.step2.post);
      this.mfa.step2.response = data.mfa ? data.mfa : {};

      if(!this.mfa.step2.response.success){
        this.loader.remove();
        this.submitting = false;
        this.mfa.step2.els.codeError.textContent = '';
        this.mfa.step2.els.codeError.textContent = this.mfa.step2.response.errorMessage ? ('Error: ' + this.mfa.step2.response.errorMessage) : 'Error: Code is not valid!';
        this.mfa.step2.els.codeInput.classList.add('fl-show-user');
        this.elementSwell(this.mfa.step2.els.codeBlock);
        this.mfa.step2.els.codeInput.focus();
      } else {
        this.mfaSuccess({
          heading: 'Cell Phone Verified',
          body: 'Your phone was registered successfully!'
        });
      }

      
    }).fail( () => {
      this.abortMFA({
        console: 'DoEntity/817 failed'
      });
    });


  }//END OF MFA STEP 2 SUBMIT


  mfaTextFail() {
	
    this.loader = new ajaxLoader(document.querySelector('.sspr-swal .swal2-content'));
    //Assign Post Values
    if(!this.mfa.textFail) this.mfa.textFail = {};
    this.mfa.textFail.post = {};
    this.mfa.textFail.post.countryCode = this.mfa.step1.post.countryCode;
    this.mfa.textFail.post.phoneNumber = this.mfa.step1.post.phoneNumber;

    //AJAX POST
    $.ajax({
      method: "POST",
      url: "/asyncloader/DoEntity/821",
      dataType: "json",
      data: this.mfa.textFail.post
    }).done((data) => {
      //console.log(data);
      
      if(!data) {
        this.abortMFA({ console: 'DoEntity/821 returned no data' });
        return;
      }else if(data.error === 'error') {
        this.abortMFA({ console: 'DoEntity/821 returned an error' });
        return;
      }

      this.mfa.textFail.response = data.mfa ? data.mfa : {};

      if(!this.mfa.textFail.response.success){
        this.abortMFA({
          error: this.mfa.textFail.response.errorMessage,
          console: 'DoEntity/821 finished but submit not successful'
        });
      } else {
        this.mfaSuccess({
          width: '37rem',
          heading: 'Thank You',
          body: '<p>We are sorry you didn\'t receive a code. As we work to correct this, no further action is needed.<p><p class="more-info">' + this.mfa.moreInfoString + '</p>'
        });

      }

          
    }).fail( () => {
      this.abortMFA({
        console: 'DoEntity/821 failed'
      });
    });

  }//END OF MFA TEXT FAIL


  mfaUnsupportedCountrySwal() {
      	console.log('country unsupported');

	    let btnText = this.data.sspr.showPrompt ? 'Continue' : 'Close';
		let bodyHtml = '<p>We are currently unable to authenticate users in <span class="highlight">' + this.mfa.step1.els.countryCodeInput.options[this.mfa.step1.els.countryCodeInput.selectedIndex].getAttribute('data-country') + '</span> via text message. As an alternative, please use the Microsoft Authenticator App.</p><p class="more-info">' + this.mfa.moreInfoString + '</p>';

	    swal2Btns({
	      width: '35rem',
	      customClass: 'sspr-swal mfa-country-unsupported',
	      allowOutsideClick: !this.data.sspr.showPrompt,
	      title: 'Country Not Supported',
	      html: bodyHtml,
	      type: 'info',
	      focusConfirm: true,
	      confirmButtonText: btnText,
	      cancelButtonText: 'Back'

	    }).then((result) => {
	      if(result.value && this.data.sspr.showPrompt) {
	          this.ssprPrompt();
	      }else if(result.dismiss === 'cancel'){
    		//Back Button
    		console.log('I hit the back button');
		  	this.mfaStep1();
  		  }
	    });

		this.loader.remove();
        this.submitting = false;
  }

  mfaSendCode() {
    this.loader = new ajaxLoader(document.querySelector('.sspr-swal .swal2-content'));
    this.submitting = true;

    //AJAX POST
    $.ajax({
      method: "POST",
      url: "/asyncloader/DoEntity/816",
      dataType: "json",
      data: this.mfa.step1.post
    }).done((data) => {
      //console.log(data);

      if(!data) {
        this.abortMFA({ console: 'DoEntity/816 returned no data' });
        return;
      }else if(data.error === 'error') {
  		  this.abortMFA({ console: 'DoEntity/816 returned an error' });
      	return;
      }

      //Successful post on step 2 - store the postString allowing user to go back and forth between steps 1 and 2 without another webservice call
      this.mfa.step1.postString = JSON.stringify(this.mfa.step1.post);

      this.mfa.step1.response = data.mfa ? data.mfa : {};

      //Invalid Country
      if(this.mfa.step1.response.countryUnsupported){
      	this.mfaUnsupportedCountrySwal();
        return;
      }

    	//Invalid Phone Number
    	if(!this.mfa.step1.response.codeSent && !this.mfa.step1.response.maxAttempts){
    		this.loader.remove();
        this.submitting = false;
        this.mfa.step1.els.phoneInput.setAttribute('data-invalid', '');
        this.mfa.step1.els.phoneError.textContent = '';

        this.mfa.step1.els.phoneError.textContent = this.mfa.step1.response.errorMessage ? ('Error: ' + this.mfa.step1.response.errorMessage) : 'Error: Number is not valid!';

    		var phoneInvalidFocus = () => {
    			this.mfa.step1.els.phoneInput.classList.add('fl-show-user');
    	    this.mfa.step1.els.phoneInput.focus();
    	    this.elementSwell(this.mfa.step1.els.phoneBlock);
    		};

    		if(document.querySelector('.mfa-step-1')){
          phoneInvalidFocus();
        } else {
          //just in case this was triggered by the resend code link in mfaStep2
    			this.mfaStep1(phoneInvalidFocus);
        }
        return;
    	}

      //abort if needed
      if(!this.mfa.step1.response.codeSent || this.mfa.step1.response.maxAttempts){
        let errorText = this.mfa.step1.response.errorMessage ? this.mfa.step1.response.errorMessage : 'Too many attempts made. Try again later.';
        this.abortMFA({
          error: errorText,
          console: 'Max Attempts'
        });
        return;
      }

    	this.mfa.step1.els.phoneInput.removeAttribute('data-invalid');
    	if(!this.mfa.step2){
    		this.mfaBuildStep2();
    	}
        //Prevent multiple submissions of the same wrong code in step 2
        this.mfa.step2.postString = null;
        //Successful post coming from step 1 - reset resendCount
        if(document.querySelector('.mfa-step-1')) this.mfa.step2.resendCount = 0;

    	//Screen readers. If a user resends code, we want the instruction to be announced AGAIN even if the textcontent is unchanged..
    	this.mfa.step2.els.instruction.textContent = '';
    	//must all be added as one block to trigger aria correctly
    	let textBlock = document.createElement('div');
    	textBlock.innerHTML = '<p>We just sent a 6 digit code to <span class="highlight">+' +  this.mfa.step1.post.countryCode + ' ' + this.mfa.step1.post.phoneNumber + '</span>.</p>';

    	//Only insert the 'Didn't get it?' link if resendCount is 2 or greater
    	if(this.mfa.step2.resendCount >= 2){
			textBlock.appendChild(this.mfa.step2.els.textFailBlock);
			textBlock.classList.add('text-failed');
    	} else {
			textBlock.classList.remove('text-failed');
    	}
    	this.mfa.step2.els.instruction.appendChild(textBlock);
    	
    	//remove any errors that may be in effect
    	this.mfa.step2.els.codeInput.classList.remove('fl-show-user');
    	this.mfa.step2.els.codeError.textContent = '';


    	if(!document.querySelector('.mfa-step-2')){
        	this.mfa.step2.els.codeInput.value = '';
    		this.mfaStep2();
    	}else{
    		//RESEND CODE link was clicked
    		this.mfa.step2.els.codeInput.value = '';
    		this.mfa.step2.els.codeInput.focus();
    	}

    	this.loader.remove();
    	this.submitting = false;

      
    }).fail( () => {
    	this.abortMFA({ console: 'DoEntity/816 failed' });
    });

  }//END of SEND CODE


  mfaNoPhoneSubmit(e) {
    //console.log('SUBMITTING NO PHONE');
    e.preventDefault();//Prevent Natural Submission

    //Prevent double click submissions
    if(!this.submitting) {
      this.submitting = true;
    } else {
      //console.log('In process, cannot submit twice');
      return;
    }

    //Assign Post Values
    this.mfa.noPhone.post = {};
    this.mfa.noPhone.post.noPhone = this.mfa.noPhone.els.radio1.checked;
    //console.log(this.mfa.noPhone.post);

    this.loader = new ajaxLoader(document.querySelector('.sspr-swal .swal2-content'));

    //AJAX POST
    $.ajax({
      method: "POST",
      url: "/asyncloader/DoEntity/815",
      dataType: "json",
      data: this.mfa.noPhone.post
    }).done((data) => {
      //console.log(data);
      
      if(!data) {
        this.abortMFA({ console: 'DoEntity/815 returned no data' });
        return;
      }else if(data.error === 'error') {
        this.abortMFA({ console: 'DoEntity/815 returned an error' });
        return;
      }

      this.mfa.noPhone.response = data.mfa ? data.mfa : {};

      if(!this.mfa.noPhone.response.success){
        this.abortMFA({
          error: this.mfa.noPhone.response.errorMessage,
          console: 'DoEntity/815 finished but submit not successful'
        });
      } else {
        this.mfaSuccess({
          body: 'You have been placed in a no cell phone group. Please be aware you will need to contact the Helpdesk if you experience account access issues.'
        });

      }

          
    }).fail( () => {
      this.abortMFA({
        console: 'DoEntity/815 failed'
      });
    });


  }//END OF NO PHONE SUBMIT


  ssprStep1Submit(e) {
    //console.log('SUBMITTING NO PHONE');
    e.preventDefault();//Prevent Natural Submission

    //Prevent double click submissions
    if(!this.submitting) {
      this.submitting = true;
    } else {
      //console.log('In process, cannot submit twice');
      return;
    }

    //Assign Post Values
    this.sspr.step1.post = {};
    this.sspr.step1.emailString = this.sspr.step1.els.emailInput.value;
    this.sspr.step1.post.email = this.sspr.step1.emailString.trim();

    //console.log(this.sspr.step1.post);

    if(!this.sspr.step1.post.email.length){
      this.loader.remove();
      this.submitting = false;
      this.sspr.step1.els.emailError.textContent = '';
      this.sspr.step1.els.emailError.textContent = 'Error: Please enter an alternate email address.';
      this.elementSwell(this.sspr.step1.els.emailBlock);
      this.sspr.step1.els.emailInput.classList.add('fl-show-user');
      this.sspr.step1.els.emailInput.focus();
      return;
    }

    //stop multiple submission of an invalid email address
    let exactRepost = this.sspr.step1.postString && JSON.stringify(this.sspr.step1.post) === this.sspr.step1.postString;
    let postSuccessful = this.sspr.step1.response && this.sspr.step1.response.codeSent;

    var emailBlockError = () => {
      if(this.loader) this.loader.remove();
      this.submitting = false;
      this.sspr.step1.els.emailError.textContent = '';
      this.sspr.step1.els.emailError.textContent = this.sspr.step1.response.errorMessage ? ('Error: ' + this.sspr.step1.response.errorMessage) : 'Error: Email is not valid!';
      this.sspr.step1.els.emailInput.classList.add('fl-show-user');
      this.elementSwell(this.sspr.step1.els.emailBlock);
      this.sspr.step1.els.emailInput.focus();

    }

    if(exactRepost && !postSuccessful){
      emailBlockError();
      return;
    }

    this.loader = new ajaxLoader(document.querySelector('.sspr-swal .swal2-content'));
    $.ajax({
      method: "POST",
      url: "/asyncloader/DoEntity/818",
      dataType: "json",
      data: this.sspr.step1.post
    }).done((data) => {
      //console.log(data);

      if(!data){
        this.abortSSPR({ console: 'DoEntity/818 returned no data' });
        return;
      } else if(data.error === 'error') {
        this.abortSSPR({ console: 'DoEntity/818 returned an error' });
        return;
      }

      this.sspr.step1.postString = JSON.stringify(this.sspr.step1.post);
      this.sspr.step1.response = data.sspr ? data.sspr : {};
         
      if(this.sspr.step1.response.codeSent){
        //Successful
        this.loader.remove();
        this.submitting = false;

        let bodyHtml = '<p>Alternate email verification sent to <span class="highlight">' + this.sspr.step1.post.email + '</span>. Please check your alternate email for a verification link to complete the registration process.</p><p class="highlight">The verification link will expire in 24 hours.</p>';

        swal2({
          width: '35rem',
          customClass: 'sspr-swal',
          allowOutsideClick: true,
          title: 'Please Confirm Your Email',
          html: bodyHtml,
          type: 'info',
          focusConfirm: true,
          confirmButtonText: 'Close',
        });
          
      } else{
        //Unsuccessful
        emailBlockError();
      }

    }).fail( () => {
      this.abortSSPR({
        console: 'DoEntity/818 failed'
      });
    });

  }//END OF ssprStep1Submit


  mfaSuccess(options){
    if(!options) options = {};
    this.loader.remove();
    this.submitting = false;

    let headingText = options.heading ? options.heading : 'Success!';
    let bodyHtml = options.body ? options.body : 'The form was submitted successfully.';
    let btnText = this.data.sspr.showPrompt ? 'Continue' : 'Close';
    let swalWidth = options.width ? options.width : '';

    swal2({
      width: swalWidth,
      customClass: 'sspr-swal',
      allowOutsideClick: !this.data.sspr.showPrompt,
      title: headingText,
      html: bodyHtml,
      type: 'success',
      focusConfirm: true,
      confirmButtonText: btnText,

    }).then((result) => {
      if(result.value && this.data.sspr.showPrompt) {
          this.ssprPrompt();
      }
    });

  }


  mfaStep1(cb) {
    //Launch Popup
    swal2({
      width: '48rem',
      allowOutsideClick: false,
      customClass: 'sspr-swal mfa-step-1 has-footer-link',
      showConfirmButton: false,
      focusConfirm: false,
      title: this.mfa.step1.els.heading,
      html: this.mfa.step1.els.form,
      onBeforeOpen: () => {
        sweetAlertCustom();
        //Prevent focus from going to the close button.
        let closeBtn = document.querySelector('.swal2-popup button.swal2-close');
        if(closeBtn) closeBtn.setAttribute('tabindex', '-1');
      },
      onOpen: () => {
        let closeBtn = document.querySelector('.swal2-popup button.swal2-close');
        if(closeBtn) closeBtn.removeAttribute('tabindex');
        if(cb && typeof cb === 'function') cb();
      }
    });

  }


  mfaStep2(cb) {

    //Launch Popup
    this.submitting = false;
    swal2({
      width: '40rem',
      allowOutsideClick: false,
      customClass: 'sspr-swal mfa-step-2 has-footer-link',
      showConfirmButton: false,
      focusConfirm: false,
      title: this.mfa.step2.els.heading,
      html: this.mfa.step2.els.form,
      onBeforeOpen: () => {
        sweetAlertCustom();
        //Prevent focus from going to the close button.
        let closeBtn = document.querySelector('.swal2-popup button.swal2-close');
        if(closeBtn) closeBtn.setAttribute('tabindex', '-1');
      },
      onOpen: () => {
        let closeBtn = document.querySelector('.swal2-popup button.swal2-close');
        if(closeBtn) closeBtn.removeAttribute('tabindex');
        if(cb && typeof cb === 'function') cb();
      }
    });

  }


  mfaNoPhone(cb){
    //console.log('Launch No Phone');
    this.submitting = false;

    //Launch Popup
    swal2({
      width: '45rem',
      allowOutsideClick: false,
      customClass: 'sspr-swal mfa-no-phone-swal',
      showConfirmButton: false,
      focusConfirm: false,
      title: this.mfa.noPhone.els.heading,
      html: this.mfa.noPhone.els.form,
      onBeforeOpen: () => {
        sweetAlertCustom();
        //Prevent focus from going to the close button.
        let closeBtn = document.querySelector('.swal2-popup button.swal2-close');
        if(closeBtn) closeBtn.setAttribute('tabindex', '-1');
      },
      onOpen: () => {
        let closeBtn = document.querySelector('.swal2-popup button.swal2-close');
        if(closeBtn) closeBtn.removeAttribute('tabindex');
        if(cb && typeof cb === 'function') cb();
      }
    });

  }



  ssprStep1(cb) {
    //console.log('sspr step 1 launch');
    //Launch Popup
    swal2({
      width: '48rem',
      allowOutsideClick: false,
      customClass: 'sspr-swal sspr-step-1',
      showConfirmButton: false,
      focusConfirm: false,
      title: this.sspr.step1.els.heading,
      html: this.sspr.step1.els.form,
      onBeforeOpen: () => {
        sweetAlertCustom();
        //Prevent focus from going to the close button.
        let closeBtn = document.querySelector('.swal2-popup button.swal2-close');
        if(closeBtn) closeBtn.setAttribute('tabindex', '-1');
      },
      onOpen: () => {
        let closeBtn = document.querySelector('.swal2-popup button.swal2-close');
        if(closeBtn) closeBtn.removeAttribute('tabindex');
        if(cb && typeof cb === 'function') cb();
      }
    });

  }


  //multipurpose abort that can be used after mfa data is posted.
  abortMFA(options){
  if(!options){
    let options = {};
  }

	this.loader.remove();
	this.submitting = false;

  if(options.console){
    console.info(options.console);
  }

  let bodyHtml = options.error ? options.error : 'Something went wrong. We could not complete the request.';
	let btnText = this.data.sspr.showPrompt ? 'Continue' : 'Close';

	swal2({
    allowOutsideClick: !this.data.sspr.showPrompt,
	  customClass: 'sspr-swal',
	  title: 'Error',
	  html: bodyHtml,
	  type: 'error',
	  focusConfirm: true,
	  confirmButtonText: btnText
    }).then((result) => {
      //console.log(result);
      if(result.value && this.data.sspr.showPrompt) {
	      this.ssprPrompt();
      }
    });
  }

  abortSSPR(options){
  if(!options){
    let options = {};
  }

  if(this.loader) this.loader.remove();
  this.submitting = false;

  if(options.console){
    console.info(options.console);
  }

  let bodyHtml = options.error ? options.error : 'Something went wrong. We could not complete the request.';

  swal2({
    allowOutsideClick: true,
    customClass: 'sspr-swal',
    title: 'Error',
    html: bodyHtml,
    type: 'error',
    focusConfirm: true,
    confirmButtonText: 'Close'
    });
  }






  ssprPrompt(){
    console.log( this.data );
    console.log("---- ^____^ ----");
    if(!this.data.sspr.showPrompt) return;
    //alert('SSPR Prompt would run now...');

    this.sspr = {};
    this.ssprBuildStep1();
    this.ssprStep1();

  }


}//End of class





//SSPR is turned on in theme setting and called from ksu_foundation_es6.js
function ssprInit() {

  var sspr;

  $.getJSON( '/asyncloader/getEntity/814', (data) => {
      let ssprData = data;
      sspr = new Sspr({
        data : ssprData
      });
   }).fail(() => {
    console.info('SSPR webservice failed');
   }); 

}
