class ClassSchedule {

  constructor(options){
  	this.setDefaults(options);
  	this.id = options.id;
	  this.data = options.data;
	  this.format = options.format;
    this.showTba = options.showTba;
    this.tbaTermCode = options.tbaTermCode;
	  //console.log('Data: ', this.data); 
  }










  start(){
    this.getElements(this.id);
    this.checkWidth();
    this.currentTime = moment();
    this.buildMonthDropdown(this.id);
    this.setListeners();
    this.drawCalendar(this.currentTime);
    this.drawTimeblock();
    
    this.trueMinuteSync = window.setInterval(this.trueMinuteCheck.bind(this), 1000);
  }













  //before starting the ticking clock, make sure the seconds are zero. 
  trueMinuteCheck(){
    var timeOfDay = moment();
    var seconds = Number(timeOfDay.seconds());
    //console.log('Seconds: ', seconds);

    if(seconds === 0){ 
      //console.log('NOW!');
      window.clearInterval(this.trueMinuteSync);
      this.tickingClock = window.setInterval(this.updateCurrentTime.bind(this), 60000);
      this.updateCurrentTime(); 
    }
  }









  setDefaults(options){
  	options["id"] = 'id' in options ? options["id"] : 'classSchedule';
  	options["data"] = 'data' in options ? options["data"] : [];
  	options["format"] = 'format' in options ? options["format"] : 'YYYY-MM-DD';

    options["showTba"] = 'showTba' in options ? options["showTba"] : false;
    options["tbaTermCode"] = 'tbaTermCode' in options ? options["tbaTermCode"].trim() : '';
    //Block showing TBA is a valid TermCode is not supplied
    if(!options["tbaTermCode"] || isNaN(options["tbaTermCode"]) || !options["tbaTermCode"].replace(/\s/g, '').length ) {
      options["showTba"] = false;
    }
    //console.log(options["showTba"]);
    //console.log(options["tbaTermCode"]);

  }







  getElements(id){
	  this.el = document.getElementById(`${id}`);
    this.calTop = this.el.querySelector('.cal-top');
    this.calTopInner = this.el.querySelector('.cal-top-inner');
	  this.loader = new ajaxLoader(this.el);
	  this.calBodyEl = this.el.querySelector('.cal tbody');
	  this.calToggleEl = this.el.querySelector('.cal-toggle');

	  this.calView = this.el.getAttribute('data-cal-view');
	  this.calToggleEl.checked = this.calView === 'month';
    this.calMonthYearBtn = this.el.querySelector('.cal-controls [data-mo-yr]');
    this.calMonth = this.el.querySelector('.cal-controls [data-cal-month]');
    this.calMonthLong = this.el.querySelector('.cal-controls [data-cal-month] .long');
    this.calMonthShort = this.el.querySelector('.cal-controls [data-cal-month] .short');

    this.calYear = this.el.querySelector('.cal-controls [data-cal-year]');
    this.calYearLong = this.el.querySelector('.cal-controls [data-cal-year] .long');
    this.calYearShort = this.el.querySelector('.cal-controls [data-cal-year] .short');
    
    this.prevBtns = this.el.querySelectorAll('[data-prev]');
    this.nextBtns = this.el.querySelectorAll('[data-next]');
    this.todayResets = this.el.querySelectorAll('[data-today]');
    this.todayBtn = this.el.querySelector('[data-display-date-area] [data-today]');
    this.todayBtnText = this.el.querySelector('[data-display-date-area] [data-today] .text');

	  this.displayDateArea = this.el.querySelector('[data-display-date-area]');
	  this.displayDateWrap = this.el.querySelector('[data-display-date]');
    this.displayDateLong = this.el.querySelector('[data-display-date] h4 .long');
    this.displayDateShort = this.el.querySelector('[data-display-date] h4 .short');

    this.timeBlock = this.el.querySelector('[data-timeblock]');
    this.weekDotWraps = this.el.querySelectorAll('[data-dots]');
    this.weekAriaDotWraps = this.el.querySelectorAll('[data-aria-dots]');
  }








  briefSwell(e){
  	e.currentTarget.removeEventListener('transitionend', this.briefSwell);
  	e.currentTarget.classList.remove('swell');
  }









  monthYearBtnClick(e){
  	//console.log(this);
  	let btn = e.currentTarget;
  	let rMonth = this.referenceDate.month();
  	let rYear = this.referenceDate.year();
  	let rDate = this.referenceDate.date();
  	let btnYear = btn.getAttribute('data-year') ? btn.getAttribute('data-year') : null;
  	let btnMonth = btn.getAttribute('data-month') ? btn.getAttribute('data-month') : null;

  	//console.log(rMonth, rYear, rDate);

    let mYear, mMonth, mDate, momentTarget;

    if(btnYear){
      mYear = btnYear;
      mMonth = Number(rMonth) + 1;
      mDate = rDate;
    }else if(btnMonth){
      mYear = rYear;
      mMonth = Number(btnMonth) + 1;
      mDate = rDate;
    }

    momentTarget = mYear + '-' + mMonth + '-' + mDate;

    //console.log(momentTarget);
    //console.log(moment(momentTarget, 'YYYY-MM-DD').format());

    //Date might be higher than available in target month.
    while(!moment(momentTarget, 'YYYY-MM-DD').isValid()){
      mDate--;
      momentTarget = mYear + '-' + mMonth + '-' + mDate;
    }

    this.drawCalendar(moment(momentTarget, 'YYYY-MM-DD'));

  }



















	buildMonthYearBtn(type, number){
  	  let myBtn = document.createElement('button');
  	  myBtn.setAttribute('type', 'button');
  	  myBtn.classList.add('button');
	  switch (type) {
	    case 'year':
	      myBtn.setAttribute('data-year', number);
	      myBtn.textContent = number;
	      break;
	    case 'month':
	    myBtn.setAttribute('data-month', number);
	    //just to have access to moment abbreviations, year and date do not matter here, just month.
	    let monthMoment = moment('2019-' + number + '-01', 'YYYY-MM-DD');
	    //console.log(monthMoment.month());
	    myBtn.textContent = monthMoment.format('MMM');
	    myBtn.setAttribute('data-month', monthMoment.month());
	      break;
	    default:
	  }
	  myBtn.addEventListener('click', this.monthYearBtnClick.bind(this));
	  return myBtn;
  	}


















  buildMonthDropdown(id){
  	let dropdown = document.createElement('div');
  	dropdown.setAttribute('class', 'mo-yr-dropdown dropdown-pane dropdown-triangle v2-area');
  	dropdown.setAttribute('data-dropdown', '');
  	dropdown.setAttribute('data-close-on-click', 'true');
  	dropdown.setAttribute('data-position', 'bottom');
  	dropdown.setAttribute('data-alignment', 'left');
  	dropdown.setAttribute('data-trap-focus', 'true');
  	dropdown.setAttribute('tabindex', '0');
  	dropdown.id = `${id}-mo-yr-dropdown`;
  	let inner = document.createElement('div');
  	inner.setAttribute('class', 'inner');
  	dropdown.appendChild(inner);
  	this.monthYearDrop = dropdown;
  	this.calMonthYearBtn.setAttribute('data-toggle', dropdown.id);
  	
  	let closeBtn = document.createElement('button');
  	closeBtn.setAttribute('type', 'button');
  	closeBtn.setAttribute('data-close', dropdown.id);
  	closeBtn.setAttribute('class', 'close-button');
  	let closeBtnIcon = document.createElement('span');
  	closeBtnIcon.classList.add('dripicons-cross');
  	closeBtnIcon.setAttribute('aria-hidden', 'true');
  	closeBtn.appendChild(closeBtnIcon);
  	let closeBtnText = document.createTextNode('Close');
  	closeBtn.appendChild(closeBtnText);
  	inner.appendChild(closeBtn);
  	this.monthYearDropCloseBtn = closeBtn;

  	let btnWrapper = document.createElement('div');
  	btnWrapper.classList.add('btnWrapper');

  	let thisYear = Number(this.currentTime.format('YYYY'));
  	let lastYear = thisYear - 1;
  	let nextYear = thisYear + 1;

  	btnWrapper.appendChild(this.buildMonthYearBtn('year', lastYear));
  	btnWrapper.appendChild(this.buildMonthYearBtn('year', thisYear));
  	btnWrapper.appendChild(this.buildMonthYearBtn('year', nextYear));
  	//console.log(this.currentTime);
  	//make month buttons
  	for(var i = 1; i <= 12; i++){
  		btnWrapper.appendChild(this.buildMonthYearBtn('month', i));
  	}

  	inner.appendChild(btnWrapper);

  	this.el.appendChild(dropdown);
  	$(dropdown).foundation();
  	//Foundation.reflow($(dropdown), 'dropdown');

  }

	















  updateMonthYearDropdown(){
  	//console.log(this);
  	let activeMonth = this.referenceDate.month();
  	let activeYear = this.referenceDate.year();
  	let activeYearBtn = this.monthYearDrop.querySelector("[data-year='" + activeYear + "']");
  	if(!activeYearBtn || !activeYearBtn.classList.contains('active')) {
  		let yearBtns = this.monthYearDrop.querySelectorAll("[data-year]");
  		for(var i = 0; i < yearBtns.length; i++){
  			yearBtns[i].classList.remove('active');
  		}
  		if(activeYearBtn) activeYearBtn.classList.add('active');
  	}
  	let activeMonthBtn = this.monthYearDrop.querySelector("[data-month='" + activeMonth + "']");
  	if(!activeMonthBtn.classList.contains('active')) {
  		let monthBtns = this.monthYearDrop.querySelectorAll("[data-month]");
  		for(var i = 0; i < monthBtns.length; i++){
  			monthBtns[i].classList.remove('active');
  		}
  		activeMonthBtn.classList.add('active');
  	}
  }

















  setListeners(){
  	window.addEventListener('resize', function(e){
  	  if(this.checkingWidth) return;
  	  this.checkingWidth = true;
  	  setTimeout(function(){
  		this.checkWidth();
  	  }.bind(this), 500);
  	}.bind(this));
  	this.calToggleEl.addEventListener('change', this.calToggle.bind(this));

    for(let i = 0; i < this.prevBtns.length; i++){
	  this.prevBtns[i].addEventListener('click', this.previousClick.bind(this));
    }
    
    for(let i = 0; i < this.nextBtns.length; i++){
  	  this.nextBtns[i].addEventListener('click', this.nextClick.bind(this));
    }

  	//Accessibility for month/year dropdown requires next three listeners
  	$(this.monthYearDrop).on('show.zf.dropdown', function () {
    	this.monthYearDrop.focus();
    }.bind(this));

  	this.monthYearDrop.addEventListener('keydown', function(e){
      if(e.target.classList.contains('dropdown-pane')){
        disableBackTab(e);
      }
    });

  	this.monthYearDropCloseBtn.addEventListener('click', function(e){
      setTimeout(function(){ this.calMonthYearBtn.focus(); }.bind(this), 100);
    }.bind(this));

	for(let i = 0; i < this.todayResets.length; i++){
		this.todayResets[i].addEventListener('click', this.resetToday.bind(this));
	}
  	
  window.addEventListener('MyDashOrderChanged', this.myDashTimelineReset.bind(this));
  window.addEventListener('MyDashFilterChanged', this.myDashTimelineReset.bind(this));

  }

  //specific scenario when packery reloads order, the scrollable area returns to 0. We want to prevent this behavior, so we can scroll to the current time and show the appropriate width version.
  myDashTimelineReset(){
    this.checkWidth();
    this.moveTimeline({
      scroll: true
    });
  }

  


  //Redraws calendar to today's view and clicks on today's date
  //triggers full reset, including object data and schedule update
  resetToday(e){
      
      function resetTodayAfterCalRedraw(){
        this.calBodyEl.removeEventListener('classScheduleCalendarBuilt', todayAfterRedraw);
        //console.log('removed');
        EventDispatcher.Custom({
          "el" : this.todayEl,
          "event" : "click"
        }); 
      }

      var todayAfterRedraw = resetTodayAfterCalRedraw.bind(this);

      if(!this.todayInView){
        this.resettingToday = true;
        this.drawCalendar(this.currentTime);
        this.calBodyEl.addEventListener('classScheduleCalendarBuilt', todayAfterRedraw);

      }else if(this.todayEl === this.selectedDateEl){
        this.todayEl.addEventListener('transitionend', this.briefSwell);
        this.todayEl.classList.add('swell');
      }else{
        EventDispatcher.Custom({
          "el" : this.todayEl,
          "event" : "click"
        }); 
      }

        this.moveTimeline({
            scroll: true,
            smooth: true
        });
        

  }














  //Always monitoring width of this widget. Narrow view condenses alot of stuff for responsive view.
  checkWidth(){
	if (this.el.offsetWidth > 381 && !this.el.classList.contains('wide')) {
	  this.el.classList.remove('narrow');
	  this.el.classList.add('wide');
	  this.calToggleEl.setAttribute('data-false', 'Week');
	  this.calToggleEl.setAttribute('data-true', 'Month');
	  flSwitchSetup(this.calToggleEl);
	} else if (this.el.offsetWidth <= 381 && !this.el.classList.contains('narrow')) {
	  this.el.classList.remove('wide');
	  this.el.classList.add('narrow');
	  this.calToggleEl.setAttribute('data-false', 'Wk');
	  this.calToggleEl.setAttribute('data-true', 'Mo');
	  flSwitchSetup(this.calToggleEl);
	}
	this.checkingWidth = false;
  }


  //Changes the schedule you are currently viewing
  //Updates the calendar if needed and updates object data
  dateClick(e){
	//alert('Hello World! ' + e.currentTarget.getAttribute('data-date'));
	this.selectedDate = moment(e.currentTarget.getAttribute('data-date'), this.format);
  this.drawSchedule();
	//if the date clicked is OUT OF RANGE
	//redraw calendar passing that date as an argument
	if(e.currentTarget.classList.contains('out-range')){
		this.drawCalendar(moment(e.currentTarget.getAttribute('data-date'), this.format));
	}else{
		for(let i = 0; i < this.showingDates.length; i++){
			if(this.showingDates[i].getAttribute('data-date') === this.selectedDate.format(this.format)){
				this.showingDates[i].classList.add('active');
			} else {
				this.showingDates[i].classList.remove('active');
			}
		}
		this.selectedDateEl = e.currentTarget;
		this.selectedInView = true;

    //only reset event dots at this time if currently resetting to todays date or selecting a new date while in month view
    if(this.resettingToday){
      this.weekDotsEval();
      this.resettingToday = null;
    }else{
      //console.log(this.weekDotsStart);
      //console.log(moment(this.selectedDate).startOf('week').format(this.format));
      if(this.calView === 'month' && this.weekDotsStart !== moment(this.selectedDate).startOf('week').format(this.format)){

        //if in week view, we would never need to re-evaluate weekDots
        this.weekDotsEval();
      }
    }
    

	}


	

	return;
  }













  //creates hour block markup before appending to DOM
  buildHourBlock(militaryTime){
  	let digit = militaryTime / 100;
  	let suffix = 'AM';
  	let midnight;
  	if(digit === 0){
  		digit = 12;
  	}else if(digit === 12){
  		suffix = 'PM';
  	}else if(digit > 12){
  		digit -= 12;
  		if(digit !== 12){
  		  suffix = 'PM';
  		}else{
  			midnight = true;
  		}
  	}

  	let hourBlock = document.createElement('div');
  	hourBlock.classList.add('hour-block');
  	hourBlock.setAttribute('data-time', militaryTime);
    hourBlock.setAttribute('aria-hidden', 'true');
  	if(midnight){
  		hourBlock.classList.add('midnight');
  	}

  	let timeDiv = document.createElement('div');
  	timeDiv.classList.add('time');
  	let timeSpan = document.createElement('span');
  	timeSpan.textContent = digit + suffix;
  	timeDiv.appendChild(timeSpan);

  	let lineDiv = document.createElement('div');
  	lineDiv.classList.add('divide');

  	hourBlock.appendChild(timeDiv);
  	hourBlock.appendChild(lineDiv);

  	return hourBlock;
  }

















  drawTimeblock(){
  	let timeBlockPad = document.createElement('div');
  	timeBlockPad.setAttribute('data-timeblock-pad', '');

	  let timeBlockPosition = document.createElement('div');
  	timeBlockPosition.setAttribute('data-timeblock-position', '');

  	timeBlockPad.appendChild(timeBlockPosition);

  	for(let i = 0; i <= 24; i++){
  		timeBlockPosition.appendChild(this.buildHourBlock(i * 100));
  	}
  	this.timeBlock.appendChild(timeBlockPad);

  	this.timeBlockEventsWrapper = document.createElement('div');
  	this.timeBlockEventsWrapper.setAttribute('data-timeblock-events-wrapper', '');
  	this.timeBlockEvents = document.createElement('div');
  	this.timeBlockEvents.setAttribute('data-timeblock-events', '');
  	this.timeLine = document.createElement('div');
  	this.timeLine.setAttribute('data-timeline', '');
    //this.timeLineOverlay = document.createElement('div');
    //this.timeLineOverlay.setAttribute('data-timeline', '');
    //this.timeLineOverlay.classList.add('overlay');
    this.timeBlockEventsWrapper.appendChild(this.timeLine);
  	this.timeBlockEventsWrapper.appendChild(this.timeBlockEvents);
    //this.timeBlockEventsWrapper.appendChild(this.timeLineOverlay);
  	

  	timeBlockPosition.appendChild(this.timeBlockEventsWrapper);

    //establish height per hour on time display

    this.timeBlockHourHeight = this.timeBlock.querySelector('.hour-block').offsetHeight;
    this.timeBlockMinuteHeight = this.timeBlockHourHeight / 60;
    //console.log('Hour Height: ' + this.timeBlockHourHeight);
    //console.log('Minute Height: ' + this.timeBlockMinuteHeight);

  //initial timeline positioning
  this.moveTimeline({
    scroll: true
  });

	this.drawSchedule();
  }



























  //if it is today, update button, scheduleToday, and parent attribute
  updateTodayBtn(bool){
      if(bool){
        this.el.setAttribute('data-today-selected', 'true');
        //this.scheduleToday = true;
        this.todayBtnText.textContent = 'Today';
        this.todayBtn.classList.add('active');
      }else{
        this.el.setAttribute('data-today-selected', 'false');
        //this.scheduleToday = false;
        this.todayBtn.classList.remove('active');
        this.todayBtnText.textContent = 'View Today';
      }
  }













  calToggle(e){

	this.calView = this.calToggleEl.checked ? 'month' : 'week';
	this.el.setAttribute('data-cal-view', this.calView);

	let isWeek = (this.calView  === 'month') ? 1 : 0;

	$.ajax(`/api/internal/POST/user/v1/field_ext_preferences/class_schedule_cal_view/${isWeek}`).done(function () {
    });


	if(this.selectedInView && this.selectedDate && this.selectedDateEl.classList.contains('prev-month')){
		//console.log('1');
		this.drawCalendar(moment(this.referenceDate).startOf('month'));
	}else if(this.selectedInView && this.selectedDate && this.selectedDateEl.classList.contains('next-month')){
		//console.log('2');
		this.drawCalendar(moment(this.referenceDate).endOf('month'));
	}else if(this.selectedInView && this.selectedDate){
		//console.log('3');
		this.drawCalendar(this.selectedDate);
	}else if(this.todayInView && this.currentTime){
		//console.log('4');
		this.drawCalendar(this.currentTime);
	}else if (this.calView  === 'week'){
		//console.log('5');
		this.drawCalendar(moment(this.referenceDate).startOf('month'));
	}else if (this.calView  === 'month'){
		//console.log('6');
		this.drawCalendar(this.referenceDate);
	}
  }
















  drawWeek(startMoment){
	let weekRow = document.createElement('tr');
	for(let i = 0; i < 7; i++){
		let dayMoment = moment(startMoment).add(i, 'days');
		let dayCell = document.createElement('td');
		let dayButton = document.createElement('button');
		dayButton.classList.add('day');
		this.showingDates.push(dayButton);

		if (dayMoment.format(this.format) === this.currentTime.format(this.format)) {
			dayButton.classList.add('today');
			this.todayEl = dayButton;
			this.todayInView = true;
			if(!this.loaded){
			this.selectedDate = moment(dayMoment);
			}
		}

		if (this.selectedDate && this.selectedDate.format(this.format) === dayMoment.format(this.format)) {
			dayButton.classList.add('active');
			this.selectedDateEl = dayButton;
			this.selectedInView = true;
		}

		if (dayMoment.month() !== this.referenceDate.month()){
			dayButton.classList.add('out-range');
		}else{
			dayButton.classList.add('in-range');
		}
		if (dayMoment.month() > this.referenceDate.month()){
			dayButton.classList.add('next-month');
		}
		if (dayMoment.month() < this.referenceDate.month()){
			dayButton.classList.add('prev-month');
		}

		dayButton.textContent = dayMoment.format('D');
		dayButton.setAttribute('data-date', dayMoment.format(this.format));
    dayButton.setAttribute('data-day-of-week', dayMoment.day());
		dayButton.addEventListener('click', this.dateClick.bind(this));
		dayCell.appendChild(dayButton);
		weekRow.appendChild(dayCell);
	}
	return weekRow;
  }




















  drawCalendar(refDate){
	//return if no redraw is necessary

  let hasFocus = document.activeElement ? document.activeElement : document.body;
  let focusInCal;
  let focusDate;

  if(hasFocus.getAttribute('data-date')) {
    focusInCal = true;
    focusDate = hasFocus.getAttribute('data-date');
  }

	this.referenceDate = moment(refDate);

	//this.calView = this.calToggleEl.checked ? 'month' : 'week';


	if(this.calMonthLong.textContent !== this.referenceDate.format('MMMM')){

		$(this.calMonth).fadeOut(300, function(){
			this.calMonthLong.textContent = this.referenceDate.format('MMMM');
			this.calMonthShort.textContent = this.referenceDate.format('MMM');
			$(this.calMonth).fadeIn(300, function(){
			}.bind(this));
		}.bind(this));
			
	}

	if(this.calYearLong.textContent !== this.referenceDate.format('YYYY')){

		$(this.calYear).fadeOut(300, function(){
			this.calYearLong.textContent = this.referenceDate.format('YYYY');
			this.calYearShort.textContent = "'" + this.referenceDate.format('YY');
			$(this.calYear).fadeIn(300, function(){
			}.bind(this));
		}.bind(this));
			
	}


	this.showingWeeks = []; 
	this.selectedDateEl = null;
	this.selectedInView = null;
	this.todayInView = null;
	this.showingDates = [];

	if(this.calView === 'month'){
		if(this.el.classList.contains('show-week-arrows')){
			this.el.classList.remove('show-week-arrows');
		}
		let monthStart = moment(this.referenceDate).startOf('month');
		let monthEnd = moment(this.referenceDate).endOf('month');
		let weekStart = moment(monthStart).startOf('week');

			
		while(weekStart.isBefore(monthEnd)){

			this.showingWeeks.push(this.drawWeek(weekStart));
			weekStart.add(7, 'days');
		}

	} else if (this.calView === 'week'){
		if(this.el.classList.contains('show-month-arrows')){
			this.el.classList.remove('show-month-arrows');
		}

		var weekStart = moment(this.referenceDate).startOf('week');
		this.showingWeeks.push(this.drawWeek(weekStart));
	}

	//this.el.setAttribute('data-weeks', this.showingWeeks.length);

	$(this.calBodyEl).fadeOut(300, function(){

		this.calBodyEl.innerHTML = '';

		for(var i = 0; i < this.showingWeeks.length; i++){
			this.calBodyEl.appendChild(this.showingWeeks[i]);
		}
		
		if(this.calView === 'month'){

			if(!this.el.classList.contains('show-month-arrows')){
				this.el.classList.add('show-month-arrows');
			}

		} else if (this.calView === 'week'){

			if(!this.el.classList.contains('show-week-arrows')){
				this.el.classList.add('show-week-arrows');
			}
		}



    EventDispatcher.Custom({
        "el" : this.calBodyEl,
        "event" : "classScheduleCalendarBuilt"
    }); 

    //update weekDots after calendar has redrawn
    //unless we are in the process of a reset
    //in that case, we will evaluate weekDots 
    if(!this.resettingToday){
      this.weekDotsEval();
    }


    //we need to find the offsetHeight without interfering with jQuerys fade
    this.calBodyEl.style.visibility = 'hidden';
    this.calBodyEl.style.display = 'table-row-group';
    this.calTop.style.height = this.calTopInner.offsetHeight + 'px';
    this.calBodyEl.style.visibility = '';
    this.calBodyEl.style.display = 'none';


		$(this.calBodyEl).fadeIn(300, function(){
      setTimeout(function(){
        EventDispatcher.PackeryResize(this.calBodyEl);
      }.bind(this), 300);
			if(focusInCal && this.selectedDateEl){
				this.selectedDateEl.focus();
			}
			if(!this.loaded){
				this.loaded = true;
				this.el.classList.add('loaded');
				this.loader.remove();
				EventDispatcher.NodeLoadedForTheFirstTime(this.calBodyEl);
			}
		}.bind(this));

    

	}.bind(this));

	//update month/year dropdown
	this.updateMonthYearDropdown();
	return;
  }





  militaryTimeToNormalTime(ts) {

    // ts usually looks like 1700 so, convert it to 5:00 PM
    if (ts == undefined)
      return "";

    var min = ts.slice(-2);
    var hour = "";
    var ampm = "AM";

    if (ts.length == 4){
      if(ts[0] === '0'){
        hour = ts[1];
      } else {
        hour = ts.slice(0, 2);
      }
    }


    if (ts.length == 3)
      hour = ts.slice(0, 1);

    if (hour == "")
      return "";

    if (hour >= 12) ampm = "PM";
    if (hour > 12) hour -= 12;

    return hour + ":" + min + " " + ampm;
  }

  addPropertiesToData(eventData) {
    
    let startString = eventData.Begin_Time;
    let endString = eventData.End_Time;

    let startMin = Number(startString.slice(-2));
    let endMin = Number(endString.slice(-2));
    let startHour;
    let endHour;

    let totalMinutes = 0;

    if (startString.length == 4){
      startHour = Number(startString.slice(0, 2));
    } else if(startString.length == 3){
      startHour = Number(startString.slice(0, 1));
    }else{
      startHour = 0;
    }

    if (endString.length == 4){
      endHour = Number(endString.slice(0, 2));
    } else if(endString.length == 3){
      endHour = Number(endString.slice(0, 1));
    }else{
      endHour = 0;
    }

    if(endHour > startHour){
      totalMinutes += (60 - startMin);
      totalMinutes += ((endHour - (startHour + 1)) * 60);
      totalMinutes += endMin;
    }else if(endHour === startHour) {
      totalMinutes += (endMin - startMin);
    }

    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    let eventLength = '';

    if(hours){
      eventLength += hours + 'hr';
      //eventLength += hours > 1 ? 'hrs' : 'hr';
    }
    if(hours && minutes){
      eventLength += ' ';
    }
    if(minutes){
      eventLength += minutes + 'min';
    }

    //if(startHour > 12) startHour -= 12;
    //console.log(startHour, startMin, this.timeBlockMinuteHeight);


    eventData.height = (totalMinutes * this.timeBlockMinuteHeight);
    eventData.top = ((startHour * 60) + startMin) * this.timeBlockMinuteHeight;
    eventData.eventLength = eventLength;
    eventData.Begin_Time_Normal = this.militaryTimeToNormalTime(eventData.Begin_Time);
    eventData.End_Time_Normal = this.militaryTimeToNormalTime(eventData.End_Time);

  }


  squeezeColumns(currentEventData){
          if (currentEventData.squeezed === this.dataLoop) {
          //console.log('already squeezed');
          return; //only want to squeeze data once per loop
          }
          currentEventData.squeezed = this.dataLoop;
          //console.log('Squeeze: ', this.squeeze);
          currentEventData.left -= ((this.squeezePercent / 100) * currentEventData.left);
          currentEventData.width -= ((this.squeezePercent / 100) * currentEventData.width);
          if (currentEventData.left < 0) currentEventData.left = 0;
          if (currentEventData.width < 0) currentEventData.width = 0;

          //console.log(currentEventData.left, ' ', currentEventData.width);

          //recursion into the shared items shared items, this shouldn't be an infinite loop since we are setting the squeezed property once this runs once.
          //if(currentEventData.gapFill === 'false'){ 
            for(let i = 0; i < currentEventData.sharedWith.length; i++){
              this.squeezeColumns(currentEventData.sharedWith[i]);
            }
          //}

        }


  determineLeftAndWidth(eventData){

    //iterate up to this index and see if any overlap
    //add references to eventData sharedWith array
    let beginTime = Number(eventData.Begin_Time);

    for(var i = 0; i < this.dataLoop; i++){
      if(Number(this.showingEventsData[i].End_Time) > beginTime){
        if(eventData.sharedWith === undefined) eventData.sharedWith = [];
        
        //sharedWith array needs to ascend by LEFT values.
        //We cannot use sort method since 'stable sort' is not supported in ie11 or edge. we need 'stable' to preserve initial order which is already sorted by Begin_Time.

        if(eventData.sharedWith.length > 0){

          for(var j = 0; j < eventData.sharedWith.length; j++){
            if(this.showingEventsData[i].left < eventData.sharedWith[j].left) {
              //put it before this iteration
              eventData.sharedWith.splice(j, 0, this.showingEventsData[i]);
              break;
            } else if(this.showingEventsData[i].left === eventData.sharedWith[j].left){
              continue;
            }

            if(j === (eventData.sharedWith.length - 1)){
              //last iteration
              eventData.sharedWith.push(this.showingEventsData[i]);
            }

          }


        }else{
          eventData.sharedWith.push(this.showingEventsData[i]);
        }

        
      } 
    }


    //Very first placement
    if(eventData.sharedWith === undefined){
      eventData.left = 0;
      eventData.width = 100;
      eventData.sharedWith = [];
      //console.log('First rendered item: ', eventData);
      eventData.gapFill = 'false';
      this.nextLeft = 100;
      return;
    }

    //YES there is sharedWith Data
    
    for(let i = 0; i < eventData.sharedWith.length; i++){
      //console.log('START');
      //console.log(this.nextLeft);
      let thisLeft = eventData.sharedWith[i].left;
      //console.log('thisLeft: ', thisLeft);
      let thisWidth = eventData.sharedWith[i].width;
      //console.log('thisWidth: ', thisWidth);


      if(i === 0 && thisLeft !== 0){
        //first iteration found a zero left opening.
        //console.log('AAA');
        eventData.left = 0;
        eventData.width = thisLeft;
        this.nextLeft = eventData.width;
        eventData.gapFill = 'true';
        break;
      }else if(i === 0 && thisLeft === 0){
        //set initialize nextLeft for this set
        this.nextLeft = thisLeft + thisWidth;
      }



      if(thisLeft === this.nextLeft){
        //next event is flush against
        //console.log('BBB');
        this.nextLeft = thisLeft + thisWidth;
      } else if (thisLeft > this.nextLeft){
        //there's a gap between events here, fill and break
        //console.log('CCC');
        eventData.left = this.nextLeft;
        eventData.width = (thisLeft - this.nextLeft);
        eventData.gapFill = 'true';
        break;       
      }

      if(i === (eventData.sharedWith.length - 1)){
        //last iteration.
        //if we havent continued or broken out of the loop yet, we need to MAKE ROOM and tack this on the end.



        //console.log('DDD');


        //find how many span the width of the chart
        let stairCaseSpan = [];

        function fillStairCaseSpan(eventData){
          for(var i = 0; i < eventData.sharedWith.length; i++){
            if(eventData.sharedWith[i].gapFill !== 'true'){
              if(stairCaseSpan.indexOf(eventData.sharedWith[i]) === -1) {
                stairCaseSpan.push(eventData.sharedWith[i]);
                fillStairCaseSpan(eventData.sharedWith[i]);

              }
            }

          }
        }

        fillStairCaseSpan(eventData);

        //console.log('StairCaseSpan: ', stairCaseSpan);

        let sharedNum = stairCaseSpan.length;
        //console.log('sharing space: ', sharedNum);
        let origWidth = eventData.sharedWith[i].width;
        //console.log('origWidth: ', origWidth);


        if((thisLeft + thisWidth) < (100 - origWidth)){
          //There is a gap at the end we can fill, don't need to squeeze anything
          eventData.left = thisLeft + thisWidth;
          eventData.width = 100 - eventData.left;
          eventData.gapFill = 'true';
          break;
        }

        //now we need to either consolidate the fillGaps or add this to the very end.

        //consolidate fillGaps


        eventData.left = this.nextLeft;
        //console.log('nextLeft Assigned: ', eventData.left);
        eventData.width = origWidth;
        //console.log('origWidth Assigned: ', eventData.width);
        
        let orig100 = origWidth * sharedNum;
        //console.log('orig100: ', orig100);


        let newWidth = orig100 / (sharedNum + 1);
        //console.log('newWidth: ', newWidth);
        this.squeeze = origWidth - newWidth;
        this.squeezePercent = newWidth;

        //eventData.left -= newWidth;
        //console.log('Eddie: ', eventData.left);
        //eventData.width -= this.squeeze;

        eventData.left -= ((this.squeezePercent / 100) * eventData.left);
        eventData.width -= ((this.squeezePercent / 100) * eventData.width);

        eventData.squeezed = this.dataLoop;
        eventData.gapFill = 'false';

        //Subtract squeeze from all sharedWith Data AND their corresponding sharedWith Data
          for(let i = 0; i < eventData.sharedWith.length; i++){       
            this.squeezeColumns(eventData.sharedWith[i]);
          }





      }

    }//End of Loop









  }



  buildEventBlock(eventData){
    let beginTime = Number(eventData.Begin_Time);
    let endTime = Number(eventData.End_Time); 
    let myEventEl = document.createElement('div');
    myEventEl.classList.add('eventblock-wrapper');
    myEventEl.setAttribute('tabindex', '0');
    let myEventElBG = document.createElement('div');
    myEventElBG.classList.add('eventblock-bg');
    myEventEl.appendChild(myEventElBG);

    let flexDiv = document.createElement('div');
    flexDiv.classList.add('eventblock-title-wrapper');
    let eventTitleEl = document.createElement('span');
    eventTitleEl.classList.add('eventblock-title');
    eventTitleEl.textContent = eventData.Course_Title;
    flexDiv.appendChild(eventTitleEl);
    let eventLocationEl = document.createElement('span');
    eventLocationEl.classList.add('eventblock-location');

    let locationString;
    if(eventData.Building){
    	locationString = eventData.Building
    }
    if(eventData.Room && !eventData.roomConflict){
    	locationString += (' - ' + eventData.Room.replace(/^0+/, ''));
    }
    eventLocationEl.textContent = locationString;


    //Show TBA only true if checked in theme settings. Validity of Term Code checked in setDefaults function.
    if( this.showTba ) {

      if( Number(eventData.TermCode) >= Number(this.tbaTermCode) ) {
        eventLocationEl.textContent = "T.B.A."
      }

    }



    flexDiv.appendChild(eventLocationEl);
    myEventEl.appendChild(flexDiv);

    let dayTimeLengthString;
    for(var i = 0; i < eventData.MeetingDays.length; i++){
      if(i === 0){
        dayTimeLengthString = eventData.MeetingDays[i].Abbreviated;
      }else{
        dayTimeLengthString += ' ' + eventData.MeetingDays[i].Abbreviated;
      }
      if(i === eventData.MeetingDays.length - 1){
        dayTimeLengthString += ' | ';
      }
    }

    dayTimeLengthString += eventData.Begin_Time_Normal + ' - ' + eventData.End_Time_Normal;

    dayTimeLengthString += ' (' + eventData.eventLength + ')';

    //console.log(eventData);

    let eventTimeEl = document.createElement('div');
    eventTimeEl.classList.add('eventblock-time');
    eventTimeEl.textContent = dayTimeLengthString;
    myEventEl.appendChild(eventTimeEl);

    let liveDot = document.createElement('div');
    liveDot.classList.add('livedot');
    liveDot.classList.add('dripicons-clock');
    liveDot.setAttribute('aria-hidden', 'true');
    myEventEl.appendChild(liveDot);

    myEventEl.setAttribute('data-height', eventData.height);
    myEventEl.style.height = eventData.height + 'px';
    myEventEl.setAttribute('data-top', eventData.top);
    myEventEl.style.top = eventData.top + 'px';
    myEventEl.style.left = eventData.left + '%';
    myEventEl.style.width = eventData.width + '%';

    return myEventEl;
  }



  //returns object containing:
  //ordered showing event data with duplicates filtered
  //web events
  //room conflict info
  getDailyEventData(day){

      var dayData = {};
      var myDay = moment(day);

      //Determine what events should display
      dayData.webCourses = [];
      dayData.inPerson = [];
      let viewingDayOfWeek = myDay.day();

      for(var i = 0; i < this.data.length; i++){
        let startDateStamp = this.data[i].Start_Date ? moment(this.data[i].Start_Date).valueOf() : 0;
        let endDateStamp = this.data[i].End_Date ? moment(this.data[i].End_Date).valueOf() : 0;
        let viewingDateTimeStamp = myDay.valueOf();
        

        if(startDateStamp <= viewingDateTimeStamp && viewingDateTimeStamp <= endDateStamp){
          //events falling in the current semester
            
            if(this.data[i].MeetingDays === undefined || this.data[i].MeetingDays.length == 0){
              //web
              dayData.webCourses.push(this.data[i]);
            } else {
              //in person
              
              dayData.inPerson.push(this.data[i]);
            }
            
        }
      }

      //Determine if a class is meeting on myDate
      dayData.showingEventsData = [];
      for(var i = 0; i < dayData.inPerson.length; i++){
          for(var j = 0; j < dayData.inPerson[i].MeetingDays.length; j++){
            if(viewingDayOfWeek === dayData.inPerson[i].MeetingDays[j].Id){
              dayData.showingEventsData.push(dayData.inPerson[i]);
            }
          }

      }

      //Order Showing Events Data by start times and length
      var orderedArray = [];
      for(var i = 0; i < dayData.showingEventsData.length; i++){
        var thisItem = dayData.showingEventsData[i];
        var thisItemString = JSON.stringify(dayData.showingEventsData[i]);
        if(i === 0) {
          orderedArray.push(JSON.parse(thisItemString));
          continue; //don't compare self to self
        }

        //console.log(thisItem);
        // console.log(thisItem.Begin_Time);
        // console.log(Number(thisItem.Begin_Time));
        let thisItemBegin = Number(thisItem.Begin_Time);
        let thisItemEnd = Number(thisItem.End_Time);

        for(var j = 0; j < orderedArray.length; j++){
          let orderedItemBegin = Number(orderedArray[j].Begin_Time);
          let orderedItemEnd = Number(orderedArray[j].End_Time);
          
          if(thisItemBegin < orderedItemBegin){
            //console.log("LESS THAN");
            orderedArray.splice(j, 0, JSON.parse(thisItemString));
            break;
          }else if(thisItemBegin === orderedItemBegin){
            //which one is longer?
            // console.log("EQUAL");
            if(thisItemEnd  > orderedItemEnd){
              orderedArray.splice(j, 0, JSON.parse(thisItemString));
              break;
            } else if(thisItemEnd  === orderedItemEnd){
              //same startTime and endTime for the displayed day.
              //If both have the same CRN....
              //We don't want to display a side by side duplicate of the time block... However, the duplicate data elements may contain a different instructor name or room number. Go ahead and store the duplicate CRN data in case we need to display all instructor names or section numbers in a later version of this project.

              let sameCRN = (thisItem.CRN === orderedArray[j].CRN);

              if (sameCRN){
                if(thisItem.Room !== orderedArray[j].Room){
                  orderedArray[j].roomConflict = true;
                }
                if(orderedArray[j].duplicateCRNs === undefined){
                  orderedArray[j].duplicateCRNs = [];
                }
                orderedArray[j].duplicateCRNs.push(JSON.parse(thisItemString));
                break;
              } else {
                orderedArray.push(JSON.parse(thisItemString));
                break;
              }
            }
          }

          if(j === orderedArray.length - 1){
            //console.log("GREATER THAN");
            orderedArray.push(JSON.parse(thisItemString));
            break;
          }
        }

      }
      //console.log(orderedArray);
      dayData.showingEventsData = JSON.parse(JSON.stringify(orderedArray));



      return dayData;

  }



  weekDotsUpdate(){
    
    let updateCount = [];

    for(let i = 0; i < this.weekDots.length; i++){

      let currentNum = this.weekDotWraps[i].hasAttribute('data-dot-count') ? Number(this.weekDotWraps[i].getAttribute('data-dot-count')) : undefined;
      let currentOut = (this.weekDotWraps[i].classList.contains('out-range'));

      if(this.weekDots[i].out){
        if(!currentOut) this.weekDotWraps[i].classList.add('out-range');
      }else{
        if(currentOut) this.weekDotWraps[i].classList.remove('out-range');
      }

      if(this.weekDots[i].num !== currentNum){
        //re-render the dots for this day
        this.weekDotWraps[i].setAttribute('data-dot-count', this.weekDots[i].num);

        let ariaText = (this.weekDots[i].num === 1) ? 'event' : 'events';
        this.weekAriaDotWraps[i].innerHTML = ' ' + this.weekDots[i].num + ' ' + ariaText;

        this.weekDotWraps[i].innerHTML = '';

        for(let j = 0; j < this.weekDots[i].num; j++){
          let dotSpan = document.createElement('span');
          dotSpan.classList.add('week-dot');
          dotSpan.textContent = '\u2022';
          this.weekDotWraps[i].appendChild(dotSpan);
        }


      }


    }



  }


  weekDotsEval(){

    let weekDots = [{},{},{},{},{},{},{}];
    let weekDotsStart;
    let dayOfWeekMoment;
    let dayOfWeekEl;

    if(this.selectedInView){

      //console.log('Selected date');
      dayOfWeekMoment = moment(this.selectedDate).startOf('week');
      this.weekDotsStart = dayOfWeekMoment.format(this.format);
      //console.log(dayOfWeekMoment.format(this.format));
      
      for(let i = 0; i < 7; i++){
        weekDots[i].num = this.getDailyEventData(dayOfWeekMoment).showingEventsData.length;

        dayOfWeekEl = this.calBodyEl.querySelector("[data-date='" + dayOfWeekMoment.format(this.format) + "']");

        if(dayOfWeekEl.classList.contains('out-range')){
          weekDots[i].out = true;
        }

        dayOfWeekMoment.add(1, 'days');
      }
      //console.log(weekDots);

    } else{
      //selected NOT in view
      if(this.calView === 'week'){

        //console.log('Week | no selected date');
        dayOfWeekMoment = moment(this.showingDates[0].getAttribute('data-date')).startOf('week');
        this.weekDotsStart = dayOfWeekMoment.format(this.format);
        //console.log(dayOfWeekMoment.format(this.format));
      
        for(let i = 0; i < 7; i++){
          weekDots[i].num = this.getDailyEventData(dayOfWeekMoment).showingEventsData.length;
          dayOfWeekEl = this.calBodyEl.querySelector("[data-date='" + dayOfWeekMoment.format(this.format) + "']");
          if(dayOfWeekEl.classList.contains('out-range')){
            weekDots[i].out = true;
          }
          dayOfWeekMoment.add(1, 'days');
        }
        //console.log(weekDots);


      } else if(this.calView === 'month'){

        //console.log('Month | no selected date');
        weekDots = [{num:0},{num:0},{num:0},{num:0},{num:0},{num:0},{num:0}];
        this.weekDotsStart = 'none';
        for(let i = 0; i < this.showingDates.length; i++){

          let eventCount = this.getDailyEventData(this.showingDates[i].getAttribute('data-date')).showingEventsData.length;
          let dayIndex = Number(this.showingDates[i].getAttribute('data-day-of-week'));
          if(eventCount > weekDots[dayIndex].num){
            weekDots[dayIndex].num = eventCount;
          }

        }

        //console.log(weekDots);

      }

    }

    











    //last step is to compare what's already there, might not need to redraw if identical
    if(this.weekDots === undefined || JSON.stringify(weekDots) !== JSON.stringify(this.weekDots)){
      //need to update weekDots display
      //console.log('update weekDots');
      //console.log(weekDots);
      this.weekDots = weekDots;
      this.weekDotsUpdate();

    }





  }





  drawSchedule(){

    //are we switching to TODAY?
    this.scheduleToday = (this.selectedDate.format(this.format) === this.currentTime.format(this.format));


    //Does the schedule match the currently selected date on the calendar?
    if(!this.scheduleDate || this.scheduleDate.format(this.format) !== this.selectedDate.format(this.format)){

      $(this.displayDateWrap).fadeOut(200, function(){
        this.displayDateLong.textContent = this.selectedDate.format('dddd, MMMM Do YYYY');
        this.displayDateShort.textContent = this.selectedDate.format("ddd, MMM Do 'YY");
        this.updateTodayBtn(this.scheduleToday);

        $(this.displayDateWrap).fadeIn(200, function(){
        }.bind(this));
      }.bind(this));



      let hasFocus = document.activeElement ? document.activeElement : document.body;
      let focusInSched;

      //or parentOfClass
      if(hasFocus.getAttribute('data-eventblock')) {
        focusInSched = true;
      }

      
      
      //Determine what events should display
      let selectedData = this.getDailyEventData(this.selectedDate);
     
      this.showingEvents = [];
      this.showingEventsData = selectedData.showingEventsData;

      //Populate showingEvents with Event Blocks
      for(let i = 0; i < this.showingEventsData.length; i++){
        this.dataLoop = i;
        this.addPropertiesToData(this.showingEventsData[i]);
        this.determineLeftAndWidth(this.showingEventsData[i]);
      }


      //Now loop through Data, add sharedWith to hold the element references data-left data-width
      for(var i = 0; i < this.showingEventsData.length; i++){
        this.showingEvents.push(this.buildEventBlock(this.showingEventsData[i]));
      }     
      //determine which are live before inserting
      this.liveEvents();

      //console.log(this.showingEvents);
      //console.log(this.showingEventsData);















      $(this.timeBlockEvents).fadeOut(200, function(){

        this.timeBlockEvents.innerHTML = '';

        for(var i = 0; i < this.showingEvents.length; i++){
          this.timeBlockEvents.appendChild(this.showingEvents[i]);
        }
        

        $(this.timeBlockEvents).fadeIn(200, function(){
          //EventDispatcher.PackeryResize(this.timeBlockEvents);
          //add later if we give users an option to expand the bottom section
          if(focusInSched && this.hasFocus){
            //??
            this.hasFocus.focus();
          }

        }.bind(this));
      }.bind(this));


  }//end of necessary schedule redraw

  this.scheduleDate = moment(this.selectedDate);
  return;
  }

























  updateCurrentTime(){
    let oldTime = moment(this.currentTime);
    this.currentTime = moment();
    //this.currentTime = moment(test, this.format);

    //we rolled into a new day
    if(oldTime.format('D') !== this.currentTime.format('D')){

      if(this.scheduleToday && this.todayInView){
        //best case scenario, resetToday takes care of all updates
        this.drawCalendar(this.currentTime);
        this.resetToday();
      }else if(this.scheduleToday && !this.todayInView){
        //user is currently navigating to a different month or week
        //schedule items, NOT the calendar
        this.selectedDate = moment(this.currentTime);
        this.drawSchedule();
      }else{
        //looking at another days schedule
        //update data only, NOT the calendar or schedule
        this.updateTodayData();
      }

      //auto scroll-to-top only if today's schedule is showing
      if(this.scheduleToday){
        this.moveTimeline({
            scroll: true,
            smooth: true
        });        
      }else{
        this.moveTimeline();         
      }      
  
    }else{
      //same day, just move line
      this.moveTimeline();
    }

    

  }







  liveEvents(){
    let linePosition = this.timeLineTop;
    //console.log('Line Position: ', linePosition);
    //console.log('Eddie Test Schedule Today 1: ', !this.scheduleToday);
    if(this.showingEvents === undefined || !this.scheduleToday) return;
    //console.log('Eddie Test Schedule Today 2: ', !this.scheduleToday);
    for(var i = 0; i < this.showingEvents.length; i++){
      //try catch this block
      //just in case loop is running when the user selects a different date on the calendar
      try {
        let top = Number(this.showingEvents[i].getAttribute('data-top'));
        //console.log('Top: ', top);
        let upcoming = top - (15 * this.timeBlockMinuteHeight);
        //console.log('Upcoming: ', upcoming);
        let bottom = top + Number(this.showingEvents[i].getAttribute('data-height'));
        //console.log('Bottom : ', bottom);
        let icon = this.showingEvents[i].querySelector('.livedot');

        if(linePosition < top && linePosition >= upcoming){
          //console.log('set upcoming');
          if(!this.showingEvents[i].hasAttribute('data-upcoming')) {
            this.showingEvents[i].setAttribute('data-upcoming', '');
            icon.classList.add('fl-warning');
            icon.classList.remove('fl-success');
          }
        }

        if(linePosition >= top && linePosition <= bottom){
          //console.log('set live');
          if(!this.showingEvents[i].hasAttribute('data-live')) {
            this.showingEvents[i].setAttribute('data-live', '');
            icon.classList.add('fl-success');
            icon.classList.remove('fl-warning');
            this.showingEvents[i].removeAttribute('data-upcoming');
          }

        }else{
          //console.log('remove live');
          if(this.showingEvents[i].hasAttribute('data-live')) {
            this.showingEvents[i].removeAttribute('data-live');
          }
        }

      } catch (exception) {
      }
    }//End of Loop
  }







  moveTimeline(options){
    let hour = this.currentTime.hour();
    let minute = this.currentTime.minute();
    //console.log('Hour: ' + hour);
    //console.log('Minute: ' + minute);
    let hourTotal = hour * this.timeBlockHourHeight;
    let minuteTotal = minute * this.timeBlockMinuteHeight;
    let linePosition = hourTotal + minuteTotal;
    this.timeLineTop = linePosition;
    this.timeLine.style.top = linePosition + 'px';
    //this.timeLineOverlay.style.top = linePosition + 'px';

    let scrollTarget = linePosition - (this.timeBlockHourHeight * 1.15);
    //console.log('Target: ' + scrollTarget);
    scrollTarget = (scrollTarget > 0) ? scrollTarget : 0;
    //console.log('Target: ' + scrollTarget);

    if(options && options.scroll){
      
        if(options.smooth){
          try{
            $(this.timeBlock).animate({
                scrollTop: scrollTarget
            }, 1000);
          }catch(exp){
            this.timeBlock.scrollTop = scrollTarget;  
          }
        }else{
          this.timeBlock.scrollTop = scrollTarget;  
        }
    }

    //check what events are LIVE!
    this.liveEvents();

  }


  //only updates today object data in the event that the timeline rolls into a new day and we DO NOT WANT to update the calendar or schedule
  updateTodayData(){

    if(this.todayInView){
      this.todayEl.classList.remove('today');
    }

    this.todayInView = false;
    this.todayEl = null;



    for(var i = 0; i < this.showingDates.length; i++){

      //console.log(this.showingDates[i].getAttribute('data-date'));
      //console.log(this.currentTime.format(this.format));

      if(this.showingDates[i].getAttribute('data-date') === this.currentTime.format(this.format)){
        
        this.showingDates[i].classList.add('today');
        this.todayInView = true;
        this.todayEl = this.showingDates[i];
        break;
      }
    }

    if(this.scheduleDate.format(this.format) === this.currentTime.format(this.format)){
      //edge case. you were looking at tomorrow's schedule at 11:59 and it rolls into the day you were viewing.
      updateTodayBtn(true);
    }

  }










  previousClick(e){
	let type = e.currentTarget.getAttribute('data-prev');
	let refDate = moment(this.referenceDate);
	switch (type) {
	  case 'month':
	  	this.drawCalendar(refDate.subtract(1, 'months'));
	    break;
	  case 'week':
	    //ref date should be current last displayed
	    //this prevents month from changing prematurely
		this.drawCalendar(moment(this.showingDates[(this.showingDates.length - 1)].getAttribute('data-date'), this.format).subtract(1, 'weeks'));
	    break;
	  default:
	}
 }









  nextClick(e){
	let type = e.currentTarget.getAttribute('data-next');
	let refDate = moment(this.referenceDate);
	switch (type) {
	  case 'month':
	  	this.drawCalendar(refDate.add(1, 'months'));
	    break;
	  case 'week':
	  //ref date should be current first displayed
	  //this prevents month from changing prematurely
		this.drawCalendar(moment(this.showingDates[0].getAttribute('data-date'), this.format).add(1, 'weeks'));
	    break;
	  default:
	}
  }







}

