// class SweetAlertsHelper {
//     constructor(){
//         const buttonType = Object.freeze({
//             CONFIRM:   "Confirm",
//             CANCEL:  "Cancel"
//         });
//     }
//
//     static getConfirmButtonText(nodeId) {
//         return this.buttonTextLookup(nodeId, this.buttonType.CONFIRM);
//     }
//     static getCancelButtonText(nodeId) {
//         return this.buttonTextLookup(nodeId, this.buttonType.CANCEL);
//     }
//     static getDefaultText(buttonType) {
//         return (buttonType === this.buttonType.CONFIRM) ? 'Okay' : 'Close';
//     }
//     static buttonTextLookup(nodeId, buttonType) {
//         if (typeof this.buttonTextLookupOverride === "function") {
//             return this.buttonTextLookupOverride(nodeId, buttonType);
//         } else {
//             return this.getDefaultText(buttonType);
//         }
//     }
// }

var KSU_SweetAlertsHelper = {
    buttonType: {
        CONFIRM: 'Confirm',
        CANCEL: 'Cancel',
    },

    getConfirmButtonText: function (notificationId) {
        return this.buttonTextLookup(notificationId, this.buttonType.CONFIRM);
    },
    getCancelButtonText: function (notificationId) {
        return this.buttonTextLookup(notificationId, this.buttonType.CANCEL);
    },
    getDefaultText: function (buttonType) {
        return (buttonType === this.buttonType.CONFIRM) ? 'Okay' : 'Close';
    },
    buttonTextLookup: function (notificationId, buttonType) {
        if (typeof this.buttonTextLookupOverride === "function") {
            return this.buttonTextLookupOverride(notificationId, buttonType);
        } else {
            return this.getDefaultText(buttonType);
        }
    }
};