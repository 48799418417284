// Left Navigation Custom Scripts
var escCloseNav1;
var siteWrapper;
var nav1Toggle;
var nav1Area;

var navNarrow;
var navNarrowLinks;
var navNarrowTabbable;
var navWide;
var navWideLinks;
var navWideTabbable;
var nav1Uls;
var nav1TopLis;
var nav1TopLeaves;

var flyoutBG;
var nav1Parent;
var nav1ParentLeaves;
var nav1DropDownPanes;

var searchWrapper;
var allFlyoutLinks;
var parentFlyoutLinks;
var inSafari;

function disableNavNarrow(){
    navNarrow.setAttribute('aria-hidden', 'true');
    for (var i = 0; i < navNarrowTabbable.length; i++) {
        navNarrowTabbable[i].setAttribute('tabindex', '-1');
    }   
}
function enableNavNarrow(){
    navNarrow.setAttribute('aria-hidden', 'false');
    for (var i = 0; i < navNarrowTabbable.length; i++) {
        navNarrowTabbable[i].setAttribute('tabindex', '0');
    }   
}
function disableNavWide(){
    navWide.setAttribute('aria-hidden', 'true');
    for (var i = 0; i < navWideTabbable.length; i++) {
        navWideTabbable[i].setAttribute('tabindex', '-1');
    }   
}
function enableNavWide(){
    navWide.setAttribute('aria-hidden', 'false');
    for (var i = 0; i < navWideTabbable.length; i++) {
        navWideTabbable[i].setAttribute('tabindex', '0');
    }   
}


function clearOpenPanels() {

    for (var i = 0; i < nav1Parent.length; i++) {
        if (nav1Parent[i].classList.contains('hover') || escCloseNav1) {
            var thisNextNode = parentOfClass(nav1Parent[i], 'nav-tier-0').querySelector('.dropdown-pane');
            var thisNextNodeInner = thisNextNode.querySelector('.nav1PanelInner');
            nav1Parent[i].classList.remove('hover');
            nav1Parent[i].setAttribute('aria-expanded', 'false');
            thisNextNode.classList.remove('is-open');
            thisNextNode.setAttribute('aria-hidden', 'true');
            var thisPanelLinks = thisNextNodeInner.querySelectorAll('a, button, input, select, textarea, iframe, [tabindex], area');
            for (var j = 0; j < thisPanelLinks.length; j++) {
                thisPanelLinks[j].setAttribute('tabindex', '-1');
            }
        }
    }
    escCloseNav1 = false;

}

function openThisPanel(e) {
    var thisTarget = e.currentTarget;
    var currentLi;
    var currentUl;
    var currentLink;

    if (thisTarget.nodeName.toLowerCase() == 'ul') {
        currentLi = e.currentTarget.firstChild;
        currentUl = e.currentTarget;
        currentLink = currentLi.querySelector('.nav1-item a');

    } else if (thisTarget.nodeName.toLowerCase() == 'li') {
        currentLi = e.currentTarget;
        currentUl = e.currentTarget.parentNode;
        currentLink = currentLi.querySelector('.nav1-item a');

    } else if (thisTarget.nodeName.toLowerCase() == 'a') {
        currentLink = e.currentTarget;
        currentLi = parentOfClass(currentLink, 'nav-tier-0');
        currentUl = currentLi.parentNode;
    } else {
    }

    var nextNode = currentLi.querySelector('.dropdown-pane');
    var nextNodeInner = nextNode.querySelector('.nav1PanelInner');
    if (!currentLink.classList.contains('hover')) {
        clearOpenPanels();
        currentLink.classList.add('hover');
        nextNode.classList.add('is-open');
        currentLink.setAttribute('aria-expanded', 'true');
        nextNode.setAttribute('aria-hidden', 'false');
        var panelLinks = nextNodeInner.querySelectorAll('a, button, input, select, textarea, iframe, [tabindex], area');
        for (var k = 0; k < panelLinks.length; k++) {
            panelLinks[k].setAttribute('tabindex', '0');
        }
        if (!currentUl.classList.contains('open')) {
            currentUl.classList.add('open');
        }
        if (!flyoutBG.classList.contains('open')) {
            flyoutBG.classList.add('open');
            var flyoutScrollAreas = currentUl.querySelectorAll('[data-scrollarea]');
            for (var l = 0; l < flyoutScrollAreas.length; l++) {
                flyoutScrollAreas[l].scrollTop = 0;
            }
        }
    }

}

//Show dropdown panel on parent list item enter
function hoverOpenDropdown(e) {
    e.stopPropagation();
    if (e.target.nodeName.toLowerCase() == 'li') {
        openThisPanel(e);
    }
}

//Hide dropdown panel on parent list item exit
function hoverCloseDropdown(e) {

    e.stopPropagation();
    clearOpenPanels();

    var thisTarget = e.currentTarget;

    if (thisTarget.nodeName.toLowerCase() == 'ul') {
        currentUl = e.currentTarget;

    } else if (thisTarget.nodeName.toLowerCase() == 'li') {
        currentUl = e.currentTarget.parentNode;
    } else {

    }

    if (currentUl.classList.contains('open')) {
        currentUl.classList.remove('open');
    }

    if (flyoutBG.classList.contains('open')) {
        flyoutBG.classList.remove('open');
    }

}

//Open navigation on mouseclick if it is closed.
//If menu is already open, we can add extra functionality here for second click.
function clickNavParent(e) {
    e.stopPropagation();
    var currentLink = e.currentTarget;
    var currentUl = parentOfClass(currentLink, 'nav1-ul');

    if (e.currentTarget.classList.contains('hover') && flyoutBG.classList.contains('open')) {
    } else if (e.currentTarget.classList.contains('hover') && !flyoutBG.classList.contains('open')) {
        e.preventDefault();
        flyoutBG.classList.add('open');
    } else {
        e.preventDefault();
        openThisPanel(e);
    }
}

//Open navigation on Tap if it is closed.
//If menu is already open, we can add extra functionality here for second click.
function tapNavParent(e) {
    var currentLink = e.currentTarget;
    var currentUl = parentOfClass(currentLink, 'nav1-ul');
    var currentLi = parentOfClass(currentLink, 'nav-tier-0');

    if (e.currentTarget.classList.contains('hover') && flyoutBG.classList.contains('open')) {
        clearOpenPanels();
        if (currentUl.classList.contains('open')) {
            currentUl.classList.remove('open');
        }
        if (flyoutBG.classList.contains('open')) {
            flyoutBG.classList.remove('open');
        }

    } else if (e.currentTarget.classList.contains('hover') && !flyoutBG.classList.contains('open')) {
        flyoutBG.classList.add('open');
    } else {
        openThisPanel(e);
    }
}


//For keyboard surfing. Hitting enter on any given tier 1 item will Toggle the menu open and closed.
//We could also program here for example if we wanted to allow user to follow this link by pressing shift + enter?
function enterNavParent(e) {

    if (e.keyCode == 13 && e.shiftKey == true) {
        e.preventDefault();
        e.stopPropagation();
        var currentHref = e.currentTarget.href;
        window.location.href = currentHref;

    } else if (e.keyCode == 13) {
        e.preventDefault();
        e.stopPropagation();

        var currentLink = e.currentTarget;
        var currentUl = parentOfClass(currentLink, 'nav1-ul');

        if (!currentLink.classList.contains('hover') && flyoutBG.classList.contains('open')) {
            openThisPanel(e);
        } else if (!currentLink.classList.contains('hover') && !flyoutBG.classList.contains('open')) {
            openThisPanel(e);
        } else {
            clearOpenPanels();
            if (currentUl.classList.contains('open')) {
                currentUl.classList.remove('open');
            }
            if (flyoutBG.classList.contains('open')) {
                flyoutBG.classList.remove('open');
            }
        }

    } else {
    }

}

//Close everything that makes up the left Nav (Clear panels and shut backdrop)
//This is for clicking body OR if user hovers over the searchbar while the menu is open.
function navCleanUp(e) {
    //This prevents a mouseenter from reopening the menu until the menu is closed
    //Safari bug is triggering re-open while closing is happening because the browser scrollbar isn't honoring the pointer-events none css property.
    if (inSafari) { nav1Area.addEventListener('mouseenter', disableACTIONS, true); }

    clearOpenPanels();

    for (var i = 0; i < nav1Uls.length; i++) {
        if (nav1Uls[i].classList.contains('open')) {
            nav1Uls[i].classList.remove('open');
        }
    }
    if (flyoutBG.classList.contains('open')) {
        flyoutBG.classList.remove('open');
    }
    //Remove event listener so user can resume using menu flyouts
    if (inSafari) {
        setTimeout(function(){
            nav1Area.removeEventListener('mouseenter', disableACTIONS, true);
        }, 550);
    }
    
}

function PersistNavStyle(NavStyle) {
    if (NavStyle === 'narrow') {
        $.ajax('/api/internal/POST/user/v1/field_ext_preferences/narrownav/1').done(function () {
            //console.log("NavSet");
        });

    } else if (NavStyle === 'wide') {
        $.ajax('/api/internal/POST/user/v1/field_ext_preferences/narrownav/0').done(function () {
            //console.log("NavSet");
        });
    }
}

function navToggle(e) {
    //If on MyDashboard, need to update layout
    if(myDashGridWrapper) myDashResize();

    //Main Part
    siteWrapper.classList.toggle('nav-narrow');
    var scrollArea;
    if (siteWrapper.classList.contains('nav-narrow')) {
        new PersistNavStyle("narrow");
        disableNavWide();
        scrollArea = navNarrow.querySelector('[data-scrollarea]');
        scrollArea.scrollTop = 0;
        enableNavNarrow();
    } else {
        new PersistNavStyle("wide");
        disableNavNarrow();
        scrollArea = navWide.querySelector('[data-scrollarea]');
        scrollArea.scrollTop = 0;
        enableNavWide();
    }

    navResizeStpFlp(550);
}

//This function only runs once when the user mouseleaves the current page's active parent link.
//This is a safeguard so that the menu doesn't annoyingly open on the user once they surf to a new page and then attempt to move their mouse away from the menu towards the main content.
function allowHoverForActiveParents() {
    for (var i = 0; i < nav1TopLis.length; i++) {
        if (nav1TopLis[i].getAttribute('data-mouseenter-set') != 'true') {
            nav1TopLis[i].addEventListener('mouseenter', hoverOpenDropdown);
            nav1TopLis[i].setAttribute('data-mouseenter-set', 'true');
            nav1TopLis[i].removeEventListener('mouseleave', allowHoverForActiveParents);
        }
    }
}

function stopBubble(e) {
    e.stopPropagation();
}

function stopDefault(e) {
    e.preventDefault();
}

function stopBoth(e) {
    e.preventDefault();
    e.stopPropagation();
}

function ariaHideLeftNav() {

    if( nav1Toggle == null )
      return;

    nav1Toggle.setAttribute('tabindex', '-1');

    if (siteWrapper.classList.contains('nav-narrow')) {
        disableNavNarrow();
    } else {
        disableNavWide();
    }
}

function ariaShowLeftNav() {
    nav1Toggle.setAttribute('tabindex', '0');

    if (siteWrapper.classList.contains('nav-narrow')) {
        enableNavNarrow();
    } else {
        enableNavWide();
    }
}

//If user shrinks window, aria hide active left navigation so that user can't tab to these menu items.
function narrowFromDesktop() {
    if (window.innerWidth < 1024) {
        ariaHideLeftNav();
        window.removeEventListener('resize', narrowFromDesktop);
        window.addEventListener('resize', widenFromMobile);
        navResizeStpFlp(550);
        profilePopMobile();
    }
}

function installLeftNavListeners() {
    if (isBaseTheme('pioneer')) {

        siteWrapper = document.getElementById('site-wrapper');
        nav1Toggle = document.getElementById('nav-toggle');
        nav1Area = document.getElementById('nav-area');

        navNarrow = document.getElementById('nav-narrow');
        navNarrowLinks = $('#nav-narrow a');
        navNarrowTabbable = $('#nav-narrow a, #nav-narrow button, #nav-narrow input, #nav-narrow select, #nav-narrow textarea, #nav-narrow iframe, #nav-narrow [tabindex], #nav-narrow area');

        navWide = document.getElementById('nav-wide');
        navWideLinks = $('#nav-wide a');
        navWideTabbable = $('#nav-wide a, #nav-wide button, #nav-wide input, #nav-wide select, #nav-wide textarea, #nav-wide iframe, #nav-wide [tabindex], #nav-wide area');

        nav1Uls = document.querySelectorAll('#nav-area .nav1-ul');
        nav1TopLis = $('#nav-area .nav1-ul .nav-tier-0:has(div ul)');
        nav1TopLeaves = $('#nav-area .nav1-ul .nav-tier-0:not(:has(div ul))');
        flyoutBG = document.getElementById('flyout-menu-bg');
        nav1Parent = $('#nav-area .nav1-ul .nav-tier-0 [data-submenu="true"] a');
        nav1ParentLeaves = $('#nav-area .nav1-ul .nav-tier-0 [data-submenu="false"] a');
        nav1DropDownPanes = document.querySelectorAll('#nav-area .dropdown-pane');
        searchWrapper = document.querySelector('#navarea2 .search-wrapper');
        allFlyoutLinks = $('#nav-area .nav1-ul .dropdown-pane a');
        parentFlyoutLinks = $('#nav-area .nav1-ul .nav-tier-1:has(ul)').children('a');
        nav1Logos = document.querySelectorAll('#nav-area .ksu-nav-logo a');

        if (document.documentElement.classList.contains('safari')){
            inSafari = true;
        }

        //Tabbing past the bottom nav logo will close the menu
        for (var i = 0; i < nav1Logos.length; i++) {
            nav1Logos[i].addEventListener('keydown', function (e) {
                if (flyoutBG.classList.contains('open') && e.keyCode == 9) {
                    navCleanUp();
                }
            });
        }


//These are so that clicks wont trigger the navCleanup function.
        if( flyoutBG != null )
          flyoutBG.addEventListener('click', stopBubble);

        for (var i = 0; i < nav1DropDownPanes.length; i++) {
            nav1DropDownPanes[i].addEventListener('click', stopBubble);
        }

        if( nav1Toggle != null )
          nav1Toggle.addEventListener('click', navToggle);

        for (var i = 0; i < nav1TopLis.length; i++) {
            nav1TopLis[i].addEventListener('click', stopBubble);
        }

//hover Closed panels
        for (var i = 0; i < nav1TopLeaves.length; i++) {
            nav1TopLeaves[i].addEventListener('mouseenter', hoverCloseDropdown);
        }

        for (var i = 0; i < parentFlyoutLinks.length; i++) {
            //if the parent link href points to the homepage, switch it out for the href of its submenus first link.
            if (parentFlyoutLinks[i].href === siteRootUrl) {
                var thisSubLinks = parentFlyoutLinks[i].parentNode.querySelectorAll('.nav-tier-1 ul a');
                var thisFirstLink = thisSubLinks[0];
                parentFlyoutLinks[i].href = getCleanUrl(thisSubLinks[0].href);
            }
        }

//click or key open panels
//keyup and keypress blockers are here to prevent nvda to bundle these keyboard events with the click event
        for (var i = 0; i < nav1Parent.length; i++) {

            var currentLi = parentOfClass(nav1Parent[i], 'nav-tier-0');
            //if the parent link href points to the homepage, switch it out for the href of its submenus first link.
            if (nav1Parent[i].href === siteRootUrl) {
                var thisSubLinks = currentLi.querySelectorAll('.dropdown-pane a');
                var thisFirstLink = thisSubLinks[0];
                nav1Parent[i].href = getCleanUrl(thisSubLinks[0].href);
            }
//Very important for nvda that parents receive this attribute
//Otherwise flyout meny does not work properly in browsers with nvda enabled
            nav1Parent[i].setAttribute('role', 'menuitem');
            nav1Parent[i].addEventListener('keydown', enterNavParent);
            nav1Parent[i].addEventListener('keypress', function (e) {
                if (e.keyCode == 13) {
                    stopBoth(e);
                }
            });
            nav1Parent[i].addEventListener('keyup', function (e) {
                if (e.keyCode == 13) {
                    stopBoth(e);
                }
            });
            nav1Parent[i].addEventListener('touchend', function (e) {
                e.preventDefault();
                e.stopPropagation();
                tapNavParent(e);
            });
            nav1Parent[i].addEventListener('click', clickNavParent);
        }

//close menu on leaf hover and leave it open if user clicks on leaf
        for (var i = 0; i < nav1ParentLeaves.length; i++) {
            nav1ParentLeaves[i].addEventListener('click', stopBubble);
            nav1ParentLeaves[i].addEventListener('touchend', function (e) {
                e.preventDefault();
                e.stopPropagation();
                var currentHref = e.currentTarget.href;
                window.location.href = currentHref;
            });
        }

//Open and close backdrop
        for (var i = 0; i < nav1Uls.length; i++) {
            nav1Uls[i].addEventListener('mouseleave', hoverCloseDropdown);

            nav1Uls[i].addEventListener('contextmenu', function (e) {
                //console.log(e);
                var currentUl = e.currentTarget;
                currentUl.removeEventListener('mouseleave', hoverCloseDropdown);
                setTimeout(function () {
                    currentUl.addEventListener('mouseleave', hoverCloseDropdown);
                }, 1000);

            });

        }

//Clean up nav if user clicks away
        document.body.addEventListener('click', function(){
            if ( flyoutBG != null && flyoutBG.classList.contains('open')) {
                navCleanUp();
            }
        });


//Clean up nav if user presses esc
        document.addEventListener('keydown', function (e) {
            if (flyoutBG != null && flyoutBG.classList.contains('open') && e.keyCode == 27) {
                escCloseNav1 = true;
                navCleanUp();
            }
        });

        for (var i = 0; i < allFlyoutLinks.length; i++) {
            allFlyoutLinks[i].addEventListener('click', function (e) {
                var newHrefStart = getCleanUrl(e.currentTarget.href);
                var oldHrefStart = getCleanUrl(window.location.href);
                if (oldHrefStart === newHrefStart) {
                    navCleanUp();
                    refreshMenuPaths();
                }
            });
        }

//Check on load for menu setup
        if( siteWrapper != null ) {
          if (siteWrapper.classList.contains('nav-narrow')) {
              disableNavWide();
          } else {
              disableNavNarrow();
          }
        }

//On load, if the viewport window starts wide, we install a resizing listener which aria hides navigation components if the user shrinks the window down to mobile view
        if (window.innerWidth >= 1024 && nav1Area != null ) {
            window.addEventListener('resize', narrowFromDesktop);
            profilePopDesktop();
        }

    }//Closing if pioneer conditional
}//Closing installListeners function

window.addEventListener('DOMContentLoaded', installLeftNavListeners);
