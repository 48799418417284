function checklistTools() {

    this.transformProcessLabel = function (processLabel) {

        processLabel = processLabel.replace(/^[A-Z]{1,4}_[0-9]{1,4} /, '');
        // strip out leading text like ASDF_1234

        processLabel = processLabel.camelCaseToText();
        processLabel = processLabel.replace(/([A-Z] [A-Z])/, function ($1) {
            var s = $1;
            return (s.replace(/\s/, ""));
        });

        // convert 'camelCase' to 'camel Case' but we need to be a little
        // careful, because if we have something like 'ExpenseWF' it would
        // turn into 'Expense W F' -- so join togehter capitals separated by spaces.
        // You could se how this could cause a problem with certain camelCases, such
        // as 'JustAWorkflow' which would tehn become 'Just AWorkflow'

        processLabel = processLabel.replace(/^ /, '');
        // strip leading space because 'CamelCase' could become ' Camel Case';
        return processLabel;
    }


    this.transform_tags = function (tags, checklistname) {

        tags['status'] = "-";
        return (tags);

    }


    this.transform_due = function (line, checklistname) {

        if (line['Due'] == "") {
            return "";
        }

        var m, d, y;

        var parts = line['Due'].split("/");
        if (parts.length == 3) {
            m = parts[0];
            d = parts[1];
            y = parts[2];
        }

        if (y == "1900") {
            return "-";
        }
        return (y + "-" + m + "-" + d);
    }


    this.transform_status = function (status, checklistname) {
        if (status == "")
            return "";

        code = status.toLowerCase();
        code = code.replace(/\W+/g, "_");

        status = status.toUpperCase();

        var labelClass;

        switch (status) {
          case 'COMPLETE':
          case 'COMPLETED':
          case 'DONE':
          case 'FINISHED':
            labelClass = 'fl-success';
            break;
          case 'PASTDUE':
          case 'PAST DUE':
            labelClass = 'fl-error';
            break;
          case 'UPCOMING':
            labelClass = 'fl-warning';
            break;
          default:
            labelClass = 'fl-info';
        }         

        return ("<div class='" + labelClass + " label checklist-status overflow-ellipsis round status-" + code + "'>" + status + "</div>");
    }

    this.transform_body = function (line, checklistname) {

        // take a line, format it nicely with the name
        // of the item followed by the content, making the
        // title a link if appropriate.

        var title;

        if (line['TitleURL'] != "") {

            title = line['TitleURL'];

            if (title.indexOf('framed=true') == -1)
                title.replace('<a ', "<a target='_blank' ");

        }
        else {
            title = line['Name'];
        }


        return title + "<p>" + line['Content'];


    }


}



