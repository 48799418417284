        function datepickerSetDate(start, end, label) {

            //Current start and end dates sent to globals.
            //Need this rangeDiff to arrive at correct dateStartingOffset
            rangeDiff = end.diff(start, "days");
            dateStartingOffset = 0; // default to 0 unless otherwise noted.

            if (label == 'All Upcoming Events')
                dateEndingOffset = 3650;

            if (label == 'Today')
                dateEndingOffset = 0;

            if (label == 'Tomorrow')
                dateStartingOffset = dateEndingOffset = 1;

            if (label == 'Next 3 Days')
                dateEndingOffset = 3;

            if (label == 'Next 7 Days')
                dateEndingOffset = 7;

            if (label == 'Next 14 Days')
                dateEndingOffset = 14;

            if (label == 'Next 30 Days')
                dateEndingOffset = 30;

            if (label == 'Custom Range') {

                $('#reportrange span').html(start.format('MM/D/YYYY') + ' - ' + end.format('MM/D/YYYY'));

                //dateStartingOffset = start.diff(moment(), "days");

                // The above line should have worked to arrive at the dateStartingOffset, BUT this
                // kept returning incorrect values. For example, it returned zero for both
                // today AND tomorrow instead of zero for today and one for tomorrow. Subtracting
                // the overall rangeDiff from the dateEndingOffset works everytime though as expected.

                // yeah, I saw that too.  I was broken for the static date picker, which i solved
                // by subtracting one from the start range -- Jared

                dateEndingOffset = end.diff(new moment(), "days");
                dateStartingOffset = dateEndingOffset - rangeDiff;

            }
            else {
                $('#reportrange span').html(label);
            }

            // eventDivTable might be null if this is initialized before the actual content.
            // most likley a race condition with pulling down the JSON calendar feed.
            if (eventDivTable != null) {
                // draw forces the re-application of the event filter, btw
                // which is what applies the new data range...
                eventDivTable.draw();
            }
        }

