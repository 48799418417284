function initFlShowmore(parentEl){
  //Make sure this has been numbered by the initial install, if using later dynamically, we need to assign it a number so it still works.
  let showMoreNum;
  //console.log(parentEl.getAttribute('data-fl-showmore'));
  if(parentEl.getAttribute('data-fl-showmore') === null || parentEl.getAttribute('data-fl-showmore') === ''){
    showMoreNum  = document.querySelectorAll('[data-fl-showmore]').length;
    parentEl.setAttribute('data-fl-showmore', showMoreNum);
    //console.log(showMoreNum);
  } else {
    showMoreNum = Number(parentEl.getAttribute('data-fl-showmore'));
    //console.log(showMoreNum);
  }

  //temporarily hide and disable contents
  parentEl.classList.add('fl-loading');
  if (parentEl.classList.contains('fl-showmore-ready')) {
    parentEl.classList.remove('fl-showmore-ready');
  }

  let itemString = parentEl.getAttribute('data-fl-showmore-item');
  let allItems = document.querySelectorAll('[data-fl-showmore="' + showMoreNum +  '"] ' + itemString);

  //Exit if there are no items to work with...
  if(allItems.length === 0){
    console.info('failed to install showmore for the following element:');
    console.info(parentEl);
    parentEl.classList.add('fl-showmore-ready');
    parentEl.classList.remove('fl-loading');
    return;
  }

  //START - hide all items to begin with
  for(let i = 0; i < allItems.length; i++){
    allItems[i].style.display = 'none';
  }

  //min and max must be valid and <= array length
  let min = (parentEl.hasAttribute('data-fl-showmore-min') && Number(parentEl.getAttribute('data-fl-showmore-min')) <= allItems.length) ? Number(parentEl.getAttribute('data-fl-showmore-min')) : null;

  let max = (parentEl.hasAttribute('data-fl-showmore-max') && Number(parentEl.getAttribute('data-fl-showmore-max')) <= allItems.length) ? Number(parentEl.getAttribute('data-fl-showmore-max')) : allItems.length;

  let moreBtnWrap = parentEl.querySelector('.fl-showmore-btn-wrap');
  let startNum;

  if (min !== null && min !== max && min < max) {

    startNum = min;

    if(!moreBtnWrap){
      moreBtnWrap = document.createElement('div');
      moreBtnWrap.classList.add('fl-showmore-btn-wrap');
      parentEl.appendChild(moreBtnWrap);
    }

    let moreBtn = moreBtnWrap.querySelector('.fl-showmore-btn');

    if(moreBtn){
      //if already there and we are updating dynamically, 
      //destroy it so that the old event listeners will go away
      moreBtnWrap.removeChild(moreBtn);
      moreBtn = null;
    }
    moreBtn = document.createElement('a');
    moreBtn.setAttribute('title', 'Click to show more or less');
    moreBtn.classList.add('fl-showmore-btn');
    moreBtn.textContent = 'Show More';
    moreBtn.setAttribute('data-showing', 'less');
    moreBtn.setAttribute('href', '');
    moreBtnWrap.appendChild(moreBtn);

    function flShowmoreClick(e) {
      e.preventDefault();
      let currShowing = moreBtn.getAttribute('data-showing');

      if(currShowing === 'less'){
        //toggling to see MORE
        moreBtn.setAttribute('data-showing', 'more');
        moreBtn.textContent = 'Show Less';

        for(let i = min; i < max; i++){

          if(i === (max - 1)){
            $(allItems[i]).slideToggle({
              duration: 600,
              complete: function(){
                EventDispatcher.PackeryResize(parentEl);
              }
            }); 
            break;      
          } else {
            $(allItems[i]).slideToggle(600);
          }

        }

            
      } else {
        //toggling to see LESS
        moreBtn.setAttribute('data-showing', 'less');
        moreBtn.textContent = 'Show More';

        for(let i = (max - 1); i >= min; i--){

          if(i === min){
            $(allItems[i]).slideToggle({
              duration: 600,
              complete: function(){
                EventDispatcher.PackeryResize(parentEl);
              }
            }); 
            break;      
          } else {
            $(allItems[i]).slideToggle(600);
          }

        }


      }
    }//Closing Click Handling Function

    moreBtn.addEventListener('click', flShowmoreClick);

  } else {
    //markup did not specify min and max OR only specified max OR min and max are equal
    //do not show the show more button in this case, just the relevant items 
    startNum = max;

    if(moreBtnWrap){
      parentEl.removeChild(moreBtnWrap);
      moreBtnWrap = null;
    }

  }


  parentEl.classList.add('fl-showmore-ready');
  //initial reveal of items here


  for(let i = 0; i < startNum; i++){

    if(i === (startNum - 1)){
        $(allItems[i]).slideToggle({
            duration: 0,
            complete: function(){
                EventDispatcher.PackeryResize(parentEl);
            }
        }); 
        break;      
    }else{
        $(allItems[i]).slideToggle(0);
    }

  }
    
  parentEl.classList.remove('fl-loading');
  return;
}



window.addEventListener('DOMContentLoaded', function(){
  let showMores = document.querySelectorAll('[data-fl-showmore]');
  for(let i = 0; i < showMores.length; i++){
    showMores[i].setAttribute('data-fl-showmore', i);
    initFlShowmore(showMores[i]);
  }
  return;
});