// Meal Plan JS
var diningFlow;

var diningAnswerArray;
var diningSubmittedAnswers;

var diningQuestionsLoaded;

var diningPlansData;
var diningPlansLoaded;

var diningCurrentDetail;
var diningNewPlanInfo;

var diningMainIcon;
var diningMainTitle;
var diningNewPlanPrefix;


var diningStepper;
var diningStart;
var diningDetail;
var diningPurchaseBtn;
var diningConfirm;
var diningConfirmTitle;
var diningSuccess;

var diningError;
var diningErrorInner;
var diningErrorTitle;
var diningErrorMessage1;
var diningErrorMessage2;
var diningHasErrorNav;

var diningPromo = null;


//This function builds out the two column plan details which change everytime
// the user clicks on a different plan to view its details. An optional RESET
// argument assumes the build is for step 1. Meaning it would include the add
// change buttons or pending blurb.
function diningBuildOverview(planInfo, reset) {

  var overview = document.createElement('div');
  overview.classList.add('overview-wrapper');
  var left = document.createElement('div');
  left.classList.add('left');
  var leftInner = document.createElement('div');
  leftInner.classList.add('left-inner');
  var currentTitle = document.createElement('h4');
  currentTitle.setAttribute('class', 'current-plan aria-only');
  var planImg = document.createElement('img');
  planImg.classList.add('plan-img');
  planImg.setAttribute('alt', planInfo['Name']);
  planImg.setAttribute('src', '/sites/all/themes/ksu_foundation/images/flash-avatar.jpg');
  planImg.onerror = function () {
    this.src = '/sites/all/themes/ksu_foundation/images/flash-avatar.jpg';
  };
  var buttonBox = document.createElement('div');
  buttonBox.classList.add('button-box');
  var right = document.createElement('div');
  right.classList.add('right');

  leftInner.appendChild(currentTitle);
  leftInner.appendChild(planImg);
  leftInner.appendChild(buttonBox);
  left.appendChild(leftInner);
  overview.appendChild(left);
  overview.appendChild(right);

  //console.log(planInfo);

  if (planInfo['Name'] != null) {
    currentTitle.textContent = planInfo['Name'];
  }
  if (planInfo['ImageName'] != null) {
    try {
      planImg.src = '/sites/all/themes/ksu_foundation/images/dining/' + planInfo['ImageName'] + '.png';
    }
    catch (error) {
    }
  }

  if (reset) {
    //ONLY if rebuilding for initial overview
    if (planInfo['CurrentRequestStatus'] && planInfo['CurrentRequestStatus'].toLowerCase() === 'pending') {
      //Pending
      var pending = document.createElement('div');
      pending.classList.add('pending');
      var pendLeft = document.createElement('div');
      pendLeft.classList.add('left');
      var pendIcon = document.createElement('span');
      pendIcon.classList.add('dripicons-warning');
      var pendRight = document.createElement('div');
      pendRight.classList.add('right');
      var pendEm = document.createElement('em');
      pendEm.textContent = 'Plan purchase pending. Please allow up to 2 business days for activation.';

      if (planInfo['CurrentRequestPlan'] != null) {
        pendEm.textContent = 'Plan purchase pending. Please allow up to 2 business days for activation of: ';
        var pendHighlight = document.createElement('span');
        pendHighlight.classList.add('highlight');
        pendHighlight.textContent = planInfo['CurrentRequestPlan'];
        pendEm.appendChild(pendHighlight);
      }

      pendRight.appendChild(pendEm);
      pendLeft.appendChild(pendIcon);
      pending.appendChild(pendLeft);
      pending.appendChild(pendRight);
      buttonBox.appendChild(pending);

    }
    else {
      //Not Pending
      //Add Button
      var addBtn = document.createElement('button');
      addBtn.setAttribute('class', 'button button-bordered diningStartBtn');
      addBtn.setAttribute('data-flow', 'add');
      addBtn.setAttribute('tabindex', '-1');
      addBtn.textContent = 'Add Plan';
      addBtn.addEventListener('click', diningGetQuestions);
      buttonBox.appendChild(addBtn);

      //Change Button
      if (planInfo['EligibleToChange'] && planInfo['EligibleToChange'].toLowerCase() === 'true') {
        var changeBtn = document.createElement('button');
        changeBtn.setAttribute('class', 'button button-bordered diningStartBtn');
        changeBtn.setAttribute('data-flow', 'change');
        changeBtn.setAttribute('tabindex', '-1');
        changeBtn.textContent = 'Change Plan';
        changeBtn.addEventListener('click', diningGetQuestions);
        buttonBox.appendChild(changeBtn);

      }
    }
  }//Ending reset branch

  function diningBuildCount(num, title, info) {
    var count = document.createElement('div');
    count.classList.add('count');
    count.textContent = num;
    right.appendChild(count);
    var countTitle = document.createElement('h4');
    countTitle.textContent = title;
    if (info) {
      var infoLink = diningMakeInfoLink(info);
      countTitle.appendChild(infoLink);
    }
    right.appendChild(countTitle);
    if(title === 'each semester'){
      countTitle.classList.add('detail-price');
      var titleHr = document.createElement('hr');
      right.appendChild(titleHr);
    }
  }

  if (!reset) {
    diningBuildCount(('$' + planInfo['Cost']), 'each semester');
  }

  if (planInfo['Swipes'] != null) {
    diningBuildCount(planInfo['Swipes'], 'meal swipes', 'dining-def1-info');
  }
  if (planInfo['DecliningBalance'] != null) {
    diningBuildCount(('$' + planInfo['DecliningBalance']), 'declining balance', 'dining-def1-info');
  }
  if (planInfo['BonusDollars'] != null) {
    diningBuildCount(('$' + planInfo['BonusDollars']), 'bonus dollars', 'dining-def1-info');
  }
  if (planInfo['GuestMeals'] != null) {
    diningBuildCount(planInfo['GuestMeals'], 'guest meals', 'dining-def2-info');
  }

  function diningBuildLocations(locations, title, info) {
    var locationTitle = document.createElement('h4');
    locationTitle.classList.add('no-count');
    locationTitle.textContent = title;
    if (info) {
      var infoLink = diningMakeInfoLink(info);
      locationTitle.appendChild(infoLink);
    }
    right.appendChild(locationTitle);
    var location = document.createElement('p');
    location.textContent = locations;
    right.appendChild(location);
  }

  if (planInfo['SwipeLocations'] != null) {
    diningBuildLocations(planInfo['SwipeLocations'], 'swipe locations');
  }
  if (planInfo['MealExchange'] != null) {
    diningBuildLocations(planInfo['MealExchange'], 'meal exchange', 'dining-def2-info');
  }

  //Promo Code Added 12-16-2019
    var promoTitle = document.createElement('h4');
    promoTitle.classList.add('no-count');
    promoTitle.innerHTML = '<span id="promo-input-label">promo code</span> <span class="optional">(optional)<span>';
    var promoInfo = diningMakeInfoLink('promo-info');
    promoTitle.appendChild(promoInfo);

    right.appendChild(promoTitle);
    var promoInput = document.createElement('input');
    promoInput.setAttribute('type', 'text');
    promoInput.id = 'promo-input';
    promoInput.setAttribute('aria-labelledby', 'promo-input-label');
    promoInput.setAttribute('maxlength', '8'); 
    
    right.appendChild(promoInput);
  //End Promo Code


  if (reset) {
    var contactLink = document.createElement('a');
    contactLink.classList.add('dripicons-question');
    contactLink.setAttribute('tabindex', '-1');
    contactLink.setAttribute('target', '_blank');
    contactLink.href = 'https://www.kent.edu/flashcard';
    contactLink.textContent = 'Contact the FLASHcard Office';
    right.appendChild(contactLink);
  }

  return overview;
}


//Returns empty string or the text the to prepend to dining plans without the in the title.
function diningCheckForTHE(planName){
  var name = planName.toLowerCase();
  var front = name.substring(0, 4);
  if(front === 'the '){
  	return '';
  }else{
  	return 'the ';
  }
}


//While the user is checking out different plans, this function changes all
// information on the Details Page.
function diningPlanDetails(e) {
  var thisId = e.currentTarget.getAttribute('data-plan-id');
  if (diningCurrentDetail === thisId) {
    stpNextStep(diningStepper, 'dining-detail');
    return;
  }

  diningCurrentDetail = thisId;
  var i = diningPlansData.length;

  while (i--) {
    if (thisId == diningPlansData[i].Id) {
      diningNewPlanInfo = diningPlansData[i];
      break;
    }
  }

  var thisInner = diningDetail.querySelector('.inner');
  var newOverview = diningBuildOverview(diningNewPlanInfo);
  var oldOverview = diningDetail.querySelector('.overview-wrapper');
  diningNewPlanPrefix = diningCheckForTHE(diningNewPlanInfo['Name']);

  diningStepper.removeAttribute('aria-live');
  if (oldOverview) {
    thisInner.removeChild(oldOverview);
    oldOverview = null;
  }
  thisInner.insertBefore(newOverview, thisInner.firstChild);

  switch (diningFlow) {
    case 'change':
      diningPurchaseBtn.textContent = 'Change to ' + diningNewPlanPrefix + diningNewPlanInfo['Name'];
      break;
    case 'add':
      diningPurchaseBtn.textContent = 'Add ' + diningNewPlanPrefix + diningNewPlanInfo['Name'];
      break;
    case 'new':
    default:
      diningPurchaseBtn.textContent = 'Purchase ' + diningNewPlanPrefix + diningNewPlanInfo['Name'];
  }

  var updateEls = document.querySelectorAll('#dining-detail [data-update], #dining-confirm [data-update], #dining-success [data-update]');
  for (var i = 0; i < updateEls.length; i++) {
    var updateType = updateEls[i].getAttribute('data-update');
    switch (updateType) {
      case 'image':
        updateEls[i].src = '/sites/all/themes/ksu_foundation/images/dining/' + diningNewPlanInfo['ImageName'] + '.png';
        break;
      case 'newplan':
        updateEls[i].innerHTML = '<span class="prefix">' + diningNewPlanPrefix + '</span>' + diningNewPlanInfo['Name'];
        break;
      case 'currentplan':
        updateEls[i].innerHTML  = '<span class="prefix">' + diningCheckForTHE(diningMainTitle.textContent) + '</span>' + diningMainTitle.textContent;
        break;
      case 'price':
        updateEls[i].textContent = diningNewPlanInfo['Cost'];
        break;
      default:
    }
  }
  diningStepper.setAttribute('aria-live', 'polite');
  stpNextStep(diningStepper, 'dining-detail');
}


//This runs when the user clicks the Purchase Button. The result of this takes
// them to the Confirm step.
function diningPurchaseClick(e) {

  //Promo Code Added 12-16-2019
  //let promoCode = document.getElementById('promo-input').value.trim();
  let promoInput = document.getElementById('promo-input');
  let promoCode = promoInput.value.trim();


  //make sure promo code is okay before allowing them to proceed
  let emojiRegEx = new RegExp('(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])');
  let emojiFound = emojiRegEx.test(promoCode);

  if(promoCode.length > 0 && promoCode.length > 8 || emojiFound){ 
    let promoErrorMsg = emojiFound ? '<p><strong>Emojis</strong> cannot be used in promo code.</p>' : '<p>Promo code cannot exceed 6 characters.</p>';

    promoInput.classList.add('fl-show-user');


    swal2({
      showCloseButton: true,
      buttonsStyling: false,
      confirmButtonClass: 'button show-focus',
      title: 'Promo Code Format',
      html: promoErrorMsg,
      type: 'info',
      confirmButtonText: "Okay",
      focusConfirm: true,
      onBeforeOpen: function onBeforeOpen() {
        sweetAlertCustom();
        //ie11
        if(document.activeElement) document.activeElement.blur();
      },
      onAfterClose: function () {
        //Timeout needed for ie11
        setTimeout(function(){
          promoInput.focus();
         }, 100);
      }
    });

    return;
  } else {
    promoInput.classList.remove('fl-show-user');
  }


  let promoDisplays = diningStepper.querySelectorAll('[data-update="promo"]');

  if(promoCode.length > 0){
    //console.log('Promo Code!');
    //console.log(promoCode.length);
    //console.log(escapeHtml(promoCode));
    diningPromo = promoCode;
    diningStepper.classList.add('has-promo-code');
    promoDisplays.forEach(function(el){
      el.textContent = diningPromo;
    });

  } else {
    //console.log(' No Promo Code....');
    diningPromo = null;
    diningStepper.classList.remove('has-promo-code');
    promoDisplays.forEach(function(el){
      el.textContent = '';
    });
  }

  var confirmBtn = diningConfirm.querySelector('#dining-confirm-btn');
  if (confirmBtn) {
    if (confirmBtn.getAttribute('data-plan-id') === diningNewPlanInfo['Id']) {
      stpNextStep(diningStepper);
      return;
    }
  }
  else {
    diningStepper.removeAttribute('aria-live');
    var confirmInner = diningConfirm.querySelector('.inner');
    confirmBtn = document.createElement('button');
    confirmBtn.id = "dining-confirm-btn";
    confirmBtn.setAttribute('class', 'button button-dining');
    confirmBtn.setAttribute('tabindex', '-1');
    confirmBtn.addEventListener('click', diningConfirmClick);
    var confirmNav = diningConfirm.querySelector('.dining-nav');
    diningStepper.removeAttribute('aria-live');
    confirmInner.insertBefore(confirmBtn, confirmNav);
  }


  confirmBtn.setAttribute('data-plan-id', diningNewPlanInfo['Id']);

  //switch here for button text
  switch (diningFlow) {
    case 'change':
      confirmBtn.textContent = 'Confirm Change';
      break;
    case 'add':
      confirmBtn.textContent = 'Confirm Addition';
      break;
    case 'new':
    default:
      confirmBtn.textContent = 'Confirm Purchase';
  }

  diningStepper.setAttribute('aria-live', 'polite');
  stpNextStep(diningStepper);

}


//FINAL SUBMISSION TO PURCHASE
function diningConfirmClick(e) {
  //FINAL WS CALL

  stepperSuspend(diningStepper);
  var diningLoader = new ajaxLoader($('#dining-stepper'));

  var diningPlan = Number(e.currentTarget.getAttribute('data-plan-id'));
  var diningUser = diningStepper.getAttribute('data-dining-user');
  var diningSemester;

  var i = diningAnswerArray.length;

  while (i--) {
    if (3 === diningAnswerArray[i]['QuestionId']) {
      diningSemester = diningAnswerArray[i]['Answer'];
      break;
    }
  }
  //console.log(diningPlan);
  //console.log(diningUser);
  //console.log(diningSemester);
  //console.log(diningPromo);
  $.ajax({
    method: "POST",
    url: "/asyncloader/DoEntity/396",
    dataType: "json",
    data: {

      "Id": diningPlan,
      "KentStateUserId": diningUser,
      "Semester": diningSemester,
      "PromoCode": diningPromo

    }
  }).done(function (data) {
    //console.log('PurchaseMealPlan');
    console.log(data);
    if (data === 'Success') {
      //WS CALL SUCCESSFUL
      diningLoader.remove();
      stepperResume(diningStepper);
      stpNextStep(diningStepper);
      diningStepper.removeAttribute('aria-live');
      setTimeout(function () {
        diningSuccess.querySelector('.platter-set').classList.add('open');
      }, 1600);
      diningStepper.setAttribute('aria-live', 'polite');
    } else if (data === 'promo_code_fail') {
      diningShowError('webservice', 'Promo Code Format', 'Promo code cannot exceed 6 characters.', 'Emojis cannot be used.');
      diningLoader.remove();
      stepperResume(diningStepper);
      stpNextStep(diningStepper, 'diningError');
    } else {
      //WS CALL RETURNED AN ERROR
      diningShowError('webservice', 'Webservice', 'Meal Plan API returned an error. (PurchaseMealPlan)', 'Please try again later.');
      diningLoader.remove();
      stepperResume(diningStepper);
      stpNextStep(diningStepper, 'diningError');
    }

  }).fail(function () {
    //WS CALL FAILED
    diningShowError('webservice', 'Webservice', 'Meal Plan API not responding. (PurchaseMealPlan)', 'Please try again later.');
    diningLoader.remove();
    stepperResume(diningStepper);
    stpNextStep(diningStepper, 'diningError');
  });

}


function diningUpdateAnswerArray(e) {
  var questionId = Number(e.currentTarget.getAttribute('data-question'));
  var answerValue = e.currentTarget.value;
  var lastQuestion;
  for (var j = 0; j < diningAnswerArray.length; j++) {
    if (questionId === diningAnswerArray[j].QuestionId) {
      diningAnswerArray[j].Answer = answerValue;
      if (j === diningAnswerArray.length - 1) {
        //last question was updated. Run next WS call to populate plans.
        diningGetPlansByAnswers(e);
        return;
      }
      break;
    }
  }
  stpNextStep(diningStepper);
}


function diningShowError(ErrCode, title, message1, message2) {
  //function assumes stepperSuspend has been called already AND loader is
  // running. So you don't need to worry about aria-live in this function
  diningHasErrorNav = diningError.querySelector('.dining-nav');
  if (!diningHasErrorNav) {
    var errorNav = diningMakeNav();
    stepperSuspend(diningStepper);
    diningErrorInner.appendChild(errorNav);
    stpReNumberThisStepper(diningStepper, true);
    stepperResume(diningStepper);
  }

  var errorCode = ErrCode.toLowerCase();
  switch (errorCode) {
    case 'webservice':
      diningError.setAttribute('data-error', 'webservice');
      break;
    case 'ineligible':
      diningError.setAttribute('data-error', 'ineligible');
      break;
    default:
  }

  diningErrorTitle.textContent = title;
  diningErrorMessage1.textContent = message1;
  if (message2){
    diningErrorMessage2.textContent = message2;
  } else {
    diningErrorMessage2.textContent = '';
  }
}


function diningBuildQuestion(question) {
  var newStep = document.createElement('div');
  newStep.classList.add('step');
  var newInner = document.createElement('div');
  newInner.classList.add('inner');
  newStep.appendChild(newInner);
  var newTitle = diningMainTitle.cloneNode(true);

  if (diningFlow === 'change' || diningFlow === 'add') {
    var currentText = document.createElement('div');
    currentText.classList.add('current');
    currentText.textContent = 'Current Selection:';
    newInner.appendChild(currentText);
    newTitle.classList.add('aria-only');
    newInner.appendChild(newTitle);
  }

  var newIcon = diningMainIcon.cloneNode(true);
  newInner.appendChild(newIcon);

  if (diningFlow === 'new') {
    newInner.appendChild(newTitle);
  }

  var newQuestion = document.createElement('p');
  newQuestion.classList.add('prompt');
  newQuestion.textContent = question.Question;
  newInner.appendChild(newQuestion);

  if (question.Id === 3) {
    var infoLink = diningMakeInfoLink();
    infoLink.setAttribute('data-swapcontent', 'dining-semester-info');
    newQuestion.appendChild(infoLink);
  }
  if (question.Hint) {
    var hint = document.createElement('p');
    hint.classList.add('hint');
    hint.textContent = 'Hint: ' + question.Hint;
    newInner.appendChild(hint);
  }

  var answers = question.Options;

  for (var i = 0; i < answers.length; i++) {
    var newAnswer = document.createElement('button');
    newAnswer.setAttribute('tabindex', '-1');
    newAnswer.setAttribute('data-question', question.Id);
    newAnswer.setAttribute('class', 'button button-dining arrow-right');
    newAnswer.textContent = answers[i];
    newAnswer.setAttribute('value', answers[i]);
    newAnswer.addEventListener('click', diningUpdateAnswerArray);
    newInner.appendChild(newAnswer);
  }//END LOOP THROUGH ANSWERS

  var newNav = diningMakeNav();
  newInner.appendChild(newNav);

  return newStep;

}


function diningMakeInfoLink(swap) {
  var infoLink = document.createElement('a');
  infoLink.href = '';
  infoLink.classList.add('flip-to-back');
  infoLink.classList.add('dripicons-information');
  infoLink.setAttribute('aria-label', 'More Information');
  infoLink.setAttribute('tabindex', '-1');
  if (swap) {
    infoLink.setAttribute('data-swapcontent', swap);
  }
  infoLink.addEventListener('click', btnFlipToBack);
  return infoLink;
}


function diningMakeNav() {
  var stepNav = document.createElement('div');
  stepNav.classList.add('dining-nav');
  var backBtn = document.createElement('button');
  backBtn.setAttribute('tabindex', '-1');
  backBtn.setAttribute('class', 'button button-bordered back');
  backBtn.textContent = "Back";
  backBtn.classList.add('arrow-left');

  var cancelLink = document.createElement('a');
  cancelLink.setAttribute('tabindex', '-1');
  cancelLink.href = '';
  cancelLink.classList.add('back');
  cancelLink.textContent = "Cancel";
  cancelLink.setAttribute('data-back', 'start');

  stepNav.appendChild(backBtn);
  stepNav.appendChild(cancelLink);
  return stepNav;
}


//This is called if a WS is called for second time
//Like if re-populating questions or re-populating available plans
function clearSetAfterStep(thisSet, thisStep) {
  var startRemoving;
  var stepsInSet = thisSet.querySelectorAll('.step');
  for (var i = 0; i < stepsInSet.length; i++) {
    if (stepsInSet[i] === thisStep) {
      startRemoving = true;
      continue;
    }
    if (startRemoving) {
      stepsInSet[i].parentNode.removeChild(stepsInSet[i]);
      stepsInSet[i] = null;
    }
  }
}


function diningGetPlansByAnswers(e) {
  //First check if we have already loaded the appropriate plans
  //aka, we went backwards in the stepper for awhile but advanced again without
  //changing any of our answers.

  //In this case, we want to avoid making another Idential WS Call because it
  // would just give us the same results and waste time.

  //When we receive a successful response in the call below, we want to store a
  // copy of the answer array. If this function is called again later, we can
  // compare the new answers to the stored answers to see if we need to do the
  // WS Call again.

  //We can't easily do a deep clone of an array containing objects without a
  // major REFERENCING HEADACHE. The stored answers would update by reference
  // everytime the user selected a new answer... So we need to compare
  // JSON.stringified versions of our arrays to get around this.

  if (diningPlansLoaded && (JSON.stringify(diningAnswerArray) === diningSubmittedAnswers)) {
    stpNextStep(diningStepper);
    return;
  }

  //If an error occurs in the ajax, these 2 commands will undo themselves in
  // the diningError function.
  stepperSuspend(diningStepper);
  var diningLoader = new ajaxLoader($('#dining-stepper'));

  var thisStep = parentOfClass(e.currentTarget, 'step');
  var thisSet = parentOfClass(thisStep, 'set');

  //Make a WS call Get Plans
  $.ajax({
    method: "POST",
    url: "/asyncloader/DoEntity/381",
    dataType: "json",
    data: {'data': diningAnswerArray}
  }).done(function (data) {

    //console.log('GetAvailablePlansByAnswers');
    //console.log(data);
    if (data.error !== 'error') {
      //WS CALL SUCCESSFUL

      if ( data.length > 0) {
        //WS CALL RETURNED PLANS

        //Successful plan retrieval. Save this info
        //incase this function is called again later.
        diningSubmittedAnswers = JSON.stringify(diningAnswerArray);
        diningPlansData = data;
        //Clear steps in this set populated by earlier WS calls
        clearSetAfterStep(thisSet, thisStep);

        var newStep = document.createElement('div');
        newStep.id = 'dining-options';
        newStep.classList.add('step');
        var newInner = document.createElement('div');
        newInner.classList.add('inner');
        newStep.appendChild(newInner);
        var newTitle = diningMainTitle.cloneNode(true);

        if (diningFlow === 'change' || diningFlow === 'add') {
          var currentText = document.createElement('div');
          currentText.classList.add('current');
          currentText.textContent = 'Current Selection:';
          newInner.appendChild(currentText);
          newTitle.classList.add('aria-only');
          newInner.appendChild(newTitle);
        }

        var newIcon = diningMainIcon.cloneNode(true);
        newInner.appendChild(newIcon);
        if (diningFlow === 'new') {
          newInner.appendChild(newTitle);
        }

        var newQuestion = document.createElement('p');
        newQuestion.classList.add('prompt');
        newQuestion.textContent = 'Select a Dining Plan to View Details';
        newInner.appendChild(newQuestion);

        var allAccess;
        var block;
        var flex;

        $.each(data, function (i) {
          if ((this.Type).toLowerCase() === 'all-access') {
            allAccess = true;
          }
          if ((this.Type).toLowerCase() === 'block') {
            block = true;
          }
          if ((this.Type).toLowerCase() === 'flex') {
            flex = true;
          }
        });


        if (allAccess) {
          var allAccessTitle = document.createElement('h5');
          allAccessTitle.textContent = 'All-Access Plans';
          var allAccessInfo = diningMakeInfoLink();
          allAccessInfo.setAttribute('data-swapcontent', 'dining-type-info');
          allAccessTitle.appendChild(allAccessInfo);
          newInner.appendChild(allAccessTitle);
          var allAccessBox = document.createElement('div');
          newInner.appendChild(allAccessBox);
        }
        if (block || flex) {
          var blockFlexTitle = document.createElement('h5');
          if (block && !flex) {
            blockFlexTitle.textContent = 'Block Plans';
          }
          else if (!block && flex) {
            blockFlexTitle.textContent = 'Flex Plans';
          }
          else {
            blockFlexTitle.textContent = 'Block & Flex Plans';
          }

          var blockFlexInfo = diningMakeInfoLink();
          blockFlexInfo.setAttribute('data-swapcontent', 'dining-type-info');
          blockFlexTitle.appendChild(blockFlexInfo);
          newInner.appendChild(blockFlexTitle);
          var blockFlexBox = document.createElement('div');
          newInner.appendChild(blockFlexBox);
        }

        var thisNode = document.getElementById('node-5556');
        thisNode.classList.remove('no-all-access');
        thisNode.classList.remove('no-block');
        thisNode.classList.remove('no-flex');
        if (!allAccess) {
          thisNode.classList.add('no-all-access');
        }
        if (!block) {
          thisNode.classList.add('no-block');
        }
        if (!flex) {
          thisNode.classList.add('no-flex');
        }

        //Build buttons and put in appropriate category.
        $.each(data, function (i) {

          var planBtn = document.createElement('button');
          planBtn.setAttribute('tabindex', '-1');
          planBtn.setAttribute('data-plan-id', this.Id);
          planBtn.setAttribute('class', 'button button-dining arrow-right');
          var planBtnName = document.createElement('span');
          planBtnName.classList.add('text');
          planBtnName.textContent = this.Name;
          var planBtnPrice = document.createElement('span');
          planBtnPrice.classList.add('price');
          planBtnPrice.textContent = '$' + this.Cost;
          planBtn.appendChild(planBtnName);
          planBtn.appendChild(planBtnPrice);
          planBtn.addEventListener('click', diningPlanDetails);

          if ((this.Type).toLowerCase() === 'all-access') {
            allAccessBox.appendChild(planBtn);
          }
          if ((this.Type).toLowerCase() === 'block') {
            blockFlexBox.appendChild(planBtn);
          }
          if ((this.Type).toLowerCase() === 'flex') {
            blockFlexBox.appendChild(planBtn);
          }
        });

        var bottomNav = diningMakeNav();
        newInner.appendChild(bottomNav);

        thisSet.appendChild(newStep);

        stpReNumberThisStepper(diningStepper, true);
        diningPlansLoaded = true;

        diningLoader.remove();
        stepperResume(diningStepper);
        stpNextStep(diningStepper);
        return;


      }
      else {
        //WS CALL RETURNED NO PLANS FOR THIS USER
        diningShowError('ineligible', 'Not Eligible For Meal Plan', 'You do not have any meal plans available for purchase at this time.', 'If you believe you have received this message in error or have any questions please contact the FLASHcard Office.');
        diningLoader.remove();
        stepperResume(diningStepper);
        stpNextStep(diningStepper, 'diningError');
      }

    }
    else {
      //WS CALL RETURNED AN ERROR
      diningShowError('webservice', 'Webservice', 'Meal Plan API returned an error. (GetAvailablePlansByAnswers)', 'Please try again later.');
      diningLoader.remove();
      stepperResume(diningStepper);
      stpNextStep(diningStepper, 'diningError');
    }

  }).fail(function () {
    //WS CALL FAILED
    diningShowError('webservice', 'Webservice', 'Meal Plan API not responding. (GetAvailablePlansByAnswers)', 'Please try again later.');
    diningLoader.remove();
    stepperResume(diningStepper);
    stpNextStep(diningStepper, 'diningError');
  });
}


//Right after the user STARTS the process. This fuction sets up all the markup
// that needs to be unique to the flow (add, change, or new)
function diningFlowMarkup() {
  diningCurrentDetail = null;
  //If final button is present, remove it.
  var confirmBtn = diningConfirm.querySelector('#dining-confirm-btn');
  if (confirmBtn) {
    confirmBtn.parentNode.removeChild(confirmBtn);
    confirmBtn = null;
  }

  //Remove old flow confirm and success messages.
  var oldMessageEls = diningStepper.querySelectorAll('#dining-confirm-message > *, #dining-success-message > *');
  for (var i = 0; i < oldMessageEls.length; i++) {
    var trash = oldMessageEls[i];
    trash.parentNode.removeChild(trash);
    trash = null;
  }

  var confirmMessage = document.getElementById('dining-confirm-message');
  var successMessage = document.getElementById('dining-success-message');
  var currentYear = 'current Academic Year';

  switch (diningFlow) {
    case 'add':
      //CONFIRM MESSAGE
      diningConfirmTitle.textContent = 'Confirm Addition';
      var cp1 = document.createElement('p');
      var ct1 = document.createTextNode('By selecting Confirm, you acknowledge you are adding ');
      cp1.appendChild(ct1);
      var ct2 = document.createElement('span');
      ct2.setAttribute('data-update', 'newplan');
      ct2.classList.add('highlight');
      ct2.classList.add('show-prefix');
      cp1.appendChild(ct2);
      var ct3 = document.createTextNode(' to ');
      cp1.appendChild(ct3);
      var ct4 = document.createElement('span');
      ct4.setAttribute('data-update', 'currentplan');
      ct4.classList.add('highlight');
      ct4.classList.add('show-prefix');
      cp1.appendChild(ct4);
      var ct5 = document.createTextNode(' for the ' + currentYear + '.');
      cp1.appendChild(ct5);
      confirmMessage.appendChild(cp1);

      var cp2 = document.createElement('p');
      var ct6 = document.createTextNode('Your account will be billed ');
      cp2.appendChild(ct6);
      var ct7 = document.createElement('span');
      ct7.classList.add('highlight');
      cp2.appendChild(ct7);
      var ct8 = document.createTextNode('an additional $');
      ct7.appendChild(ct8);
      var ct9 = document.createElement('span');
      ct9.setAttribute('data-update', 'price');
      ct7.appendChild(ct9);
      var ct10 = document.createTextNode(' each semester');
      ct7.appendChild(ct10);
      var ct11 = document.createTextNode('.');
      cp2.appendChild(ct11);
      confirmMessage.appendChild(cp2);

      //SUCCESS MESSAGE
      var sp1 = document.createElement('p');
      var st1 = document.createTextNode('You\'ve added ');
      sp1.appendChild(st1);
      var st2 = document.createElement('span');
      st2.classList.add('highlight');
      st2.classList.add('show-prefix');
      st2.setAttribute('data-update', 'newplan');
      sp1.appendChild(st2);
      var st3 = document.createTextNode(' to your current meal plan. Your meal plan status is ');
      sp1.appendChild(st3);
      var st4 = document.createElement('span');
      st4.classList.add('highlight');
      st4.textContent = 'pending';
      sp1.appendChild(st4);
      var st5 = document.createTextNode('.');
      sp1.appendChild(st5);
      successMessage.appendChild(sp1);
      break;

    case 'change':
      //CONFIRM MESSAGE
      diningConfirmTitle.textContent = 'Confirm Change';
      var cp1 = document.createElement('p');
      var ct1 = document.createTextNode('By selecting Confirm, you acknowledge you are changing from ');
      cp1.appendChild(ct1);
      var ct2 = document.createElement('span');
      ct2.classList.add('highlight');
      ct2.classList.add('show-prefix');
      ct2.setAttribute('data-update', 'currentplan');
      cp1.appendChild(ct2);
      var ct3 = document.createTextNode(' to ');
      cp1.appendChild(ct3);
      var ct4 = document.createElement('span');
      ct4.classList.add('highlight');
      ct4.classList.add('show-prefix');
      ct4.setAttribute('data-update', 'newplan');
      cp1.appendChild(ct4);
      var ct5 = document.createTextNode(' for the ' + currentYear + '.');
      cp1.appendChild(ct5);
      confirmMessage.appendChild(cp1);

      //SUCCESS MESSAGE
      var sp1 = document.createElement('p');
      var st1 = document.createTextNode('You\'ve changed your meal plan to ')
      sp1.appendChild(st1);
      var st2 = document.createElement('span');
      st2.classList.add('highlight');
      st2.classList.add('show-prefix');
      st2.setAttribute('data-update', 'newplan');
      sp1.appendChild(st2);
      var st3 = document.createTextNode('. Your meal plan status is ');
      sp1.appendChild(st3);
      var st4 = document.createElement('span');
      st4.classList.add('highlight');
      st4.textContent = 'pending';
      sp1.appendChild(st4);
      var st5 = document.createTextNode('.');
      sp1.appendChild(st5);
      successMessage.appendChild(sp1);
      break;
    case 'new':
    default:
      //CONFIRM MESSAGE
      diningConfirmTitle.textContent = 'Confirm Purchase';
      var cp1 = document.createElement('p');
      var ct1 = document.createTextNode('By selecting Confirm, you acknowledge you will be enrolled in ');
      cp1.appendChild(ct1);
      var ct2 = document.createElement('span');
      ct2.classList.add('highlight');
      ct2.classList.add('show-prefix');
      ct2.setAttribute('data-update', 'newplan');
      cp1.appendChild(ct2);
      var ct3 = document.createTextNode(' for the ' + currentYear + '.');
      cp1.appendChild(ct3);
      confirmMessage.appendChild(cp1);

      var cp2 = document.createElement('p');
      var ct4 = document.createTextNode('Your account will be billed ');
      cp2.appendChild(ct4);
      var ct5 = document.createElement('span');
      ct5.classList.add('highlight');
      cp2.appendChild(ct5);
      var ct6 = document.createTextNode('$');
      ct5.appendChild(ct6);
      var ct7 = document.createElement('span');
      ct7.setAttribute('data-update', 'price');
      ct5.appendChild(ct7);
      var ct8 = document.createTextNode(' each semester');
      ct5.appendChild(ct8);
      var ct9 = document.createTextNode('.');
      cp2.appendChild(ct9);
      confirmMessage.appendChild(cp2);

      //SUCCESS MESSAGE
      var sp1 = document.createElement('p');
      var st1 = document.createTextNode('You\'ve purchased ');
      sp1.appendChild(st1);
      var st2 = document.createElement('span');
      st2.classList.add('highlight');
      st2.classList.add('show-prefix');
      st2.setAttribute('data-update', 'newplan');
      sp1.appendChild(st2);
      var st3 = document.createTextNode('. Your meal plan status is ');
      sp1.appendChild(st3);
      var st4 = document.createElement('span');
      st4.classList.add('highlight');
      st4.textContent = 'pending';
      sp1.appendChild(st4);
      var st5 = document.createTextNode('.');
      sp1.appendChild(st5);
      successMessage.appendChild(sp1);
  }
}


//This runs right when a user STARTS the process.
//So they clicked Add, Change, or Purchase a Dining Plan.
function diningGetQuestions(e) {
  //First check if we have already loaded the appropriate questions
  var passedEvent = e;
  var newFlow = e.currentTarget.getAttribute('data-flow');

  if (diningQuestionsLoaded && newFlow === diningFlow) {
    stpNextStep(diningStepper);
    return;
  }

  stepperSuspend(diningStepper);
  var diningLoader = new ajaxLoader($('#dining-stepper'));

  var thisStep = parentOfClass(e.currentTarget, 'step');
  var thisSet = parentOfClass(thisStep, 'set');

  //Make a WS call Get Questions
  $.get("/asyncloader/DoEntity/391").done(function (data) {
    //console.log('GetQuestions');
    //console.log(data);

    if (!data.error) {

      if (data.length > 0) {
        //WS CALL SUCCESSFUL WITH RETURNED QUESTIONS
        diningFlow = newFlow;
        //Clear steps in this set populated by earlier WS calls
        clearSetAfterStep(thisSet, thisStep);
        //Ensures non-redundant WS calls when loading plans
        diningPlansLoaded = null;

        diningAnswerArray = [];
        $.each(data, function (i) {
          //initialize answer array
          diningAnswerArray.push({
            "QuestionId": this.Id,
            "Answer": null
          });
          //create and add steps here
          var newStep = diningBuildQuestion(this);
          thisSet.appendChild(newStep);
        });

        //new flow scenerios here
        diningFlowMarkup();
        stpReNumberThisStepper(diningStepper, true);
        diningLoader.remove();

        diningQuestionsLoaded = true;
        stepperResume(diningStepper);
        stpNextStep(diningStepper);
        return;

      }
      else {
        //WS call was successful but there were NO QUESTIONS.
        //If only SOME users get questions...
        //we could call the GetAvailablePlans function here.
        diningFlow = newFlow;
        diningPlansLoaded = null;
        diningAnswerArray = [];
        diningFlowMarkup();
        diningLoader.remove();
        stepperResume(diningStepper);
        diningGetPlansByAnswers(passedEvent);
      }
    }
    else {
      //WS Responded with an Error.

      diningAnswerArray = null;
      diningShowError('webservice', 'Webservice', 'Meal Plan API returned an error. (GetQuestions)', 'Please try again later.');
      diningLoader.remove();
      stepperResume(diningStepper);
      stpNextStep(diningStepper, 'diningError');
    }

  }).fail(function () {
    //WS CALL FAILED
    diningAnswerArray = null;
    diningShowError('webservice', 'Webservice', 'Meal Plan API not responding. (GetQuestions)', 'Please try again later.');
    diningLoader.remove();
    stepperResume(diningStepper);
    stpNextStep(diningStepper, 'diningError');
  });

}


//On load, this is the initial setup
function diningPlanInstall() {
  diningStepper = document.getElementById('dining-stepper');
  diningStart = diningStepper.querySelector('.step.current');

  diningDetail = document.getElementById('dining-detail');
  diningPurchaseBtn = document.getElementById('dining-purchase-btn');
  diningPurchaseBtn.addEventListener('click', diningPurchaseClick);
  diningConfirm = document.getElementById('dining-confirm');
  diningConfirmTitle = document.getElementById('dining-confirm-title');
  diningSuccess = document.getElementById('dining-success');


  diningError = document.getElementById('diningError');
  diningErrorInner = diningError.querySelector('.inner');
  diningErrorTitle = document.getElementById('diningErrorTitle');
  diningErrorMessage1 = document.getElementById('diningErrorMessage1');
  diningErrorMessage2 = document.getElementById('diningErrorMessage2');


  var startBtn = diningStepper.querySelector('.diningStartBtn');
  if (startBtn) {
    var startBtns = diningStepper.querySelectorAll('.diningStartBtn');
    for (var i = 0; i < startBtns.length; i++) {
      startBtns[i].addEventListener('click', diningGetQuestions);
    }
  }

  diningMainTitle = document.createElement('h4');

  if (diningStart.id === 'dining-new' || diningStart.id === 'dining-pending') {
    diningMainIcon = diningStart.querySelector('.plate-svg');
    diningMainTitle.textContent = 'No Meal Plan';
  }
  else if (diningStart.classList.contains('dining-overview')) {
    diningMainIcon = diningStart.querySelector('.plan-img');
    diningMainTitle.textContent = diningStart.querySelector('.current-plan').textContent;
  }
  else {
    diningMainIcon = document.createElement('img');
    diningMainIcon.src = '/sites/all/themes/ksu_foundation/images/flash-avatar.jpg';
    diningMainIcon.alt = '';
    diningMainTitle.textContent = 'Error';
  }

}
