function flSwitchAnimateHandler(e){
  flSwitchAnimate(e.currentTarget);
}

function flSwitchAnimate(el) {
    //console.log('switch animated');
    let label = el.nextElementSibling;
    let ariaSpan = label.querySelector('.aria-only');
    let labelStart = Number(label.getAttribute('data-start-width'));
    let labelPaddle = label.querySelector('[data-paddle]');
    let labelLeft = label.querySelector('[data-left]');
    let labelLeftStart = Number(labelLeft.getAttribute('data-start-width'));
    let labelRight = label.querySelector('[data-right]');
    let labelRightStart = Number(labelRight.getAttribute('data-start-width'));
    let show = el.getAttribute('data-show');
    let falseText = el.getAttribute('data-false');
    let trueText = el.getAttribute('data-true');

    if(el.checked){
        ariaSpan.textContent = trueText + ' | toggle for ' + falseText;
    }else{
        ariaSpan.textContent = falseText + ' | toggle for ' + trueText;
    }

    if(show === 'both'){
        label.style.width = '';
        labelLeft.style.width = '';
        labelRight.style.width = '';

        if(el.checked){
            labelPaddle.style.left = labelLeft.offsetWidth + 'px';
            labelPaddle.style.width = (labelRight.offsetWidth + 3) + 'px';
        }else{
            labelPaddle.style.left = '';
            labelPaddle.style.width = labelLeft.offsetWidth + 'px';
        }        

    }else if(show === 'active'){
        let paddleWidth = labelPaddle.offsetHeight;
        labelPaddle.style.width = paddleWidth + 'px';
        labelPaddle.style.borderRadius = '100%';

        
            if(el.checked){
                
                if(el.getAttribute('data-width') === 'constant'){
                    label.style.width = '';
                    labelLeft.style.width = '';
                    labelRight.style.width = '';
                    labelPaddle.style.left = ((labelLeftStart + labelRightStart - paddleWidth) + 3) + 'px';
                } else {
                    label.style.width = (labelLeftStart + paddleWidth + 10) + 'px';
                    labelLeft.style.width = labelLeftStart + 'px';
                    labelRight.style.width = paddleWidth + 'px';
                    labelPaddle.style.left = (labelLeftStart + 3) + 'px';
                } 

            }else{
                labelPaddle.style.left = '';

                if(el.getAttribute('data-width') === 'constant'){
                    label.style.width = '';
                    labelLeft.style.width = '';
                    labelRight.style.width = '';
                } else {
                    label.style.width = (labelRightStart + paddleWidth + 10) + 'px';
                    labelLeft.style.width = paddleWidth + 'px';
                    labelRight.style.width = labelRightStart + 'px';
                }  
            } 
         
    }

}


function flSwitchSetup(el, update) {
  let label = el.nextElementSibling.nodeName.toLowerCase() === 'label' ? el.nextElementSibling : null;
  if(!el.hasAttribute('data-fl-switch') || !label) return;

  let textFalse = el.getAttribute('data-false');
  let textTrue = el.getAttribute('data-true');
  let textPosition = el.getAttribute('data-position');
  let textShow = el.getAttribute('data-show');
  let textHighlight = el.hasAttribute('data-highlight');
  let checked = el.checked;
  let ariaSpan = document.createElement('span');
  ariaSpan.classList.add('aria-only');
  ariaSpan.setAttribute('aria-live', 'polite');
  let labelLeft = document.createElement('span');
  labelLeft.setAttribute('data-left', '');
  labelLeft.setAttribute('aria-hidden', 'true');
  let labelRight = document.createElement('span');
  labelRight.setAttribute('data-right', '');  
  labelRight.setAttribute('aria-hidden', 'true');
  let labelPaddle = document.createElement('span');
  labelPaddle.setAttribute('data-paddle', '');
  labelPaddle.setAttribute('aria-hidden', 'true');
  let labelPaddleFalse = document.createElement('span');
  labelPaddleFalse.setAttribute('data-false', '');
  let labelPaddleTrue = document.createElement('span');
  labelPaddleTrue.setAttribute('data-true', '');
  labelPaddle.appendChild(labelPaddleFalse);
  labelPaddle.appendChild(labelPaddleTrue);

  
  function stuffLabel() {
    label.innerHTML = '';
    label.appendChild(ariaSpan);
    label.appendChild(labelLeft);
    label.appendChild(labelRight);
    label.appendChild(labelPaddle);
  }

  function addAndRunListener() {
    el.addEventListener('change', flSwitchAnimateHandler);
    flSwitchAnimate(el);
  }

  if(textShow === 'both' && textFalse && textTrue) {
    //Text displayed inside switch and both showing at all times
    stuffLabel();
    labelLeft.textContent = textFalse;
    labelRight.textContent = textTrue;
    labelPaddleFalse.textContent = textFalse;
    labelPaddleTrue.textContent = textTrue;
    label.setAttribute('data-start-width', label.offsetWidth);
    labelLeft.setAttribute('data-start-width', labelLeft.offsetWidth);
    labelRight.setAttribute('data-start-width', labelRight.offsetWidth);

    addAndRunListener();
    el.classList.add('ready');
    
    //label.style.width = label.offsetWidth + 'px';

    

  } else if(textShow === 'active' && textFalse && textTrue) {
    //Alternate text display where the paddle is a circle and the active state text displays next to the paddle
    stuffLabel();
    labelLeft.textContent = textTrue;
    labelRight.textContent = textFalse;
    label.setAttribute('data-start-width', label.offsetWidth);
    labelLeft.setAttribute('data-start-width', labelLeft.offsetWidth);
    labelRight.setAttribute('data-start-width', labelRight.offsetWidth);

    addAndRunListener();
    el.classList.add('ready');
    
    //label.style.width = label.offsetWidth + 'px';

  } else {
    //Required attributes missing, remove data-fl-switch which will remove all custom styles
    console.log('required switch attributes not provided. aborting...');
    el.removeAttribute('data-fl-switch');
    return;
  }


}//Ending setupSwitcH

//remove label markup and data-attributes
function flSwitchKill() {
}

function flSwitchInstall() {
  var allSwitches = document.querySelectorAll('[data-fl-switch]');
  for(var i = 0; i < allSwitches.length; i++) {
    flSwitchSetup(allSwitches[i]);
  }
}//Ending installSwitchES

window.addEventListener('DOMContentLoaded', flSwitchInstall);