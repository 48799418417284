//Error Codes
//421: User hit confirm address button. AJAX post finished, but the response did not have the WasSuccessful element.
//521: User hit confirm address button. AJAX post failed.

class AddressValidate {

  constructor(options){
    console.log(options.data.AddressValidate);
    if (options.data.AddressValidate) {
      this.data =  Object.values (options.data.AddressValidate.data);
      console.log(this.data);
    } else {
      return [];
    };
    this.knowledgeBaseURL = 'https://kent.teamdynamix.com/TDClient/2005/Portal/KB/?CategoryID=14452';
    // this.ssprData = "22";
    //console.log(this.data);
    //START
    this.disclaimer();
  }

  failAlert(errorCode){

    swal2("Confirmation Not Received", "Something went wrong and we were unable to receive your confirmation. You may be asked to confirm your information again next time you log into FlashLine. Error Code: " + errorCode, "error").then(() => { 
        console.info("Error Code: " + errorCode);
          this.confirmResolve();
      });

  }

  successAlert(){

    swal2({
        width: '32rem',
        customClass: '',
        allowOutsideClick: false,
        title: 'Thank You!',
        html: '<p>You have successfully confirmed your information.</p><p>To learn more about how Kent State can use your data, <a href="' + this.knowledgeBaseURL + '" target="_blank">visit the KSU Knowledge Base</a>.</p>',
        type: 'success',
        focusConfirm: true,
        confirmButtonText: 'Close'

      }).then((result) => {
          //console.info("POST successful");
          this.confirmResolve();

        if(result.value) {
          //console.info("Confirm Button");
        }
        else if(result.dismiss === 'cancel'){
          //console.log('Cancel Button');
        }
        else if(result.dismiss === 'close' || result.dismiss === 'esc'){
          //console.log('User hit X or ESC');
        }

    });

    

  }        

  disclaimer(){

    swal2Btns({
        width: '35rem',
        customClass: '',
        allowOutsideClick: false,
        title: 'Do we have your latest information?',
        html: '<p>You are seeing this prompt because the University needs to confirm your information is up-to-date. Please continue to review your personal information.</p>',
        type: 'warning',
        focusConfirm: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Later'

      }).then((result) => {
        if(result.value) {
          this.step1();
        }
        else if(result.dismiss === 'cancel'){
          //console.log('User wants to do this later');
        }
        else if(result.dismiss === 'close' || result.dismiss === 'esc'){
          //console.log('User hit X or ESC');
        }

    });


  }


  getAddressBlock(iVal){
    let myData = this.data[iVal];

    let addressBlock = document.createElement('div');
    addressBlock.classList.add('address-block');
    console.log(myData);
    if(typeof myData.type === 'undefined') return addressBlock;

    function getLineEl(lineText, classString){
      let lineEl = document.createElement('div');
      lineEl.textContent = lineText;
      if(typeof classString !== 'undefined')lineEl.className = classString;
      return lineEl;
    }

    let options = {
      plainText : (typeof myData.purpose === 'string' && myData.purpose.trim()) ? myData.purpose.trim() : null,
      ariaOpen : 'Expand ' + myData.type + ' address definition',
      ariaClose : 'Close ' + myData.type + ' address definition'
    };

    // if(myData.type.toLowerCase() === 'permanent'){
    //   options.customHTML = '<p>Permanent address is defined as a person’s primary residence. For domestic students, it is usually where their parents live or where they go after the semester and during breaks. For International students, it is their country of origin and must be a physical address.</p>';
    // }

    if(myData.type.toLowerCase() === 'local'){
      options.customHTML = '<p>Local address is an address that is off-campus and geographically near Kent or the campus a student attends. This is considered a temporary type of address.</p>';
    } else {
      options.customHTML = '<p>Permanent address is defined as a person’s primary residence. For domestic students, it is usually where their parents live or where they go after the semester and during breaks. For International students, it is their country of origin and must be a physical address.</p>';
    }

    let headingEl = getLineEl(myData.type, 'type');
    if(options.plainText || (typeof options.customHTML === 'string' && options.customHTML))headingEl.appendChild(infoBox.getToggle(options));
    addressBlock.appendChild(headingEl);

    let addressWrap = document.createElement('div');
    addressWrap.classList.add('address-wrap');

    //Start Date
    // if(typeof myData.startDate !== 'undefined'){
    //  let dateString = myData.startDate + ' - ';
    //  dateString += myData.endDate ? myData.endDate : '(No end date)';
    //  addressWrap.appendChild(getLineEl(dateString, 'date'));
    // }

    let addressEl = document.createElement('div');
    addressEl.classList.add('address');
    if(typeof myData.line1 !== 'undefined'){
      if(myData.line1)addressEl.appendChild(getLineEl(myData.line1));
      if(myData.line2)addressEl.appendChild(getLineEl(myData.line2));
      if(myData.line3)addressEl.appendChild(getLineEl(myData.line3));
      // if(myData.line4)addressEl.appendChild(getLineEl(myData.line4));
    }else{
      addressEl.textContent = 'No address on file';
      addressEl.classList.add('fl-error');
    }
    addressWrap.appendChild(addressEl);

    var city = "";

    if(typeof myData.city !== 'undefined') 
      city = myData.city;

      var state = "";
      if(typeof myData.state !== 'undefined')
      state = myData.state;

      var zip = "";
      if(typeof myData.zip !== 'undefined')
      zip = myData.zip;

      var zipFull = zip.toString().slice(0,5);

      var country = "";
      if(typeof myData.country !== 'undefined')
      country = myData.country;

      var cityStateZipCountry = city + ", " + state + " " + zipFull + " " + country; 

      addressWrap.appendChild(getLineEl(cityStateZipCountry, 'cityStateZipCountry'));



    // if(typeof myData.city !== 'undefined')addressWrap.appendChild(getLineEl(myData.city, 'city'));

    // if(typeof myData.state !== 'undefined')addressWrap.appendChild(getLineEl(myData.state, 'state'));

    // if(typeof myData.zip !== 'undefined')addressWrap.appendChild(getLineEl(myData.zip, 'zip'));

    addressBlock.appendChild(addressWrap);
    addressBlock.appendChild(document.createElement('hr'));
    return addressBlock;

  }


  step1() {

    swal2Btns({
        width: '35rem',
        customClass: 'address-validate address-review',
        allowOutsideClick: false,
        title: 'Do we have your latest information?',
        html: '<p class="instructions">Please confirm the following addresses are correct:</p>',
        focusConfirm: true,
        confirmButtonText: 'Confirm All',
        cancelButtonText: 'Update',
        confirmButtonClass: 'button has-icon-left show-focus',
        cancelButtonClass: 'button has-icon-left',
      onBeforeOpen: () => {
        sweetAlertCustom();

        //Add Heading Icon
        let myIconDiv = document.createElement('div');
        myIconDiv.setAttribute('class', 'swal2-custom-icon');
        let myIcon = document.createElement('span');
        myIcon.setAttribute('aria-hidden', 'true');
        myIcon.setAttribute('class', 'fl-icon icon-verify-address');
        myIconDiv.appendChild(myIcon);
        let swalHeader = document.querySelector('.swal2-popup .swal2-header');
        let swalTitle = document.querySelector('.swal2-popup .swal2-title');
        swalHeader.insertBefore(myIconDiv, swalTitle);

        //Add Button Icons
        let cancelBtn = document.querySelector('.swal2-popup button.swal2-cancel');
        cancelBtn.innerHTML = '<span aria-hidden="true" class="dripicons-pencil"></span>Update';
        let confirmBtn = document.querySelector('.swal2-popup button.swal2-confirm');
        confirmBtn.innerHTML = '<span aria-hidden="true" class="dripicons-thumbs-up"></span>Confirm All';
        console.log(this.data);
        // if(1 || this.data.length){

          let addressList = document.createElement('div');
          addressList.classList.add('address-list');
          
         // for(let i = 0; i < Object.keys(this.data).length; ++i){
          for(let i = 0; i < this.data.length; ++i){
            console.log(i);
            addressList.appendChild(this.getAddressBlock(i));
          }

          document.getElementById('swal2-content').appendChild(addressList);
        //}

      },
      onOpen: () => {
      },
      preConfirm: () => {

        return new Promise((resolve) => {
          //use ball fall animation instead of spinner
          this.confirmResolve = resolve;
          sweetAlertUseBallFall();

          //console.log(document.activeElement);

          //Settimeout for testing only, use AJAX below instead
          // setTimeout( () => {
          //     this.successAlert();
          //     //this.failAlert(421);
          //     //this.failAlert(521);
          // }, 30000);

          
          $.ajax({
            method: "POST",
            cache: false,
            url: "/asyncloader/doEntity/826",
            dataType: "json",
            headers: {
              'X-Function-Name': 'VerifyAddressInformation',
            },
            data: {},
            data: {'UserName': KSU_UserHelper.getUsername() },
            }).done((data) => {
                console.log(data);
                if(data == 'Success'){
                  this.successAlert();
                } else{
                  this.failAlert(421);
                }

            }).fail(() => {
              this.failAlert(521);
            });
            

        });//End of promise
      }
      }).then((result) => {
        if(result.value) {
          //THIS STAYS EMPTY!
        }
        else if(result.dismiss === 'cancel'){
          //console.log('Update Clicked');
          window.open('/sso/pickup/banweb?framed=true&dest=https://keys.kent.edu/ePROD/bwgkogad.P_SelectAtypView&framed=true', '_blank');
        }
        else if(result.dismiss === 'close' || result.dismiss === 'esc'){
          //console.log('User hit X or ESC');
        }

    });

  }


}//End of class




var addressValidate;

//SSPR popup is a theme setting. addressValidateInit() called from ksu_foundation_es6.js
function addressValidateInit() {
  

  // var addressValidateData = {
  //   "addressValidate" : [
  //     {
  //       "type" : "Permanent",
  //       "startDate" : "Jul 05, 2017",
  //       "endDate" : "",
  //       "line1" : "1234 Summit Drive",
  //       "line2" : "Kent, OH 44240",
  //       "line3" : null,
  //       "line4" : null,
  //       "county" : "Portage Co.",
  //       "countryCode" : "USA",
  //       "purpose": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas tempor lectus justo. Aenean consequat metus nulla."
  //     },
  //     {
  //       "type" : "Local",
  //       "startDate" : "Jul 05, 2017",
  //       "endDate" : "",
  //       "line1" : "1234 Summit Drive",
  //       "line2" : "Kent, OH 44240",
  //       "line3" : null,
  //       "line4" : null,
  //       "county" : "Portage Co.",
  //       "countryCode" : "USA",
  //       "purpose": "I am overridden by customHTML if provided."
  //     }
  //   ] 
  // }



// let ssprData = addressValidateData;//DUMMY DATA
// addressValidate = new AddressValidate({
//   data : ssprData
// });
  // console.log("line 306");
  $.getJSON( '/asyncloader/getEntity/814', (data) => {
      let ssprData = data;
      
      //let ssprData = addressValidateData;//DUMMY DATA
      addressValidate = new AddressValidate({
        data : ssprData
      });
   }).fail(() => {
    console.info('SSPR webservice failed. Address Validation popup aborted.');
   }); 

}
