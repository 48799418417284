/* Only load skeleton screens for custom theme */
let skeletonKey;

if( KSU_UserHelper != null ) {
  switch (KSU_UserHelper.CustomTheme) {
    case 'pioneer-dark':
    case 'pioneer-halloween':
      skeletonKey = 'dark';
      break;
    default:
      skeletonKey = 'light';
  }

  let skeletonPath = '/sites/all/themes/ksu_foundation/images/skeleton/mydash-'
  let skeletonURLs = [
    skeletonPath + skeletonKey + '-grid.gif',
    skeletonPath + skeletonKey + '-list.gif',
    skeletonPath + skeletonKey + '-list-lg.gif'
  ];

  function buildPrefetch(url, type){
    let prefetch = document.createElement('link');
    prefetch.setAttribute('rel', 'prefetch');
    prefetch.setAttribute('href', url);
    prefetch.setAttribute('as', type);
    return prefetch;
  }

  skeletonURLs.forEach(function(item){
    document.body.appendChild(buildPrefetch(item, 'image'));
  });
}
