class ts {
  constructor() {
    this.start = Date.now();
    this.current = this.start;
    this.total = 0;
    console.log(`Start:${this.start}`);
  }
  stamp(name = '') {
    let now = Date.now();
    let newTotoal = now - this.start;
    let elapsed = now - this.current;
    console.log(
      `<----- ${name} last...:${elapsed} -- BOT:${newTotoal} -- Start:${this.start} Current:${now}`,
    );
    this.current = now;
    this.total = newTotoal;
  }
}
