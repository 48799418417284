class DisplayHelper {
    constructor(properties) {
        this.properties = JSON.parse(properties);
    }

    attachAttribute(attribute, data) {
        this.properties[attribute] = data;
    }

    getProperties() {
        return this.properties;
    }

    isModalNotificationPostbacksDisabled(user, debugKey = 'notifications') {
        return this.properties.notifications.postback_disabled && user.isAdmin() || URLHelper.isSelfDebugFor(debugKey);
    }
}