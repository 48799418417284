function faculty_dash_refresh() {
    $.get('/asyncloader/nojs/node/render/5621?nocache=true', function (data) {

        $('#node-5621').find('.panel-body').html("");

        $('#node-5621').html(data.markup);
    });
    return false;
}


function formatCacheCreationDate(cacheCreationDate) {
    if (cacheCreationDate != null) {
        var m = new moment(cacheCreationDate, 'X');

        var s = m.calendar(
            null, {
                sameDay: '[today]',
                nextDay: '[tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[yesterday]',
                lastWeek: '[last] dddd',
                sameElse: 'DD/MM/YYYY'
            }) + " at " + m.format("h:mm a");

        return s;
    }
}

