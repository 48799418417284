//Function for new UI which can be run on menu anchor as data
//Compares page URL with anchor href and assigns anchor class
//to indicate page-current or page-path
function menuTrail(menuItem) {
    var currentHref = window.location.href;
    //Safe gaurd just in case initially ends in #
    var currentLast = currentHref.charAt(currentHref.length - 1);
    if (currentLast === '#') {
        currentHref = currentHref.slice(0, (currentHref.length - 1));
    }
    var currentPath = currentHref.replace(siteRootUrl, '');
    var currentPathArray = currentPath.split('/');
    var currentElementClean;

    var itemHref = menuItem.href;
    //Safe gaurd just in case initially ends in #
    var itemLast = itemHref.charAt(itemHref.length - 1);
    if (itemLast === '#') {
        itemHref = itemHref.slice(0, (itemHref.length - 1));
    }
    var itemPath = itemHref.replace(siteRootUrl, '');
    var itemPathArray = itemPath.split('/');
    var itemElementClean;

//After splitting both paths into arrays,compare the last index
//An exact match before cleaning indicates page-current
    if (currentPathArray.length === itemPathArray.length) {
        var last = (currentPathArray.length - 1);
        if (currentPathArray[last] === itemPathArray[last]) {
            if (!(menuItem.classList.contains('page-current'))) {
                menuItem.classList.add('page-current');
            }
        }

    }

//If menu link is shorter than the current page
//Check the menu items last index against the equivalent index in the page path
//If these are equal, assign page-path
    if (currentPathArray.length > itemPathArray.length) {
        var itemlast = (itemPathArray.length - 1);

        currentElementClean = getCleanUrl(currentPathArray[itemlast]);
        itemElementClean = getCleanUrl(itemPathArray[itemlast]);

        if (currentElementClean === itemElementClean) {
            if (!(menuItem.classList.contains('page-path'))) {
                menuItem.classList.add('page-path');
            }
        }

    }

//Lastly, there are some situations where a child link is active
//but the parent anchor URL doesn't directly relate
//So check if the parent node is of type li
//if it is, loop through its children to see if any match the window URL
//if they do, the parent can also get the class page-page
    var itemParent = menuItem.parentNode;

    if (itemParent.classList.contains('nav1-item')){
        itemParent = itemParent.parentNode;
    }

    if (itemParent.nodeName.toLowerCase() === 'li') {

        var liKids = itemParent.querySelectorAll('*');
        var nestedActive = false;

        for (var i = 0; i < liKids.length; i++) {
            if (liKids[i].nodeName.toLowerCase() === 'a' && liKids[i].href === currentHref) {
                nestedActive = true;
                break;
            }
        }

        if (nestedActive === true && !(menuItem.classList.contains('page-path')) && !(menuItem.classList.contains('page-current'))) {
            menuItem.classList.add('page-path');
            //console.log(menuItem);
        }

    }


}//End of menuTrail function


function menuTrailPopulate() {
//First, loop through and remove page-current and page-path
//This is so we can fire the menuTrailPopulate function without needing
//a page reload to effectively updating all menu item classes

    var oldPageCurrents = document.querySelectorAll('.page-current');
    for (var i = 0; i < oldPageCurrents.length; i++) {
        oldPageCurrents[i].classList.remove('page-current');
    }
    var oldPagePaths = document.querySelectorAll('.page-path');
    for (var i = 0; i < oldPagePaths.length; i++) {
        oldPagePaths[i].classList.remove('page-path');
    }

    for (var i = 0; i < navNarrowLinks.length; i++) {
        menuTrail(navNarrowLinks[i]);
    }
    for (var i = 0; i < navWideLinks.length; i++) {
        menuTrail(navWideLinks[i]);
    }
    for (var i = 0; i < navMobileLinks.length; i++) {
        menuTrail(navMobileLinks[i]);
    }
    for (var i = 0; i < localNavLinks.length; i++) {
        menuTrail(localNavLinks[i]);
    }

}

function installMenuTrail() {
    if (isBaseTheme('pioneer')) {
        menuTrailPopulate();

//hover open panels
//these conditional statements make sure a user can click a parent level item and then move their mouse safely to the page content upon reload without the menu popping back open.

        for (var i = 0; i < nav1Parent.length; i++) {

            var currentLi = parentOfClass(nav1Parent[i], 'nav-tier-0');
            
            if (!nav1Parent[i].classList.contains('page-current')) {
                currentLi.addEventListener('mouseenter', hoverOpenDropdown);
                currentLi.setAttribute('data-mouseenter-set', 'true');
            }

            if (nav1Parent[i].classList.contains('page-current')) {
                currentLi.addEventListener('mouseleave', allowHoverForActiveParents);
            }

        }

    }//Closing if pioneer conditional
}//Closing installMenuTrail function

window.addEventListener('DOMContentLoaded', installMenuTrail);