facRosterTables = new Array();

function
facultyRoster(container, currentTerm) {

    this.dt = new Array();
    this.container = "#" + container;

    this.courseContainer = this.container + " >  .courselist";

    this.selectedTerm = currentTerm;
    // by default we use the current term, but we update
    // this when the term selector changes.

    this.terms = new Array();
    this.courses = new Array();
    this.termPicker;
    this.notificationLoader;
    this.loaded;

    this.init = function () {

        // deprecating 
        var createTermLabel = function () {
            var termLabel = document.createElement("span");
            termLabel.className = "fac-term-label";
            termLabel.innerHTML = "Term:";
            termLabel.style = 'display: none;';
            return termLabel;
        }

        var createTermBox = function () {
            //var termBox = document.createElement("span");
            var termBox = document.createElement("div");
            termBox.setAttribute('id', 'termbox');
            termBox.className = "fac-term-box";
            //termBox.style = 'display: none;'; // looks broke to me...'
            termBox.style.float = 'left';
            return termBox;
        };

        var createCourseButton = function ( isActive ) {
            var courseButton = document.createElement("button");
            courseButton.className = "button small fac-roster-button fac-roster-button-schedule margin-right-1";
            if( isActive == true )
              courseButton.className += " active";

            courseButton.style = 'display: none';
            courseButton.type = "button";
            courseButton.setAttribute('aria-active', true);
            courseButton.innerHTML = "Schedule";
            courseButton.onclick = function () {
                this.fetchAndShowCourses();
                $('.fac-roster-button').removeAttr('aria-active');
                $('.fac-roster-button-schedule').attr('aria-active', true);
                $('.fac-roster-button.active').removeClass('active');
                $('.fac-roster-button-schedule').addClass('active');
            }.bind(this);
            return courseButton;
        }.bind(this);

        var createGradingButton = function ( isActive ) {
            var gradingButton = document.createElement("button");
            gradingButton.className = "button small fac-roster-button fac-roster-button-grading margin-right-1";
            if( isActive == true )
              gradingButton.className += " active";

            gradingButton.type = "button";
            gradingButton.style = 'display: none';
            gradingButton.innerHTML = "Grading";
            gradingButton.onclick = function () {
                this.showGrading();
                $('.fac-roster-button').removeAttr('aria-active');
                $('.fac-roster-button-grading').attr('aria-active', true);
                $('.fac-roster-button.active').removeClass('active');
                $('.fac-roster-button-grading').addClass('active');
            }.bind(this);
            return (gradingButton);
        }.bind(this);

        var createRosterButton = function ( isActive ) {
            var rosterButton = document.createElement("button");
            rosterButton.className = "button small fac-roster-button fac-roster-button-roster roster-button";
            if( isActive == true )
              rosterButton.className += " active";

            rosterButton.type = "button";
            rosterButton.style = 'display: none';
            rosterButton.innerHTML = "Roster";
            rosterButton.onclick = function () {
                this.showRoster();
                $('.fac-roster-button').removeAttr('aria-active');
                $('.fac-roster-button-roster').attr('aria-active', true);
                $('.fac-roster-button.active').removeClass('active');
                $('.fac-roster-button-roster').addClass('active');
            }.bind(this);
            return (rosterButton);
        }.bind(this);

        var createCourseListContainer = function () {
            var courselist = document.createElement("div");
            courselist.className = 'courselist';
            return (courselist);
        }

        var createCourseButtonSuperSelectContainer = function() {
            var container = document.createElement('div');
            container.setAttribute('id', 'courseButtons');
            return( container);
        }

        var createFloatClearDiv = function() {
            var d = document.createElement('div');
      
            d.style.clear = 'left';
            return( d );
        }

        var createButtons = function() { 
            var sel = document.createElement('select');
            sel.setAttribute('id', 'courseButtons-select');
            sel.dataset.superselectId = 'courseButtons';
            sel.style.display = 'none';
            sel.style.width = '200px';

            var op = document.createElement('option');
            op.value = 'Schedule';
            op.innerHTML = 'Schedule';
            op.setAttribute('selected', true );
 
            var op2 = document.createElement('option');
            op2.value = 'Roster';
            op2.innerHTML = 'Roster';

            var op3 = document.createElement('option');
            op3.value = 'Gradingg3';
            op3.innerHTML = 'Grading';

            sel.addEventListener('change', function( e ) { 
                //We throw a custom event to merely reset the superselect in the showCourses function. If that is the case, return immediately
              if(e.detail && e.detail.superselect === 'true'){
                return;
              }
              if( document.getElementById('courseButtons-select').selectedIndex == 0 ) this.fetchAndShowCourses();
              if( document.getElementById('courseButtons-select').selectedIndex == 1 ) this.showRoster();
              if( document.getElementById('courseButtons-select').selectedIndex == 2 ) this.showGrading();
            }.bind(this) );
      
            sel.appendChild( op );
            sel.appendChild( op2 );
            sel.appendChild( op3 );


          return(sel);
          
        }.bind(this);

        // $(this.container).append(createTermLabel());
        $(this.container).append(createTermBox()); // this should float left;


        $(this.container).append( createCourseButtonSuperSelectContainer() );

        $(this.container).append( createFloatClearDiv() );

        $(this.container).append( createButtons() );
        superselect();

        // ok, so now I should just be able to create the select box .


        /*
        $(this.container).append(createCourseButton( true ));
        $(this.container).append(createGradingButton());
        $(this.container).append(createRosterButton());

        */
        $(this.container).append(createCourseListContainer());

        this.notificationLoader = new ajaxLoader(this.courseContainer);
        this.fetchTerms();



        // note, fetchTerms also calls fetchAndShowCourses.  fetchTerms
        // previously synchronously loaded in the terms, but that
        // is depricated.  Instead, we just call fetchAndShowCourses after
        // fetchTerms is .done().
    }

    this.fetchTerms = function () {
        // first and foremost we need to get a list of terms.
        // we call this synchronously because there is a race
        // condition with fetchAndShowCourses.

        $.ajax({
            url: '/wse/661',
            // so i need to do something a little different here.  Basically, if there are no
            // courses for the selected term, set the term to the first one.

            error: function (data) {
                $(this.courseContainer).html("Sorry, we encountered an error while retrieving the terms.");

                this.notificationLoader.remove();
                this.loaded = true;
                EventDispatcher.NodeLoadedForTheFirstTime($(this.container)[0]);
            },

            // its possible that the selectedTerm doesn't actually exist as a list
            // of terms to pick from. Thats because the current term could be summer,
            // and the faculty member may not have courses in that term. So, in that
            // case, use the first one in the list.

            success: function (data) {
                var first = true;
                var firstTerm;
                var sawSavedTerm = false;

                if( data.Httpcode == "0" ) {
                
                  if( data.Message == "Forced Failure" ) {
                    $( this.courseContainer ).html( "The faculty roster is currently unavailable."
                      + " This is most likely due to planned maintenance or service degradation.");
                  } else {
                    $( this.courseContainer ).html( "Sorry, the faculty roster is currently unavailable. Reloading the page might help." );
                  }

                  $( this.courseContainer ).show();
                  this.notificationLoader.remove();
                }


                for (var x = 0; x < data.Result.length; x++) {

                    var term = data.Result[x];

                    if (first == true) {
                        // remember the first term code in the list.
                        firstTerm = term.Code;
                        first = false;
                    }

                    // did we see the current term in the list of terms
                    // this teacher is teaching?  yes?
                    if (term.Code == this.selectedTerm)
                        sawSavedTerm = true;

                    this.terms.push({"Code": term.Code, 'Description': term.Description});
                }

                // ok, so if we didn't see the current term in the list of
                // terms for the teacher, then just show the first one in the list.

                if (sawSavedTerm == false)
                    this.selectedTerm = firstTerm;

                // we obviously can only create the term picker after we
                // know what terms are out there for this teacher.

                if( this.terms.length > 0 )
                  this.createTermPicker('termbox');
                this.fetchAndShowCourses();

            }.bind(this),
        });
    }

    this.createTermPicker = function (spanID) {

        // so there might be a better way to do this, but keeping a reference to
        // termPicker in the object allows us to easily interact with it on-change,
        //  Otherwise, while the value would be exposed (if I didn't used bind,)
        // it would be more difficult to update savedTerm and
        // invoke showCourses (without using event bubbling, etc)

        this.termPicker = document.createElement("select");
        this.termPicker.style.width = "200px";
        var createTermOption = function (term) {
            var opt = document.createElement("option");
            opt.value = term.Code;
            opt.text = term.Description;

            // if the term code is the selected term, make sure its selected.
            if (term.Code == this.selectedTerm)
                opt.selected = true;

            return opt;

        }.bind(this);

        this.termPicker.onchange = function () {

            this.selectedTerm = this.termPicker.options[this.termPicker.selectedIndex].value;
            // this ensures savedTerm is updated every time the term picker is changed.
            // now we don't have to go back and check ever single time...

            $(this.courseContainer).fadeOut(400,
                function () {
                    $(this.courseContainer).empty();
                    this.fetchAndShowCourses();
                    // $(this.courseContainer).fadeIn();
                }.bind(this));


              //Since we are purposely triggering the superselect here to reset the index. We need to make sure that the handling function doesn't run again (line 137)
              //This fixes the fadeIn fadeOut problems that were happening
              document.getElementById('courseButtons-select').selectedIndex = 0;

              var element = document.getElementById('courseButtons-select');
              if( typeof window.CustomEvent === "function" ) {

                var event = new CustomEvent('change', {
                    detail: {
                        superselect: 'true'
                    },
                    bubbles: true
                });
                element.dispatchEvent(event);
              } else {
                var event = new Event('change', {
                    detail: {
                        superselect: 'true'
                    },
                    bubbles: true
                });
                element.dispatchEvent( event );
              }


            /*
                $('.fac-roster-button').removeAttr('aria-active');
                $('.fac-roster-button-schedule').attr('aria-active', true);
                $('.fac-roster-button.active').removeClass('active');
                $('.fac-roster-button-schedule').addClass('active');
            */

        }.bind(this);

        //for( var x = 0; x < this.terms.length; x++ ) {
        // here we loop over each term and build the option for the select.
        this.terms.map(function (t) {
            this.termPicker.appendChild(createTermOption(t)); // this.terms[x] ) );
        }.bind(this));

        $('#' + spanID).append(this.termPicker);
    }

    this.fetchAndShowCourses = function () {
        // did we pull down this term before? yes? then
        // just use the saved data -- otherwise we have
        // to ajax it in.

        if (this.courses[this.selectedTerm] == undefined) {
            $.ajax({
                url: "/asyncloader/getEntity/656",
                headers: {
                    'X-Token-Facultyterm': this.selectedTerm,
                }
            }).done(function (data) {

                // sort by SUBJECT, then TITLE, then CRN.
                data = data.sort(function (a, b) {
                    if (a.Details.SUBJECT > b.Details.SUBJECT) return 1;
                    if (a.Details.SUBJECT < b.Details.SUBJECT) return -1;

                    if (a.Details.TITLE > b.Details.TITLE) return 1;
                    if (a.Details.TITLE < b.Details.TITLE) return -1;

                    if (a.Details.CRN > b.Details.CRN) return 1;
                    if (a.Details.CRN < b.Details.CRN) return -1;

                    return 0;
                });

                this.courses[this.selectedTerm] = data;


                if( this.courses.length > 0 )
                {
                  $('#courseButtons-select').show();
                  $('#courseButtons-select').addClass('superselect');
                  superselect( '#courseButtons-select' );
                }
                this.showCourses();

            }.bind(this));
        }
        else
            this.showCourses();
    }

    this.showCourses = function (term) {

        $(this.courseContainer).empty();

        // build out a list of each course in the specified term.
        // recall that this.courses is populated by the ajax request
        // in fetchAndShowCourses

        for (var x = 0; x < this.courses[this.selectedTerm].length; x++) {
            var course = this.courses[this.selectedTerm][x];
            if (course.Details.TERM == this.selectedTerm) {
                $(this.courseContainer).append(this.buildCourse(course));
            }
        }
        if (x == 0) {
            $('.fac-term-box').hide();
            $(this.courseContainer).html("You don't appear to be teaching any courses.");
        }
        else {
            $('.fac-roster-button').show();
            $('.fac-term-box').show();
            $('.fac-term-label').show();
        }

        $(this.courseContainer).fadeIn(function(){

          EventDispatcher.PackeryResize($(this.container)[0]);

          if(!this.loaded){
            this.loaded = true;
            EventDispatcher.NodeLoadedForTheFirstTime($(this.container)[0]);
          }
          
        }.bind(this));

    }

    this.buildCourse = function (course) {

        var courseDiv = document.createElement("div");
        courseDiv.className = "grid-x grid-padding-x grid-padding-y courseDiv";

        // buildHeaderColumn is used to create the container, and text,
        // that acts as a title for the course.

        courseDiv.appendChild(this.buildHeaderColumn(course));
        courseDiv.appendChild(this.buildCacheColumn(course));
        courseDiv.appendChild(this.buildCourseLeftColumn(course));
        courseDiv.appendChild(this.buildCourseRightColumn(course));
        return (courseDiv);

    }

    this.buildCourseRightColumn = function (course) {
        // three helper functions local to this method.
        // two simplify the creation and styling of the
        // labels and content

        var createLabel = function (labelText) {
            var el = document.createElement("div");
            el.className = "cell small-4";
            el.innerHTML = "<b>" + labelText + "</b>";
            return el;
        };

        var createText = function (labelVal) {
            var el = document.createElement("div");
            el.className = "cell small-8";
            el.innerHTML = labelVal;
            return el;
        };

        // ----------------------------------------

        var rightCol = document.createElement("div");
        rightCol.className = "cell small-12";

        var rightDetails = document.createElement("div");
        rightDetails.className = "grid-x grid-padding-y grid-padding-x";

        if( course.MeetingInfo != undefined ) 
        {

          // Render meeting times
          if ( course.MeetingInfo.BEGIN_TIME != undefined) {
              var days = new Array();
              for (var x = 0; x < course.MeetingInfo.MeetingDays.length; x++) { 
                  //days.push(course.MeetingInfo.MeetingDays[x].LongName);

                  var md = course.MeetingInfo.MeetingDays[x];
  
                   
                  var str = "<li>" + md.LongName;

                  if( md.BeginTime != null ) {
                      str += ": " + this.militaryTimeToNormalTime(  md.BeginTime);
                    if( md.EndTime != null ) // the End Time is here.
                        str += " - " + this.militaryTimeToNormalTime( md.EndTime);             
                  }
                 str += "<br>";
                 str += course.MeetingInfo.MeetingDays[x].Room + " " + course.MeetingInfo.MeetingDays[x].BuildingDesc; 
                 str += "</li>";
                  days.push( str ); 
              }
              rightDetails.appendChild(createLabel("Meeting Time:"));
              rightDetails.appendChild(createText( "<ul>" + days.join("<br>") + "</ul>" ));
          
          }

          /*
          // LOCATION
          if (course.MeetingInfo.BUILDING != undefined) {
              rightDetails.appendChild(createLabel("Location:"));

              var locations = "";
              for( var i = 0; i < course.MeetingInfo.MeetingDays.length; i++ ) {
                locations += course.MeetingInfo.MeetingDays[i].LongName 
                  + " : " + course.MeetingInfo.MeetingDays[i].Room 
                  + " " + course.MeetingInfo.MeetingDays[i].BuildingDesc + "<br>";
              }
              rightDetails.appendChild(createText(locations));
              //rightDetails.appendChild(createText(course.MeetingInfo.BUILDING + " " + course.MeetingInfo.ROOM));
          } */

        }
        
        // SCHEDULE
        rightDetails.appendChild(createLabel("Schedule Type:"));
        rightDetails.appendChild(createText(course.Details.SCHED_TYPE_DESC));

        // SECTION DATES / CODE

        if( course.Details.PTRM_CODE != undefined  ) {
          rightDetails.appendChild(createLabel("Official Section Dates Type:"));
          rightDetails.appendChild(createText(course.Details.PTRM_CODE + " - " + course.Details.PTRM_CODE_DESC + " " + course.Details.CRSE_START + " through " + course.Details.CRSE_END));
        }

        // INSTRUCTION METHOD
        rightDetails.appendChild(createLabel("Instruction Method:"));
        rightDetails.appendChild(createText(course.Details.INSTR_METHOD_DESC));

        // ENROLLMENT
        rightDetails.appendChild(createLabel("Enrolled:"));

        rightDetails.appendChild(createText(course.Details.ENROLLED_CNT + " of " + course.Details.MAX_ENROLL + " seats filled."));

        rightCol.appendChild(rightDetails);

        return (rightCol);

    }

    this.buildCourseLeftColumn = function (course) {

        var createLabel = function (labelText, labelVal) {
            var el = document.createElement("div");
            //el.className = "cell small-12 medium-12 large-2";
            el.className = "cell shrink";
            el.innerHTML = "<b>" + labelText + ": </b> " + labelVal;
            return el;
        };

        var createText = function (labelVal) {
            var el = document.createElement("div");
            //el.className = "cell small-12 medium-12 large-2";
            el.className = "cell shrink";
            el.innerHTML = labelVal;
            return el;
        };

        // --------------------------------------------

        var leftCol = document.createElement("div");
        leftCol.className = "cell small-12";

        var leftDetails = document.createElement("div");
        leftDetails.className = "grid-x grid-margin-x";

        leftDetails.appendChild(createLabel("Course", course.Details.COURSE));
        //leftDetails.appendChild(createText(course.Details.COURSE));

        leftDetails.appendChild(createLabel("Section", course.Details.SECT));
        //leftDetails.appendChild(createText(course.Details.SECT));

        leftDetails.appendChild(createLabel("CRN", course.Details.CRN));
        //leftDetails.appendChild(createText(course.Details.CRN));

        leftCol.appendChild(leftDetails);

        return leftCol;
    }

    /* grading methods below */

    this.showGrading = function (term) {

        var buildGradesForCourse = function () {

            var courseWrapper = document.createElement("div");
            courseWrapper.className = "grid-x grid-padding-x grid-padding-y courseDiv";
            courseWrapper.className += ' course-' + course.Details.CRN + "-" + course.Details.TERM;

            var headerColumn = this.buildHeaderColumn(course);
            var cacheColumn = this.buildCacheColumn(course);
            var leftCol = this.buildCourseLeftColumn(course);
            var rightCol = this.buildGradesRightColumn(course.Details.CRN, course.Details.TERM);

            courseWrapper.appendChild(headerColumn);
            courseWrapper.appendChild(cacheColumn);
            courseWrapper.appendChild(leftCol);
            courseWrapper.appendChild(rightCol);

            return (courseWrapper);

        }.bind(this);

        $(this.courseContainer).empty();

        // show only grades for the currently selected term.  Remember
        // that this.selectedTerm gets updated every time someone changes
        // the term selector.

        for (var x = 0; x < this.courses[this.selectedTerm].length; x++) {
            var course = this.courses[this.selectedTerm][x];
            if (course.Details.TERM == this.selectedTerm)
                $(this.courseContainer).append(buildGradesForCourse());
        }
        EventDispatcher.PackeryResize($(this.container)[0]);
    }

    this.buildGradesRightColumn = function (crn, term, forceRefresh) {

        var buildRefreshButton = function (crn, term) {

            var refreshButton = document.createElement("a");
            refreshButton.innerHTML = "<nobr><i class='dripicons-clockwise' aria-hidden='true'></i><span class='fac-refresh-text'> Refresh</span></nobr>";
            refreshButton.onclick = function () {
                this.className += " active-refresh";
            }
            refreshButton.dataset.crn = crn;
            refreshButton.dataset.term = term;

            var refreshButtonWrapper = document.createElement("span");
            refreshButtonWrapper.onclick = function () {
                // onclick bubbles up to this event. Note that the previous
                // set active-refresh on itself.

                // see above where we set active-refresh, but also set
                // the crn and term?  we're fetching that same data.
                var myCrn = $('.active-refresh').data('crn');
                var myTerm = $('.active-refresh').data('term');

                // rebuild the grades.
                $('.course-' + crn + "-" + term).find('.right-child').empty().append(this.buildGradesRightColumn(myCrn, myTerm, true));

            }.bind(this);

            refreshButtonWrapper.appendChild(refreshButton);

            return (refreshButtonWrapper);

        }.bind(this);


        var divID = "grades-" + crn + "-" + term;

        var url = '/asyncloader/getEntity/646' + (forceRefresh ? "/true" : "");

        var gradeDiv = document.createElement("div");
        gradeDiv.setAttribute('id', divID);
        gradeDiv.className = "cell small-12 right-child";


        $.ajax({
            url: url,
            headers: {
                'X-Token-Facultyterm': term,
                'X-Token-Facultycrn': crn
            }
        }).done(function (data, success, jqXHR) {

            var inner = document.createElement('div');
            inner.className = "cache-text";

            var cacheCreationDate = jqXHR.getResponseHeader('X-cacheCreationDate');

            if (cacheCreationDate != "")
                inner.innerHTML = "<span class='fac-last-updated'>Updated " + formatCacheCreationDate(cacheCreationDate)
                    + "&nbsp;&nbsp;|&nbsp;&nbsp;</span>";

            inner.appendChild(buildRefreshButton(crn, term));

            $('#cache-' + crn + "-" + term).empty()
            $('#cache-' + crn + "-" + term).append(inner);

            $('#' + divID).append(this.buildGradesRightColumnDetails(data, crn, term));

            this.buildCharts(crn, term);

        }.bind(this));

        return gradeDiv;

    }

    this.buildGradesRightColumnDetails = function (courseData, CRN, term) {

        var gradeWidget = function (title, url, data) {

            var buildStatusIcon = function (startDate, endDate) {
                var icons = [
                    'dripicons-warning fl-warning',
                    'dripicons-checkmark fl-success',
                    'icon-disabled fl-disabled'
                ];
                var start = Date.parse(startDate);
                var end = Date.parse(endDate);
                var today = Date.parse(new Date());

                var iconIndex = 1;
                if (today < start)
                    iconIndex = 2;
                if (today > end)
                    iconIndex = 0;
                return ("<i class='grading-window-icon " + icons[iconIndex] + "' aria-hidden='true'></i>");

            }

            var retObj = new Object();

            var c1 = document.createElement('div');
            c1.className = 'cell small-4';
            c1.innerHTML = "<a href='" + url + "' >" + title + "</a>";

            var c2 = document.createElement('div');
            c2.className = 'cell small-4';
            c2.innerHTML = buildStatusIcon(data.StartDate, data.EndDate) + " " + data.StartDate + " " + data.EndDate;

            var c3 = document.createElement('div');
            c3.className = 'cell small-4';
            c3.innerHTML = ' <div style="height: 50px; width: 50px;"><canvas data-full="' + data.Eligible + '"'
                + ' data-eligible="' + (data.Eligible - data.Graded) + '"'
                + ' width="50" height="50" class="myChart myChart-' + CRN + "-" + term + '" ></canvas>';

            c3.innerHTML += "<div>" + data.Graded + " / " + data.Eligible + " reported</div></div>";
            retObj.c1 = c1;
            retObj.c2 = c2;
            retObj.c3 = c3;

            return (retObj);
        };

        var detailsContainer = document.createElement("div");
        detailsContainer.className = "grid-x";

        var h1 = document.createElement("div");
        h1.className = "cell small-4";
        h1.innerHTML = "<b>Grade Assignment</b>";

        var h2 = document.createElement("div");
        h2.className = "cell small-4";
        h2.innerHTML = "<b>Grading Window</b>";

        var h3 = document.createElement("div");
        h3.className = "cell small-4";
        h3.innerHTML = "<b>Status</b>";

        detailsContainer.appendChild(h1);
        detailsContainer.appendChild(h2);
        detailsContainer.appendChild(h3);

        if (courseData.APVGradeDetails != undefined) {
            var x = gradeWidget("Academic Engagement", "/sso/pickup/banweb?dest=https://keys.kent.edu/ePROD/bwlkprtr.p_facclalistattendance&framed=true", courseData.APVGradeDetails);
            detailsContainer.appendChild(x.c1);
            detailsContainer.appendChild(x.c2);
            detailsContainer.appendChild(x.c3);
        }

        if (courseData.MidtermGradeDetails != undefined) {
            var x = gradeWidget("Midterm", "/sso/pickup/banweb?framed=true&dest=https://keys.kent.edu/ePROD/bwlkfmgd.P_FacMidGrd", courseData.MidtermGradeDetails);
            detailsContainer.appendChild(x.c1);
            detailsContainer.appendChild(x.c2);
            detailsContainer.appendChild(x.c3);
        }

        if (courseData.FinalGradeDetails != undefined) {
            var x = gradeWidget("Final", "/sso/pickup/banweb?framed=true&dest=https://keys.kent.edu/ePROD/bwlkffgd.P_FacFinGrd", courseData.FinalGradeDetails);
            detailsContainer.appendChild(x.c1);
            detailsContainer.appendChild(x.c2);
            detailsContainer.appendChild(x.c3);
        }

        return (detailsContainer);

    }

    /* roster methods below */
    /*-----------------------------------------------------*/

    this.showRoster = function () {

        $(this.courseContainer).empty();

        for (var x = 0; x < this.courses[this.selectedTerm].length; x++) {
            var course = this.courses[this.selectedTerm][x];

            if (course.Details.TERM == this.selectedTerm)
                $(this.courseContainer).append(this.buildRoster(course));
        }
        EventDispatcher.PackeryResize($(this.container)[0]);
    }

    this.buildRoster = function (course) {

        var courseWrapper = document.createElement("div");

        var courseHeader = document.createElement("div");
        courseWrapper.appendChild(courseHeader);

        var courseDiv = document.createElement("div");
        courseDiv.className = "grid-x grid-padding-x grid-padding-y courseDiv";

        var headerDiv = this.buildHeaderColumn(course);
        var cacheDiv = this.buildCacheColumn(course);
        var leftCol = this.buildCourseLeftColumn(course);
        var rightCol = this.buildRosterRightColumn(course);

        courseDiv.appendChild(headerDiv);
        courseDiv.appendChild(cacheDiv);
        courseDiv.appendChild(leftCol);
        courseDiv.appendChild(rightCol);

        var rosterDiv = document.createElement("div");
        rosterDiv.className = "cell small-12 roster-container";
        rosterDiv.setAttribute('id', 'roster-' + course.Details.CRN + "-" + course.Details.TERM);
        courseDiv.appendChild(rosterDiv);

        courseWrapper.appendChild(courseDiv);
        return (courseWrapper);

    }

    this.buildRosterRightColumn = function (course) {

        var rosterDiv = document.createElement("div");
        rosterDiv.className = "cell small-9";

        var rosterButton = document.createElement("input");
        rosterButton.type = 'button';
        rosterButton.value = 'View Roster';
        rosterButton.className = "button button-bordered";
        rosterButton.dataset.courseTerm = course.Details.TERM;
        rosterButton.dataset.courseCrn = course.Details.CRN;

        rosterButton.onclick = function () {
            // ok this is where things get...  weird.
            // the button knows it's context. It knows the CRN and TERM
            // associated with itself. However, its not in the context
            // of -this- object.  I can make it in the context of -this-
            // object, by by appending .bind(this) to the onclick event
            // handler, but it loses its identity -- we no longer know what
            // button was clicked.
            this.nextSibling.style.display = 'inline-block';
            this.style.display = 'none';
            this.className += " roster-is-active";
        };

        hideRosterButton = document.createElement("input");
        hideRosterButton.type = 'button';
        hideRosterButton.value = 'Hide Roster';
        hideRosterButton.className = 'button button-bordered';
        hideRosterButton.dataset.courseTerm = course.Details.TERM;
        hideRosterButton.dataset.courseCrn = course.Details.CRN;
        hideRosterButton.style.display = 'none';

        hideRosterButton.onclick = function () {
            $("#roster-" + this.dataset.courseCrn + "-" + this.dataset.courseTerm).fadeOut(function () {
                $("#roster-" + this.dataset.courseCrn + "-" + this.dataset.courseTerm).empty();
                $("#roster-" + this.dataset.courseCrn + "-" + this.dataset.courseTerm).show();
                EventDispatcher.PackeryResize($(this.container)[0]);
            }.bind(this));
            this.style.display = 'none';
            this.previousSibling.style.display = 'inline-block';
            $('#cache-' + this.dataset.courseCrn + "-" + this.dataset.courseTerm).empty();
        };

        // so, we're going to put that button in another container.  The span
        // will be in the context of -this- object. It then just needs to look
        // and find which was has 'is-active' and pull the crn.
        //
        // Sweet Lord, I should just use a global

        var rosterButtonContainer = document.createElement("span");
        rosterButtonContainer.onclick = function () {

            var term = $('.roster-is-active').data('course-term');
            var crn = $('.roster-is-active').data('course-crn');

            $("[data-roster-is-active=true]").attr('data-roster-is-active', 'false');
            $("[data-roster-is-active]").attr('roster-is-active', 'false');
            // ok, so now we know the term and crn (and by extenstion)
            // the div that the roster needs to displayed in...

            // $('#roster-' + crn + "-" + term).empty().append(this.buildRosterDetails(term, crn));

            $('#roster-' + crn + "-" + term).empty();
            this.buildRosterDetails( term, crn, false);


            $('.roster-is-active').removeClass('roster-is-active');

        }.bind(this);

        if (course.Details.ENROLLED_CNT == 0) {
            var noStudents = document.createElement("div");
            noStudents.innerHTML = "No enrolled students.";
            rosterDiv.appendChild(noStudents);
        }
        else {
            var studentCount = document.createElement("span");
            studentCount.style.paddingLeft = "1em";
            studentCount.innerHTML = course.Details.ENROLLED_CNT + " of " + course.Details.MAX_ENROLL + " seats filled.";
            rosterButtonContainer.appendChild(rosterButton);
            rosterButtonContainer.appendChild(hideRosterButton);
            rosterButtonContainer.appendChild(studentCount);
        }
        rosterDiv.appendChild(rosterButtonContainer);

        return (rosterDiv);

    }

//asdfasdf
    this.buildRosterDetails = function( term, crn, nocache ) {
      var pb = document.createElement('button');
      pb.addEventListener('click', function() { this.dt[crn+":"+term].print();}.bind(this) );
      pb.innerHTML = 'Print';
      pb.className = 'button';

      var cb = document.createElement('button');
      cb.addEventListener('click', function() { this.dt[crn+":"+term].copy();}.bind(this) );
      cb.innerHTML = 'Copy';
      cb.className = 'button';

      var eb = document.createElement('button');
      eb.addEventListener('click', function() {

   
        var emailArray = []; 
        for( var x = 0; x < this.dt[crn+":"+term]._dataset.length; x++)
        {
          if( this.dt[crn+":"+term]._dataset[x].data[0].children[0].checked) {
            emailArray.push(this.dt[crn+":"+term]._dataset[x].data[6] + "@kent.edu" );
          }
        }
        if (emailArray.length == 0) {
            swal2("Please select one or more students to email.", "You may select all students by clicking the checkbox in the roster's header.", "info");
            return;
        }
        KSUMain.sendEmails(emailArray);


      }.bind(this) );

      eb.innerHTML = 'E-mail';
      eb.className = 'button';
      
      var controlContainer = document.createElement('div');
      controlContainer.className = 'grid-x';
      
      var searchContainer = document.createElement('div');
      searchContainer.className = 'cell small-6';
      searchContainer.setAttribute('id', 'search-' + crn + "-" + term );
      
      var buttonContainer = document.createElement('div');
      buttonContainer.className = 'cell small-6 faculty-roster-control-button-container';
      buttonContainer.appendChild( pb ); 
      buttonContainer.appendChild( eb ); 
      buttonContainer.appendChild( cb ); 
      
      controlContainer.appendChild( searchContainer );
      controlContainer.appendChild( buttonContainer );
      $('#roster-' + crn + "-" + term).append( controlContainer );

      url = '/asyncloader/getentity/651' + (nocache ? "/true" : "");

      this.notificationLoader = new ajaxLoader('#roster-' + crn + "-" + term);
     
      $.ajax({
              url: url,
              cache: false,
              headers: {
                  'X-Token-Facultyterm': term,
                  'X-Token-Facultycrn': crn
              }

          }
      ).fail( function(data, success, jqXHR ){

        $('#roster-' + crn + '-' + term).html("Something went wrong.<p>");
        this.notificationLoader.remove();


      }.bind(this)).done(function (data, success, jqXHR) {
        var buildCacheAge = function (cacheCreationDate, crn, term) {

          var buildRefreshButton = function (crn, term) {

              var refreshButton = document.createElement("a");

              refreshButton.setAttribute('id', 'refresh-' + crn + "-" + term );
              refreshButton.dataset.crn = crn;
              refreshButton.dataset.term = term;
              refreshButton.innerHTML = "<nobr><i class='dripicons-clockwise' aria-hidden='true'></i><span class='fac-refresh-text'> Refresh</span></nobr>";

              // no longer relies on a parent / child div and two separate events
              refreshButton.addEventListener('click', function(e) {
                  $('#roster-' + e.currentTarget.dataset.crn + '-' 
                    + e.currentTarget.dataset.term).empty().append(
                      this.buildRosterDetails(e.currentTarget.dataset.term, e.currentTarget.dataset.crn, true));

              }.bind(this));

            return( refreshButton ); 

          }.bind(this);

          var buildCacheWrapper = function (cacheCreationDate) {
              var cacheWrapper = document.createElement("span");
              cacheWrapper.className = 'cache-text';

              if (cacheCreationDate != "") {
                  cacheWrapper.innerHTML = "<span class='fac-last-updated'>Updated " + formatCacheCreationDate(cacheCreationDate)
                      + "&nbsp;&nbsp;|&nbsp;&nbsp;</span>"
              }
              return cacheWrapper;
            }

            var cacheWrapper = buildCacheWrapper(cacheCreationDate);
            cacheWrapper.appendChild(buildRefreshButton(crn, term));

            return (cacheWrapper);

        }.bind(this);

        var cacheCreationDate = jqXHR.getResponseHeader('X-cacheCreationDate');
        $('#cache-' + crn + "-" + term).empty().append(buildCacheAge(cacheCreationDate, crn, term));


        this.dt[crn+":"+term] = new divTable( 'roster-' + crn + "-" + term);
        this.dt[crn+":"+term]._stripe = true;
        this.dt[crn+":"+term]._searchable = true;
        this.dt[crn+":"+term]._searchContainer = 'search-' + crn + "-" + term;
        this.dt[crn+":"+term]._entriesPrefix = "";
        this.dt[crn+":"+term]._entriesSeparator = "of"; 
        this.dt[crn+":"+term]._entriesPostfix = "";
        this.dt[crn+":"+term]._entriesThrough = "-";
        this.dt[crn+":"+term]._pageLeftText = '<i class="dripicons-chevron-left"></i>';
        this.dt[crn+":"+term]._pageRightText= '<i class="dripicons-chevron-right"></i>';  

        var cbspan = document.createElement('span');
        var cblabel = document.createElement('label');
        cblabel.setAttribute('for', 'emails-' + crn + "-" + term);

        var cb = document.createElement('input');
        cblabel.setAttribute('id', 'emails-' + crn + "-" + term);
        cb.type = 'checkbox';
        cb.className = 'fl-check';
        cbspan.appendChild( cb );
        cbspan.appendChild( cblabel );
        this.dt[crn+":"+term].addHeader( {
          text: cbspan,
          classes: "cell medium-shrink large-1", // large-12",
          printable: false,
          sortable: false,
          sortColumn: 0,
          cellEventHandler: {
            eventType: 'click',
            eventHandler: function(e) {
              e.currentTarget.parentElement.children[0].children[0].children[0].checked = !e.currentTarget.parentElement.children[0].children[0].children[0].checked;
        
              for( var x = 0; x < this.dt[crn+":"+term]._dataset.length; x++) {
                this.dt[crn+":"+term]._dataset[x].data[0].children[0].checked = e.currentTarget.parentElement.children[0].children[0].children[0].checked;
              }
            
            }.bind(this)
          }

        });


        this.dt[crn+":"+term].addHeader({ 
          text: "",
          classes: "cell small-12 medium-shrink hide-for-large",
          printable: false,
          sortable: false,
        });


        // spacer for photo column
        this.dt[crn+":"+term].addHeader({
          text: "",
          classes: "cell small-1 show-for-large", // 2 medium-shrink hide-for-large",
          printable: false,
          sortable: false,
        });

        this.dt[crn+":"+term].addHeader({
          text: "Last<span class='hidemedium'> Name</span>",
          classes: "cell medium-shrink large-3", // 4
          sortColumn: 3,
          sortable: true,
          defaultSort: true,
          // sortDirection:  'desc'
        });


        this.dt[crn+":"+term].addHeader({ 
          text: "",
          classes: "cell small-12 medium-shrink hide-for-large",
          printable: false,
          sortable: false,
        });


        this.dt[crn+":"+term].addHeader({
          text: "First<span class='hidemedium' > Name</span>",
          classes: "cell medium-shrink large-2", // 6
          sortable: true,
          sortColumn: 4
        });

        this.dt[crn+":"+term].addHeader({
          text: "ID",
          classes: "cell medium-shrink large-3", // 4
          sortColumn: 5,
          sortable: true,
        });

        this.dt[crn+":"+term].addHeader({
          text: "Email",
          classes: "cell medium-shrink large-2", // 7
          sortColumn: 6,
          sortable: true 
        });



        this.dt[crn+":"+term].addColumnDescriptors({printable: false, classes: "cell event-item small-1  medium-1 large-1 bordered",
          cellEventHandler: {
            eventType: 'click',
          } 

        });
        this.dt[crn+":"+term].addColumnDescriptors({printable: false, classes: "cell event-item hide-for-large medium-11 bordered", cellEventHandler: 
          {
            eventType: 'click', 
            eventHandler: function(e) { 
              e.currentTarget.parentElement.children[0].children[0].children[0].checked = !e.currentTarget.parentElement.children[0].children[0].children[0].checked;   
            }   
          } 
        })   

        // photo column
        this.dt[crn+":"+term].addColumnDescriptors({printable: false, classes: "cell event-item small-1  medium-1 large-1 bordered show-for-large",
        });

        this.dt[crn+":"+term].addColumnDescriptors({classes: "cell event-item show-for-large small-12  medium-8 large-3 bordered", 
          cellEventHandler: 
          {
            eventType: 'click', 
            eventHandler: function(e) { 
              e.currentTarget.parentElement.children[0].children[0].children[0].checked = !e.currentTarget.parentElement.children[0].children[0].children[0].checked;   
            }   
          } 
        });
        this.dt[crn+":"+term].addColumnDescriptors({classes: "cell event-item show-for-large small-12  medium-8 large-2 bordered",
          cellEventHandler: 
          {
            eventType: 'click', 
            eventHandler: function(e) { 
              e.currentTarget.parentElement.children[0].children[0].children[0].checked = !e.currentTarget.parentElement.children[0].children[0].children[0].checked;   
            }   
          } 
        });
        this.dt[crn+":"+term].addColumnDescriptors({classes: "cell event-item show-for-large small-12  medium-4 large-3 bordered overflow-ellipsis",
          cellEventHandler: 
          {
            eventType: 'click', 
            eventHandler: function(e) { 
              e.currentTarget.parentElement.children[0].children[0].children[0].checked = !e.currentTarget.parentElement.children[0].children[0].children[0].checked;   
            }   
          } 
        });
        this.dt[crn+":"+term].addColumnDescriptors({classes: "cell event-item show-for-large small-12  medium-8 large-2 bordered overflow-ellipsis",
          cellEventHandler: 
          {
            eventType: 'click', 
            eventHandler: function(e) { 
              e.currentTarget.parentElement.children[0].children[0].children[0].checked = !e.currentTarget.parentElement.children[0].children[0].children[0].checked;   
            }   
          } 
        });

        var imageCache = new Array();
        for( var x = 0; x < data.length; x++ )
        {

          imageCache[x] = new Image();
          imageCache[x].src = data[x].photo; 

          var xbspan = document.createElement('span');
          xbspan.style.paddingLeft = ".25em";
          var xb = document.createElement('input');
          xb.type = 'checkbox';
          xb.className = 'fl-check';
          xb.setAttribute('id', crn + "-" + term + "-" + data[x].FLASH_ID); 
          var xblabel = document.createElement('label');
          xblabel.setAttribute('for', crn + "-" + term + "-" + data[x].FLASH_ID); 
          xbspan.appendChild(xb);
          //xblabel.append(xb);
          xbspan.appendChild(xblabel);

          var fields = [ xbspan, 
            "<div style='padding: .1em 1em 2em 0em'><span style='font-size: 120%'>" 
             + "<img class='roster-image-photo-mobile' src='" + data[x].photo + "'>"
            + data[x].LAST_NAME + ", " + data[x].FIRST_NAME + "</span><br>" + data[x].USERID + " " + data[x].FLASH_ID + "@kent.edu </div>",
            "<img class='roster-image-photo' src='" + data[x].photo + "'>",
            data[x].LAST_NAME, 
            data[x].FIRST_NAME, 
            data[x].USERID, 
            data[x].FLASH_ID ];

          this.dt[crn+":"+term].addRow( fields,"cell", ['asdf']); 
        } 
        this.dt[crn+":"+term].draw();
        this.notificationLoader.remove();

      }.bind(this));

    }


    /* misc methods below */

    this.buildHeaderColumn = function (course) {
        var el = document.createElement("div");
        el.className = "cell shrink"; // small-6";
        el.innerHTML = "<b>" + course.Details.SUBJECT + " - " + course.Details.TITLE + "</b>";
        return (el);
    }

    this.buildCacheColumn = function (course) {
        var el = document.createElement("div");
        el.className = "cell auto  text-right";
        el.setAttribute("id", "cache-" + course.Details.CRN + "-" + course.Details.TERM);
        return (el);
    }

    this.militaryTimeToNormalTime = function (ts) {

        // ts usually looks like 1700 so, convert it to 5:00 PM
        if (ts == undefined)
            return "";

        var min = ts.slice(-2);
        var hour = "";
        var ampm = "AM";

        if (ts.length == 4)
            hour = ts.slice(0, 2);

        if (ts.length == 3)
            hour = ts.slice(0, 1);

        if (hour == "")
            return "";

        if (hour >= 12) ampm = "PM";
        if (hour > 12) hour -= 12;

        return hour + ":" + min + " " + ampm;
    }

    // this is mostly legacy code we pulled from the old faculty dashboard.
    this.buildCharts = function (CRN, term) {
        $('.myChart-' + CRN + '-' + term).each(function () {
            var data = {
                datasets: [
                    {
                        // Full = 1st key
                        // Full - Eligible = 2nd key
                        data: [],
                        backgroundColor: [
                            "#36A2EB",
                            "#c9c9c9"
                        ],
                        hoverBackgroundColor: [
                            "#36A2EB",
                            "#c9c9c9"
                        ]
                }]
            };

            if ($(this).data('eligible') != $(this).data('full')) {
                data.datasets[0].data.push($(this).data('full'));
                data.datasets[0].data.push($(this).data('eligible'));
            }
            else {
                data.datasets[0].data.push(0);
                data.datasets[0].data.push($(this).data('full'));
            }

            new Chart($(this), {
                type: 'doughnut',
                data: data,
                options: {
                  cutout: '40%',
                  plugins: {
                    legend: {
                      display: false
                    },
                    tooltip: {
                      enabled: false
                    }
                  }
                }
            });
        });
    }

    // this is a whole bunch of biz that I didn't touch.... much
    // it did update to use facRosterTables, which is in the global
    // scope -- something I'm not immediately able to address.

    this.datatablesInit = function () {
        $('.data-table').each(function (index) {
            var title = $(this).closest('.course-info').find('h3').first().text().trim();
            title = "title";
            facRosterTables[index] = KSUJSPlugins.dataTables({
                select: {style: 'multi+shift'},
                columnDefs: [{
                    orderable: false,
                    className: 'select-checkbox',
                    targets: 0
                }],

                defaultElement: $(this),
                order: [[1, 'asc']],
                buttons: [
                    {
                        text: 'Email',
                        action: function () {
                            var emailArray = [];
                            $.map(facRosterTables[index].rows('.selected').data(), function (i) {
                                emailArray.push(i[5]);
                            });

                            if (emailArray.length == 0) {
                                swal2("Please select one or more students to email.", "You may select all students by clicking the checkbox in the roster's header.", "info");
                                return;
                            }
                            KSUMain.sendEmails(emailArray);
                        },
                    },
                    {
                        extend: 'print',
                        title: title
                    },
                    {
                        extend: 'copy'
                    }
                ]
            });
            facRosterTables[index].selectedAll = 0;
            facRosterTables[index].on('click', 'th:first', function (event) {
                    if (facRosterTables[index].selectedAll == 0) {
                        facRosterTables[index].selectedAll = 1;
                        facRosterTables[index].rows().select();
                    }
                    else {
                        facRosterTables[index].rows().deselect();
                        facRosterTables[index].selectedAll = 0;
                    }
                }
            );
        });
    }
}
