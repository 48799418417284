// Url Tracing
var siteRootUrl;

//Used with event capture set to true to temporarily disable keyboard or click
function disableACTIONS(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
}

function freezeUser(){
    document.addEventListener('keydown', disableACTIONS, true);
    document.addEventListener('mousedown', disableACTIONS, true);
    document.addEventListener('click', disableACTIONS, true);
}

function thawUser(){
    document.removeEventListener('keydown', disableACTIONS, true);
    document.removeEventListener('mousedown', disableACTIONS, true);
    document.removeEventListener('click', disableACTIONS, true);
}

function parentOfClass(startingEl, classToFind) {
    var el = startingEl;
    var parent;
    // traverse parents
    while (el) {
        parent = el.parentElement;
        if (parent && parent.classList.contains(classToFind)) {
            return parent;
        }
        el = parent;
    }
    return null;
}

function tempFocus(e){
    e.currentTarget.setAttribute('tabindex', '-1');
    e.currentTarget.removeEventListener('blur', tempFocus);
}

function disableBackTab(e) {
    if(e.keyCode === 9 && e.shiftKey === true){
        e.preventDefault();
        e.stopPropagation();
        return false;
    }
}

//takes value of href and returns the URL without appended #s and ?s
function getCleanUrl(hrefValue) {
    var argUrl = hrefValue;
    var hashIndexInUrl = argUrl.indexOf("#");
    var qMarkIndexInUrl = argUrl.indexOf("?");
    var cleanUrl;
    //Find Old Url
    if (hashIndexInUrl === -1) {
        cleanUrl = (qMarkIndexInUrl === -1) ? argUrl : argUrl.slice(0, qMarkIndexInUrl);
    } else {
        cleanUrl = argUrl.slice(0, hashIndexInUrl);
        cleanUrl = (qMarkIndexInUrl === -1) ? cleanUrl : cleanUrl.slice(0, qMarkIndexInUrl);
    }
    return cleanUrl;
}

function clearMobilePath() {
    var elementsInPath = mobilePathWrapper.querySelectorAll('*');
    for (var i = 0; i < elementsInPath.length; i++) {
        mobilePathWrapper.removeChild(elementsInPath[i]);
        elementsInPath[i] = null;
    }
}

function fillMobilePath() {
    var currentHref = window.location.href;
    var currentPath = currentHref.replace(siteRootUrl, '');
    var currentPathArray = currentPath.split('/');

    var hashInElement = currentPathArray[0].indexOf("#");
    var cleanElement = currentPathArray[0];
    var elementHash = null;

    if (hashInElement === -1) {
        //console.log('This had no hash sign.');
        var qMarkInElement = currentPathArray[0].indexOf("?");
        cleanElement = (qMarkInElement === -1) ? cleanElement : cleanElement.slice(0, qMarkInElement);
    } else {
        cleanElement = currentPathArray[0].slice(0, hashInElement);
        elementHash = currentPathArray[0].slice(hashInElement + 1);
        var qMarkInElement = currentPathArray[0].indexOf("?");
        cleanElement = (qMarkInElement === -1) ? cleanElement : cleanElement.slice(0, qMarkInElement);
        //console.log(cleanElement);
        //console.log(elementHash);
    }

    var pathSpan1Words = null;
    var pathSpan2Words = null;

    switch (cleanElement) {
        case 'StudentDashboard':
        case 'finances':
        case 'StudentResources':
        case 'career-services':
            pathSpan1Words = 'Student';
            if (pageTitle) {
                pathSpan2Words = pageTitle.textContent;
            }
            break;
        case 'EmployeeDashboard':
        case 'employee-workflows':
        case 'EmployeeResources':
        case 'reports':
            pathSpan1Words = 'Employee';
            if (pageTitle) {
                pathSpan2Words = pageTitle.textContent;
            }
            break;
        case 'FacultyDashboard':
        case 'AdvisorDashboard':
        case 'Faculty-Resources':
            pathSpan1Words = 'Faculty & Advisors';
            if (pageTitle) {
                pathSpan2Words = pageTitle.textContent;
            }
            break;
        /*
        case 'MyDashboard':
          pathSpan1Words = 'My Dashboard';
          break;
        */
        //case 'welcome':
        //case 'KSUView':
        case 'campusresources':
            //case 'PreRelease':
            //case 'my-profile':
            if (pageTitle) {
                pathSpan1Words = pageTitle.textContent;
            }
            break;
        default:
    }

    if (pathSpan1Words === null) {
        var mobilePathLogo = document.createElement('img');
        mobilePathLogo.src = '/sites/all/themes/ksu_foundation/images/flashline-logo.png';
        mobilePathLogo.alt = 'Flashline';
        if( mobilePathWrapper != null ) {
          mobilePathWrapper.appendChild(mobilePathLogo);
        }
    } else {
        var pathSpan1 = document.createElement('span');
        var pathSpan1Node = document.createTextNode(pathSpan1Words);
        pathSpan1.appendChild(pathSpan1Node);
        mobilePathWrapper.appendChild(pathSpan1);

        if (pathSpan2Words !== null) {
            var pathSpan2 = document.createElement('span');
            var pathSpan2Node = document.createTextNode(pathSpan2Words);
            pathSpan2.appendChild(pathSpan2Node);
            mobilePathWrapper.appendChild(pathSpan2);
        }

        if (elementHash !== null) {
            var nodeOnPage = document.getElementById(elementHash);
            var nodeOnPageTitle;
            var pathSpan3Words;
            var pathSpan3;
            var pathSpan3Node;

            try {
                nodeOnPageTitle = nodeOnPage.parentNode.querySelector('h2');
                pathSpan3Words = nodeOnPageTitle.textContent;
                pathSpan3 = document.createElement('span');
                pathSpan3Node = document.createTextNode(pathSpan3Words);
                pathSpan3.appendChild(pathSpan3Node);
                mobilePathWrapper.appendChild(pathSpan3);
            } catch (error) {
                try {
                    nodeOnPageTitle = nodeOnPage.parentNode.querySelector('h3');
                    pathSpan3Words = nodeOnPageTitle.textContent;
                    pathSpan3 = document.createElement('span');
                    pathSpan3Node = document.createTextNode(pathSpan3Words);
                    pathSpan3.appendChild(pathSpan3Node);
                    mobilePathWrapper.appendChild(pathSpan3);
                } catch (error) {
                }
            }
            //check for h2 first and h3 second
            //if neither are found, do nothing
            //nested try catch to avoid error if neither exist

        }
    }



}

function refreshMenuPaths() {
    setTimeout(function () {
        clearMobilePath();
        fillMobilePath();
        menuTrailPopulate();
    }, 100);
}

function installUrlListeners() {
    if (isBaseTheme('pioneer')) {
        siteRootUrl = window.location.protocol + '//' + window.location.host + '/';
        mobilePathWrapper = document.getElementById('mobile-bar-path');
        try {
            pageTitle = document.getElementById('page-title');
        } catch (error) {
        }

        fillMobilePath();

    }//Closing if pioneer conditional
}//Closing installListeners function

window.addEventListener('DOMContentLoaded', installUrlListeners);
