class SwipeOutHunger {

  constructor(options){
    this.dropdownStart = 'max';//'min', 'max', or 'zero'
    //this.blockRequest = true;
    //options.data.BankEmpty = true;
    this.current = {};
    this.donate = {};
    this.request = {};
    this.error = {};
    this.env = KSU_EnvironmentHelper ? KSU_EnvironmentHelper.environment : null;
    this.refreshData(options.data);
    this.getElements();
    this.setListeners();
    this.start();
  }



  updateMealPlanTile(data) {
    if(!this.mealPlan.node) {
      //console.log('not here');
      return;
    }

    if(data.DonatePending) {
      let plural = data.DonatePending > 1;
      let message = "*" + data.DonatePending + " pending donation" + (plural ? "s" : "") + " not reflected in total";

      this.mealPlan.pendingMsg.textContent = message;
      
      this.mealPlan.node.classList.add('soh-pending');

    } else {
      this.mealPlan.node.classList.remove('soh-pending');
    }

    setTimeout(() => { 
      let mealPlanFlipper = this.mealPlan.node.querySelector('.flipper');
      flipperForceResize(0, mealPlanFlipper);
      EventDispatcher.PackeryResize(this.mealPlan.node);

    }, 100);

  }

  //this is called in constructor and also when a successful POST is made to outsystems. The response will include new rawData. On success, freeze user, refresh data, and evaluatePlusUpdate
  refreshData(rawData){
    //console.log(rawData);

    if(rawData) {
      this.bankEmpty = rawData.BankEmpty;
      this.donateData = {};
      this.requestData = {};

      this.donateData.donated = 'Donated' in rawData  ? rawData["Donated"] : null;
      this.donateData.donatePending  = 'DonatePending' in rawData ? rawData["DonatePending"] : null;
      this.donateData.donateMax = 'DonateMax' in rawData ? rawData["DonateMax"] : null;
      this.donateData.canDonate = 'CanDonate' in rawData ? rawData["CanDonate"] : null;

      this.requestData.requestReceived = 'RequestReceived' in rawData  ? rawData["RequestReceived"] : null;
      this.requestData.requestPending  = 'RequestPending' in rawData ? rawData["RequestPending"] : null;
      this.requestData.requestMax = 'RequestMax' in rawData ? rawData["RequestMax"] : null;
      this.requestData.canRequest = 'CanRequest' in rawData ? rawData["CanRequest"] : null;


      this.data = Object.assign({}, this.donateData, this.requestData);
    } else {
        this.data = null;
        this.donateData = null;
        this.requestData = null;
    }

  }


  getElements(){
    this.node = document.getElementById('node-6061');
    this.flipper = this.node.querySelector('.flipper');
    this.superSelect = document.getElementById('soh-superselect');
    this.content = document.getElementById('soh-content');
    this.donate.extraInfo = {};
    this.donate.extraInfo.el = document.getElementById('soh-flipper-back-1');
    this.request.extraInfo = {};
    this.request.extraInfo.el = document.getElementById('soh-flipper-back-2');

    //There was uncertainty about the footer URL which is reused throughout piece
    this.footerLink = this.node.querySelector('.panel-footer a');

    if(this.footerLink) {
      this.footerLinkURL = this.footerLink.getAttribute('href');
      let sohFooterLinks = document.querySelectorAll('[data-url="soh-footer"]');
      for(var i = 0; i < sohFooterLinks.length; i++){
        sohFooterLinks[i].setAttribute('href', this.footerLinkURL);
      }
    }

    this.donate.extraInfo.maxItems = this.donate.extraInfo.el.querySelectorAll('.soh-faq-max');
    this.donate.extraInfo.maxDt = this.donate.extraInfo.el.querySelector('.max-dt');
    this.donate.extraInfo.maxDd = this.donate.extraInfo.el.querySelector('.max-dd');
    this.request.extraInfo.maxItems = this.request.extraInfo.el.querySelectorAll('.soh-faq-max');
    this.request.extraInfo.maxDt = this.request.extraInfo.el.querySelector('.max-dt');
    this.request.extraInfo.maxDd = this.request.extraInfo.el.querySelector('.max-dd');
    this.mealPlan = {};
    this.mealPlan.node = document.getElementById('node-5556');
    this.mealPlan.pendingMsg = document.querySelector('#node-5556 .soh-pending-message');

  }

  setListeners(){
    this.superSelect.addEventListener('change', this.superSelectChange.bind(this));
  }

  disableAction(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
  }

  disableKeys(e) {
    let blockAction = false;
    let blockKeys;
    let pressedKey;

    if (e.key !== undefined) {
      //console.log(1);
      blockKeys = ['enter', ' '];
      pressedKey = e.key.toLowerCase();

    } else if (e.code !== undefined) {
      //console.log(2);
      blockKeys = ['enter', 'space'];
      pressedKey = e.code.toLowerCase();

    } else if (e.keyCode !== undefined) {
      //console.log(3);
      blockKeys = [13, 32];
      pressedKey = e.keyCode;
    }

    if(blockKeys.indexOf(pressedKey) !== -1){
      blockAction = true;
      //console.log('blocked key: ' + pressedKey);
    }

    if(blockAction){
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    return;
  }

  freezeNode() {
    this.node.addEventListener('keydown', this.disableKeys, true);
    this.node.addEventListener('keyup', this.disableKeys, true);
    this.node.addEventListener('mousedown', this.disableAction, true);
    this.node.addEventListener('click', this.disableAction, true);
    this.loader = new ajaxLoader(this.content);
    this.frozen = true;
  }

  thawNode() {
    this.loader.remove();
    this.frozen = false;
    this.node.removeEventListener('keydown', this.disableKeys, true);
    this.node.removeEventListener('keyup', this.disableKeys, true);
    this.node.removeEventListener('mousedown', this.disableAction, true);
    this.node.removeEventListener('click', this.disableAction, true);
  }




donationSuccessAlert(e) {
  this.donate.success = true;
  //console.log('Success Alert');
  //console.log(e);

  swal2Btns({
    confirmButtonText: "Share",
    focusConfirm: true,
    showConfirmButton: false,//Delete when ready for twitter
    focusCancel: true,//Delete when ready for twitter
    cancelButtonClass: 'button show-focus',//Delete when ready for twitter
    imageUrl: '/sites/all/themes/ksu_foundation/images/dining/swipe-out-hunger/squirrel-crown-nut.svg',
    imageAlt: 'Squirrel wearing a crown and holding an acorn.',
    customClass: 'soh-alert soh-donation-success',
    title: 'Thank you!',
    html: '<p>' + this.donate.postNum + ' guest meal' + (this.donate.postNumPlural ? 's have ' : ' has ') + 'been donated to help feed a fellow Flash. Your meal plan total will reflect your donation by tomorrow.</p>',
    onBeforeOpen: () => {
      sweetAlertCustom();

      //This is a hack to keep the swal showing after confirm button click
      let swalActions = document.querySelector('.soh-donation-success .swal2-actions');
      swalActions.addEventListener('click', function(e){
        if(e.target.classList.contains('swal2-confirm')){
          e.preventDefault();
          e.stopPropagation();
          window.open('https://twitter.com/', '_blank');
          return false;
        }
      }, true);

    }
    }).then((result) => {
        if(result.value) {
          //TWITTER
          //Fallback in case hack above fails
          window.open('https://twitter.com/', '_blank');
        }
        this.donate.resolve();
    });

}




areYouSureAlert(e) {

  this.donate.postNum = Number(this.donate.dropdown.value);
  this.donate.postNumPlural = this.donate.postNum !== 1;

  swal2Btns({
    confirmButtonText: "Yes, donate " + this.donate.postNum + " guest meal" + (this.donate.postNumPlural ? "s" : ""),
    focusConfirm: true,
    cancelButtonText: "No",
    imageUrl: '/sites/all/themes/ksu_foundation/images/dining/swipe-out-hunger/squirrel-crown-nut.svg',
    imageAlt: 'Squirrel wearing a crown and holding an acorn.',
    customClass: 'soh-alert soh-are-you-sure',
    title: 'You are about to donate ' + this.donate.postNum + " guest meal" + (this.donate.postNumPlural ? "s" : ""),
    html: '<p>' + (this.donate.postNumPlural ? "These swipes " : "This swipe ") + 'will be removed from your guest meals total and will not affect your general meal swipes balance.</p>',
    showLoaderOnConfirm: true,
    preConfirm: () => {
        return new Promise((resolve) => {
          //use ball fall animation instead of spinner
          this.donate.resolve = resolve;
          sweetAlertUseBallFall();


          //Settimeout for testing only, use AJAX below instead
          //setTimeout( () => {
              //this.donationSuccessAlert(e);
              /*
              swal2("Error", "Your donation was not successful. Please try again later.", "error").then(() => { 
                console.info("Post response message not 'success'");
                this.donate.resolve();
              });
              */

              /*
              swal2("Error", "Your donation was not successful. Please try again later.", "error").then(() => { 
                console.info("Post failed");
                this.donate.resolve();
              });
              */
          //}, 3000);



          //console.log(this.donate.postNum);
          this.freezeNode();
          $.ajax({
            method: "POST",
            cache: false,
            url: "/asyncloader/doEntity/812",
            dataType: "json",
            headers: {
              'X-Token-Quantity': this.donate.postNum
            },
            data: {}
            }).done((data) => {
              //console.log(data);
            if(data.WasSucessful){
              //This updates the piece without needing a browser refresh.
              this.updateMealPlanTile(data);
              this.refreshData(data);
              this.evaluatePlusUpdate();
              this.donationSuccessAlert(e);
            } else{
              swal2("Error", "Your donation was not successful. Please try again later.", "error").then(() => { 
                console.info("Post response message not 'success'");
                this.donate.resolve();
              });
            }

          }).fail(() => {
            swal2("Error", "Your donation was not successful. Please try again later.", "error").then(() => { 
                console.info("Post failed");
                this.donate.resolve();
              });
          });








        });//End of promise
    },
    onBeforeOpen: () => {
      sweetAlertCustom();
      if(document.activeElement) {
        this.returnFocus = document.activeElement ? document.activeElement : this.donate.submitBtn;
        document.activeElement.blur();
      }
      this.donate.success = null;
    }
    }).then((result) => {
      //THIS RUNS LAST ONCE A RESOLVE IS HIT
      if(this.frozen) this.thawNode();
      //console.log(result);
      if(this.donate.success) {
        this.returnFocus = this.superSelect.classList.contains('active') ?  this.superSelect : document.querySelector('.superselect-item[data-val="donate"]');
      }
      setTimeout(() => { 
        this.returnFocus.focus();
      }, 100);


    });

}

bankEmptyAlert() {

  swal2Btns({
    confirmButtonText: "More Information",
    focusConfirm: true,
    imageUrl: '/sites/all/themes/ksu_foundation/images/dining/swipe-out-hunger/squirrel-crown-nut.svg',
    imageAlt: 'Squirrel wearing a crown and holding an acorn.',
    customClass: 'soh-alert soh-bank-empty',
    title: 'Check Back Soon!',
    html: '<p>We are building a bank of meal swipes that will be available to request.</p>',
    onBeforeOpen: () => {
      sweetAlertCustom();
      if(document.activeElement) {
        document.activeElement.blur();
      }
      this.superSelect.selectedIndex = 0;

      EventDispatcher.Custom({
        "el" : this.superSelect,
        "event" : "change"
      });

      //This is a hack to keep the swal showing after confirm button click
      let swalActions = document.querySelector('.soh-bank-empty .swal2-actions');
      swalActions.addEventListener('click', (e) => {
        if(e.target.classList.contains('swal2-confirm')){
          e.preventDefault();
          e.stopPropagation();
          window.open(this.footerLinkURL, '_blank');
          return false;
        }
      }, true);

    },
    onAfterClose: () => {
      //Accessibility, send back visible focus
      this.returnFocus = this.superSelect.classList.contains('active') ?  this.superSelect : document.querySelector('.superselect-item[data-val="donate"]');

      setTimeout(() => { 
        this.returnFocus.focus();
      }, 100);
    }
    }).then((result) => {
      if(result.value) {
        //Fallback in case hack above fails
        window.open(this.footerLinkURL, '_blank');
      }

    });

}

//use bankEmptyAlert for now. If we need a custom message, we can define it here or in Drupal JS without needing a code promo.
blockRequestAlert() {
  this.bankEmptyAlert();
}


  start(){
    this.freezeNode();//thawed once initialized
    this.evaluatePlusUpdate();
  }

  //runs everytime Jared's SuperSelect is clicked, even if there is not a change
  superSelectChange(e){

    let selected = e.currentTarget.value.toLowerCase();


    //This blocks the REQUEST tab if blockRequest is true or if the meal bank is empty
    
    if (selected === 'request') {
      if (this.blockRequest) {
        this.blockRequestAlert();
        return;
      } else if (this.bankEmpty) {
        this.bankEmptyAlert();
        return;
      }
    }


    this.evaluatePlusUpdate();

  }


  donateDropdownChange(e){
    let selected = Number(e.currentTarget.value);

    this.donate.split2.textContent = (selected === 1) ? 'guest meal.' : 'guest meals.';
    
    if(selected){
      this.donate.dropdown.setCustomValidity("");
      this.donate.submitBtn.classList.remove('disabled');
      this.donate.dropdown.classList.remove('fl-show-user');
    }else {
      this.donate.submitBtn.classList.add('disabled');
    }



  }



donateDropdownInvalidMsg(e) {
  //console.log(e);
  let selected = Number(e.currentTarget.value);
  if(!selected){
    e.currentTarget.setCustomValidity("Please select number of meals.");
    e.currentTarget.classList.add('fl-show-user');
  }
}


  donateFormSubmit(e){
    //console.log('donateFormSubmit');
    //console.log(e);
    e.preventDefault();
    e.stopPropagation();
    let selected = Number(this.donate.dropdown.value);

    if(selected){
      //TRIGGER CONFIRMATION SWEET ALERT
      this.areYouSureAlert(e);
    }

    return false;

  }


  extraInfoLink(backID){

    let link = document.createElement('a');
    link.href = "#";
    link.className = "flip-to-back more-info-link dripicons-information";
    link.setAttribute('data-swapcontent', backID);
    link.addEventListener('click', btnFlipToBack);
    return link;

  }




  buildDonate(data) {
      //Update back of flipper
      let maxPlural = data.donateMax > 1;
      this.donate.extraInfo.maxDt.textContent = data.donateMax + ' meal' + (maxPlural ? 's' : '');
      this.donate.extraInfo.maxDd.textContent = data.donateMax + ' guest meal' + (maxPlural ? 's' : '');

      if(data.donateMax) {
        for(var i = 0; i < this.donate.extraInfo.maxItems.length; i++) {
          this.donate.extraInfo.maxItems[i].style.display = '';
        }
      } else {
        for(var i = 0; i < this.donate.extraInfo.maxItems.length; i++) {
          this.donate.extraInfo.maxItems[i].style.display = 'none';
        }
      }


    let given = data.donated + data.donatePending;
    let hitMax = data.donateMax && data.donated >= data.donateMax;

    //Update Squirrel
    let squirrelSrc = "/sites/all/themes/ksu_foundation/images/dining/swipe-out-hunger/";
    if(given && data.canDonate) {
      squirrelSrc += "squirrel-crown-nut.svg";
    } else if(given && !data.canDonate) {
      squirrelSrc += "squirrel-crown.svg";
    }else if(data.canDonate) {
      squirrelSrc += "squirrel-nut.svg";
    } else {
      squirrelSrc += "squirrel.svg";
    }
    
    let el = document.createElement('div');
    el.setAttribute('data-donate', '');
    let row = document.createElement('div');
    row.className = "grid-x grid-margin-x";
    let left = document.createElement('div');
    left.className = "cell left";
    let squirrel = document.createElement('img');
    squirrel.className = "squirrel";
    squirrel.src = squirrelSrc;
    squirrel.alt = "";
    let right = document.createElement('div');
    right.className = "cell right";

    //given message
    let donatePlural = data.donated > 1;
    let pendingPlural = data.donatePending > 1;


    let givenEl;
    if(given){
      givenEl = document.createElement('div');
      givenEl.className = 'donate-history';
      if(hitMax) {
        givenEl.innerHTML = "<span class='highlight'>" + data.donated + " meal" + (donatePlural ? "s have" : " has") + " been provided because of your donation!</span>";
        givenEl.appendChild(this.extraInfoLink('soh-flipper-back-1'));
      } else if (data.donated && data.donatePending) {
        givenEl.innerHTML = "You have donated " + data.donated + " meal" + (donatePlural ? "s" : "") + " this semester. " + data.donatePending + " additional donation" + (pendingPlural ? "s are" : " is") + " still pending.";
      } else if (data.donated) {
        givenEl.innerHTML = "You have donated " + data.donated + " meal" + (donatePlural ? "s" : "") + " this semester.";
      } else if (data.donatePending) {
        givenEl.innerHTML = "You have " + data.donatePending + " pending meal donation" + (pendingPlural ? "s." : ".");
      }

    }

    //can't donate message
    let cantEl;
    if(!data.canDonate && !hitMax) {
        let cantMsg;
        cantMsg = "You cannot donate " + (given ? "any more " : "") + "meals at this time.";

        cantEl = document.createElement('p');
        cantEl.innerHTML = cantMsg;
        cantEl.appendChild(this.extraInfoLink('soh-flipper-back-1'));

    }

    //Build donate form
    this.donate.form = null;
    if(data.canDonate){
      this.donate.form = document.createElement('form');
      this.donate.form.setAttribute('method', 'get');
      this.donate.form.setAttribute('action', '');
      let donateFieldSet = document.createElement('fieldset');
      this.donate.form.appendChild(donateFieldSet);
      let donateLegend = document.createElement('legend');
      donateLegend.className = 'aria-only';
      donateLegend.textContent = 'I want to donate guest meals';
      donateFieldSet.appendChild(donateLegend);
      let dropdownArea = document.createElement('div');
      dropdownArea.className = 'donate-drop-area';
      donateFieldSet.appendChild(dropdownArea);
      let span1 = document.createElement('span');
      span1.setAttribute('aria-hidden', 'true');
      span1.textContent = 'I want to donate';
      span1.className = 'highlight split-1';
      dropdownArea.appendChild(span1);
      let label = document.createElement('label');
      label.className = 'aria-only';
      label.setAttribute('for', 'soh-dropdown');
      label.textContent = 'How many guest meals you would like to donate?';
      dropdownArea.appendChild(label);
      this.donate.dropdown = document.createElement('select');
      this.donate.dropdown.id = 'soh-dropdown';

      let startPlural = true;

      for (let i = 0; i <= data.canDonate; i++) {
          let option = document.createElement("option");



          switch (this.dropdownStart) {
            case 'zero':
              if(i === 0){
                option.setAttribute('selected', '');
                option.value = '';
              }else {
                option.value = i;
              }
              break;

            case 'min':
              if(i === 0) continue;
              if(i === 1) option.setAttribute('selected', '');
              option.value = i;
              startPlural = false;
              break;

            case 'max':
            default:
              if(i === 0) continue;
              if(i === data.canDonate) option.setAttribute('selected', '');
              option.value = i;

          }



          option.text = i;
          this.donate.dropdown.appendChild(option);
      }


      this.donate.dropdown.setAttribute('required', '');
      this.donate.dropdown.addEventListener('invalid', this.donateDropdownInvalidMsg);

      dropdownArea.appendChild(this.donate.dropdown);
      this.donate.dropdown.addEventListener('change', this.donateDropdownChange.bind(this));
      this.donate.split2 = document.createElement('span');
      this.donate.split2.setAttribute('aria-hidden', 'true');

      let split2Text = startPlural ? 'guest meals.' : 'guest meal.';
      this.donate.split2.textContent = split2Text;

      this.donate.split2.className = 'highlight split-2';
      dropdownArea.appendChild(this.donate.split2);
      dropdownArea.appendChild(this.extraInfoLink('soh-flipper-back-1'));

      let donateSubmitArea = document.createElement('div');
      donateFieldSet.appendChild(donateSubmitArea);
      donateSubmitArea.className = 'donate-button-area';
      this.donate.submitBtn = document.createElement('input');
      this.donate.submitBtn.setAttribute('type', 'submit');
      this.donate.submitBtn.value = 'Donate';
      this.donate.submitBtn.className = "button";
      if(this.dropdownStart === 'zero') this.donate.submitBtn.classList.add('disabled');

      this.donate.form.addEventListener('submit', this.donateFormSubmit.bind(this));
      donateSubmitArea.appendChild(this.donate.submitBtn);
      



    }
   
    el.appendChild(row);
    row.appendChild(right);
    row.appendChild(left);
    row.appendChild(right);
    left.appendChild(squirrel);
    if(givenEl) right.appendChild(givenEl);
    if(cantEl) right.appendChild(cantEl);
    if(this.donate.form) right.appendChild(this.donate.form);

    return el;
  }


  buildRequest(data) {
      //Update back of flipper
      let maxPlural = data.requestMax > 1;
      this.request.extraInfo.maxDt.textContent = data.requestMax + ' meal' + (maxPlural ? 's' : '');
      this.request.extraInfo.maxDd.textContent = data.requestMax + ' meal' + (maxPlural ? 's' : '');

      if(data.requestMax) {
        for(var i = 0; i < this.request.extraInfo.maxItems.length; i++) {
          this.request.extraInfo.maxItems[i].style.display = '';
        }
      } else {
        for(var i = 0; i < this.request.extraInfo.maxItems.length; i++) {
          this.request.extraInfo.maxItems[i].style.display = 'none';
        }
      }

    let el = document.createElement('div');
    el.setAttribute('data-request', '');

    let requestedPlural = Number(data.requestReceived) > 1;
    let pendingPlural = Number(data.requestPending) > 1;

    if(data.requestReceived || data.requestPending){
      let historyEl = document.createElement('p');
      historyEl.className = 'soh-history';
      if (data.requestReceived && data.requestPending) {
        historyEl.innerHTML = "You have received " + data.requestReceived + " meal" + (requestedPlural ? "s" : "") + " this semester. " + data.requestPending + " additional request" + (pendingPlural ? "s are" : " is") + " still pending.";
      } else if (data.requestReceived) {
        historyEl.innerHTML = "You have received " + data.requestReceived + " meal" + (requestedPlural ? "s" : "") + " this semester.";
      } else if (data.requestedPending) {
        historyEl.innerHTML = "You have " + data.requestPending + " pending meal request" + (pendingPlural ? "s." : ".");
      }

      el.appendChild(historyEl);
    }

      




    let overView = document.createElement('p');
    let link = this.extraInfoLink('soh-flipper-back-2');
    let hitMax = data.requestMax && data.requestReceived >= data.requestMax;

    if(data.requestPending || !data.canRequest){
      if(hitMax){
        overView.textContent = "You have been awarded the maximum number of meals allowed per semester.";
        
      } else if(data.requestPending){
        overView.textContent = "We are processing your current request. Please check back later.";
        
      }else {
        overView.textContent = "You cannot request " + (data.requestReceived || data.requestPending ? 'any more ' : '') + "meals at this time.";
      }
      overView.appendChild(link);
      el.appendChild(overView);
      
    } else {
      let canRequestPlural = Number(data.canRequested) > 1;
      overView.textContent = "You are eligible to request meals! Complete the following questionnaire to submit your request.";


      let buttonArea = document.createElement('div');
      buttonArea.className = 'button-area';
      this.request.surveyBtn = document.createElement('a');
      this.request.surveyBtn.textContent = 'Questionnaire';
      this.request.surveyBtn.setAttribute('role', 'button');
      this.request.surveyBtn.setAttribute('target', '_blank');

      let surveyURL;
      switch (this.env) {
        case 'prod':
        case 'preprod':
          surveyURL = 'https://apps.kent.edu/MealPlanSignUp/Questionaire.aspx';
          break;
        case 'test':
          surveyURL = 'https://appsqa.kent.edu/MealPlanSignUp/Questionaire.aspx';
            break;
        case 'dev':
          surveyURL = 'https://appsdev.kent.edu/MealPlanSignUp/Questionaire.aspx';
          break;
        default:
          console.log('Survey URL not established');
      }

      if(surveyURL) this.request.surveyBtn.setAttribute('href', surveyURL);
      
      this.request.surveyBtn.className = "button";


      overView.appendChild(link);
      el.appendChild(overView);
      buttonArea.appendChild(this.request.surveyBtn);
      el.appendChild(buttonArea);

      
    }

    


    return el;
  }

  buildError(data) {
    let el = document.createElement('div');
    el.setAttribute('data-error', '');
    let heading = document.createElement('h3');
    heading.textContent = 'Data Unavailable!';
    el.appendChild(heading);
    let overView = document.createElement('p');
    overView.textContent = "Please check back later.";
    el.appendChild(overView);
    return el;
  }

  //puts the appropriate content into the DOM. If element already in storage, use that. Otherwise, build a new component.
  drawContent(updateName, data) {
    this.content.innerHTML = '';

    this.current.name = updateName;
    this.current.data = data;

    let content;

    switch(this.current.name) {
      case ('error'):
        if(this.error.el && JSON.stringify(this.current.data) === JSON.stringify(this.error.data)) {
          content = this.error.el;
        } else {
          content = this.buildError(data);
          this.error.el = content;
          this.error.data = data;
        }
        break;

      case ('donate'):
        if(this.donate.el && JSON.stringify(this.current.data) === JSON.stringify(this.donate.data)) {
          content = this.donate.el;
        } else {
          content = this.buildDonate(data);
          this.donate.el = content;
          this.donate.data = data;
        }
        break;

      case ('request'):
        if(this.request.el && JSON.stringify(this.current.data) === JSON.stringify(this.request.data)) {
          content = this.request.el;
        } else {
          content = this.buildRequest(data);
          this.request.el = content;
          this.request.data = data;
        }
        
        break;
      default:
    }


    this.current.el = content;
    this.content.appendChild(content);
  }


  //Determines if view needs to be updated based on the current superselect value and available data.
  //returns boolean telling if update will occur
  evaluatePlusUpdate() {

    let currentName = this.current.name;
    let currentDataString = JSON.stringify(this.current.data);

    let newName = this.superSelect.value;
    let newData = (newName === 'donate') ? this.donateData : this.requestData;

    if(this.dataError(newName)) {
      newName = 'error';
      newData = null;
    }

    let newDataString = JSON.stringify(newData);
     
    if (currentName !== newName || currentDataString !== newDataString) {
      this.updateContent(newName, newData);
      //console.log('going to update');
      return true;
    } else {
      //console.log('nothing to update');
      if(this.frozen) this.thawNode();
      return false;
    }

  }


  //Fade out, replace content, fade back in
  updateContent(updateName, data){

    let firstFade = this.initialized ? 300 : 0;

    $(this.content).fadeOut(firstFade, function(){
      this.drawContent(updateName, data);
      this.content.style.visibility = 'hidden';
      this.content.style.display = 'block';

      setTimeout(function(){
        //https://css-tricks.com/does-flexbox-have-a-performance-problem/
        //this seems to let flexbox finish before resizing flipper

        flipperForceResize(300, this.flipper);
        this.content.style.display = 'none';
        this.content.style.visibility = 'visible';
        $(this.content).fadeIn(300, function(){
          //packery resize
          flipperForceResize(0, this.flipper);//double check
          EventDispatcher.PackeryResize(this.node);

          if(this.frozen) {
            this.thawNode();
          }

          
          if(!this.initialized) {
            //safety ensures there is no overlapping on initial pageload
            EventDispatcher.Custom({
              "el" : window,
               "event" : "resize"
            });
            //INSURANCE if this initializes before the needed images are loaded.
            window.addEventListener('load', function(){
              EventDispatcher.Custom({
                "el" : window,
                "event" : "resize"
              }); 
            });

            EventDispatcher.NodeLoadedForTheFirstTime(this.node);
            this.initialized = true;
          }

        }.bind(this));

      }.bind(this), 200);
      
      
    }.bind(this));

  }


  hasNull(myObject){
    let hasNull = false;
    Object.keys(myObject).forEach(function (myValue) {
      //console.log(myValue); // key
      //console.log(myObject[myValue]); // value
      if (myObject[myValue] === null) hasNull = true;
    });
    return hasNull;
  }

  //Returns TRUE if there is not enough data to display the desired superselect value
  dataError(view){
    if(!this.data) return true;

    switch(view) {
      case 'donate':
        if (!this.donateData || this.hasNull(this.donateData)){
         return true;
        }
        break;
      case 'request':
        if (!this.requestData || this.hasNull(this.requestData)){
         return true;
        }
        break;
      default:
    }

    return false;

  }



}//End of class