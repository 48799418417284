
var ForgotUsernameUtilities = {

  onCaptchaExpire: function() {
    $('#captcharequired').show(500);
    $('#cap').val("");
  },

  onData: function() {
    $('#captcharequired').hide();
    $('#cap').val( grecaptcha.getResponse()  );
  },

  onSubmit: function() {

    if( $('#altemail').val() == "" ) {
      $('#pleaseAddEmail').show(500);
      return false;
    }

    if( $('#altemail').val().indexOf('@') == -1 ) {
      $('#invalidemail').show(500);
      return false;
    } 

    if( $('#cap').val() == "" ) {
      $('#captcharequired').show(500);
      $('#g-recaptcha').hide(); 
      $('#g-recaptcha').show(500); 
      return false;
    }
    $("#fmu").submit();
  }, 

  onLoad: function() {
    grecaptcha.render('g-recaptcha');
    $('.fmuform').show( 500 ); 
    $('#sub').show();
  }
}
