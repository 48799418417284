function worklistTools() {

    this.showNewChecklist = function () {
        if (!$('#myModal').length) {
            $('body').append(' <div class="large reveal" id="myModal" data-reveal data-v-offset="0"><div id="myModalInternal">Please wait...</div></div>');
        }
        $.getJSON('/asyncloader/nojs/node/render/5331', function (d) {
            $('#myModalInternal').html(d.markup);
        }); // $('#myModal').foundation('reveal', 'open'); // });
        var popup = new Foundation.Reveal($('#myModal'));
        popup.open();

        // when modal is closed, set focus either to checklistpicker, or to the menu (if in mobile view)
        $(document).on('closed.zf.reveal', '[data-reveal]', function () {
            if ($('.checklistpicker:visible').length > 0)
                $('.checklistpicker:visible').focus();
            else if ($('#navarea3-toggle').length > 0)
                $('#navarea3-toggle').focus();
        });

        $('#mylists-dropdown').hide();
    }

    this.showOldChecklist = function (node, div) {

        if ($(this).attr('data-tooltip')) {
            $(this).foundation('hide');
        }

        $('.check-list-header a').reverse().each(function () {
            // make sure the element we're loading has url data attribute
            if ($(this).data().hasOwnProperty('url'))
                KSUMain.load_checklist($(this).text(), '/asyncloader/nojs/node/render/' + $(this).data('url'), false);
        });

        $('#mylists-dropdown').hide();
        $('.checklist-content:visible').hide();
        $('#' + div).fadeIn();

        $('#checklist-dropdown').foundation('open');


        //Needs initial delay since off-canvas takes 500 to appear
        setTimeout(function () {
            $('.check-list-header a.active').removeClass('active');

            if (node == '4191') {
                $('a[data-url="4191/checklist"]').addClass('active');
            } else {
                $('a[data-url=' + node + ']').addClass('active');
            }

            $('.check-list-header a.active').focus();

        }, 500);

    }

}

