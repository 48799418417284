$(document).ready(function () {
    $('.user_emulator_trigger').click(function (e) {
        var container = $('.user_emulator_container');
        var isOpen = container.hasClass('open');
        if (isOpen) {
            container.removeClass('open');
            $(this).find('i').removeClass('dripicons-chevron-right').addClass('dripicons-chevron-left');
        }
        else {
            container.addClass('open');
            $(this).find('i').removeClass('dripicons-chevron-left').addClass('dripicons-chevron-right');
        }
    });
    $('.user_emulator .user-selector').on('click', function () {
        var user = $(this).attr('data-user');
        if (user == '') {
            user = $('#user_emulator_input').val();
        }
        $.ajax('/asyncloader/emulate/' + user).done(function () {
            location.reload();
        });

    });
});
