/*
Create an infobox in HTML:
<button data-infobox="Whatever you want to appear"></button>

Optional accessibility attributes: (Generic open/close labels added automatically if these are not provided.)
data-aria-open = ""
data-aria-close = ""

Create an infobox with JS:
The infoBox.getToggle method returns a toggle element. Just place it anywhere on the page with JS appendChild/insertBefore and you're in business. customHTML will trump plainText if provided:

var myAwesomeToggle = infoBox.getToggle({
plainText : "",
customHTML : "",
ariaOpen : "",
ariaClose : ""
});

*/

class InfoBox {

  constructor() {
    //console.info('Info Box Constructor');
    this.addListeners();
  }

  addListeners() {
    let toggles = document.querySelector('[data-infobox]');
    if(toggles){
      toggles = document.querySelectorAll('[data-infobox]');
      for(let i = 0; i < toggles.length; ++i){
        let myToggle = toggles[i];
        if(!myToggle.getAttribute('data-infobox') || myToggle.hasAttribute('data-custom')) continue;

        myToggle.classList.add('infobox-toggle');
        myToggle.classList.add('dripicons-information');
        
        if(!myToggle.getAttribute('data-aria-open')){
          myToggle.setAttribute('aria-label', 'Open More Information');
        }
        myToggle.setAttribute('aria-expanded', 'false');
        myToggle.addEventListener('click', this.toggleInfoBox.bind(this));
      }
    }

  }


  getToggle(options) {
  	options = (typeof options !== 'undefined') ?  options : {};
	  let myToggle = document.createElement('button');
	  myToggle.setAttribute('data-infobox', '');
    options.ariaOpen = options.ariaOpen ? options.ariaOpen : 'Open More Information';
    myToggle.setAttribute('aria-label', options.ariaOpen);
    myToggle.setAttribute('aria-expanded', 'false');

    myToggle.setAttribute('class', 'infobox-toggle dripicons-information');

    if(options.customHTML || options.plainText){
    	myToggle.setAttribute('data-custom', '');
    }

	  myToggle.addEventListener('click', function(e){
		  e.stopPropagation();
		  this.toggleInfoBox(e, options);
    }.bind(this), true);
  
    return myToggle;

  }


  getHooks(el){
  	let hooks = {};
  	if(el.hasAttribute('data-infobox')){
  		hooks.toggle = el;
  		hooks.toggleSibling = hooks.toggle.nextElementSibling;
  		hooks.parent = hooks.toggle.parentNode;
  		hooks.infoBox = hooks.parent.querySelector('.infobox');
  		hooks.infoBoxClose = hooks.parent.querySelector('.infobox [data-infobox-close]');
  	}else if(el.hasAttribute('data-infobox-close')){
		  hooks.infoBoxClose = el;
		  hooks.infoBox = hooks.infoBoxClose.parentNode;
		  hooks.parent = hooks.infoBox.parentNode;
		  hooks.toggle = hooks.infoBox.previousElementSibling;
  	}

  	return hooks;
  }


  closeInfoBox(e, options){

  	let hooks = this.getHooks(e.currentTarget);

  	if(options.ariaOpen){
  	  //no action needed
  	} else if(hooks.toggle.getAttribute('data-aria-open')){
  	  options.ariaOpen = hooks.toggle.getAttribute('data-aria-open');
  	} else {
	    options.ariaOpen = 'Open More Information';
  	}

    hooks.toggle.setAttribute('aria-label', options.ariaOpen);
  	hooks.toggle.setAttribute('aria-expanded', 'false');

    hooks.toggle.focus();
  	if(hooks.infoBox) hooks.parent.removeChild(hooks.infoBox);
  	hooks.infoBox = null;
  	
  }


  openInfoBox(e, options){
  	options = (typeof options !== 'undefined') ? options : {};

	  let hooks = this.getHooks(e.currentTarget);
	
	  hooks.infoBox = document.createElement('div');
	  hooks.infoBox.className = 'infobox v2-area';
	  //hooks.infoBox.setAttribute('aria-live', 'polite');

	  hooks.infoBoxClose = document.createElement('button');
	  hooks.infoBoxClose.setAttribute('data-infobox-close', '');
    hooks.infoBoxClose.className = 'infobox-close dripicons-cross circle-icon';
    //prevent duplicate toggle for screen reader
	  //hooks.infoBoxClose.setAttribute('aria-hidden', 'true');
	  //hooks.infoBoxClose.setAttribute('tabindex', '-1');

    if(options.ariaClose){
      //good
    } else if(hooks.toggle.getAttribute('data-aria-close')){
      options.ariaClose = hooks.toggle.getAttribute('data-aria-close');
    } else {
      options.ariaClose = 'Close More Information';
    }

    hooks.infoBoxClose.setAttribute('aria-label', options.ariaClose);
	  hooks.infoBoxClose.addEventListener('click', function(e){
		  this.closeInfoBox(e, options);
    }.bind(this));

    hooks.infoBox.appendChild(hooks.infoBoxClose);
    hooks.infoBoxContent = document.createElement('div');
	  hooks.infoBoxContent.className = 'infobox-content';
	  hooks.infoBox.appendChild(hooks.infoBoxContent);

	  if(options.customHTML){
		  hooks.infoBoxContent.innerHTML = options.customHTML;
	  } else if(options.plainText){
      hooks.infoBoxContent.textContent = options.plainText;
    } else if(hooks.toggle.getAttribute('data-infobox')){
		  hooks.infoBoxContent.textContent = hooks.toggle.getAttribute('data-infobox');
	  } else {
	    //no content to reveal!
	  }

    if(hooks.toggleSibling){
      console.log(hooks.toggleSibling);
      hooks.parent.insertBefore(hooks.infoBox, hooks.toggleSibling);
    }else{
      hooks.parent.appendChild(hooks.infoBox);
    }

    hooks.infoBoxClose.focus();

	  hooks.toggle.setAttribute('aria-expanded', 'true');
    hooks.toggle.setAttribute('aria-label', options.ariaClose);

  }


  toggleInfoBox(e, options){
  	options = (typeof options !== 'undefined') ?  options : {};
  	let el = e.currentTarget;

  	//Toggle flagged for custom content, but none was provided
  	if(el.hasAttribute('data-custom') && !options.customHTML && !options.plainText) return;

  	let expanded = el.getAttribute('aria-expanded');

  	if(expanded === 'true'){
  		this.closeInfoBox(e, options);
  	} else {
		  this.openInfoBox(e, options);
  	}
  }

}


var infoBox;
document.addEventListener('DOMContentLoaded', function(e){
  infoBox = new InfoBox();
});