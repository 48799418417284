class UserHelper {
    constructor(user) {
        let local_user = JSON.parse(user);
        this.Username = local_user.Username;
        this.LegalName = local_user.LegalName;
        this.DisplayName = local_user.DisplayName;
        this.Roles = local_user.Roles;
        this.Id = local_user.Id;
        this.IsDiagnostic = local_user.IsDiagnostic;
        this.BaseTheme = local_user.BaseTheme;
        this.CustomTheme = local_user.CustomTheme;
    }

    getBaseTheme() {
        return this.BaseTheme;
    }

    getCustomTheme() {
        return this.CustomTheme;
    }

    getUsername() {
        return this.Username;
    }

    attachDataToUser(property, data) {
        this[property] = data;
    }

    getDataFromUser(property) {
        return this[property];
    }

    isDiagnostic() {
        return this.IsDiagnostic;
    }

    getRoles() {
        return this.Roles;
    }

    getName(legal = false) {
        return legal ? this.LegalName : this.DisplayName;
    }

    hasRole(role) {
        return this.Roles.includes(role)
    }

    isAdmin() {
        return this.hasRole("administrator")
    }

    isSelfDebugFor(debugKey) {
        return URLHelper.isSelfDebugFor(debugKey);
    }

}