var navArea2;

var nav2SearchToggleMobile;
var nav2SearchToggleDesktop;

var nav2SearchWrapper;
var nav2SearchClose;

var nav2SearchForm;
var nav2SearchInput;

var nav2SearchHistory;
var nav2SearchHistoryLast;


//This function reveals the searchbar and sends focus to the search input
function mobileSearchToggle(e) {
    navArea2.classList.toggle('search-open');
    nav2SearchWrapper.classList.toggle('open');
    if (nav2SearchWrapper.classList.contains('open') && window.innerWidth < 1200) {
        setTimeout(function(){ nav2SearchInput.focus(); }, 100);
    }else{
        $(nav2SearchHistory).foundation('close');
        //accessibility - return focus to toggle if mobile
        if ((e.currentTarget === nav2SearchClose) && (window.innerWidth < 1024)){
			setTimeout(function(){ nav2SearchToggleMobile.focus(); }, 100);
        } else if ((e.currentTarget === nav2SearchClose) && (window.innerWidth < 1200)){
			setTimeout(function(){ nav2SearchToggleDesktop.focus(); }, 100);
        }
    }
}

function closeMobileSearch(e){
	navArea2.classList.remove('search-open');
    nav2SearchWrapper.classList.remove('open');
}

//accessibility if esc
function nav2SearchEsc(e){
	if(e.keyCode === 27){
		$(nav2SearchHistory).foundation('close');
        if (window.innerWidth < 1024){
			setTimeout(function(){ nav2SearchToggleMobile.focus(); }, 100);
        } else if (window.innerWidth < 1200){
			setTimeout(function(){ nav2SearchToggleDesktop.focus(); }, 100);
        } else {
        	nav2SearchInput.classList.add('closed-by-esc');
			setTimeout(function(){ nav2SearchInput.focus(); }, 100);
        }
	}
}  


//This function lets us style the search icon and placeholder text
function searchIconOnOff() {
    nav2SearchWrapper.classList.toggle('input-focused');
}

//if user opens history on desktop and resizes window down to mobile, we need the search area to be automatically toggled
function nav2SearchHistoryResize(e){
	if((window.innerWidth < 1200) && !(navArea2.classList.contains('search-open'))){
		navArea2.classList.add('search-open');
		nav2SearchWrapper.classList.add('open');
	}
}


function installSearch() {
	if (isBaseTheme('pioneer')) {
		navArea2 = document.getElementById('navarea2');
    if( navArea2 == null )
      return;

		nav2SearchToggleMobile = document.getElementById('navarea2-search-toggle-mobile');
        nav2SearchToggleDesktop = document.getElementById('navarea2-search-toggle-desktop');

		nav2SearchWrapper = navArea2.querySelector('.search-wrapper');
		nav2SearchClose = nav2SearchWrapper.querySelector('.close-search-toggle');

    	nav2SearchForm = nav2SearchWrapper.querySelector('form');
		nav2SearchInput = nav2SearchWrapper.querySelector('input[type="search"]');

    	nav2SearchHistory = navArea2.querySelector('[id^="search-history"]');
    	nav2SearchHistoryLast = nav2SearchHistory.querySelector('a:last-child');

    	navToggle = document.getElementById('nav-toggle');


    	//attach event handler to search toggles
		nav2SearchClose.addEventListener('click', mobileSearchToggle);
        nav2SearchToggleMobile.addEventListener('click', mobileSearchToggle);
        nav2SearchToggleDesktop.addEventListener('click', mobileSearchToggle);

        //hook to style magnifying glass icon and input placeholder text on focus
		nav2SearchInput.addEventListener('focus', function(){
			searchIconOnOff();
			//this is a accessibility safety if user just closed this with esc, we don't want to reopen it
			if(nav2SearchInput.classList.contains('closed-by-esc')){
				nav2SearchInput.classList.remove('closed-by-esc');
				return;
			}
			if(!(nav2SearchHistory.classList.contains('is-open'))){
				
                navArea2.classList.add('search-open');
                nav2SearchWrapper.classList.add('open');
                nav2SearchWrapper.classList.add('input-focused');
                $(nav2SearchHistory).foundation('open');
			}
		});

        //need to close if user tabs up from input
		nav2SearchInput.addEventListener('keydown', function(e){
			if(e.shiftKey && e.keyCode === 9) {
                $(nav2SearchHistory).foundation('close');
		        if (window.innerWidth < 1024){
		        	e.preventDefault();
					setTimeout(function(){ nav2SearchToggleMobile.focus(); }, 100);
		        } if (window.innerWidth < 1200){
		        	e.preventDefault();
					setTimeout(function(){ nav2SearchToggleDesktop.focus(); }, 100);
		        }
            }			
		});

        //need to close if user tabs past X
		nav2SearchClose.addEventListener('keydown', function(e){
			if(!e.shiftKey && e.keyCode === 9) {
                $(nav2SearchHistory).foundation('close');
		        if (window.innerWidth < 1024){
		        	e.preventDefault();
					setTimeout(function(){ $('#navarea3-toggle').focus(); }, 100);
		        }
            }			
		});

        //accessibility - need to close if user tabs past last link
        if(nav2SearchHistoryLast){
    		nav2SearchHistoryLast.addEventListener('keydown', function(e){
    			if(!e.shiftKey && e.keyCode === 9) {
    		        if (window.innerWidth > 1200){
    		        	$(nav2SearchHistory).foundation('close');
    		        }
                }			
    		});
        }

        nav2SearchInput.addEventListener('blur', searchIconOnOff);

    	//stops history dropdown from closing if user clicks twice on search input
    	nav2SearchForm.addEventListener('click', function(e){
    		if(nav2SearchHistory.classList.contains('is-open'))
    		e.stopPropagation();
    	});

        $('#search-history').on('show.zf.dropdown', function () {
            if (flyoutBG.classList.contains('open')) {
                navCleanUp();
            }
        	//This code hides hides/show left nav toggle based on whether or not search history is open
            navToggle.style.cssText = "opacity: 0; pointer-events: none;";

            //if user opens history on desktop and resizes window down to mobile, we need the search area to be automatically toggled open
            window.addEventListener('resize', nav2SearchHistoryResize);
            window.addEventListener('keydown', nav2SearchEsc, true);

        });

        $('#search-history').on('hide.zf.dropdown', function () {
        	//This code hides hides/show left nav toggle based on whether or not search history is open
            navToggle.style.cssText = "";


			//if user opens history on desktop and resizes window down to mobile, we need the search area to be automatically toggled open
            window.removeEventListener('resize', nav2SearchHistoryResize);
            window.removeEventListener('keydown', nav2SearchEsc, true);
            closeMobileSearch();

        });

    }//Closing if Pioneer
}//Ending installSearch

window.addEventListener('DOMContentLoaded', installSearch);
