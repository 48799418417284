var KSUMain_ES6 = {

    cancelLogout: false,

    logout: function() {

      swal2Btns({
        confirmButtonText: "Log me out now",
        cancelButtonText: "Keep me logged in",
        focusConfirm: true,
        showConfirmButton: true,
        focusCancel: true,
        title: 'Logging out in&nbsp; <span id="countdown">10</span>&nbsp;seconds..',
        html: '<p>You should completely close your browser as you may remain logged into other systems besides FlashLine.</p>',
        preConfirm: () => {
          window.location.href = "/user/logout";
        }
      }).then((result) => {
          this.cancelLogout = true;
        });

        this.cancelLogout = false;
        this.countDownTimer(10);

    },

    countDownTimer: function( x ) {

      if( this.cancelLogout )
        return;

      $('#countdown').html( x-- );

      if( x >= 0 )
        window.setTimeout( function() { this.countDownTimer( x ); }.bind(this), 1000 );
      else
        window.location.href = "/user/logout";

    },



    getCookie: function(cname) {
      /* simply pass the name of the cookie to get its value */
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    clearCookie: function(cname) {
      document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },

    sspr: function() {
      /* called on page load.  Check if we should prompt for sspr / mfa data */
      if( document.referrer.indexOf('login') !== -1 || document.referrer.endsWith('.edu/') ) {
        this.helperSSPR = (typeof KSU_DisplayHelper !== 'undefined' && typeof KSU_DisplayHelper.properties.sspr !== 'undefined');

        if( this.querySSPRshowPrompt() || this.queryMFAshowPrompt() ){ 
          //console.log('SSPR or MFA');
          this.suppressModals = true;
          ssprInit();
        }else if(this.queryAddressValidateShowPrompt()) {
          // hi amy, i removed this just in case 
           this.suppressModals = true;
           addressValidateInit();
        }
      }
    },

    querySSPRshowPrompt: function() {
      if(this.helperSSPR){
        return (KSU_DisplayHelper.properties.sspr.sspr) ? KSU_DisplayHelper.properties.sspr.sspr.showPrompt : false;
      }
    },

    queryMFAshowPrompt: function() {
      if(this.helperSSPR){
        return (KSU_DisplayHelper.properties.sspr.mfa) ? KSU_DisplayHelper.properties.sspr.mfa.showPrompt : false;
      }
    },

    queryAddressValidateShowPrompt: function() {
      if(this.helperSSPR){
        console.log(KSU_DisplayHelper.properties);
        if( KSU_DisplayHelper.properties.address_verification == false) {
          
          console.log("address verification is turned off"); 
          return false;
        }
        console.log("address verification is turned on");
        return (KSU_DisplayHelper.properties.sspr.addressValidate) ? KSU_DisplayHelper.properties.sspr.addressValidate.showPrompt : false;
      }
    },

    sdmpicker: function() {

      var selectedCountry  = $('select.sdm option:selected').text();

      if( selectedCountry == "United States and Territories") {
        $('#select-decision-state-modal').show();
      }
      else
        $('#select-decision-state-modal').hide();

      // if no country is selected, or if country is selected and no state is selected...
      if( 
        $('select.sdm option:selected').val() == -1 || 
        ( $('select.sdm option:selected').text() == "United States and Territories" && $('select.sdms option:selected').val() == -1 ) 
      ) { 
        // hide select button.
        $('.swal2-confirm').hide();

      }
      else {

        $('.swal2-confirm').show();

      }

    },
  
    sdmdiscerner: function() {

      var selectedCountry  = $('select.sdm option:selected').text();

      if( selectedCountry == "United States and Territories" ) 
         return( $('select.sdms option:selected').val() );
      else
         return( $('select.sdm option:selected').val() );

    },

    init: function () {
        this.feedbackForm();
    },
    load: function () {
        this.sspr();
        this.listsCount();
        this.messageCount();
        this.notifications();
        this.engageMiniCalendar();
    },

    engageMiniCalendar: function () {

      var maxEventCount = 4;
      if( $(".mini-engage").length > 0) {

        // build out the list of engage events.
        // make sure it returns a 200, that the result set
        // isn't empty, and that we have at >0 events.

        $.ajax({
          url: '/wse/586',
          cache: false
        }).fail( function( data, success, jqXHR ) {
          $(".mini-engage").html("Sorry, something went wrong.");
        }).success( function( data, success, jqXHR ) {
       
          if( data.Httpcode != 200 ) {
            $(".mini-engage").html("Sorry, something went wrong.");
            return;
          }
          if( data.Result == "" ) {
            $(".mini-engage").html("Sorry, something went wrong.");
            return;
          }
 
          var totalEventCount = eventCount = data.Result.channel.item.length;

          if( eventCount  == 0 ) {
            $(".mini-engage").html("Sorry, it looks like there are no upcoming Student Organization Events at this time.");
            return;
          }

          // show only 4 events.
          if( eventCount  > maxEventCount  ) { 
            eventCount = 4;
          }

          moment.tz.setDefault("America/New_York");
          for( var i = 0; i < eventCount; i++ ) {
            var start = new moment( data.Result.channel.item[i].start );
            var formatted =  start.format("ddd, MMM Do, h:mm a") ;

            var ev =  document.createElement("div");
            ev.innerHTML = "<b><a href='" + data.Result.channel.item[i].link+ "' target='_new'>" + data.Result.channel.item[i].title  + "</a></b><br>" + formatted; 

            $(".mini-engage").append(ev);
          }

          if( totalEventCount > maxEventCount ) {
            var more = document.createElement('div');
            more.className = 'v2-area';
            more.innerHTML = "<div style='text-align: right'><a href='https://kent.campuslabs.com/engage/events' target='_blank'>View all " + totalEventCount + " events <span class='dripicons-chevron-right'></span></a></div>";
            $(".mini-engage").append(more);
          }

        });
      }
      if( $(".engage-links").length > 0 ) {

        // get the list of engage links
        $.ajax({
          url: '/asyncloader/nojs/node/render/' + $(".engage-links").data("cid"), // 6121 in prod
          cache: false
        }).fail( function( data, success, jqXHR ) {
          $(".engage-links").html("Sorry, something went wrong.");
        
        }).success( function( data, success, jqXHR ) {

          var parser= new DOMParser();
          var doc = parser.parseFromString( data.markup, 'text/html');
          $(".engage-links").append( doc.body.getElementsByClassName("panel")[0]);

        }); 

      }
    },
    feedbackForm: function () {
        $('.feedback-link').unbind('click').on('click', function () {

            swal2Btns({
                title: 'Feedback',
                text: $('.feedback-help').text(),
                type: 'info',
                input: 'textarea',
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (value) {
                            resolve();
                        } else {
                            resolve('You need to write something!');
                        }
                    })
                },
                inputPlaceholder: "Write something"
            }).then((result) => {
                if (result.value) {
                    $('.feedback-message').text(result.value);
                    $('.feedback-form').submit();
                    swal2("Nice!", "We got your message loud and clear.", "success");
                }
            });

        });
    },
    listsCount: function () {
        if (!$(".checklistpicker").length) {
            return;
        }
        jQuery.getJSON("/asyncloader/getEntity/696", function (data) {
            if (data['TotalCount'] != null && data['TotalCount'] > 0) {
                var lists_update = jQuery('.checklist-dropdown-init');
                var badge = lists_update.find('span.badge');
                let count = Number(data['TotalCount']);
                let ariaLabel = (count === 1) ? ' list' : ' lists';

                if (badge.size()) {

                  badge.find('.badge-num').text(count);
                  badge.find('.aria-only').text(ariaLabel);

                } else {
                    var lists = jQuery('.checklistpicker');
                    lists.addClass('has-badge');
                    lists.append('<span class="badge main-menu-lists-count"><span class="badge-num">' + count + '</span><span class="aria-only">' + ariaLabel + '</span></span>');
                }
                addBadges();
            }
        });
    },
    messageCount: function () {
        if (!$(".main-menu-email").length) {
            return;
        }

        jQuery.getJSON("/asyncloader/messagecount", function (data) {
            if (data['fullcount'] != null && data['fullcount'] > 0) {
                var mail = jQuery('.main-menu-email');
                var mailParent = mail.parent();
                var badge = mailParent.find('span.badge');
                let count = Number(data['fullcount']);
                let ariaLabel = (count === 1) ? ' unread message' : ' unread messages';

                if (badge.size()) {


                  badge.find('.badge-num').text(count);
                  badge.find('.aria-only').text(ariaLabel);

                } else {
                    mailParent.append('<span class="badge main-menu-email-count"><span class="badge-num">' + count + '</span><span class="aria-only">' + ariaLabel + '</span></span>');
                }
                addBadges();
            }
        });
    },

    notifications: function () {


        var fromLogin = document.referrer.indexOf('login') !== -1 || document.referrer.endsWith('.edu/');

        var notificationLoader = new ajaxLoader('#notification-list');
        $.getJSON("/asyncloader/notifications", function (data) {
            var items = [];

            if (data.Code !== 'Success') {
                $(".notification-count").hide();
                $('<div class="notification-row row text-center"><div class="column">Notifications temporarily unavailable.</div></div>').appendTo("#notification-list");
                notificationLoader.remove();
                return;
            }

            if (data.Payload == undefined) {
                $(".notification-count").hide();
                $('<div class="notification-row row text-center"><div class="column">You have no new notifications.</div></div>').appendTo("#notification-list");
                notificationLoader.remove();
                return;
            }


            var notificationsVersion = data.version;

            var decision = data.Payload.Decision;
            data = data.Payload.Notifications;
            var count = 0;
            var notificationButtons = false;




           if( decision != null && decision.Decided != true && fromLogin  ) {

              this.suppressModals = true;
              modalMessage = decision.Message;
              cancelButtonText = "Cancel";
              confirmButtonText = "OK";
              showCancelButton = false;
              showConfirmButton = true;
              confirmMarkAsClicked = true;



                      modalMessage += "<select id='select-decision-country-modal' class='sdm' onchange='KSUMain_ES6.sdmpicker();'>";
                      modalMessage += "<option value='-1'>-- Select your country --</option>";
                      decision.Decisions.forEach( function(item, idx) {
                        if( item.Text.substring(0,2) == "c:" )
                          modalMessage += "<option value='" + item.Id + "'>" + item.Text.substring(2) + "</option>";
                        });
                      modalMessage += "</select><br>";

                      modalMessage += "<select id='select-decision-state-modal' class='sdms' style='display: none' onchange='KSUMain_ES6.sdmpicker();'>";
                      modalMessage += "<option value='-1'>-- Select your state --</option>";
                      decision.Decisions.forEach( function(item, idx) {
                        if( item.Text.substring(0,2) == "s:" )
                          modalMessage += "<option value='" + item.Id + "'>" + item.Text.substring(2) + "</option>";
                        });
                      modalMessage += "</select>";

//                modalMessage += "decision " + decision.Decisions[x].Text + " " + decision.Decisions[x].Id;



              swal2( {
                title:    decision.Title,
                html:     modalMessage,
                footer: "<div class='swal2-notification-id' style='display: none;'></div>",
                allowOutsideClick: false,
                cancelButtonText: cancelButtonText,
                confirmButtonText: confirmButtonText,
                showCancelButton: showCancelButton,
                showConfirmButton: showConfirmButton,
                customClass: 'modalSwal notificationSwal modalDecision',
                preConfirm: () => {
                  var decisionId = KSUMain_ES6.sdmdiscerner();
                    var postData = {
                        notificationId: decision.Id, //val.Id,
                        decisionId: decisionId,
                        clicked: confirmMarkAsClicked,
                        read: true
                    };

                    $.post("/asyncloader/notifications/v1", postData, function (result) {
                        swal.close();
                        //if( confirmButtonURL != null )
                       //     window.location.href = confirmButtonURL;
                        return( true );
                    });
                  
                 }
              });




          } 






            if( data != null) 
            $.each(data, function (key, val) {

              // ignore KSUMobile only notifications
              if( val.Method == "KSUMobile" )
                return;

              if( val.Type == "Normal" ) {

                if( val.Url == null ) 
                  val.Url = '/node/416?notificationid=' + val.Id;

               // Default notification row
                var notificationString = '<div ';
                
                if (val.Url != '#') {
                    notificationString +=
                        'data-href="' + val.Url + '" ' +
                        ' tabindex="0"';
                }
                

                notificationString +=
                    'class="notification-row row ' + (val.Clicked ? "" : "unclicked") + '" ' +
                    'data-nid="' + val.Id + '" ' +
                    'data-did="' + val.DeliveryId + '"' +
                    'data-ver="' + notificationsVersion + '" ' + 
                    'data-type="' + val.Type + '">' +
                    '' +
                    '<i ' +
                    'data-hover-delay="50"' +
                    'data-tooltip ' +
                    'aria-haspopup="true" ' +
                    'data-disable-hover="false" ' +
                    'tabindex="0" ' +
                    'class="has-tip icon-circle notify-icon ' + (val.Clicked === true ? "icon-read" : "icon-unread") + '" ' +
                    'title="' + (val.Clicked === true ? "Read" : "Mark as read") + '"' +
                    'aria-hidden="true"></i>' +
                    '<div class="column">' +
                    '<div class="readmore-notification"><b>' + val.Title + '</b> ' +
                    (val.ShortDescription ? val.ShortDescription : '') + '</div>';


                // Finish rest of notification row
                notificationString += '<div class="noti-date text-light7">' + val.StartDate + ' - ' + val.EndDate + '</div>' +
                    '</div>' +
                    '</div>';


                if( val.WasRead == true || val.Clicked == true ) {
                  ;
                }
                else
                  items.push(notificationString);

                // Notification badge count

                count = (val.Read || val.WasDelivered) ? (count) : (count + 1);

              }

              if( val.Type == "Modal" && fromLogin && !this.suppressModals ) {

                if( notificationsVersion == "v1" ) {
                  if( val.Clicked === true ) {
                    return; // since this is in an each, this is effectively a continue.
                  }
                  else {
                    // make sure we only show one modal.
                    this.suppressModals = true;
                  }
                }

                var confirmButtonText = "OK";
                var showConfirmButton = false;
                var confirmButtonURL;

                var cancelButtonText = "Close";
                var showCancelButton = false;
                var cancelButtonURL;

                var modalMessage = val.Message;
                var modalTitle = val.Title;          

                var messageID = val.Id;

                confirmMarkAsRead = true;
                cancelMarkAsRead = true;



                if( notificationsVersion == "v1" ) {
                  showCancelButton = true;
                  showConfirmButton = true;

                  if( val.ShowOKButtonOnly == true ) 
                    showCancelButton = false;

                  cancelMarkAsClicked = false;
                  confirmMarkAsClicked = true;
                  if( val.Url != null ) 
                    confirmButtonURL = val.Url;
  
                }


                

                if( notificationsVersion == "v2" ) {

                  if( val.Actions != null ) {
                    switch( val.Actions.length ) {

                      // this is indeed intended to fall through to case 1
                      // if Actions.length == 2
                      case 2:   cancelButtonText = val.Actions[1].Text;
                                cancelButtonURL = val.Actions[1].URL;
                                cancelMarkAsClicked = val.Actions[1].MarkAsRead == true;
                                // if( cancelButtonURL != null ) 
                                //  cancelButtonURL = cancelButtonURL.replace('<USERNAME>', KSU_UserHelper.getUsername());
                                showCancelButton = true; 
                      case 1:   confirmButtonText = val.Actions[0].Text;
                                confirmButtonURL = val.Actions[0].URL; 
                                confirmMarkAsClicked = val.Actions[0].MarkAsRead == true;
                                //if( confirmButtonURL != null )
                                //  confirmButtonURL = confirmButtonURL.replace('<USERNAME>', KSU_UserHelper.getUsername() );
                                showConfirmButton = true; 
                    }
                  }
                  else {
                    // no actions were specified...
                    showCancelButton = true;
                    showConfirmButton = true;
                    confirmMarkAsClicked = true;
                  }
                }

                swal2( {
                  title:    val.Title,
                  html:     modalMessage, 
                  footer: "<div class='swal2-notification-id' style='display: none;'>(" + val.Id + ")</div>",
                  allowOutsideClick: false,
                  cancelButtonText: cancelButtonText,
                  confirmButtonText: confirmButtonText,
                  showCancelButton: showCancelButton,
                  showConfirmButton: showConfirmButton, 
                  customClass: 'modalSwal notificationSwal modalNonDecision',
                  preConfirm: () => {

                    if( notificationsVersion == "v1") {
                      var postData = {
                          notificationId: val.Id,
                          decisionId: null,
                          clicked: confirmMarkAsClicked,
                          read: true
                      };
                    }

                    if( notificationsVersion == "v2") {
                      var postData = {
                          deliveryIds: val.DeliveryId,
                          read: confirmMarkAsClicked,
                          delivered: true
                      };
                    }

                    $.post("/asyncloader/notifications", postData, function (result) {
                        swal.close();
                        if( confirmButtonURL != null ) {
                            if( confirmButtonURL.indexOf("framed=true") != -1 )
                              window.location.href = confirmButtonURL;
                            else
                              window.open( confirmButtonURL, "_blank");
                        }
                        return( true );
                    });

                  }
                }).then((result) => {
                        
                  if (result.dismiss === Swal.DismissReason.cancel) {

                    if( notificationsVersion == "v1") {
                      var postData = {
                          notificationId: val.Id,
                          decisionId: null,
                          clicked: false,
                          read: true
                      };
                    }

                    if( notificationsVersion == "v2") {
                      var postData = {
                          deliveryIds: val.DeliveryId,
                          read: cancelMarkAsClicked,
                          delivered: true
                      };
                    }


                    $.post("/asyncloader/notifications", postData, function (result) {
                        swal.close();
                        if( cancelButtonURL != null ) {

                            if( cancelButtonURL.indexOf("framed=true") != -1 )
                              window.location.href = cancelButtonURL;
                            else
                              window.open( cancelButtonURL, "_blank");

                        }
                        return( true );
                    });

                  }
                }); 


              }


            });

            // Add to list
            $(items.join("")).appendTo("#notification-list");

            if (notificationButtons == true) {
                $("div.button-group").closest("div.notification-row").toggleClass("contains-buttons");
            }

            if (!items.length) {
                $(".notification-count").hide();
                $('<div class="notification-row row text-center"><div class="column">You have no new notifications.</div></div>').appendTo("#notification-list");
                notificationLoader.remove();
                return;
            }

            KSUJSPlugins.tooltips();
            KSUJSPlugins.rowClicks();
            $('.readmore-notification').readmore();

            // Unread notification badge
            let ariaLabel = (count === 1) ? ' unread notification' : ' unread notifications';
            $(".notification-count .badge-num").text(count);
            $(".notification-count .aria-only").text(ariaLabel);

            if (count < 1) {
                $(".notification-count").hide();
            } else {
                $(".notification-count").show();
            }
            addBadges();

            notificationLoader.remove();

            // Copy notification to mobile view
            // $('#alert_menu').clone().appendTo('.mobileNotifications');

            // Add scrollbar to notification lists
            // $('#notification-list').mCustomScrollbar({
            //     theme: "minimal-dark"
            // });

            // Mark all notifications as read
            $('#notification-dropdown').on('show.zf.dropdown', function (e) {
                //$(".notification-count").hide().text("0");

                $('#notification-list:eq(0) div[data-nid].unclicked').each(function () {
                    var row = $(this);


                    if( row.data('ver') == "v1" ) {
                      $.post("/asyncloader/notifications", {
                          notificationId: row.data('nid'),
                          decisionId: null,
                          clicked: "false",
                          read: "true"
                      })
                    }

                    if( row.data('ver') == "v2" ) {
                      $.post("/asyncloader/notifications", {
                          deliveryIds: row.data('did'),
                          delivered: "true",
                          read: "false"
                      })
                    }

                });
            });

            $('#notification-dropdown div[data-type!="Decision"].unclicked, button[data-did]').on('click', function (e) {
                e.stopPropagation();
                var button = $(this);
                var noti_div = button.closest('div.row');

                button.removeClass('unclicked');
                var loader = new ajaxLoader(noti_div);



                var postData = new Object();
                if(  button.data('ver') == "v1" ) {
                  postData = {
                    notificationId: button.data('nid'),
                    clicked: "true",
                    read: "true",
                  }
                }

                if(  button.data('ver') == "v2" ) {
                  postData = {
                    deliveryIds: button.data('did'),
                    read: "true",
                    delivered: "true",
                  }
                }

                $.post("/asyncloader/notifications", postData
                ).done(function () {
                    noti_div.fadeOut().remove();

                    if (button.data('did')) {
                        var buttonAction = button.text();
                        var title = button.closest('div.row').find('b').eq(0).text();
                        button.closest('div.row').removeClass('unclicked');

                        button.closest('.notification-decision').replaceWith(function () {
                            return $('<div class="alert alert-success">"' + buttonAction + '" action taken for <b>' + title + '</b>' +
                                '</div>').hide().fadeIn();
                        });
                    } else {

                    }

                    if (!$('#notification-list .notification-row').length) {
                        $('<div class="notification-row row text-center"><div class="column">You have no new notifications.</div></div>').appendTo("#notification-list");
                    }

                    loader.remove();
                }).fail(function () {
                    swal2("Oh nuts!", "Could not submit decision.", "error");

                    loader.remove();
                });
            });

            $('#notification-dropdown div.column a[data-readmore-toggle], #notification-dropdown .has-tip').on('click', function (e) {
                e.stopPropagation();
            });

            $('.notification-row .has-tip.icon-unread').on('click', function () {
                var icon = $(this);
                var row = $(this).closest('div.row');
                var loader = new ajaxLoader(row);

                // Remove unread highlight
                icon.removeClass('icon-unread').addClass('icon-read');
                row.removeClass('unclicked');

                $.post("/asyncloader/notifications", {
                    notificationId: row.data('nid'),
                    decisionId: null,
                    clicked: "true",
                    read: "true"
                }).done(function () {
                    row.fadeOut();
                    loader.remove();
                }).fail(function () {
                    swal2("Oh nuts!", "Could not submit decision.", "error");

                    loader.remove();
                });
            });

            $('.dismiss-notification, #notification-list li').has('.dismiss-notification').on('click', function (e) {
                $(this).closest('li').fadeOut();
                $('.notification-count').text($('.notification-count').text() - 1);
            });
        })
            .fail(function () {
                $(".notification-count").hide();
                $('<div class="notification-row row"><div class="column">Notifications unavailable. Please check back later.</div></div>').appendTo("#notification-list");
                notificationLoader.remove();
            });
    }
    // END

};

jQuery(function ($) {
    KSUMain_ES6.init();
});
$(window).load(function () {
    KSUMain_ES6.load();
});
