var myDashData;
var myDashDataNew;

var myDashTitleBar;
var myDashMoveBtns;
var myDashBookmarkBtns;
var myDashFilter;
var myDashGridBtn;
var myDashListBtn;

var myDashGridWrapper;
var myDashGrid;
var $myDashGrid;
var myDashDraggies;
var myDashJustMoved;

var myDashLimbo;
var myDashShow;
var myDashGridItems;
var myDashLeftOvers;

var myDashGridStart;
var myDashGridEnd;

var myDashOverlay;

var myDashTriggered;
var myDashInstalled;
var myDashProcessing;
var myDashContentChanged;
var myDashOrientationChanged;

var myDashWaitInterval = 100;
var myDashWaitTime = 0;
var myDashWaiting;

var myDashFitting;
var myDashXValueReset;
var myDashLoadingTimeout = null;

Packery.Packer.prototype.columnPack = function( rect ) {
  for ( var i=0; i < this.spaces.length; i++ ) {
    var space = this.spaces[i];
    var canFitInSpaceColumn;

    if ( myDashFitting ) {
      canFitInSpaceColumn = space.x <= rect.x &&
      space.x + space.width >= rect.x + rect.width &&
      space.height > rect.height + 1;
      if(canFitInSpaceColumn && (space.y > 0) && (space.y > rect.y)) {
        rect.y = space.y;
        this.placed( rect );
        break;
      }
    } else {

      canFitInSpaceColumn = space.x <= rect.x &&
      space.x + space.width >= rect.x + rect.width &&
      space.height >= rect.height - 0.01; // fudge number for rounding error
      if(canFitInSpaceColumn) {
        rect.y = space.y;
        this.placed( rect );
        break;
      }      

    }

  }
};

Packery.prototype.layout = function() {
  var layoutDoneCount = 0;
  function isLayoutDone(){
    layoutDoneCount++;
    if (layoutDoneCount === 2){
      window.removeEventListener('layoutDone', isLayoutDone, true);
      layoutDoneCount = 0;
      //console.log('Layout is truly done');
      EventDispatcher.Custom({
        "el" : window,
        "event" : "layoutTrulyDone"
      });      
    }
  }
  window.addEventListener('layoutDone', isLayoutDone, true);
  this._resetLayout();
  this._manageStamps();

  // don't animate first layout
  var layoutInstant = this._getOption('layoutInstant');
  var isInstant = layoutInstant !== undefined ?
    layoutInstant : !this._isLayoutInited;
  this.layoutItems( this.items, isInstant );

  // flag for initalized
  this._isLayoutInited = true;

  //console.log('Layout 2!!');
  EventDispatcher.Custom({
    "el" : window,
    "event" : "layoutDone"
  });
};


Packery.prototype._emitCompleteOnItems = function( eventName, items ) {
  var _this = this;
  function onComplete() {
    _this.dispatchEvent( eventName + 'Complete', null, [ items ] );
    //console.log('Layout 1!!');
    EventDispatcher.Custom({
      "el" : window,
      "event" : "layoutDone"
    });
  }

  var count = items.length;
  if ( !items || !count ) {
    onComplete();
    return;
  }

  var doneCount = 0;
  function tick() {
    doneCount++;
    if ( doneCount == count ) {
      onComplete();
    }
  }

  // bind callback
  items.forEach( function( item ) {
    item.once( eventName, tick );
  });


};



Packery.prototype.fit = function( elem, x, y ) {

  var fitDoneCount = 0;
  function isFitDone(){
    fitDoneCount++;
    if (fitDoneCount === 2){
      window.removeEventListener('fitDone', isFitDone, true);
      fitDoneCount = 0;
      //console.log('Fit is truly done');
      EventDispatcher.Custom({
        "el" : window,
        "event" : "fitTrulyDone"
      });      
    }
  }
  window.addEventListener('fitDone', isFitDone, true);

  var item = this.getItem( elem );
  if ( !item ) {
    return;
  }

  // stamp item to get it out of layout
  this.stamp( item.element );
  // set placing flag
  item.enablePlacing();
  this.updateShiftTargets( item );
  // fall back to current position for fitting
  x = x === undefined ? item.rect.x: x;
  y = y === undefined ? item.rect.y: y;
  // position it best at its destination
  this.shift( item, x, y );
  this._bindFitEvents( item );
  item.moveTo( item.rect.x, item.rect.y );
  // layout everything else
  this.shiftLayout();
  // return back to regularly scheduled programming
  this.unstamp( item.element );
  this.sortItemsByPosition();
  item.disablePlacing();

  //console.log('Fit 2!!');
  EventDispatcher.Custom({
    "el" : window,
    "event" : "fitDone"
  });

};



Packery.prototype._bindFitEvents = function( item ) {
  var _this = this;
  var ticks = 0;
  function onLayout() {
    ticks++;
    if ( ticks != 2 ) {
      return;
    }
    _this.dispatchEvent( 'fitComplete', null, [ item ] );
    //console.log('FIT 1!!');
  	EventDispatcher.Custom({
  	  "el" : window,
  	  "event" : "fitDone"
  	});
  }
  // when item is laid out
  item.once( 'layout', onLayout );
  // when all items are laid out
  this.once( 'layoutComplete', onLayout );
};


Packery.prototype.resetCoords = function() {
  this._resetLayout();
};


// get JSON-friendly data for item X positions
Packery.prototype.getShiftPositions = function( attrName ) {
  attrName = attrName || 'id';
  var _this = this;
  return this.items.map( function( item ) {
    return {
      attr: item.element.getAttribute( attrName ),
      x: item.rect.x / _this.packer.width
    }
  });
};



function reAssessRecordedXValues(){
// X Values need reset if one of these conditions are met
	if(!myDashShow.length){ return; }

    if(myDashGridWrapper.getAttribute('data-order') !== 'phone' && myDashGridWrapper.getAttribute('data-filter') === 'all' && !myDashDataNew.xValues.length){

        //this block only runs the first time a user visits the new dashboard in grid view with pinned items, we need to post starting X positions
        //console.log('reAssess 1');
        myDashDataNew.xValues = $myDashGrid.packery( 'getShiftPositions', 'data-node' );
        myDashDataPost();

    } else if(myDashGridWrapper.getAttribute('data-order') !== 'phone' && myDashGridWrapper.getAttribute('data-filter') === 'all' && myDashXValueReset){

        //this block only runs if there are new pinned items to be added to pre-existing x values
        //console.log('reAssess 2');
        myDashDataNew.xValues = $myDashGrid.packery( 'getShiftPositions', 'data-node' );
        myDashDataPost();
        myDashXValueReset = false;

    } else if(myDashGridWrapper.getAttribute('data-order') !== 'phone' && myDashGridWrapper.getAttribute('data-filter') !== 'all' && myDashXValueReset){

        //this block only runs if we are in a filtered view BUT there are new pinned items to add to pre-existing x values.
        //console.log('reAssess 3');

        //console.log('BEFORE:');
        //console.log(myDashDataNew.xValues);

        var screenXvalues = $myDashGrid.packery( 'getShiftPositions', 'data-node' );
        for (var i = 0; i < myDashLeftOvers.length; i++){
    		var thisNodeId = myDashLeftOvers[i].getAttribute('data-node');
    
    		for(var j = 0; j < screenXvalues.length; j++){

			    if(thisNodeId === screenXvalues[j].attr){
			        myDashDataNew.xValues.push({
			            attr: thisNodeId,
			            x: screenXvalues[j].x
			        });
			        //console.log('added ' + thisNodeId + ' to current X values.');
			        break;
			    }

    		}
        }
        //console.log('AFTER:');
        //console.log(myDashDataNew.xValues);

        myDashDataPost();
        myDashXValueReset = false;
        
    } else {
      //Do Nothing
    }
    return;
}





function fitAllBasedOnSavedXPercentages(cb){

  myDashFitting = true;
  $myDashGrid.data('packery').options.transitionDuration = "0.0s";
  $myDashGrid.packery('resetCoords');
  
  let matches = [];
  myDashLeftOvers = [];

  for(var i = 0; i < myDashShow.length; i++){
    let thisItem = myDashShow[i];
    
    for(var j = 0; j < myDashDataNew.xValues.length; j++){

        if(thisItem.getAttribute('data-node') === myDashDataNew.xValues[j].attr){
            matches.push({
                el: thisItem,
                x: myDashDataNew.xValues[j].x
            });
            break;
        }
        
        if(j === myDashDataNew.xValues.length - 1){
          myDashLeftOvers.push(thisItem);
        }

    }
  }

  //console.log('matches');
  //console.log(matches);


  var gridWidth = myDashGrid.clientWidth;
  var matchLoop = (matches.length > 0) ? (matches.length - 1) : 0;

  function trueLayoutComplete(){
      window.removeEventListener('layoutTrulyDone', trueLayoutComplete);
      if(cb) cb();
      return;
  }

  function trueFitComplete(){

      //console.log(matchLoop);
		  if(matchLoop === 0){
			  window.removeEventListener('fitTrulyDone', trueFitComplete);
        myDashFitting = false;
        window.addEventListener('layoutTrulyDone', trueLayoutComplete);
        $myDashGrid.packery('shiftLayout');
		    return;
		  }
		  matchLoop--;
	    fitAllMatches();
  }

  window.addEventListener('fitTrulyDone', trueFitComplete);

  function fitAllMatches(){
    //abort early if gridsize if off. Not worth the effort if we need to redo the positioning process anyway.
    let currentGridWidth = myDashGridWrapper.offsetWidth;
    if (currentGridWidth !== myDashGridStart){
      myDashFitting = false;
      window.removeEventListener('fitTrulyDone', trueFitComplete);
      if(cb) cb();
      return;
    }

    if(matches.length){

            let thisX = matches[matchLoop].x;
            let thisEl = matches[matchLoop].el;

            let percent;
                if(thisX > .4){
                    percent = Number(gridWidth * .66);
                } else if(thisX > .2) {
                    percent = Number(gridWidth * .33);
                } else {
                    percent = 0;
                }

            $myDashGrid.packery( 'fit', thisEl, percent, 0 );

    }else{
        myDashFitting = false;
        //console.log('no items');
        window.addEventListener('layoutTrulyDone', trueLayoutComplete);
        $myDashGrid.packery('shiftLayout');
        return;
    }
  }


  function leftoverStart(){
    window.removeEventListener('layoutTrulyDone', leftoverStart);
    //console.log('leftovers!!!');
    fitAllMatches();
    return;    
  }

  if(myDashLeftOvers.length){
  	myDashXValueReset = true;
    window.addEventListener('layoutTrulyDone', leftoverStart);
    $myDashGrid.packery('layout');
    return;
  }else{
    //setTimeout(function(){
		  fitAllMatches();
    //}, 100);
    
    return;
  }

}

function myDashGhostGridItems(state, drag){
	if(state === 'off'){
		for(var i = 0; i < myDashShow.length; i++){
			myDashShow[i].classList.remove('dragging');
            myDashShow[i].classList.remove('ghost');
        }		
	}else{
		for(var i = 0; i < myDashShow.length; i++){
            if(drag){
            	myDashShow[i].classList.add('dragging');
            }
            myDashShow[i].classList.add('ghost');
        }		
	}
}


function myDashPackeryLayout(cb){
   $myDashGrid.data('packery').options.transitionDuration = "0.0s";
    //This timeout is cleared if layout completes before time argument
    if(!myDashLoadingTimeout){
      myDashLoadingTimeout = setTimeout(function(){
        myDashLoading('on');
      }, 1000);
    }

    //steppers and flippers need accurate height before layout
    EventDispatcher.Custom({
        "el" : window,
        "event" : "resize"
    });

    if(!myDashDataNew.xValues.length || myDashGridWrapper.getAttribute('data-order') === 'phone'){
      $myDashGrid.one('layoutComplete', cb);
      $myDashGrid.packery('layout');
    }else{
        fitAllBasedOnSavedXPercentages(cb);
    }

}


function myDashZindexLayer(){

    var indexCounter = 1;
    for (var i = (myDashGridItems.length - 1); i >= 0; i--) {
        myDashGridItems[i].style.zIndex = indexCounter;
        indexCounter++;
    }
}


function myDashReorderDOM(gridItem){
  //If focused on or in a grid item, we need to reorder the DOM around that item so that focus is not lost.

  var hasFocus = document.activeElement ? document.activeElement : document.body;

  var gridItemFocused = hasFocus.classList.contains('packery-grid-item');
  var focusInGridItem = parentOfClass(hasFocus, 'packery-grid-item');

  var activeEl = null;

  if(gridItem && gridItem.classList.contains('packery-grid-item')){
  	activeEl = gridItem;
  }else if (gridItemFocused){
	activeEl = gridItemFocused;
  }else if(focusInGridItem){
  	activeEl = focusInGridItem;
  }

  if(activeEl){

	  var activeIndex = myDashGridItems.indexOf(activeEl) > -1 ? myDashGridItems.indexOf(activeEl) : null;
	  var inGrid = document.querySelector('[data-packery-grid] #' + activeEl.id);
  }

  if(activeIndex && inGrid){
  	
    var beforeArr = [];
    var afterArr = [];

    if(myDashGridItems.length > 1){

    	if(activeIndex > 0){
    		//before array
    		beforeArr = myDashGridItems.slice(0, activeIndex);
    	}

    	if(activeIndex < (myDashGridItems.length - 1)){
    		//after array
    		afterArr = myDashGridItems.slice((activeIndex + 1));
    	}

    }

    for(var i = 0; i < beforeArr.length; i++) {
    	if(myDashLimbo.indexOf(beforeArr[i]) === -1){
			myDashGrid.insertBefore(beforeArr[i], activeEl);
		}
    }

    for(var i = 0; i < afterArr.length; i++) {
    	if(myDashLimbo.indexOf(afterArr[i]) === -1){
		    myDashGrid.appendChild(afterArr[i]);
		}
    }
  } else {
  	//No active Index, just simple reorder
  	//console.log('simple reorder');
    for(var i = 0; i < myDashGridItems.length; i++) {
    	if(myDashLimbo.indexOf(myDashGridItems[i]) === -1){
          myDashGrid.appendChild(myDashGridItems[i]);
		  }
    }  	
  }

    myDashZindexLayer();

    //Need to communicate to certain nodes.
    EventDispatcher.Custom({
      "el" : myDashGridWrapper,
      "event" : "MyDashOrderChanged"
    });

}



function myDashJustDraggedItem(e) {

    var itemElems = $myDashGrid.packery('getItemElements');
    myDashGridItems = [];
    myDashShow = [];

    for (var i = 0; i < itemElems.length; i++) {
        myDashGridItems.push(itemElems[i]);
        myDashShow.push(itemElems[i]);
    }

	  myDashReorderDOM(myDashJustMoved);

    if(myDashGridWrapper.getAttribute('data-order') !== 'phone'){
      myDashDataNew.xValues = $myDashGrid.packery( 'getShiftPositions', 'data-node' );
    }

    /*
    Occasionally, Draggabilly allows you to drop items in positions which are not Packed yet. The result is a packed position on reload that may not match the intended saved position exactly. If we include this code, it would force a layout update after each drag, BUT this make things a little harder to use.
    */
    /*
	setTimeout(function () {
	    $myDashGrid.packery('reloadItems');
	    myDashLayout();
	}, 100);
	*/

}


function myDashDataSetup(data) {
    //Initially should be equal. Used to track changes.
    var dataString = JSON.stringify(data);
    myDashData = JSON.parse(dataString);
    myDashDataNew = JSON.parse(dataString);

    return;
}


function myDashPackeryInstall() {
    if ($myDashGrid) $myDashGrid.packery('destroy');

    $myDashGrid = $(myDashGrid).packery({
        itemSelector: '.packery-grid-item',
        columnWidth: '.packery-grid-sizer',
        gutter: '.packery-gutter-sizer',
        percentPosition: true,
        resize: false, //false to minimize moving of items
        initLayout: false
    });

    // draggable
    myDashDraggies = [];
    $myDashGrid.find('.packery-grid-item').each(function (i, gridItem) {
        var draggie = new Draggabilly(gridItem);
        myDashDraggies.push(draggie);// bind drag events to Packery

        $myDashGrid.packery('bindDraggabillyEvents', draggie);
        draggie.disable();//only enabled in edit mode
        draggie.unbindHandles();
        
        draggie.on( 'dragStart', function( e, pointer ) {
            myDashJustMoved = parentOfClass(e.target, 'packery-grid-item');
            //myDashJustMoved.focus();
        });

    });

    
    $myDashGrid.on('dragItemPositioned', myDashJustDraggedItem);

    myDashDataSetup(my_packery_data);

}


function myDashDataPost(postData) {

    $.ajax({
        method: "POST",
        url: "api/v1/dashboards/my",
        //async: false,
        dataType: "json",
        contentType: 'application/json',
        data: JSON.stringify(myDashDataNew),
        timeout: 3000

    }).done(function (data) {
        //console.log(data);

        if (data['Status'] === 'Success') {
            //WS CALL SUCCESSFUL
            myDashDataSetup(data['Result']);

            if(postData && postData.swalSuccess){
            	postData.swalSuccess();
            }

        } else {
            //WS CALL RETURNED AN ERROR
            if(postData && postData.swalFail){
            	postData.swalFail();
            }

        }

    }).fail(function () {
        //WS CALL FAILED
        if(postData && postData.swalFail){
            postData.swalFail();
        }
    });

    return;

}


function myDashMoveBtn(options){

	var moveBtns = document.querySelectorAll('button[data-move]');
    var moveBtn;

    if (window.matchMedia("(min-width: 750px)").matches) {
        moveBtn = moveBtns[(moveBtns.length - 1)];
    } else {
        moveBtn = moveBtns[0];
    }

    if(options){
    	var thisEvent = options.event ? options.event : null;
    	var thisDetail = options.detail ? options.detail : {};
    	var thisFocus = options.focus ? options.focus : null;

    	var thisFocusMove = options.focusMove === false ? false : true;

    	if(thisDetail && thisDetail.focus){
    		//already defined in detail
    	}else if(thisFocus){
    		thisDetail.focus = thisFocus;
    	}else if(!thisFocusMove){
    		thisDetail.focus = null;
    	}else if(thisFocusMove){
			thisDetail.focus = moveBtn;
    	}

    	//console.log(thisDetail);

    }

    if(thisEvent){

        EventDispatcher.Custom({
          "el" : moveBtn,
          "event" : thisEvent,
          "detail" : thisDetail
        });

	}

    return moveBtn;
}







// Get CURRENT Info
function myDashCurrentInfo(){
	var data = {};

    //Called when moving items is complete, this saves the current order to the database
    data.domOrder = [];
    var itemElems = $myDashGrid.packery('getItemElements');

    $(itemElems).each(function (i, itemElem) {

        var thisNodeId = Number(itemElem.getAttribute('data-node'));
        data.domOrder.push(thisNodeId);

    });

    data.layout = myDashGridWrapper.getAttribute('data-layout');
    data.order = myDashGridWrapper.getAttribute('data-order');
    data.filter = myDashGridWrapper.getAttribute('data-filter');

    var moveBtns = document.querySelectorAll('button[data-move]');
    var bookmarkBtns = document.querySelectorAll('button[data-bookmark]');


    if (window.matchMedia("(min-width: 750px)").matches) {
        data.device = 'desktop';
        data.moveBtn = moveBtns[(moveBtns.length - 1)];
        data.bookmarkBtn = bookmarkBtns[(bookmarkBtns.length - 1)];
    } else {
        data.device = 'phone';
        data.moveBtn = moveBtns[0];
        data.bookmarkBtn = bookmarkBtns[0];
    }

    if (data.layout === 'list') {
        data.updateKey = 'order_phone'
    } else {
        data.updateKey = 'order_' + data.device;
    }


    data.changed = false;

    if (JSON.stringify(myDashData[data.updateKey]) !== JSON.stringify(data.domOrder)) {
        data.changed = true;
    }

    if (JSON.stringify(myDashData.xValues) !== JSON.stringify(myDashDataNew.xValues)) {
        data.changed = true;
    }

	return data;
}




//Interactivity Essentials

function myDashDisableACTIONS(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
}

function myDashFreezeControls() {
    myDashTitleBar.addEventListener('keydown', myDashDisableACTIONS, true);
    myDashTitleBar.addEventListener('mousedown', myDashDisableACTIONS, true);
    myDashTitleBar.addEventListener('click', myDashDisableACTIONS, true);
}


function myDashThawControls() {
    myDashTitleBar.removeEventListener('keydown', myDashDisableACTIONS, true);
    myDashTitleBar.removeEventListener('mousedown', myDashDisableACTIONS, true);
    myDashTitleBar.removeEventListener('click', myDashDisableACTIONS, true);
}

function myDashToolTip(el, state) {
    if (el.hasAttribute('data-tooltip')) {

        var thisTip = document.getElementById(el.getAttribute('data-toggle'));
        if (state === 'on') {
            thisTip.style.display = 'none';
            thisTip.classList.remove('hide');
        } else if (state === 'off') {
            thisTip.style.display = 'none';
            thisTip.classList.add('hide');
        }

    }
    return;
}

function myDashLoading(state) {
    var myDashControls = myDashTitleBar.querySelectorAll('button, select');

    switch (state) {
        case 'on':
            myDashFreezeControls();

            for (var i = 0; i < myDashControls.length; i++) {
                myDashControls[i].classList.add('disabled');
                myDashToolTip(myDashControls[i], 'off');
            }

            myDashGridWrapper.classList.add('loading');
            break;
        case 'off':

            for (var i = 0; i < myDashControls.length; i++) {
                if (myDashControls[i].hasAttribute('data-disabled')) continue;
                myDashControls[i].classList.remove('disabled');
                myDashToolTip(myDashControls[i], 'on');
            }

            myDashGridWrapper.classList.remove('loading');
            myDashThawControls();

            break;
        default:

    }
}

function myDashLayoutAfter(){
setTimeout(function () {

    myDashGridEnd = myDashGridWrapper.offsetWidth;
    //console.log('FINISH');

    if (myDashGridEnd !== myDashGridStart){
      //console.log('starting width: ' + myDashGridStart);
      //console.log('ending width: ' + myDashGridEnd);
      //console.log('The width was changing during update');
    }

    if(myDashOrientationChanged){
      //console.log('Orientation changed during update');
    }

    if(myDashContentChanged){
      //console.log('Content changed during update');
    }
    

    if (myDashGridEnd !== myDashGridStart || myDashOrientationChanged || myDashContentChanged) {

        //console.log("REDO!");
        //won't fire without timeout...
        setTimeout(function () {
            myDashLayout();
        }, 100);

    } else {

        reAssessRecordedXValues();
        //console.log("GOOD!");
        clearTimeout(myDashLoadingTimeout);
        myDashLoadingTimeout = null;
        myDashProcessing = false;
        myDashThawControls();
        $myDashGrid.data('packery').options.transitionDuration = "0.4s";
        myDashGhostGridItems('off');
        myDashLoading('off');
        //console.log('loading off');
        window.addEventListener('resize', myDashResize);
    }

}, 100);
}

function myDashLayout(){
    if(!myDashInstalled || myDashTriggered) return;
    //This won't run if currently resizing
    myDashProcessing = true;
    myDashContentChanged = false;
    myDashOrientationChanged = false;
    myDashGridStart = myDashGridWrapper.offsetWidth;
    //console.log('START');
    myDashFreezeControls();
    myDashPackeryLayout(myDashLayoutAfter);
}


// FILTERING

var filterFns = {
    all: function (gridItem) {
        return true;
    },
    links: function (gridItem) {
        return $(gridItem).find('article').hasClass('node-extended-link');
    },
    student: function (gridItem) {
        return !_.isEmpty(_.intersection([
            'page_student_dashboard_optimized__panel',
            'page_finances__panel',
            'page_career_services__panel',
            'page_student_resources__panel',
            'page_transfer_kent_state__panel_context_860c3467-1b03-4b7f-ba1e-5dc037f6699d'
        ], _.split( $(gridItem).find('article[data-categories]').attr('data-categories'), ' ')));
    },
    employee: function (gridItem) {
        return !_.isEmpty(_.intersection([
            'page_employee_dashboard__panel',
            'page_employee_resources__panel',
            'page_cognosreports__panel',
            'page_employee_workflows__panel',
        ], _.split( $(gridItem).find('article[data-categories]').attr('data-categories'), ' ')));
    },
    advisor: function (gridItem) {
        return !_.isEmpty(_.intersection([
            'page_advisor_dashboard__panel',
            'page_resources_faculty__panel',
        ], _.split( $(gridItem).find('article[data-categories]').attr('data-categories'), ' ')));
    },
    faculty: function (gridItem) {
        return !_.isEmpty(_.intersection([
            'page_faculty_dashboard__panel',
            'page_resources_faculty__panel'
        ], _.split( $(gridItem).find('article[data-categories]').attr('data-categories'), ' ')));
    },
    welcome: function (gridItem) {
        return !_.isEmpty(_.intersection([
            'page_welcome__panel'
        ], _.split( $(gridItem).find('article[data-categories]').attr('data-categories'), ' ')));
    }
};


function myDashFilterChange(e) {
    var thisValue = this.value ? this.value : 'all';
    myDashGhostGridItems('on');
    //filter change delay for ghost animation
    setTimeout(function(){
		myDashFilterSet(thisValue);
    }, 300);
}

function myDashFilterSet(filter) {

    var setFilter = filter ? filter : 'all';
    var options = myDashFilter.options;
    var validFilter;

    while(!validFilter) {
      // 1-7-2020 We now only show certain options if user has that role. If a saved filter is no longer available, we need to set fallback.

      for (var i = 0; i < options.length; i++) {
        if (options[i].value === setFilter) {
          validFilter = true;
          console.log('found filter option');
          myDashFilter.selectedIndex = i;
          break;
        }
        if (i === (options.length - 1)) {
          //invalid, set to fallback
          setFilter = 'all';
          myDashDataNew['current_filter'] = setFilter;
          myDashDataPost();
          console.log('couldn\'t find filter option. Setting fallback...');
        }
      }

    }

    myDashGridWrapper.setAttribute('data-filter', setFilter);
    var filterValue = filterFns[setFilter];

    myDashShow = [];
    myDashLimbo = [];

    for(var i = 0; i < myDashGridItems.length; i++){

      var thisItem = myDashGridItems[i];
      var showMe = filterValue(thisItem);

      if(showMe){
        myDashShow.push(thisItem);
        myDashGrid.appendChild(thisItem);
      }else{
        //HIDE
          myDashLimbo.push(thisItem);
          myDashGridWrapper.appendChild(thisItem);
      }


    }

    //console.log(myDashShow);
    //console.log(myDashLimbo);
    

    for (var i = 0; i < myDashMoveBtns.length; i++) {
        if (setFilter === 'all' && myDashGridItems.length) {
            myDashMoveBtns[i].classList.remove('disabled');
            myDashMoveBtns[i].removeAttribute('data-disabled');
            myDashToolTip(myDashMoveBtns[i], 'on'); 
        } else {
            myDashMoveBtns[i].classList.add('disabled');
            myDashMoveBtns[i].setAttribute('data-disabled', 'true');
            myDashToolTip(myDashMoveBtns[i], 'off'); 
        }
    }

    for (var i = 0; i < myDashBookmarkBtns.length; i++) {
        if (setFilter === 'all') {
            myDashBookmarkBtns[i].classList.remove('disabled');
            myDashBookmarkBtns[i].removeAttribute('data-disabled');
            myDashToolTip(myDashBookmarkBtns[i], 'on'); 
        } else {
            myDashBookmarkBtns[i].classList.add('disabled');
            myDashBookmarkBtns[i].setAttribute('data-disabled', 'true');
            myDashToolTip(myDashBookmarkBtns[i], 'off'); 
        }               
    }

    $myDashGrid.packery('reloadItems');


    //won't fire without timeout...
    setTimeout(function () {
		EventDispatcher.Custom({
          "el" : myDashGridWrapper,
          "event" : "MyDashFilterChanged"
        });
        for(var i = 0; i < myDashLimbo.length; i++){
            myDashLimbo[i].classList.add('limbo');
        }
        for(var i = 0; i < myDashShow.length; i++){
            myDashShow[i].classList.remove('limbo');
        }
        myDashInstructionEval();
        //EventDispatcher.ResizeWindow();
        if(!myDashInstalled){
            myDashPackeryLayout(myDashAfterResize);
        }else{
            myDashLayout();
            //Anytime the filter is changed by the user
            myDashDataNew['current_filter'] = setFilter;
            myDashDataPost();
        }
    }, 100);


}










// ORDERING

function myDashOrderToDisplay(){
    //figure out appropriate order to display based on screen size and layout
    var currentDevice;
    var listView = (myDashGridWrapper.getAttribute('data-layout') === 'list');

    if (window.matchMedia("(min-width: 750px)").matches) {
        currentDevice = 'desktop';
    } else {
        currentDevice = 'phone';
    }

    var newOrder;
    //determine if user has saved order, if not, use any other saved order before defaulting to none
    switch (currentDevice) {
        case 'phone':
            if (myDashData['order_phone']) {
                newOrder = 'phone';
            } else if (myDashData['order_desktop']) {
                newOrder = 'desktop';
            } else {
                newOrder = 'none';
            }
            break;
        case 'desktop':
        default:
            if (listView) {
                if (myDashData['order_phone']) {
                    newOrder = 'phone';
                } else if (myDashData['order_desktop']) {
                    newOrder = 'desktop';
                } else {
                    newOrder = 'none';
                }

            } else {
                //GRIDVIEW
                if (myDashData['order_desktop']) {
                    newOrder = 'desktop';
                } else if (myDashData['order_phone']) {
                    newOrder = 'phone';
                } else {
                    newOrder = 'none';
                }
            }
    }//SWITCH END

    return newOrder;

}


function myDashChangeOrder(device) {
    myDashGridWrapper.setAttribute('data-order', device);

    var newOrder = myDashData[('order_' + device)];

    myDashGridItems = [];
    myDashShow = [];

    //Reorder in DOM
    for (var i = 0; i < newOrder.length; i++) {

        var gridItem;
        let inLimbo;

        for(var j = 0; j < myDashLimbo.length; j++){
          if(myDashLimbo[j].id === ('grid_' + newOrder[i])){
            inLimbo = true;
            gridItem = myDashLimbo[j];
            myDashGridItems.push(gridItem);
            break;
          }
        }

        //should be true if inLimbo is undefined
        if(!inLimbo){
          gridItem = document.querySelector('#grid_' + newOrder[i]);
          //verify this item exists
          if(gridItem){
            myDashGridItems.push(gridItem);
            myDashShow.push(gridItem);
          }
        }
    }

    myDashReorderDOM();

    $myDashGrid.packery('reloadItems');
    
    if(!myDashInstalled){
        //initial run-through
        var startingFilter = myDashData['current_filter'] ? myDashData['current_filter'] : 'all';
        myDashFilterSet(startingFilter);
    }else {
        myDashPackeryLayout(myDashAfterResize);
    }

}


//Accessibility During Edit Mode
function myDashTabbableGrid(state){
    var allTabbable;

    switch (state) {
        case 'on':
            allTabbable = myDashGridWrapper.querySelectorAll('.packery-grid-item a, .packery-grid-item button, .packery-grid-item input, .packery-grid-item select, .packery-grid-item textarea, .packery-grid-item iframe, .packery-grid-item [tabindex]:not(svg), .packery-grid-item area');
            for (var i = 0; i < allTabbable.length; i++) {
                allTabbable[i].setAttribute('tabindex', '-1');
            }
            /*
            for (var i = 0; i < myDashGridItems.length; i++) {
                myDashGridItems[i].setAttribute('tabindex', '0');
            }
            */
        break;
        case 'off':
            allTabbable = myDashGridWrapper.querySelectorAll('.packery-grid-item a, .packery-grid-item button, .packery-grid-item input, .packery-grid-item select, .packery-grid-item textarea, .packery-grid-item iframe, .packery-grid-item [tabindex], .packery-grid-item area');
            for (var i = 0; i < allTabbable.length; i++) {
                allTabbable[i].removeAttribute('tabindex');
            }
            /*
            for (var i = 0; i < myDashGridItems.length; i++) {
                myDashGridItems[i].removeAttribute('tabindex');
            }
            */
        break;
        default:
    }   


}


function myDashKeyboardMove(e){

    if (myDashProcessing || !myDashGridWrapper.classList.contains('editing')) return;
    //Can only move in edit mode while Packery is not working

    var gridItem = e.target;
    var itemIndex = myDashGridItems.indexOf(gridItem);
    //console.log(e);
    var browserKey = (e.which) ? e.which : e.keyCode;
    //37 left arrow, 39 right arrow
    var arrowKeys = [37, 39];
    var isArrowKey = (arrowKeys.indexOf(browserKey) !== -1);

    if(isArrowKey){

        var prevEl;
        var nextEl;

        switch(browserKey) {
          case 37:
            
            if(gridItem === myDashGridItems[0]){
                //Do Nothing
                //alert('First Item!');
            }else{
                prevEl = myDashGridItems[(itemIndex - 1)];
                nextEl = gridItem.nextElementSibling;

                myDashGridItems[itemIndex] = prevEl;
                myDashGridItems[(itemIndex - 1)] = gridItem;

                //we don't want the focused item to move in the DOM because it will lose focus and the keyboard help text will blink.
                if(nextEl){
                    myDashGrid.insertBefore(prevEl, nextEl);
                }else{
                    myDashGrid.appendChild(prevEl);
                }

                myDashZindexLayer();
                $myDashGrid.packery('reloadItems');
                myDashLayout();
                
            }
          break;    
          case 39:
            if(gridItem === myDashGridItems[(myDashGridItems.length - 1)]){
                //Do Nothing
                //alert('Last Item!');
            }else{
                nextEl = myDashGridItems[(itemIndex + 1)];

                myDashGridItems[itemIndex] = nextEl;
                myDashGridItems[(itemIndex + 1)] = gridItem;

                myDashGrid.insertBefore(nextEl, gridItem);

                myDashZindexLayer();
                $myDashGrid.packery('reloadItems');
                myDashLayout();
                
            }
          break;
          default:
        }
    }

}































//Disabled Messages

function myDashDisabledDuringMove(e) {
    var thisTarget = e.currentTarget;
    if (thisTarget.hasAttribute('data-disabled') && myDashGridWrapper.classList.contains('editing')) {

            if (thisTarget === myDashFilter) {
                switch (e.type) {
                    case 'keydown':

                    	var browserKey = (e.which) ? e.which : e.keyCode;

                        //Shift, Tab, Escape
                        var allowKeys = [16, 9, 27];
                        var inAllowKeys = (allowKeys.indexOf(browserKey) !== -1);
                        
                        if(!inAllowKeys){
                        	e.preventDefault();
                        	e.stopPropagation();
                        }

                        //Enter, Space, ArrowUp, ArrowDown
                        var alertKeys = [13, 32, 38, 40];
                        var inAlertKeys = (alertKeys.indexOf(browserKey) !== -1);

                        //Exit if we don't need to show a sweet alert
                        if (!inAlertKeys) return;

                        break;
                    case 'mousedown':
                    case 'click':
                    default:
			            e.stopPropagation();
			            e.preventDefault();                        
                }
            }


            var doneNow = false;

            swal2Btns({
                showCloseButton: true,
                closeButtonAriaLabel: '',
                stopKeydownPropagation: false,
                buttonsStyling: false,
                confirmButtonClass: 'button show-focus',
                cancelButtonClass: 'button',
                title: 'Currently Moving Items',
                html: 'Click <strong>Done Editing</strong> when you are finished.',
                type: 'info',
                confirmButtonText: "Okay",
                cancelButtonText: "I'm Done Editing Now",
                focusConfirm: true,
                onBeforeOpen: function onBeforeOpen() {
                    sweetAlertCustom();

                    //ie11
                    if(document.activeElement) document.activeElement.blur();
                    
                    for (var i = 0; i < myDashMoveBtns.length; i++) {
                        myDashMoveBtns[i].classList.add('fl-show-user');
                    }
                },
                onAfterClose: function () {
                //Timeout needed for ie11
                setTimeout(function(){

                    if(doneNow){

                        if(document.documentElement.getAttribute('data-whatinput') === 'keyboard'){
                            myDashMoveBtn({
                            	event: 'click',
                            	focus: thisTarget
                            });
                        }else{
                            myDashMoveBtn({
                            	event: 'click'
                            });
                        }

                    }else{
                        thisTarget.focus();
                    }

                    //either way, remove show-user class
                    for (var i = 0; i < myDashMoveBtns.length; i++) {
                        myDashMoveBtns[i].classList.remove('fl-show-user');
                    } 
                }, 100);
                }
            }).then(function(result){
                if(result.dismiss == 'cancel'){
                   doneNow = true;
                }
            });

            return false;

    }
}



function myDashDisabledWhenNoItems(e){
  var thisTarget = e.currentTarget;


  if (thisTarget.hasAttribute('data-disabled') && !myDashGridItems.length){

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        swal2({
            customClass: '',
            title: 'No Pinned Items',
            html: "Add items to My Dashboard by clicking the pin icon <span class='dripicons-pin fl-icon inline-icon'></span> wherever you see it throughout FlashLine. Then, you can use this button to move them around your screen.",
            type: 'info',
            focusConfirm: true,
            onBeforeOpen: function onBeforeOpen() {
                sweetAlertCustom();

                for (var i = 0; i < myDashMoveBtns.length; i++) {
                    myDashMoveBtns[i].classList.add('fl-show-user');
                }
                
            },
            onAfterClose: function () {
            //Timeout needed for ie11
            setTimeout(function(){ 
                for (var i = 0; i < myDashMoveBtns.length; i++) {
                    myDashMoveBtns[i].classList.remove('fl-show-user');
                }
            }, 100);
            }
        });        


  }
}


function myDashDisabledDuringFilter(e){
  var thisTarget = e.currentTarget;
  if (thisTarget.hasAttribute('data-disabled') && myDashGridWrapper.getAttribute('data-filter') !== 'all'){

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        var customMsg;
        if(thisTarget.hasAttribute('data-move')){
            customMsg = 'moving';
        }else if(thisTarget.hasAttribute('data-bookmark')){
            customMsg = 'adding bookmarks';
        }

        var filterText = myDashFilter.options[myDashFilter.selectedIndex].text;

        var resetFilter = false;

        swal2Btns({
            customClass: '',
            title: 'Filter On',
            html: 'The <strong>' + filterText + '</strong> filter is turned on. Turn off filter to allow ' + customMsg + '?',
            type: 'info',
            confirmButtonText: "Yes, Turn Off Filter",
            cancelButtonText: "No",
            showLoaderOnConfirm: true,
            focusConfirm: true,
            onBeforeOpen: function onBeforeOpen() {
                sweetAlertCustom();

                //ie11
                if(document.activeElement) document.activeElement.blur();

                myDashFilter.classList.add('fl-show-user');
            },
            onAfterClose: function () {
            //timeout needed for ie11
            setTimeout(function(){

                if(resetFilter){

    				myDashGhostGridItems('on');
    				//filter change delay for ghost animation
    				setTimeout(function(){

                      myDashFilterSet('all');
                      if(document.documentElement.getAttribute('data-whatinput') === 'keyboard'){
                          thisTarget.focus();
                      }else{
                          myDashFilter.focus();
                      }
                      myDashFilter.classList.remove('fl-show-user');

					}, 300);

                }else{
                    thisTarget.focus();
                    if(!myDashGridWrapper.classList.contains('no-items')){
						myDashFilter.classList.remove('fl-show-user');
                    }
                }
            }, 100);
            },
            preConfirm: function () {
                return new Promise(function (resolve) {
                    //use ball fall animation instead of spinner
                    sweetAlertUseBallFall();
                    resetFilter = true;
                    resolve();
                });
            }
        });


  }
}



function myDashInstructionEval() {
    var hasItems = (Number(myDashGridItems.length) > 0);
    var filterEmpty = (Number(myDashLimbo.length) === Number(myDashGridItems.length));
    var filter = myDashGridWrapper.getAttribute('data-filter');

    if(hasItems && !filterEmpty){
        //There ARE items showing
        myDashGridWrapper.classList.remove('no-items');
        myDashFilter.classList.remove('fl-show-user');
        return;
    }

    var myDashInstruction = document.getElementById('mydash-instruction');
    var titleBox = myDashInstruction.querySelector('[data-title]');
    var msgBox = myDashInstruction.querySelector('[data-msg]');
    var footerBox = myDashInstruction.querySelector('[data-footer]');
    var title;
    var msg;
    var showFooter;

    if(!hasItems && filter === 'all'){
        title = '<h3>No Pinned Items</h3>';
        msg = '<p>You can place your own content here by clicking the pin icon <span class="dripicons-pin fl-icon inline-icon"></span> wherever you see it throughout FlashLine!</p>';
        showFooter = false;

    }else if(filterEmpty){ 
        title = '<h3>No Pinned ' + myDashFilter[myDashFilter.selectedIndex].text + '</h3>';
        msg = '<p>You don\'t have any pinned items that match the current <strong>' + myDashFilter[myDashFilter.selectedIndex].text + '</strong> filter.</p><p>Remember, you can pin content by clicking the pin icon <span class="dripicons-pin fl-icon inline-icon"></span> wherever you see it throughout FlashLine!</p>';
        showFooter = true;

    }


    function updateInstructions() {
        //actually switch out content
        titleBox.innerHTML = title;
        msgBox.innerHTML = msg;

        if(showFooter){
            footerBox.style.display = 'block';
            myDashFilter.classList.add('fl-show-user');
        }else{
            footerBox.style.display = 'none';
            myDashFilter.classList.remove('fl-show-user');
        }

        //Make sure move buttons are disabled if not already
		for (var i = 0; i < myDashMoveBtns.length; i++) {
			if(!myDashMoveBtns[i].getAttribute('data-disabled')){
	            myDashMoveBtns[i].classList.add('disabled');
	            myDashMoveBtns[i].setAttribute('data-disabled', 'true');
	            myDashToolTip(myDashMoveBtns[i], 'off');
	        } 
		}
    }

    var instructionFade = document.querySelector('#mydash-instruction [data-instruction]');
    var alreadyShowing = myDashGridWrapper.classList.contains('no-items');

    //This ensures the correct animation to play whether this piece is appearing for the first time, or already showing but needs to update its content
    if(alreadyShowing){
        $(instructionFade).fadeOut(function(){
            updateInstructions();
            $(instructionFade).fadeIn();
        });
    }else{
        updateInstructions();
        myDashGridWrapper.classList.add('no-items');
    }

}


function myDashremoveFromArray(removeThis, fromArray){
    var newArray = [];

    for(var i = 0; i < fromArray.length; i++){
        if(removeThis !== fromArray[i]){
            newArray.push(fromArray[i]);
        }
    }
    return newArray;
}

function myDashRemoveItem(data, id){
    //If this is running, unpinning AJAX was already successful

    //Clean up Data
    //console.log(data['Result']);
    myDashDataSetup(data['Result']);

    if (id === 5191){
        for (var i = 0; i < myDashBookmarkBtns.length; i++) {
            myDashBookmarkBtns[i].classList.remove('hide');
        }        
    }

    var el = document.querySelector('#grid_' + id);
    if(el){
        myDashGridItems = myDashremoveFromArray(el, myDashGridItems);
        myDashShow = myDashremoveFromArray(el, myDashShow);
        myDashGridWrapper.appendChild(el);
    }

    $myDashGrid.packery('reloadItems');

    setTimeout(function () {
      el.classList.add('limbo');
    }, 100); 


    swal2({
        customClass: 'pinnedswal',
        title: "Unpinned",
        text: "You unpinned this content from your dashboard page! Items may float upwards to fill empty space.",
        type: "success",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Close",
        focusCancel: "true",
        onBeforeOpen: function onBeforeOpen() {
            sweetAlertCustom();
            if(document.activeElement) document.activeElement.blur();

            //myDashLoading('on');
            
            //setTimeout(function(){
				//myDashGhostGridItems('on');
			//}, 300);
        },
        onAfterClose: function () {
        //timeout needed for ie11
        myDashGhostGridItems('on');
        myDashGridStart = myDashGridWrapper.offsetWidth;
        window.removeEventListener('resize', myDashResize);
        setTimeout(function(){
            //Layout must be set AFTER close. Sweetalert Overlay messes up Packery.
            myDashPackeryLayout(myDashLayoutAfter);
            myDashInstructionEval();
            if(document.documentElement.getAttribute('data-whatinput') === 'keyboard'){
                myDashMoveBtn().focus();
            }
        }, 300);         
        }
    });

}


function myDashAfterResize() {

var delayStart = 100;
var delayReRun = 0;

if(myDashTriggered){
  //need more delay if resizing browser window
  delayStart = 200;
  delayReRun = 500;   
}

setTimeout(function () {

    myDashGridEnd = myDashGridWrapper.offsetWidth;
    //console.log('FINISH');

    if (myDashGridEnd !== myDashGridStart){
      //console.log('starting width: ' + myDashGridStart);
      //console.log('ending width: ' + myDashGridEnd);
      //console.log('The width was changing during update');
    }

    if(myDashOrientationChanged){
      //console.log('Orientation changed during update');
    }

    if(myDashContentChanged){
      //console.log('Content changed during update');
    }

    if (myDashGridEnd !== myDashGridStart || myDashOrientationChanged || myDashContentChanged) {

        //console.log("REDO!");
        setTimeout(function () {
            myDashResizeDelay();
        }, delayReRun);

    } else {

		    reAssessRecordedXValues();    
        //console.log('GOOD!');
        clearTimeout(myDashLoadingTimeout);
        myDashLoadingTimeout = null;
        myDashProcessing = false;
        myDashTriggered = false;

        $myDashGrid.data('packery').options.transitionDuration = "0.4s";
        myDashGhostGridItems('off');
        myDashLoading('off');
        //console.log('loading off');
        window.addEventListener('resize', myDashResize);

        //Tablet and Mobile orientation change
        //we cannot rely on window resize firing for all devices
        window.addEventListener('orientationchange', function(){
            //console.log('orientation change');
            //important to set this flag if a resize is already in progress
            myDashOrientationChanged = true;
            myDashResize();
        });

        if(!myDashInstalled){ 
            //Installation Finished
            var myDashControls = myDashTitleBar.querySelectorAll('button, select');
            for (var i = 0; i < myDashControls.length; i++) {
                myDashControls[i].removeAttribute('disabled');
            }

            for (var i = 0; i < myDashGridItems.length; i++) {
                myDashGridItems[i].addEventListener('keydown', myDashKeyboardMove);
            }

            myDashGridWrapper.classList.add('installed');
            myDashInstalled = true;    

              //myDashboard throws this event when it finishes installing. The Class schedule is an example of a node that listens for this. Node 5831.
              EventDispatcher.Custom({
                "el" : myDashGridWrapper,
                "event" : "dashInstalled"
              });

        }
    }


}, delayStart);

return;

}


function myDashResizeDelay() {
    myDashProcessing = true;
    myDashContentChanged = false;
    myDashOrientationChanged = false;
    myDashGridStart = myDashGridWrapper.offsetWidth;
    //console.log('START');
    myDashFreezeControls();

    //figure out appropriate order to display based on screen size and layout
    var newOrder = myDashOrderToDisplay();

    var currentOrder = myDashGridWrapper.getAttribute('data-order');
    if (newOrder !== currentOrder) {
        //need to change order
        myDashChangeOrder(newOrder);
    } else {
        //no change necessary
        myDashPackeryLayout(myDashAfterResize);
    }

    return;
}


function myDashResize(e) {
    //Only runs if not already triggered by window resizing
    //console.log('Resize Event');
    if (!myDashTriggered) {

        //exit immediately if vertical resize
        if (e && e.type === 'resize' && myDashGridWrapper.offsetWidth === myDashGridStart) {
            return;
        }

        //horizontal resize
        window.removeEventListener('resize', myDashResize);
        myDashTriggered = true;
        myDashLoading('on');
        myDashGhostGridItems('on', 'drag');
        //$myDashGrid.data('packery').options.transitionDuration = "0.0s";

        //set to 500 intentionally to minimize calls during window resizing
        setTimeout(function () {
          myDashResizeDelay();
        }, 500);
    }
    return;
}

function myDashToggleLayout(e) {
//Runs if not disabled or the active layout
    if (!this.hasAttribute('data-disabled') && !this.classList.contains('active')) {

        var newLayout;

        if (this === myDashGridBtn) {
            newLayout = 'grid';
            myDashListBtn.classList.remove('active');

        } else if (this === myDashListBtn) {
            newLayout = 'list';
            myDashGridBtn.classList.remove('active');  

        }

		myDashGhostGridItems('on');
    	//filter change delay for ghost animation
    	setTimeout(function(){
	        myDashGridWrapper.setAttribute('data-layout', newLayout);
	        this.classList.add('active');

	        myDashResizeDelay();

	        //Anytime the layout is changed by the user
	        myDashDataNew['current_layout'] = newLayout;
	        myDashDataPost();
    	}.bind(this), 300);

    }
}


function myDashBookmarkBtnClick(e){

    if (!this.hasAttribute('data-disabled')) {

        $.ajax({
        method: 'POST',
        url: "/api/v1/dashboards/my/pin",
        dataType: "json",
        contentType: 'application/json',
        data: JSON.stringify({"node": 5191})
        }).done(function (data) {
            if (data['Status'] === 'Success' && data['Message'] === 'Successful Add') {
                location.reload();
            } else {
               //swal2("Error", "Please try again.", "error");
            }
        }).fail(function () {
                //swal2("Error", "Please try again.", "error");
        });

    }//End of if not disabled
}


function myDashMoveBtnClick(e) {

if (!this.hasAttribute('data-disabled')) {

	//When triggered programatically, look for instructions in e.detail
	var sendFocus = null;
	var saveOrder = true;
	var currentInfo = null;
	//console.log(e);
	if(e.detail && e.detail.focus){
	  sendFocus = e.detail.focus;
	}
	if(e.detail && e.detail.save === false){
	  saveOrder = false;
	}
	if(e.detail && e.detail.current){
	  currentInfo = e.detail.current;
	}


    if (myDashGridWrapper.classList.contains('editing')) {
        //Shut Off Editing
        document.removeEventListener('keydown', myDashEscape);

        myDashTabbableGrid('off');
        //document.body.classList.remove('editing');
        myDashGridWrapper.classList.remove('editing');
        myDashFilter.classList.remove('disabled');
        myDashFilter.removeAttribute('data-disabled');
        myDashGridBtn.classList.remove('disabled');
        myDashGridBtn.removeAttribute('data-disabled');
        myDashToolTip(myDashGridBtn, 'on');
        myDashListBtn.classList.remove('disabled');
        myDashListBtn.removeAttribute('data-disabled');
        myDashToolTip(myDashListBtn, 'on');
        for (var i = 0; i < myDashBookmarkBtns.length; i++) {
            myDashBookmarkBtns[i].classList.remove('disabled');
            myDashBookmarkBtns[i].removeAttribute('data-disabled');
            myDashToolTip(myDashBookmarkBtns[i], 'on');        
        }

        //Button Update
        for (var i = 0; i < myDashMoveBtns.length; i++) {
            var btnIcon = myDashMoveBtns[i].querySelector('span[data-btn-icon]');
            var btnText = myDashMoveBtns[i].querySelector('span[data-btn-text]');
            btnText.textContent = 'Move';
            btnIcon.className = 'dripicons-move';
            myDashMoveBtns[i].setAttribute('aria-label', 'Enable moving by drag or arrow keys');
        }

        myDashDraggies.forEach(function (draggie) {
            draggie.disable();
            draggie.unbindHandles();
        });

        if(saveOrder){
        	if(!currentInfo) currentInfo = myDashCurrentInfo();

		    if (currentInfo.changed) {
		        //Order Changed, go ahead and update
		        myDashDataNew[currentInfo.updateKey] = currentInfo.domOrder;

		        var swalLayoutText;
		        switch (currentInfo.layout) {
		          case 'list':
		                swalLayoutText = '<strong class="mydash-swal-layout-text">List View <span class="dripicons-menu fl-icon inline-icon"></span></strong>';
		            break;
		          case 'grid':
		            if(currentInfo.device === 'phone'){
		                swalLayoutText = '<strong class="mydash-swal-layout-text">List View <span class="dripicons-menu fl-icon inline-icon"></span></strong>';
		            }else{
		                swalLayoutText = '<strong class="mydash-swal-layout-text">Grid View <span class="dripicons-view-thumb fl-icon inline-icon"></span></strong>';
		            }
		            break;
		          default:
		            swalLayoutText = 'view';
		        }        

		        var postData = {};
		        postData.swalSuccess = function (){
			    	swal2({
				        title: 'Saved Layout',
				        html: "Your " + swalLayoutText + " has saved. Items may float upwards to fill empty space.",
				        type: 'success',
				        focusConfirm: true,
				        onBeforeOpen: function onBeforeOpen() {
				        	sweetAlertCustom();

				        	//myDashLoading('on');

							if(sendFocus){ 
                               if(document.activeElement) document.activeElement.blur();
                            }

				        	//setTimeout(function(){
				        		//myDashGhostGridItems('on');
				        	//}, 300);
				        	
				        },
				        onAfterClose: function () {
				        //myDashLoading('on');
                myDashGhostGridItems('on');
                        //Timeout needed for ie11
                        setTimeout(function(){ 
                        	myDashPackeryLayout(myDashLayoutAfter);
					    	if(sendFocus) {
                                sendFocus.focus();
                            }
                        }, 300);
				        }
				    });
				};

		        postData.swalFail = function (){
			    	swal2({
				        title: 'Error',
				        html: "The update to your " + swalLayoutText + " was not saved. Please try again later.",
				        type: 'error',
				        focusConfirm: true,
				        onBeforeOpen: function onBeforeOpen() {
				            sweetAlertCustom();
				            if(sendFocus){
                                //ie11
                                if(document.activeElement) document.activeElement.blur();
                            }
				        },
				        onAfterClose: function () {
                        //Timeout needed for ie11
                        setTimeout(function(){ 
                            if(sendFocus) {
                                sendFocus.focus();
                            }
                        }, 100);
				        }
				    });
				};

		        myDashDataPost(postData);

		    }else{
		    	//No changes to save
		    	if(sendFocus) sendFocus.focus();
	    	}

	    }else{
	    	//Don't try to save, just escape and send focus to an element if specified
	    	if(sendFocus) sendFocus.focus();	    	
	    }


    } else {
        //Turn On Editing
        //document.body.classList.add('editing');
        myDashGridWrapper.classList.add('editing');
		document.addEventListener('keydown', myDashEscape);
        myDashTabbableGrid('on');

        myDashFilter.classList.add('disabled');
        myDashFilter.setAttribute('data-disabled', 'true');
        myDashGridBtn.classList.add('disabled');
        myDashGridBtn.setAttribute('data-disabled', 'true');
        myDashToolTip(myDashGridBtn, 'off');
        myDashListBtn.classList.add('disabled');
        myDashListBtn.setAttribute('data-disabled', 'true');
        myDashToolTip(myDashListBtn, 'off');
        for (var i = 0; i < myDashBookmarkBtns.length; i++) {
            myDashBookmarkBtns[i].classList.add('disabled');
            myDashBookmarkBtns[i].setAttribute('data-disabled', 'true');
            myDashToolTip(myDashBookmarkBtns[i], 'off');        
        }

        //Prevent tabbing to links and buttons inside grid items

        //Button Update
        for (var i = 0; i < myDashMoveBtns.length; i++) {
            var btnIcon = myDashMoveBtns[i].querySelector('span[data-btn-icon]');
            var btnText = myDashMoveBtns[i].querySelector('span[data-btn-text]');
            btnText.textContent = 'Done Editing';
            btnIcon.className = 'dripicons-checkmark';
            myDashMoveBtns[i].removeAttribute('aria-label');
        }

        myDashDraggies.forEach(function (draggie) {
            draggie.enable();
            draggie.bindHandles();
        });

    }

  }
}


































//Fires when content changes dynamically
function myDashPackeryResizeHandler(e){
    myDashContentChanged = true;

    var gridItem;
    if(e.target.classList.contains('packery-grid-item')){
      gridItem = e.target;
    }else{
      gridItem = parentOfClass(e.target, 'packery-grid-item');
    }

    //only needed when My Dashboard is fully loaded and there are no event handlers currently executing
    if(myDashInstalled && !myDashTriggered && !myDashProcessing){
        if(!document.documentElement.classList.contains('swal2-shown')){
            //console.log(gridItem);
            $myDashGrid.packery( 'fit', gridItem );
            //console.log('safe');
        }else{
            //console.log('unsafe - sweetalert showing');
        }
    }
}


var MyDashboard = {
    init: function (el) {
        this.ResizeListener(el);
    },
    ResizeListener: function (el) {
        el.addEventListener("PackeryResize", myDashPackeryResizeHandler);
    }
};

function myDashEscape(e) {
    //Double Sweet Alert can happen. We need to make sure it doesn't because that situation prevents the onAfterClose function from running!
	if(document.documentElement.classList.contains('swal2-shown')) return;

    var browserKey = (e.which) ? e.which : e.keyCode;
    //27 Esc Key
    if (browserKey === 27){

      var currentInfo = myDashCurrentInfo();

      var currentFocus = document.activeElement ? document.activeElement : document.body;

      var returnFocus = (currentFocus.classList.contains('packery-grid-item') || currentFocus === document.body) ? myDashMoveBtn() : currentFocus;

      if(currentInfo.changed){

    	var noSave;
    	var save;

        swal2Btns({
            showCloseButton: true,
            closeButtonAriaLabel: '',
            stopKeydownPropagation: false,
            buttonsStyling: false,
            confirmButtonClass: 'button show-focus',
            cancelButtonClass: 'button',
            title: 'Unsaved Changes',
            html: 'Do you want to save before you exit?',
            type: 'info',
            confirmButtonText: "Yes, save my changes",
            cancelButtonText: "No",
            focusConfirm: true,
            showLoaderOnConfirm: true,
            onBeforeOpen: function onBeforeOpen() {
                sweetAlertCustom();
                //ie11
                if(document.activeElement) document.activeElement.blur();

                document.removeEventListener('keydown', myDashEscape);
            },
            onAfterClose: function () {
            //Timeout needed for ie11
            setTimeout(function(){ 

                if(save){
                	//Save then Esc
                    myDashMoveBtn({
                        event: 'click',
                        detail: {
                            save: true,
                            current: currentInfo,
                            focus: returnFocus
                        }
                    });

                }else if(noSave){
                	//Esc without saving
                	myDashGhostGridItems('on');
                  myDashGridStart = myDashGridWrapper.offsetWidth;
                  window.removeEventListener('resize', myDashResize);
    				      //filter change delay for ghost animation
    				      setTimeout(function(){
					            myDashDataNew.xValues = myDashData.xValues;
                      var resetOrder = myDashOrderToDisplay();
                      myDashChangeOrder(resetOrder); 

                	    myDashMoveBtn({
                		    event: 'click',
                		    detail: {
                			  save: false,
                              focus: returnFocus
                		    }
                	  });
                	}, 300);

                }else{
                	//only put this listener back on if the user dismisses modal without choosing an action.
                	document.addEventListener('keydown', myDashEscape);
                    currentFocus.focus();
                }
            }, 100);
            },
            preConfirm: function () {
                return new Promise(function (resolve) {
                    //use ball fall animation instead of spinner
                    sweetAlertUseBallFall();
                    save = true;
                    resolve();
                });
            }
        }).then(function(result){
            if(result.dismiss == 'cancel'){
                noSave = true;
            }
        });

      }else{
      	//No change happened. We can just turn off editing.
        myDashMoveBtn({
            event: 'click',
            detail: {
                save: false,
                focus: returnFocus
            }
        });

      }


    }//End Esc Key
}


function myDashLaunch() {

    //addEventListeners
    var myDashInstructionFilterReset = document.querySelector('#mydash-instruction [data-filter-reset]');
    myDashInstructionFilterReset.addEventListener('click', function(e){
    	e.preventDefault();
    	e.stopPropagation();
		myDashFilterSet('all');
    });

    for (var i = 0; i < myDashMoveBtns.length; i++) {
        myDashMoveBtns[i].addEventListener('click', myDashDisabledWhenNoItems);
        myDashMoveBtns[i].addEventListener('click', myDashDisabledDuringFilter);
        myDashMoveBtns[i].addEventListener('click', myDashMoveBtnClick);
    }
    
    //make sure titlebar is not hidden behind sticky topbar when you tab to it
    document.getElementById('mydash-titlebar').addEventListener('focus', function(){
		if(document.documentElement.getAttribute('data-whatinput') === 'keyboard'){
        	$("html, body").animate({scrollTop: 0}, 'slow');
        }
    }, true);

    for (var i = 0; i < myDashBookmarkBtns.length; i++) {
        myDashBookmarkBtns[i].addEventListener('click', myDashDisabledDuringMove);
        myDashBookmarkBtns[i].addEventListener('click', myDashDisabledDuringFilter);
        myDashBookmarkBtns[i].addEventListener('click', myDashBookmarkBtnClick);
        //Set in template Script by PHP
        if(showBookmarkBtn){ myDashBookmarkBtns[i].classList.remove('hide'); }
    }

    myDashFilter.addEventListener('mousedown', myDashDisabledDuringMove);
    myDashFilter.addEventListener('keydown', myDashDisabledDuringMove);
    myDashFilter.addEventListener('click', myDashDisabledDuringMove);
    myDashFilter.addEventListener('change', myDashFilterChange);

    myDashGridBtn.addEventListener('click', myDashDisabledDuringMove);
    myDashGridBtn.addEventListener('click', myDashToggleLayout);
    
    myDashListBtn.addEventListener('click', myDashDisabledDuringMove);
    myDashListBtn.addEventListener('click', myDashToggleLayout);

    MyDashboard.init(myDashGridWrapper);

    //Check saved settings

    var startingLayout = myDashData['current_layout'] ? myDashData['current_layout'] : 'grid';

    if (startingLayout === 'list'){
      myDashGridWrapper.setAttribute('data-layout', 'list');
      myDashGridBtn.classList.remove('active');
      myDashListBtn.classList.add('active');
    } else{
      myDashGridWrapper.setAttribute('data-layout', 'grid');
      myDashListBtn.classList.remove('active');
      myDashGridBtn.classList.add('active');
    }

    function postLoadListeners(){

        var myDashAccordions = document.querySelectorAll('[data-accordion]');
        for(var i = 0; i < myDashAccordions.length; i++){
            $(myDashAccordions[i]).on('down.zf.accordion', function (e) {
                EventDispatcher.PackeryResize(this);
            }); 
            $(myDashAccordions[i]).on('up.zf.accordion', function (e) {
                EventDispatcher.PackeryResize(this);
            });        
        }

        var myDashOrbits = document.querySelectorAll('[data-orbit]');
        for(var i = 0; i < myDashOrbits.length; i++){
            $(myDashOrbits[i]).on('slidechange.zf.orbit', function (e) {
                setTimeout(function(){
                    //steppers and flippers need accurate height before layout
                    EventDispatcher.Custom({
                        "el" : window,
                        "event" : "resize"
                    });           
                    EventDispatcher.PackeryResize(this);
                }.bind(this), 100);

            });        
        }

    }//End of postLoadListeners

    //myDashResizeDelay completes initial setup
    //check to make sure all nodes have loaded completely
    //Returns true if we are still waiting on nodes to render
    function needToWait(){

      //Strip out nodes to wait for that are NOT EXPECTED
      for(let i = 0; i < waitForNodes.length; i++){
        let waitFor = waitForNodes[i];

        let relevent = false;
        for(let k = 0; k < expectedNodes.length; k++){
          let expected = expectedNodes[k];
          if(waitFor === expected){
            relevent = true;
            break;
          }
        }

        if (!relevent){
            let index = waitForNodes.indexOf(waitFor);
            if (index > -1) {
              waitForNodes.splice(index, 1);
            }          
        }
      }

      //Strip out nodes that are already loaded
      for(let i = 0; i < waitForNodes.length; i++){
        let waitFor = waitForNodes[i];

        for(let k = 0; k < loadedNodes.length; k++){
          let loaded = loadedNodes[k];
          if(waitFor === loaded){
            let index = waitForNodes.indexOf(waitFor);
            if (index > -1) {
              waitForNodes.splice(index, 1);
            }
            break;
          }
        }
      }

      //Check superSelects
      let superSelects = document.querySelectorAll('select.superselect');
      let superSelectWait = false;

      for(var i = 0; i < superSelects.length; i++){
        if(superSelects[i].classList.contains('initialized') || superSelects[i].classList.contains('js-error')){
            continue;
        } else{
            superSelectWait = true;
            break;
        }
      }

      if(waitForNodes.length || superSelectWait){
        //console.log('You need to wait');
        return true;
      } else{
        //console.log('You can go ahead');
        return false;
      }
    }


    function checkIfNeedToWait(){
        myDashWaitTime += myDashWaitInterval;
        var waitTimeout = myDashWaitTime > 5000;

        if(!needToWait() || waitTimeout){

            if(waitTimeout) {
                //console.log('waiting timed out...');
            }

            window.clearInterval(myDashWaiting);
            postLoadListeners();
            myDashResizeDelay();            
        }
    }


    if(needToWait()){
        myDashWaiting = window.setInterval(checkIfNeedToWait, myDashWaitInterval);
    }else{
        postLoadListeners();
        myDashResizeDelay();
    }


}


function myDashInstall() {

    myDashGridWrapper = document.querySelector('#mydash-grid-wrapper');

    if (myDashGridWrapper) {

        myDashGrid = document.getElementById('mydash-grid');
        myDashTitleBar = document.getElementById('mydash-titlebar');
        myDashLoader = document.getElementById('mydash-skeletons');
        myDashFilter = document.getElementById('mydash-filter');
        myDashGridBtn = document.getElementById('mydash-grid-btn');
        myDashListBtn = document.getElementById('mydash-list-btn');
        myDashBookmarkBtns = document.querySelectorAll('button[data-bookmark]');
        myDashMoveBtns = document.querySelectorAll('button[data-move]');
		myDashShow = [];
    	myDashLimbo = [];

        myDashLoading('on');
        myDashGhostGridItems('on');

        myDashPackeryInstall();

        window.addEventListener('load', myDashLaunch);

    }//End of IF myDash Wrapper
}


document.addEventListener('DOMContentLoaded', myDashInstall);
