/*
    build EventDispatcher for packery
 */

var EventDispatcher = {

    //setTimout is needed to actually dispatch the events in order to avoid call stack limit error. This is possible if the user has a large amount of pinned items.

    init: function () {

    },
    PackeryResize: function (el) {

      let myEvent;

      if (typeof window.CustomEvent === "function") {
        /* for early versions of IE */
        myEvent = new CustomEvent('PackeryResize', {
                //detail: { data: "any extra?" },
                bubbles: true,
                cancelable: false
        });
      } else {
        myEvent = new Event('PackeryResize', {
                //detail: { data: "any extra?" },
                bubbles: true,
                cancelable: false
            });
      }

	  //console.info("Element is: ", el);
      if(el){
        window.setTimeout( function() {
          //console.log("PackeryResize");
          el.dispatchEvent(myEvent);
        }.bind(this), 
          0
        );
      }

    },

    //Indication that a node initially rendered with JS has displayed for the first time
    NodeLoadedForTheFirstTime: function (el) {

      let myEvent;

      if (typeof window.CustomEvent === "function") {
        /* for early versions of IE */
        myEvent = new CustomEvent('NodeLoadedForTheFirstTime', {
                //detail: { data: "any extra?" },
                bubbles: true,
                cancelable: false
            });
      } else {
        myEvent = new Event('NodeLoadedForTheFirstTime', {
                //detail: { data: "any extra?" },
                bubbles: true,
                cancelable: false
            });
      }

      //console.info("Element is: ", el);
      if(el){
        window.setTimeout( function() {
          //console.log("NodeLoadedForTheFirstTime");
          el.dispatchEvent(myEvent);
        }.bind(this), 
          0
        );
      }

    },

    Custom: function (options) {

      let myEvent;
      let myOptions = {};

      //console.log( "Custom event called with these options: " + options['el'] + " : " + options['event'] + " : " + options['detail'] );

      function setDefaults(options){
        myOptions["el"] = 'el' in options ? options["el"] : null;
        myOptions["event"] = 'event' in options ? options["event"] : null;
        myOptions["detail"] = 'detail' in options ? options["detail"] : null;
        myOptions["bubbles"] = 'bubbles' in options ? options["bubbles"] : true;
        myOptions["cancelable"] = 'cancelable' in options ? options["cancelable"] : false;  
      }

      setDefaults(options);
      if(!myOptions["el"] || !myOptions["event"]) return;

      if (typeof window.CustomEvent === "function") {
        /* for early versions of IE */
        myEvent = new CustomEvent(myOptions["event"], {
                detail: myOptions["detail"],
                bubbles: myOptions["bubbles"],
                cancelable: myOptions["cancelable"]
            });
      } else {
        myEvent = new Event(myOptions["event"], {
                detail: myOptions["detail"],
                bubbles: myOptions["bubbles"],
                cancelable: myOptions["cancelable"]
            });
      }


        window.setTimeout( function() {
          //console.log("Custom event");
          myOptions["el"].dispatchEvent(myEvent);
        }.bind(this), 
          0
        );

    }
};


/*
taken out of here: https://github.com/metafizzy/isotope/blob/master/dist/isotope.pkgd.js
 */
Packery.prototype._getFilterTest = function (filter) {
    if (jQuery && this.options.isJQueryFiltering) {
        // use jQuery
        return function (item) {
            return jQuery(item.element).is(filter);
        };
    }
    if (typeof filter == 'function') {
        // use filter as function
        return function (item) {
            return filter(item.element);
        };
    }
    // default, use filter as selector string
    return function (item) {
        return matchesSelector(item.element, filter);
    };
};

Packery.prototype.filter = function (filter) {

    var items = this.items;

    var test = this._getFilterTest(filter);

    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        var isMatched = test(item);
    }

};

/*
My Dashboard class
 */

class Dashboard {
    constructor(options) {
        this.start = 0;
        this.end = 0;
        this.initial_options = options;
        this.packery_options = options['packery_options'];
        this.layout_options = options['layout_options'];
        this.debug = options['debug'];
        this.ExpandOptions();
    }

    /*

     */
    OverloadPackery() {
        this.packery.shiftLayout = function () {
            console.log('shiftLayout - Overloaded');
            Packery.prototype.shiftLayout.call(this);
        }
    }

    shiftLayout() {
        console.log('shiftLayout');
        this.packery.shiftLayout();
    }

    LogMessage(message) {
        if (this.debug) {
            console.log(message);
        }
    }

    ExpandOptions() {
        this.gridWrapper = document.querySelector(this.layout_options['gridWrapperSelector']);
        this.grid = document.querySelector(this.layout_options['gridSelector']);
        this.titleBar = document.querySelector(this.layout_options['titleBarSelector']);
        this.controls = this.titleBar.querySelectorAll('button, select');

        this.skeletons = document.querySelector(this.layout_options['skeletonsSelector']);
        this.moveButtons = document.querySelectorAll(this.layout_options['moveSelector']);
        this.filterDropdown = document.querySelector(this.layout_options['filterDropdownSelector']);
        this.listViewButton = document.querySelector(this.layout_options['listViewSelector']);
        this.gridViewButton = document.querySelector(this.layout_options['gridViewSelector']);
    }

    InitializePackery() {
        if (this.gridWrapper) {
            this.packery = new Packery(this.grid, this.packery_options);
            this.OverloadPackery();
            this.draggies = [];

            myDashMoveBtns = this.grid.querySelectorAll('button[data-move]');
            var elements = this.packery.getItemElements();
            for (var i = 0; i < elements.length; i++) {
                var draggie = new Draggabilly(elements[i]);
                // bind drag events to Packery
                this.draggies.push(draggie);
                this.packery.bindDraggabillyEvents(draggie);
                draggie.disable();

            }
            this.grid.addEventListener('dragItemPositioned', this.UpdateDomToMatchPackery.bind(this));

        } else {
            this.LogMessage('The Grid Does not have a Wrapper');
        }
    }

    UpdateDomToMatchPackery(event) {
        var itemElems = this.packery('getItemElements');

        for (var i = 0; i < itemElems.length; i++) {
            this.grid.appendChild(itemElems[i]);
        }
    }

    Setup() {
        this.ChangeState('on');
        this.InitializePackery();
        this.Data(my_packery_data);
        this.InstallListeners();
        this.ResizeDelay();
        this.ChangeState('off');
        this.LogMessage('Done');
    }

    Data(data) {
        //Initially should be equal. Used to track changes.
        var dataString = JSON.stringify(data);

        this.data = JSON.parse(dataString);
        this.newData = JSON.parse(dataString);

    }

    UpdateToolTip(el, state) {
        if (el.hasAttribute('data-tooltip')) {
            var thisTip = document.getElementById(el.getAttribute('data-toggle'));
            if (state === 'on') {
                thisTip.classList.remove('hide');
            } else if (state === 'off') {
                thisTip.classList.add('hide');
            }

        }
    }

    FreezeControls() {
        this.titleBar.addEventListener('keydown', this.myDashDisableACTIONS.bind(this), true);
        this.titleBar.addEventListener('mousedown', this.myDashDisableACTIONS.bind(this), true);
        this.titleBar.addEventListener('click', this.myDashDisableACTIONS.bind(this), true);

        for (var i = 0; i < this.controls.length; i++) {
            this.controls[i].classList.add('disabled');
            this.UpdateToolTip(this.controls[i], 'off');
        }

        this.gridWrapper.classList.remove('loaded');
        this.gridWrapper.classList.add('loading');
    }

    ThawControls() {


        for (var i = 0; i < this.controls.length; i++) {
            this.controls[i].removeAttribute('disabled');
        }

        for (var i = 0; i < this.controls.length; i++) {
            if (this.controls[i].hasAttribute('data-disabled')) continue;
            this.controls[i].classList.remove('disabled');
            this.UpdateToolTip(this.controls[i], 'on');
        }

        this.gridWrapper.classList.remove('loading');
        this.gridWrapper.classList.add('loaded');

        this.titleBar.removeEventListener('keydown', this.myDashDisableACTIONS.bind(this), true);
        this.titleBar.removeEventListener('mousedown', this.myDashDisableACTIONS.bind(this), true);
        this.titleBar.removeEventListener('click', this.myDashDisableACTIONS.bind(this), true);
    }

    ChangeState(state) {
        switch (state) {
            case 'on':
                this.FreezeControls();
                break;
            case 'off':
                this.ThawControls();
                break;
            default:
        }
    }

    InstallListeners() {
        window.addEventListener('load', function () {
            for (var i = 0; i < this.moveButtons.length; i++) {
                this.moveButtons[i].addEventListener('click', this.myDashMoveBtnClick.bind(this));
            }

            this.filterDropdown.addEventListener('mousedown', this.myDashDisabledDuringMove.bind(this));
            this.filterDropdown.addEventListener('keydown', this.myDashDisabledDuringMove.bind(this));
            this.filterDropdown.addEventListener('click', this.myDashDisabledDuringMove.bind(this));
            this.filterDropdown.addEventListener('change', this.myDashFilterChange.bind(this));

            this.gridViewButton.addEventListener('click', this.myDashDisabledDuringMove.bind(this));
            this.gridViewButton.addEventListener('click', this.myDashToggleLayout.bind(this));

            this.listViewButton.addEventListener('click', this.myDashDisabledDuringMove.bind(this));
            this.listViewButton.addEventListener('click', this.myDashToggleLayout.bind(this));

            this.gridWrapper.addEventListener('click', function () {
                if (!this.gridWrapper.classList.contains('editing')) {
                    setTimeout(function () {
                        this.LogMessage('gridWrapper click');
                        this.shiftLayout();
                    }.bind(this), 500);
                }

            }.bind(this));

            this.start = this.gridWrapper.offsetWidth;
        }.bind(this));

        this.gridWrapper.addEventListener("PackeryResize", function (e) {
            console.info("PackeryResize");
            this.shiftLayout();
        }.bind(this));
    }

    ResizeDelay() {
        this.start = this.gridWrapper.offsetWidth;
        console.log('START', this.start);

        //check device and layout
        var currentDevice;
        var listView = (this.gridWrapper.getAttribute('data-layout') === 'list');

        if (window.matchMedia("(min-width: 640px)").matches) {
            currentDevice = 'desktop';
        } else {
            currentDevice = 'phone';
        }

        var newOrder;
        switch (currentDevice) {
            case 'phone':
                if (this.data['order_phone']) {
                    newOrder = 'phone';
                } else if (this.data['order_desktop']) {
                    newOrder = 'desktop';
                } else {
                    newOrder = 'none';
                }
                break;
            case 'tablet':
            case 'laptop':
            case 'desktop':
            default:
                if (listView) {
                    if (this.data['order_phone']) {
                        newOrder = 'phone';
                    } else if (this.data['order_desktop']) {
                        newOrder = 'desktop';
                    } else {
                        newOrder = 'none';
                    }

                } else {
                    //GRIDVIEW
                    if (this.data['order_desktop']) {
                        newOrder = 'desktop';
                    } else if (this.data['order_phone']) {
                        newOrder = 'phone';
                    } else {
                        newOrder = 'none';
                    }
                }
        }//SWITCH END


        //load new order if not current
        var currentOrder = this.gridWrapper.getAttribute('data-order');
        if (newOrder !== currentOrder) {
            this.ChangeOrder(newOrder);
        } else {
            console.log('22222');

            this.once('layoutComplete', function () {
                console.log('22222');
            }.bind(this));

            this.packery.layout();
        }
    }

    ChangeOrder(device) {
        this.LogMessage('ChangeOrder');
        this.gridWrapper.setAttribute('data-order', device);

        var newOrder = this.data[('order_' + device)];

        //Reorder in DOM
        // TODO: thsis
        for (var i = 0; i < newOrder.length; i++) {
            var gridItem = document.getElementById(('grid_' + newOrder[i]));
            this.grid.appendChild(gridItem);
        }

        //Update Packery order to match DOM
        this.packery.reloadItems();


        //Update Rendered page layout
        console.log('111111');

        document.addEventListener('layoutComplete', function () {
            console.log('9999999');
            console.log(this);

        }.bind(this));


        this.packery.layout();
    }

    AfterLayout() {

        this.end = this.gridWrapper.offsetWidth;
        console.log('FINISH', this.end);

        if (this.start !== this.end) {

            console.log("BAD! Container width was changing during packery update... Redo!");
            setTimeout(function () {
                this.ResizeDelay();
            }.bind(this), 500);

        } else {

            window.addEventListener('resize', this.ResizeDelay.bind(this));
            console.log('GOOD!');
        }
    }

    once(fn, context) {
        this.LogMessage('one');
        var result;

        return function () {
            if (fn) {
                result = fn.apply(context || this, arguments);
                fn = null;
            }

            return result;
        };
    }


    myDashMoveBtnClick(e) {

    }

    myDashDisableACTIONS() {

    }

    myDashDisabledDuringMove() {

    }

    myDashToggleLayout() {

    }

    myDashFilterChange() {

    }
}


document.addEventListener('DOMContentLoaded', function () {
    if (false) {
        var KSU_Dashboard = new Dashboard({
            packery_options: {
                itemSelector: '.packery-grid-item',
                columnWidth: '.packery-grid-sizer',
                gutter: '.packery-gutter-sizer',
                isJQueryFiltering: true,
                percentPosition: true,
                resize: false,
            },
            layout_options: {
                gridWrapperSelector: '#mydash-grid-wrapper',
                gridSelector: '#mydash-grid',
                titleBarSelector: '#mydash-titlebar',
                skeletonsSelector: '#mydash-skeletons',
                moveSelector: 'button[data-move]',
                filterDropdownSelector: '#mydash-filter',
                listViewSelector: '#mydash-list-btn',
                gridViewSelector: '#mydash-grid-btn'
            },
            debug: true
        });
         KSU_Dashboard.Setup();
         KSU_Dashboard.shiftLayout()
    }

});
