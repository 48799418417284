// Local Top Navigation Custom Scripts
var localNavTabs;

function enterDropdownTopLink(e) {

    if (e.keyCode == 13 && e.shiftKey == true) {
        e.preventDefault();
        e.stopPropagation();
        var currentHref = e.currentTarget.href;
        window.location.href = currentHref;

    } else if (e.keyCode == 13) {

        e.preventDefault();
        e.stopPropagation();

        var currentLink = e.currentTarget;
        var currentLi = currentLink.parentNode;
        var currentPanel = currentLi.querySelector('.dropdown-pane');

        navCleanUp();
        if (!currentLink.classList.contains('hover')) {
            $(currentPanel).foundation('open');
        } else {
            $(currentPanel).foundation('close');
        }
    } else {
    }

}

function tapDropdownTopLink(e) {

    e.preventDefault();
    e.stopPropagation();

    var currentLink = e.currentTarget;
    var currentLi = currentLink.parentNode;
    var currentPanel = currentLi.querySelector('.dropdown-pane');

    navCleanUp();
    if (!currentLink.classList.contains('hover')) {
        $(currentPanel).foundation('open');
    } else {
        $(currentPanel).foundation('close');
        //var currentHref = e.currentTarget.href;
        //window.location.href = currentHref;      
    }

}

function installLocalNavListeners() {
    if (isBaseTheme('pioneer')) {
        localNavTabs = document.getElementById('local-nav-tabs');
        localNavTopLinks = $('#local-nav-tabs .nav-tier-1:has(div ul)').children('a');
        localNavLinks = document.querySelectorAll('#local-nav-tabs a');

//key or touch open panel
        for (var i = 0; i < localNavTopLinks.length; i++) {
            localNavTopLinks[i].setAttribute('role', 'menuitem');
            localNavTopLinks[i].addEventListener('keydown', enterDropdownTopLink);
            localNavTopLinks[i].addEventListener('keypress', function (e) {
                if (e.keyCode == 13) {
                    stopBoth(e);
                }
            });
            localNavTopLinks[i].addEventListener('keyup', function (e) {
                if (e.keyCode == 13) {
                    stopBoth(e);
                }
            });
            localNavTopLinks[i].addEventListener('touchend', tapDropdownTopLink);
            if (localNavTopLinks[i].href === siteRootUrl) {
                var thisSubLinks = localNavTopLinks[i].parentNode.querySelectorAll('.nav-tier-1 ul a');
                var thisFirstLink = thisSubLinks[0];
                localNavTopLinks[i].href = getCleanUrl(thisSubLinks[0].href);
            }
            localNavTopLinks[i].addEventListener('mouseenter', function (e) {
                if (flyoutBG.classList.contains('open')) {
                    navCleanUp();
                }
            });

        }

        for (var i = 0; i < localNavLinks.length; i++) {
            localNavLinks[i].addEventListener('click', function (e) {
                var newHrefStart = getCleanUrl(e.currentTarget.href);
                var oldHrefStart = getCleanUrl(window.location.href);
                if (oldHrefStart === newHrefStart) {
                    refreshMenuPaths();
                }
            });
        }


    }//Closing if pioneer conditional
}//Closing installListeners function

window.addEventListener('DOMContentLoaded', installLocalNavListeners);
