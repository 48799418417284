class URLHelper {
    constructor() {

    }

    static getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    static getUrlParam(parameter, defaultvalue) {
        var urlparameter = defaultvalue;
        if (window.location.href.indexOf(parameter) > -1) {
            urlparameter = this.getUrlVars()[parameter];
        }
        return urlparameter;
    }

    static isSelfDebugFor(debugKey) {
        return (this.getUrlParam('debug', 'false').toLowerCase() === debugKey.toLowerCase());
    }
}