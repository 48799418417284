class Logger {
    constructor(options) {
      this.options = options
      this.isDark = window.matchMedia("(prefers-color-scheme: dark)").matches
    }
    Log(message) {
      console.log(message)
    }
    debug(message) {
      if (this.isDark) {
        console.log(`%c${message}`, `color:White`)
      } else {
        console.log(`%c${message}`, `color:Black`)
      }
    }
    success(message) {
      console.log(`%c${message}`, `color:Green`)
    }
    info(message) {
      console.log(`%c${message}`, `color:#3298dc`)
    }
    warn(message) {
      console.log(`%c${message}`, `color:Orange`)
    }
    error(message) {
      console.log(`%c${message}`, `color:Red`)
    }
    newrelic(message) {}
  }