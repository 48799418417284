// Tooltip Cleanup For topbar links like blackboard, drive, and email


function tempTipHide(e){
  var el = e.currentTarget;
  if(document.activeElement === el && document.documentElement.getAttribute('data-whatinput') === 'keyboard'){
    return;
  }

  $(el).foundation('hide');

  if(document.activeElement !== el && document.documentElement.getAttribute('data-whatinput') !== 'keyboard'){
    el.removeEventListener('mouseleave', tempTipHide);
  }
  return;
}



function tooltipCleanup(){
    document.addEventListener('click', function(){
        var activeEl = document.activeElement;
        if( activeEl != null && activeEl.getAttribute('data-tooltip')){
          activeEl.addEventListener('mouseleave', tempTipHide);
        }
    });
    return;
}

window.addEventListener('load', tooltipCleanup);
