/**
 * @file
 * Placeholder file for custom sub-theme behaviors.
 *
 */


(function ($, Drupal) {

    /**
     * Use this behavior as a template for custom Javascript.
     */
    Drupal.behaviors.exampleBehavior = {
        attach: function (context, settings) {
            //alert("I'm alive!");
        }
    };

})(jQuery, Drupal);


try { 
  var KSU_UserHelper = new UserHelper(KSU_User);
  var KSU_DisplayHelper = new DisplayHelper(KSU_Display_properties);
  var KSU_EnvironmentHelper = new EnvironmentHelper(KSU_Environment_properties);
} catch( e ) {
    //console.log(e);
}

function showDSC( name, id ) {
  $('.dsc' ).hide( function() { $('.' + name ).fadeIn(); } );
  $('.dscselector' ).removeClass('active');
  $('.dscselector.' + id ).addClass('active');

  setTimeout(function(){
    EventDispatcher.PackeryResize($('#node-746')[0]);
  }, 400);
}


function thumbsUpEvent(categories) {
    $.post("/asyncloader/thumb", {action: 'up', categories: categories}).done(function () {
        alert("We'll show you more events like this in the future");
    });
}


function thumbsDownEvent(categories) {
    $.post("/asyncloader/thumb", {action: 'down', categories: categories}).done(function () {
        alert("We'll show you fewer events like this in the future");
    });
}

function revalidate() {

  Swal.fire({
    title: "Please reauthenticate",
    text: "Please re-enter your password below to continue.",
    type: "warning",
    showConfirmButton: true,
    showCancelButton: true,
    cancelButtonText: "Close",
    focusCancel: "true",
    input: 'password',
    inputPlaceholder: 'Enter your password',

    preConfirm: function() {

      var p = btoa( $('.swal2-input').first().val() );

      $.ajax({
        method: "POST",
        url: "/asyncloader/password-check",
        dataType: "json",
        beforeSend: function(xhr){xhr.setRequestHeader('X-Token-Key', 'data');},
        data: {
          "data": p,
          "method": 'external'
        }
      }).done(function (data) {

        if( data.Result === 'Success' )
          location.replace('/ws_auth/redirector');

        if( data.Result === 'Fail' )
          alert("Sorry, did you mistype your password?");
        console.log("result: " + data.Result );
      });
    }
  });

}


  function pioneerSwitcher() {

    var currentBaseTheme = $('body').attr('data-base');

    var newTheme = 'pioneer';
    var newBaseTheme = $(this).find('img').data('base-theme');
    $('.thumbnail-active').removeClass('thumbnail-active');
    $(this).find('img').addClass('thumbnail-active');
    var loader = new ajaxLoader($('#user-profile-user-preferences').closest('.panel-default').first());
    $.post("/asyncloader/themeselect", {theme: newTheme}).done(function () {
        loader.remove();

        /*
            swal2({
                title: "Success!",
                text: "Welcome to the new FlashLine experience. You may return to your previous interface from your settings.",
                type: "success",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                focusCancel: "true"
            });
        */
        //Force the page to refresh

        if (currentBaseTheme != newBaseTheme) {
            setTimeout(function () {
                //location.reload(true);
                location.href = location.href = "?splash=true";
                //location.reload(true);
            }, 1000);
        }
    });
    if (currentBaseTheme == newBaseTheme) {

        // Remove previous custom theme
        $("body").removeClass(function (index, css) {
            return (css.match(/(^|\s)custom-theme-\S+/g) || []).join(' ');
        });
        $('body').attr("id", '');

        $("body").removeClass(function (index, css) {
            return (css.match(/(^|\s)base-theme-\S+/g) || []).join(' ');
        });
        $('body').addClass(newBaseTheme);

        $('body').addClass('custom-theme-' + newTheme);
    }
    window.Event.cancelBubble = true;
}


function labelKeyPress(e, onKeyPress, dontUncheck) {

    /*
        e simply contains the event data, 
        
        onkeypress is what we want to do (if anything) after the state changes.

        dontUncheck should be true, typically, for radio buttons (since radio buttons
        are typically not toggleable -- clicking the label again usually doesn't 
        uncheck a radio button.


        If you're programmatically creating a checkbox / radio button, you should assign
        this to the onkeypress event like:

          x.onkeypress = function(event) {
              labelKeyPress(event, function() { dothis();  });


          or directly in HTML as:

          <label onkeypress="labelKeyPress(event, function() { dothis(); } );">...</label>

    */

    if (e.key === "Enter" || e.charCode == 32) {

        var forAttr = e.target.getAttribute('for');

        if (forAttr == null || forAttr === "") {
            console.log("label has missing or empty 'for' attribute");
            return;
        }

        if (window.document.getElementById(forAttr).checked == true && dontUncheck != true) {
            window.document.getElementById(forAttr).checked = false;
        } else
            window.document.getElementById(forAttr).checked = true;

        // e.preventDefault();

        if (onKeyPress != null) {
            onKeyPress();
        }
    }
}


function datatablesColumnSort(dt, c, sort_dir) {
    // nice little helper function to sort
    // datatable dt by column no. c, ascending or descending...
    //
    // 1 == sort ascending
    // 0 == sort descending

    if (sort_dir == 1) {
        dt.column(c).order('asc').draw();
    } else {
        dt.column(c).order('desc').draw();
    }

}

function isBaseTheme(key) {
    return ($("body").attr('data-base').indexOf("base-theme-" + key) !== -1);
}

function showCal() {
    var firsttime = false;
    if (!$('#mycal').length) {

        $('body').append(' <div class="large reveal" id="mycal" data-reveal data-v-offset="0"><div id="myecalInternal">Please wait...</div></div>');
        $.getJSON('/asyncloader/nojs/node/render/5361', function (d) {
            $('#myecalInternal').html(d.markup);
        }); // $('#myModal').foundation('reveal', 'open'); // });
        firsttime = true;
    }
    var popup = new Foundation.Reveal($('#mycal'));
    /*$('#event-preferences-container').hide();
    $('.events-body').show();
    $('.events-sidebar-container').show();
    
    $('#cal-type-all').prop('checked', true);
*/
    popup.open();
    if (firsttime == false && 0) {
        // eventDivTable.tagFilter();
        // eventDivTable.draw();
    }

}

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

function escapeHtml(text) {
  var map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };

  return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}


var $ = jQuery;
var KSUJSPlugins = {

    init: function () {
        this.orbit();
        this.scrollTop();
        this.sideNav();
        this.gridstack();
        this.tooltips();
        this.rowClicks();
        this.magellan();
        this.rejectIE();

        // W.I.P.

        $('.voice-search').on('click', function () {

            if (is_listening == false) {
                recognition.start();
                is_listening = true;
            } else {
                is_listening = false;
                recognition.stop();
            }
        });


        if (findGetParameter('showcal') == 'true')
            showCal();

        if (!isBaseTheme("pioneer")) {


            $('#navarea3').find('.checklistpicker').on('click', function () {
                if ($('#mylists-dropdown').length > 0) {
                    $('#mylists-dropdown').remove();
                }
                $.getJSON('/asyncloader/nojs/node/render/5341', function (d) {
                    $("#navarea3").find('.checklistpicker').parent().append("<div style='display: none;' id='mylists-dropdown'>" + d.markup + "</div>");
                    buildWorklistDropdownMobile();
                });

                return false;
            });

            $('#navarea2').find('.checklistpicker').on('click', function () {
                if ($('#mylists-dropdown').is(':visible')) {
                    $('#mylists-dropdown').hide();
                    return false;
                }
                if ($('#mylists-dropdown').length > 0 && !$('#mylists-dropdown').is(':visible')) {
                    $('#mylists-dropdown').remove();
                }

                $.getJSON('/asyncloader/nojs/node/render/5341', function (d) {
                    $("#navarea2").find('.checklistpicker').parent().append("<div style='display: none;' id='mylists-dropdown'>" + d.markup + "</div>");
                    //$(".checklistpicker:eq(1)").parent().append("<div style='display: none;' id='mylists-dropdown'>" + d.markup + "</div>");
                    buildWorklistDropdown();
                });
                return false;
            });
        }

        if (isBaseTheme("pioneer")) {

            $('#navarea3').find('.checklistpicker').on('click', function () {
                if ($('#mylists-dropdown').is(':visible')) {
                    $('#mylists-dropdown').hide();
                    return false;
                }
                if ($('#mylists-dropdown').length > 0 && !$('#mylists-dropdown').is(':visible')) {
                    $('#mylists-dropdown').remove();
                }

                $.getJSON('/asyncloader/nojs/node/render/5341', function (d) {
                    $("#navarea3").find('.checklistpicker').parent().append("<div style='display: none;' id='mylists-dropdown'>" + d.markup + "</div>");
                    buildWorklistDropdown();
                });
                return false;
            });

            $('#navarea2-right').find('.checklistpicker').on('click', function () {
                if ($('#mylists-dropdown').is(':visible')) {
                    $('#mylists-dropdown').hide();
                    return false;
                }
                if ($('#mylists-dropdown').length > 0 && !$('#mylists-dropdown').is(':visible')) {
                    $('#mylists-dropdown').remove();
                }

                $.getJSON('/asyncloader/nojs/node/render/5341', function (d) {
                    $("#navarea2-right").find('.checklistpicker').parent().append("<div style='display: none;' id='mylists-dropdown'>" + d.markup + "</div>");
                    buildWorklistDropdown();
                });
                return false;
            });

        }//Ending pioneer worklist click functions


        $('.ecal-sidebar-menu').on('click', function () {

            showCal();

            /*
              var firsttime = false;
              if (!$('#mycal').length) {

                  $('body').append(' <div class="large reveal" id="mycal" data-reveal data-v-offset="0"><div id="myecalInternal">Please wait...</div></div>');
                  $.getJSON('/asyncloader/nojs/node/render/5361', function (d) {
                      $('#myecalInternal').html(d.markup);
                  }); // $('#myModal').foundation('reveal', 'open'); // });
                  firsttime = true;
              }
              var popup = new Foundation.Reveal($('#mycal'));
              $('#event-preferences-container').hide();
              $('.events-body').show();
              $('.events-sidebar-container').show();

              $('#cal-type-all').prop('checked', true);

              popup.open();
              if (firsttime == false) {
                  eventDivTable.tagFilter();
                  eventDivTable.draw();
              }
              */
            return false;

        });

        //notifications menu item on mobile just takes you to notifications page
        var mobileNotificationMenuItem = document.querySelector('#nav3 a.notifications-init');

        if( mobileNotificationMenuItem != null ) {
          mobileNotificationMenuItem.addEventListener('click', function (e) {
              e.preventDefault();
              var currentHref = '/node/416';
              window.location.href = currentHref;
          });
        }

        var that = this;
        $('a[href*=#]:not([href=#])').click(function () {
            that.smoothScroll($(this).prop('hash'));
        });

        // Remove enter key from dropdown so the search still submits
        // @TODO: I can't be doing this because it won't allow enter on normal
        // dropdown stuff
        Foundation.Keyboard.register('Dropdown', {
            'ESCAPE': 'close',
            'TAB': 'tab_forward',
            'SHIFT_TAB': 'tab_backward'
        });

        if (location.hash) {
            setTimeout(function () {
                that.smoothScroll($(location).prop('hash'));
            }, 1);
        }

        // Linkify - "Read more" for long texts
        $('.event-desc-detail').linkify().readmore();
    },

    rejectIE: function () {
        $.reject({
            reject: {all: false, msie: 9},
            display: ['firefox', 'chrome', 'msie'],
            imagePath: '/sites/all/themes/ksu_foundation/js/jReject/images/',
            closeCookie: true
        });
    },

    magellan: function () {
        // Scroll spy
        if ($('[data-magellan-target]').length && $('[data-magellan]').length) {
            new Foundation.Magellan($('[data-magellan]'), {threshold: 80});
        }
    },

    gridstack: function () {
        if (!$('.grid-stack-item').length) {
            return;
        }

        var options = {
            float: true,
            cellHeight: 'auto',
            verticalMargin: '10px',
            alwaysShowResizeHandle: true
        };

        $('.grid-stack').gridstack(options);
        this.grid = $('.grid-stack').data('gridstack');

        function serialize_widget_map() {
            var res = _.map($('.grid-stack .grid-stack-item:visible'), function (el) {
                el = $(el);
                var node = el.data('_gridstack_node');
                return {
                    id: el.find('article').attr('id'),
                    x: node.x,
                    y: node.y,
                    width: node.width,
                    height: node.height
                };
            });
            return res;
        }

        this.load_grid = function () {
            var that = this;
            $.get("/asyncloader/customdash", function (data) {
                try {
                    var items = GridStackUI.Utils.sort(JSON.parse(data));
                    _.each(items, function (node) {
                        var el = $('#' + node.id).closest('div.grid-stack-item');
                        that.grid.update(el, node.x, node.y, node.width, node.height);
                    }, this);
                } catch (e) {

                }
            });
        }.bind(this);

        $('#page-title').append(' <small><button href="#" class="button has-icon-left" id="edit-dash"><i class="dripicons-pencil" aria-hidden="true"></i> <span>Edit Dashboard</span></button></small>');
        $('#page-title').append(' <small><button href="#" class="button button-bordered hidden" id="reset-dashboard"><i class="dripicons-trash" aria-hidden="true"></i> <span>Reset Dashboard</span></button></small>');

        $(document).ready(function () {

            if (!$('#node-5191').length) {
                $('#toolbox-bookmarks').show();
            }
            if (!$('#node-746').length) {
                $('#toolbox-checklists').show();
            }
            
            // this will check if the div exists and only do work
            // if needed by the page.

        });

        $("#toolbox-bookmarks").click(function () {
            $.ajax('/asyncloader/pintile/5191').done(function () {
                location.reload();
            });
        });

        $("#toolbox-checklists").click(function () {
            $.ajax('/asyncloader/pintile/746').done(function () {
                location.reload();
            });
        });

        $('#reset-dashboard').on('click', function () {
            $(this).text('Resetting...');

            // Save on change
            $.post("/asyncloader/customdash", {layout: JSON.stringify({})}, function (data) {
                window.location.reload();
            });
        });

        $("#edit-dash").toggle(function () {
            $(this).blur();

            // Clicked edit
            $('.grid-stack').data('gridstack').enable();
            $(this).addClass('success').find('i').addClass('dripicons-checkmark').removeClass('dripicons-pencil');
            $(this).find('span').text('Done Editing');

            $('.grid-stack').on('change', function (e, items) {
                // resizeAll();

                // Save on change
                $.post("/asyncloader/customdash", {layout: JSON.stringify(serialize_widget_map())}, function (data) {
                });
            });
        }, function () {
            $(this).blur();

            // Clicked done

            var btn = $(this);
            btn.prop("disabled", true);
            btn.find('span').text('Saving...');
            btn.find('i').addClass('spin-icon spin').removeClass('dripicons-checkmark');

            btn.prop("disabled", false);
            btn.find('i').addClass('dripicons-pencil').removeClass('spin-icon spin');
            btn.removeClass('success').find('span').text('Edit Dashboard');

            $('.grid-stack').data('gridstack').disable();
        });

        function resizeItem(obj) {
            obj.height(obj.find('article').outerHeight() + 40);
        }

        function resizeAll() {
            $('.grid-stack-item').each(function () {
                resizeItem($(this));
            });
        }

        this.load_grid();
        this.grid.disable();
        // resizeAll();
    },

    smoothScroll: function (hash) {
        // Smooth scroll to hash
        var nodeid = hash.replace("#", "");
        var query = "";
        if(nodeid.indexOf('node-') > -1){
            query = '[data-nodeid=' + nodeid.substring(5) + ']';
        }else{
            query = '[id=' + nodeid + ']';
        }
        var target = $(query).first().closest('.pane-content');
        if (target.length && isBaseTheme('classic')) {
            $('html,body').animate({
                scrollTop: target.offset().top - (Foundation.MediaQuery.current == 'small' ? 42 : 0)
            }, 1000);
            return false;
        }
        if (target.length && isBaseTheme('pioneer')) {
            $('html,body').animate({
                scrollTop: target.offset().top - (Foundation.MediaQuery.current == 'small' ? 48 : 72)
            }, 1000);
            return false;
        }
    },

    openNavLink: function (href) {
        if (!isBaseTheme("pioneer")) {
            var currentNav = $('.side-nav a[href$="' + href + '"]').first();

            // Remove active from all other links first
            $('.side-nav :not(a[href$="' + href + '"])').removeClass('active');

            // Expand our current href page
            var sideNav = $('.side-nav');
            sideNav.foundation('down', currentNav.parents('.is-accordion-submenu').last())
            sideNav.foundation('down', currentNav.parents('.is-accordion-submenu').first());
            currentNav.addClass('active');
        }
    },

    sideNav: function () {
        // Accordion side nav
        var hash = window.location.pathname + window.location.hash
        if (!isBaseTheme("pioneer")) {
            KSUJSPlugins.openNavLink(hash);

            // When clicking another menu, remove active class
            $('.is-submenu-item a').on('click', function () {
                $(this).closest('ul').find('a').removeClass('active');
                $(this).addClass('active');
            });

            // Clicking a menu in mobile, close the side-nav before navigating
            $('li:not(.is-accordion-submenu-parent).is-submenu-item a').on('click', function () {
                $('.off-canvas').foundation('close');
            })
        }
    },

    scrollTop: function () {
        //ScrollTop - Go back to top button
        $(window).scroll(function () {
            if ($(this).scrollTop() > 200) {
                $(".scrolltop").fadeIn('slow');
            } else {
                $(".scrolltop").fadeOut('slow');
            }
        });
        $(".scrolltop").click(function () {
            $("html, body").animate({scrollTop: 0}, 'slow');
        });
    },

    orbit: function () {
        $('.orbit').each(function () {
            var startSlide = $(this).data('startposition');
            if (startSlide) {
                var newSlide = $(this).find('[data-slide=' + startSlide + ']');
                $(this).foundation('changeSlide', true, newSlide, startSlide);
            }
        });
    },

    tooltips: function () {
        $('[data-tooltip]').each(function (e) {
            if (typeof $(this).attr('data-toggle') === typeof undefined || $(this).attr('data-toggle') === false) {
                new Foundation.Tooltip($(this), {
                    // hoverDelay: 0,
                    fadeInDuration: 300,
                    fadeOutDuration: 300
                });
            }
        });
    },

    rowClicks: function () {
        // Make an element link to it's data-href
        $('[data-href][data-target="_blank"]').on('click', function (e) {
            window.open($(this).data('href'), '_blank');
        });

        $('[data-href]:not([data-target="_blank"])').on('click', function (e) {
            window.location = $(this).data('href');
        });
    }

};


var KSUMain = {

    init: function () {
        // Removed single sign out for now
        // this.logout();
        //this.fixTiles();
        this.logoutReminder();
        this.pinning();
        this.searchResultFormat();

        // Check bar
        this.checklist();

        this.gpaCalculator();
    },

    sendEmails: function (emails) {
        KSUMain.sendEmailsSub(emails, true);
    },
    sendEmailsSub: function (emails, showswal) {
        var timeout = 2000;
        var mailtoPrefix = 'mailto:?bcc=';
        var maxEmailRecips = 100;
        var separator = ';';
        var currentIndex = 0;
        var nextIndex = 0;

        if (emails.length <= maxEmailRecips) {

            var joined = emails.join(";");
            joined = joined.replace(/;$/, '');

            window.open(mailtoPrefix + joined, "_" + Math.random());
            return;
        }

        if (showswal) {
            swal2("Emailing", "Because you have selected too many recipients, we must open your mail client in batches. You will see several windows to fit all recipients into an email. You may need to enable pop ups for this functionality to work correctly.", "info");
        }


        setTimeout(function () {
            KSUMain.sendEmailsSub(emails.slice(0, maxEmailRecips), false);
            KSUMain.sendEmailsSub(emails.slice(maxEmailRecips), false);
        }, timeout);
    },

    iframe: function () {
        var iFrameID = document.getElementById('iframer');
        console.log(iFrameID);
        if (iFrameID) {
            // here you can make the height, I delete it first, then I make it again
            iFrameID.height = "";
            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
        }
    },

    searchResultFormat: function () {
        $('#solr-group-Extended-Link-all > ol, #solr-group-External-Tile-all > ol, #solr-group-Link-Collection-all > ol, #solr-group-Card-all > ol').each(function () {
            $(this).addClass('row');
        });

        $('#solr-group-Extended-Link-all > ol > li, #solr-group-External-Tile-all > ol > li, #solr-group-Link-Collection-all > ol > li, #solr-group-Card-all > ol > li').each(function (e) {
            $(this).addClass('column medium-4 small-12');
        });
    },


    logoutReminder: function () {
        var url = 'https://support.kent.edu/sims/helpcenter/common/layout/SelfhelpArticleView.seam?inst_name=kent_student&article_id=8210-8096-9810&actionMethod=helpcenter%2Fcommon%2Flayout%2FSelfHelpSearchArticleResult.xhtml%3AivyTechSHPKbManager.searchKBRelatedArticleList&cid=304289';
        $('a:has(.main-menu-drive)').on('click', function () {
            var html = '<div class="alert callout" data-closable><div class="callout-top"><h2>This is Important!</h2><button class="close-button" aria-label="Dismiss alert" type="button" data-close><span class="dripicons-cross" aria-hidden="true"></span><span class="text">Close</span></button></div><div class="callout-bottom"><p>Please remember to use the "Sign Out" button in Gmail/Google Drive when you are finished. <a target="_blank" href="' + url + '">Why?</a></p></div></div>';
            $('#main-content').after(html);
        });
    },

    gpaCalculator: function () {
        if (!$('#gpa-calculator').length) {
            return;
        }

        var grade_points = {
            "A": 4,
            "A-": 3.7,
            "B+": 3.3,
            "B": 3,
            "B-": 2.7,
            "C+": 2.3,
            "C": 2,
            "C-": 1.7,
            "D+": 1.3,
            "D": 1,
            "F": 0,
            "NF": 0,
            "SF": 0,
            "U": 0
        };

        // Build default grade selector
        var selector = '<select style="text-align-last:center;width:100px;">';
        selector += '<option name="grade" value="-1">-</option>';
        for (var i in grade_points) {
            selector += '<option name="grade" value="' + i + '">' + i + '</option>';
        }
        selector += '</select>';

        // Start courses at 1
        var courseNumber = 1;

        function addCourseRow() {
            $('#gpa-calculator table tbody').append('<tr><td>Course  ' + courseNumber + '</td><td>' + selector + '</td><td><input style="margin:0; bntext-align:center;width:70px;" min="0" type="number" maxlength="5"></td></tr>');
            courseNumber++;
        }

        function calculate() {
            var totalPoints = 0;
            var totalCredits = 0;

            $('#gpa-calculator table tr').each(function () {
                var grade = $(this).find('td:nth-child(2) > select').val();
                var credits = $(this).find('td:nth-child(3) > input').val();

                if (grade !== -1 && credits) {
                    credits = parseInt(credits.trim());

                    if (grade in grade_points) {
                        var points = parseInt(grade_points[grade]);

                        totalPoints += points * credits;
                        totalCredits += credits;
                    } else {
                        // bad value
                    }
                }
            });


            var gpa = totalPoints / totalCredits;
            gpa = Math.floor(gpa * 1000) / 1000;

            return gpa.toFixed(3);
        }

        // Add 4 to start
        for (var i = 1; i < 5; i++) {
            addCourseRow();
        }

        $('#GPACalcForm').on('submit', function (e) {
            e.preventDefault();

            var gpa = calculate();
            if (isNaN(gpa)) {
                swal2("Whoops", "Could not calculate your GPA.", 'error');
            } else {
                swal2("Approximate GPA", "Your estimated GPA is " + gpa, 'info');
            }
        });

        $('#GPACalcForm input[name="AddCourse"]').on('click', function () {
            addCourseRow();
        });

        $('#GPACalcForm input[name="Clear"]').on('click', function () {
            $('#GPACalcForm').trigger('reset');
        });
    },


    programmatically_open_checklist: function (node, div) {

        // Hide tooltip that opens
        if ($(this).attr('data-tooltip')) {
            $(this).foundation('hide');
        }

        $('.check-list-header a').reverse().each(function () {
            // make sure the element we're loading has url data attribute
            if ($(this).data().hasOwnProperty('url'))
                KSUMain.load_checklist($(this).text(), '/asyncloader/nojs/node/render/' + $(this).data('url'), false);
        });

        $('#checklist-dropdown').foundation('open');
        //Needs initial delay since off-canvas takes 500 to appear
        setTimeout(function () {
            $('.check-list-header a.active').removeClass('active');

            if (node == '4191') {
                $('a[data-url="4191/checklist"]').addClass('active');
            } else {
                $('a[data-url=' + node + ']').addClass('active');
            }

            $('.check-list-header a.active').focus();

        }, 500);

        // Switching between checklists
        $('.check-list-header a[data-url]').on('click', function (e) {
            e.preventDefault();
            // this might not be used anymore?
            var id = slugify($(this).text());

            $('.check-list-header a.active').removeClass('active');

            //fade-in needs to be a callback function of the fade-out
            //so that the transition in smooth and the new div can occupy the same space
            $('.checklist-content:visible').fadeOut(400, function () {
                $('#' + div).fadeIn();
            });

            $(this).addClass('active');

        });

    },

    checklist: function () {
        // Slide out from right when clicking icon

        $('.checklist-dropdown-init').on('click', function (e) {
            e.preventDefault();

            // Hide tooltip that opens
            if ($(this).attr('data-tooltip')) {
                $(this).foundation('hide');
            }

            $('.check-list-header a').reverse().each(function () {
                // make sure the element we're loading has url data attribute
                if ($(this).data().hasOwnProperty('url'))
                    KSUMain.load_checklist($(this).text(), '/asyncloader/nojs/node/render/' + $(this).data('url'), false);
            });

            $('#checklist-dropdown').foundation('open');
            //Needs initial delay since items inside off-canvas are not visible yet
            setTimeout(function () {
                $('.check-list-header a.active').removeClass('active');
                $('.check-list-header a').first().addClass('active');
            }, 500);

        });


        // Switching between checklists
        $('.check-list-header a[data-url]').on('click', function (e) {
            e.preventDefault();
            var id = slugify($(this).text());

            $('.check-list-header a.active').removeClass('active');

            //fade-in needs to be a callback function of the fade-out
            //so that the transition in smooth and the new div can occupy the same space
            $('.checklist-content:visible').fadeOut(400, function () {
                $('#' + id).fadeIn();
            });

            $(this).addClass('active');

        });

    },

    load_checklist: function (id, url, force) {
        id = slugify(id);

        // Only load the checklist once
        if ($('#' + id).length && !force) {
            return false;
        }

        // Add content container

        if (force) {
            //$('#' + id).closest('.checklist-content').remove();
        }

        $('#checklist-content-wrapper').append('<div id="' + id + '" class="checklist-content"></div>');

        // Get URL to checklist and append to content container
        var checklist = $('#' + id);
        var loader = new ajaxLoader(checklist)
        $.get(url, function (data) {
            if (data.markup !== "0") {
                checklist.html(data.markup);
            }

            loader.remove();
        });

        if (force) {
            checklist.fadeIn();
        }


        return true;
    },

    logout: function () {
        var loaded = 0;
        var urls = [
            'https://commerce.cashnet.com/cashnetk/selfserve/Postsync.aspx',
            'https://learn.kent.edu/webapps/login/?action=logout',
            'https://accounts.google.com/Logout',
            'https://keys.kent.edu/ePROD/twbkwbis.P_Logout',
            'https://solutions.kent.edu/ParkingServices/logout.aspx'
        ];

        function imageLoad() {
            loaded++;

            // All images "loaded"
            if (loaded == urls.length) {
                window.location = '/user/logout';
            }
        }

        $('a[href="/user/logout"]').on('click', function (e) {
            e.preventDefault();
            new ajaxLoader('body');

            $.each(urls, function (i, url) {
                $('<img/>').attr('src', url).appendTo('body').on('error', imageLoad);
            });
        });
    },
    pinning: function () {
        var grid = $('.grid-stack').data('gridstack');
        var onMyDashboard = document.querySelector('#mydash-grid-wrapper');

        var icons = {
            'unpin': '<a href="#" title="Remove from My Dashboard" class="button-pin" data-tooltip aria-haspopup="true" data-current="unpin" aria-label="unpin"><span aria-hidden="true" class="dripicons-cross"></span></a>',
            'pin': '<a href="#" title="Pin to My Dashboard" class="button-pin" data-tooltip aria-haspopup="true" data-current="pin" aria-label="pin"><span aria-hidden="true" class="icon-pin"></span></a>',
            'pinned': '<a href="#" title="Pinned" class="button-pin pinned" data-tooltip aria-haspopup="true" data-current="pinned" aria-label="pinned"><span aria-hidden="true" class="icon-pinned"></span></a>',
        };

        if (grid || onMyDashboard) {
            // Unpin icon
            $('article.pinned').has('.tile, .media-links').prepend(icons.unpin);

            $('article.pinned > .node-extended-link').prepend(icons.unpin);
            $('article.pinned > .quicklinks > .quick-item').prepend(icons.unpin);
            $('article.pinned > .panel').prepend(icons.unpin);
            $('article.pinned .flipper-front .flipper-content').prepend(icons.unpin);
        } else {
            // Pin icon
            $('article.unpinned').has('.tile, .media-links').prepend(icons.pin);
            $('a[data-nodeid].unpinned').prepend(icons.pin);
            $('.three-col-links > li[data-nodeid].unpinned').prepend(icons.pin);

            $('article.unpinned > .panel').prepend(icons.pin);
            $('article.unpinned .flipper-front .flipper-content').prepend(icons.pin);

            // Pinned icon
            $('article.pinned').has('.tile, .media-links').prepend(icons.pinned);
            $('a[data-nodeid].pinned').prepend(icons.pinned);
            $('.three-col-links > li[data-nodeid].pinned').prepend(icons.pin);

            $('article.pinned > .panel').prepend(icons.pinned);
            $('article.pinned .flipper-front .flipper-content').prepend(icons.pinned);
        }

        KSUJSPlugins.tooltips();

        function pinClick(e) {

            stopBoth(e);

            var $pinLink = $(this);
            var $pinIcon = $(this).children('span[aria-hidden]').first();

            var currentState = $pinLink.attr('data-current');

            //User clicked on a check mark
            if (currentState === 'pinned') {

                swal2Btns({
                    customClass: 'pinnedswal',
                    title: 'Pinned Content',
                    text: 'This content is already pinned. You can manage pinned content from My Dashboard.',
                    type: 'info',
                    confirmButtonText: "Go to My Dashboard",
                    showLoaderOnConfirm: true,
                    preConfirm: function () {
                        return new Promise(function (resolve) {
                            //use ball fall animation instead of spinner
                            sweetAlertUseBallFall();
                            window.location.href = "/MyDashboard";
                        });
                    }
                });
                return;
            }

            //Get the article ID, start the spin.
            var id = $pinLink.closest('[data-nodeid]').data('nodeid');
            if (!id) {
                id = $pinLink.closest('article').attr('id').replace('node-', '');
            }
            $pinIcon.removeClass('icon-pin dripicons-cross').addClass('spin-icon spin');

            var pin_verb = (currentState === 'pin') ? "POST" : "DELETE";

            var onMyDashboard = document.querySelector('#mydash-grid-wrapper');

            $.ajax({
                method: pin_verb,
                url: "/api/v1/dashboards/my/pin",
                dataType: "json",
                contentType: 'application/json',
                data: JSON.stringify({"node": id})
            }).done(function (data) {
                if (data['Status'] === 'Success' && data['Message'] === 'Successful Add') {
                    $pinLink.attr('data-current', 'pinned');
                    $pinLink.attr('aria-label', 'pinned');

                    swal2Btns({
                        customClass: 'pinnedswal',
                        title: 'Success',
                        text: 'You pinned this content to your custom dashboard page!',
                        type: 'success',
                        confirmButtonText: "Go to My Dashboard",
                        showLoaderOnConfirm: true,
                        preConfirm: function () {
                            return new Promise(function (resolve) {
                                //use ball fall animation instead of spinner
                                sweetAlertUseBallFall();
                                window.location.href = "/MyDashboard";
                            });
                        }
                    });

                    $pinLink.attr('title', 'pinned');
                    $pinLink.addClass('pinned');

                    $pinIcon.removeClass('spin-icon spin').addClass('icon-pinned');
                    var tooltipId = $pinLink.attr('aria-describedby');
                    var thisTip = document.getElementById(tooltipId);
                    thisTip.textContent = "Pinned";

                } else if (data['Status'] === 'Success' && data['Message'] === 'Successful Removal') {

                    if(onMyDashboard){
                        myDashToolTip($pinLink[0], 'off');
                        myDashRemoveItem(data, id);
                    }
                    
                    if (grid) {

                        swal2({
                            customClass: 'pinnedswal',
                            title: "Success",
                            text: "You unpinned this content from your dashboard page!",
                            type: "success",
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: "Close",
                            focusCancel: "true"
                        });

                        $('#toolbox-bookmarks').show();
                        $('#' + $pinLink.data('toggle')).remove();
                        grid.removeWidget($pinLink.closest('.grid-stack-item'));
                        if (!$('.grid-stack-item').length) {
                            $('#edit-dash').remove();
                            $('.instruction-text').hide().removeClass('hidden').fadeIn();
                        }
                        $pinIcon.addClass('icon-pin').removeClass('dripicons-cross spin-icon spin');
                        $pinLink.closest('article').first().fadeOut().remove();
                    }
                } else {
                    //Unsuccessful pin attempt
                    swal2("Oh nuts!", "Could not submit pin request.", "error");
                    $pinIcon.removeClass('spin-icon spin');
                    //return icon to its starting state
                    if (currentState === 'pin') {
                        $pinIcon.addClass('icon-pin');
                    }
                    if (currentState === 'unpin') {
                        $pinIcon.addClass('dripicons-cross');
                    }

                }

            }).fail(function () {
                //Unsuccessful pin attempt
                swal2("Oh nuts!", "Could not submit pin request.", "error");
                $pinIcon.removeClass('spin-icon spin');
                //return icon to its starting state
                if (currentState === 'pin') {
                    $pinIcon.addClass('icon-pin');
                }
                if (currentState === 'unpin') {
                    $pinIcon.addClass('dripicons-cross');
                }
            });


            // //AJAX
            // $.post("/asyncloader/pintile", {nodeid: id}).done(function (result) {
            //
            //     if (result == 'unpinned') {
            //         $pinLink.attr('data-current', 'pin');
            //         $pinLink.attr('aria-label', 'pin');
            //
            //         swal2({
            //             customClass: 'pinnedswal',
            //             title: "Success",
            //             text: "You unpinned this content from your dashboard page!",
            //             type: "success",
            //             showConfirmButton: false,
            //             showCancelButton: true,
            //             cancelButtonText: "Close",
            //             focusCancel: "true"
            //         });
            //
            //         $('#toolbox-bookmarks').show();
            //
            //         if (grid) {
            //             $('#' + $pinLink.data('toggle')).remove();
            //             grid.removeWidget($pinLink.closest('.grid-stack-item'));
            //             if (!$('.grid-stack-item').length) {
            //                 $('#edit-dash').remove();
            //                 $('.instruction-text').hide().removeClass('hidden').fadeIn();
            //             }
            //             $pinIcon.addClass('icon-pin').removeClass('dripicons-cross spin-icon spin');
            //             $pinLink.closest('article').first().fadeOut().remove();
            //         }
            //
            //     }
            //     else if (result == 'pinned') {
            //         $pinLink.attr('data-current', 'pinned');
            //         $pinLink.attr('aria-label', 'pinned');
            //
            //         swal2Btns({
            //             customClass: 'pinnedswal',
            //             title: 'Success',
            //             text: 'You pinned this content to your custom dashboard page!',
            //             type: 'success',
            //             confirmButtonText: "Go to My Dashboard",
            //             showLoaderOnConfirm: true,
            //             preConfirm: function () {
            //                 return new Promise(function (resolve) {
            //                     //use ball fall animation instead of spinner
            //                     sweetAlertUseBallFall();
            //                     window.location.href = "/MyDashboard";
            //                 });
            //             }
            //         });
            //
            //         $pinLink.attr('title', 'pinned');
            //         $pinLink.addClass('pinned');
            //
            //         $pinIcon.removeClass('spin-icon spin').addClass('icon-pinned');
            //         var tooltipId = $pinLink.attr('aria-describedby');
            //         var thisTip = document.getElementById(tooltipId);
            //         thisTip.textContent = "Pinned";
            //
            //     }
            //     else {
            //         //Unsuccessful pin attempt
            //         swal2("Oh nuts!", "Could not submit pin request.", "error");
            //         $pinIcon.removeClass('spin-icon spin');
            //         //return icon to its starting state
            //         if (currentState === 'pin') {
            //             $pinIcon.addClass('icon-pin');
            //         }
            //         if (currentState === 'unpin') {
            //             $pinIcon.addClass('dripicons-cross');
            //         }
            //     }
            //
            // }).fail(function (result) {
            //     $pinLink.before('<div data-alert class="callout alert radius">' +
            //         'Panel could not be pinned.' +
            //         '</div>').hide().slideDown();
            // }).always(function (result) {
            //     $pinLink.closest('div.callout').fadeOut(2000, function () {
            //         $(this).remove();
            //     });
            //
            //
            // });//End of AJAX

        }

        //Collect anchors and assign listener
        var pinLinkArray = document.querySelectorAll('.button-pin');

        for (var i = 0; i < pinLinkArray.length; i++) {
            pinLinkArray[i].addEventListener('click', pinClick);
        }

    }

};


function ajaxLoader(el, options) {
    // Becomes this.options
    var defaults = {
        duration: 800,
        opacity: 0.7,
        text: '',
        classOverride: false
    };
    this.options = jQuery.extend(defaults, options);
    this.container = $(el);

    this.init = function () {
        var container = this.container;
        // Delete any other loaders
        this.remove();
        // Create the overlay
        var overlay = $('<div></div>').css({
            'opacity': this.options.opacity,
            'content': ' ',
            'display': 'none'
        }).addClass('ajax_overlay').html('<span class="aria-only" role="alert" aria-live="assertive">Loading...</span>' + this.options.text);
        // add an overriding class name to set new loader style
        if (this.options.classOverride) {
            overlay.addClass(this.options.classOverride);
        }
        var inner = $('<div></div>').addClass('ajax_overlay_inner');
        // insert overlay and loader into DOM

        container.prepend(overlay);
        overlay.prepend(inner);
        inner.prepend( $('<div></div>').addClass('ajax_loader spin-icon spin') );
        overlay.fadeIn(this.options.duration);

    };

    this.remove = function () {
        EventDispatcher.PackeryResize(this.container[0]);

        var overlay = this.container.children(".ajax_overlay");
        if (overlay.length) {
            overlay.fadeOut(this.options.classOverride, function () {
                overlay.remove();
            });
        }
    };

    this.init();
}


function shadeBlend(p, c0, c1) {
    var n = p < 0 ? p * -1 : p, u = Math.round, w = parseInt;
    if (c0.length > 7) {
        var f = c0.split(","),
            t = (c1 ? c1 : p < 0 ? "rgb(0,0,0)" : "rgb(255,255,255)").split(","),
            R = w(f[0].slice(4)), G = w(f[1]), B = w(f[2]);
        return "rgb(" + (u((w(t[0].slice(4)) - R) * n) + R) + "," + (u((w(t[1]) - G) * n) + G) + "," + (u((w(t[2]) - B) * n) + B) + ")"
    } else {
        var f = w(c0.slice(1), 16),
            t = w((c1 ? c1 : p < 0 ? "#000000" : "#FFFFFF").slice(1), 16),
            R1 = f >> 16,
            G1 = f >> 8 & 0x00FF, B1 = f & 0x0000FF;
        return "#" + (0x1000000 + (u(((t >> 16) - R1) * n) + R1) * 0x10000 + (u(((t >> 8 & 0x00FF) - G1) * n) + G1) * 0x100 + (u(((t & 0x0000FF) - B1) * n) + B1)).toString(16).slice(1)
    }
}

function slugify(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

// Reverse function
// http://stackoverflow.com/questions/1394020/jquery-each-backwards
jQuery.fn.reverse = [].reverse;

jQuery(function ($) {
    KSUJSPlugins.init();
    KSUMain.init();
});


jQuery.fn.extend({
    getPath: function () {
        var paths = [];

        this.each(function (index, element) {
            var path, $node = jQuery(element);

            while ($node.length) {
                var realNode = $node.get(0), name = realNode.localName;
                if (!name) {
                    break;
                }

                name = name.toLowerCase();
                var parent = $node.parent();
                var sameTagSiblings = parent.children(name);

                if (sameTagSiblings.length > 1) {
                    allSiblings = parent.children();
                    var index = allSiblings.index(realNode) + 1;
                    if (index > 0) {
                        name += ':nth-child(' + index + ')';
                    }
                }

                path = name + (path ? ' > ' + path : '');
                $node = parent;
            }

            paths.push(path);
        });

        return paths.join(',');
    }
});

function isValidEmailAddress(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
};
