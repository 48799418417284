(function() {
    if (typeof window.CustomEvent === "function") return false; //If not IE

    function CustomEvent(event, params) {
        params = params || {
            bubbles: false,
            cancelable: false,
            detail: undefined
        };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
})();


// check to see if jquery is available to us.
// technically, anything could be assigned to $
// but in our case, that would be jquery

if( $ != null ) {
  $(document).ready(function() {
      superselect();
  });
} 

function superselectResizer() {

    /* the smart way -- only resize once every half a second */
    if ($('body').data('allowSuperSelectResize') != 'false') {
        // i think this is in some ways cleaner than just creating some
        // js variable to hold a state...

        var timeout = 1000;
 
        // if this is the first reszie, do it immediately 
        if ($('body').data('allowSuperSelectResize') != 'true') 
            firstTime = true;
  

        $('body').data('allowSuperSelectResize', 'false')
        /* don't let any other resize event try to resize */

        /* wait a half-second, and resize. */


        function superSelectView() {
            $(".superselect-spacer").each(function(i) {
                let $superSelect = $( '#' + $(this).data('parentID') );
                let changeView;

              if( $superSelect != false ) {

                if ($(this).innerWidth() != this.scrollWidth) {
                      /* overflow */ 
                      $(this).parent().removeClass('active');
                      changeView = !($superSelect.hasClass('active'));
                      $superSelect.addClass('active');
                } else {
                      /* underflow */
                      $superSelect.removeClass('active');
                      changeView = !($(this).parent().hasClass('active'));
                      $(this).parent().addClass('active');
                }
                if(firstTime) {
                    $superSelect.addClass('initialized');
                }
                if(changeView) {
                    EventDispatcher.PackeryResize($superSelect[0]);
                }
                
              }
            });

            $('body').data('allowSuperSelectResize', 'true');
            // since this may run on first page load, programmatically creating
            // a new superselect should set allowSuperSelectResize to true
            //$('body').data('allowSuperSelectResize', 'true')
        }




        if(!firstTime){
          window.setTimeout(superSelectView.bind(this), timeout);
        } else {
          superSelectView();
        }




    }


};

function superselect(customSelector) {
    

    // typically we just look for selects with the class 'superselect'
    // but we may need to invoke this manually if we are programmatically
    // generating selects.

    var selector = "select.superselect";

  


    if (customSelector != null)
        selector = customSelector;


    // for each selector that matches...
    $(selector).not('.initialized').each(function(i) {

        // ignore if there is no id on the select 
        if ($(this).attr('id') == null) {
            console.log("could not generate superselect -- no ID associated with element");
            $(this).addClass('js-error');
            return;
        }
        
        // for our convenience, what is our ID?
        var parentID = $(this).attr('id');

        var superID;

        // if data-superselect-id is specified, we don't want to create and append
        // a div -- we want to use an existing div.

        if ($(this).data('superselect-id') != null) {
            // extract that and set it asside
            superID = $(this).data('superselect-id');
        } else {
            // otherwise, generate a new ID based off select's ID
            superID = parentID + "-superselect";
        }


        // initially hide the select box
        // Doing this with CSS 

        // add an on-change event on the select, so when it changes, we also add / remove
        // the 'selected' class to the alternative list of items.
        $('select.superselect').change(function() {

            // remove all selected classes on change.
            // go to the parent of the select, and find the first div, and then its children

            $('#' + superID).children(".superselect-item").each(function(i) {
                $(this).removeClass("selected");
            });
            $('#' + superID).children("div.superselect-item:nth-child(" + (this.selectedIndex * 2 + 1) + ")").addClass("selected");


        });

        // now we need to add the new menu items

        // if we need to create the new container, do so now.
        // or, rather, if no existing div is specified, create one.

        if ($(this).data('superselect-id') == null) {
            var container = document.createElement("div");
            container.className = "superselect";
            container.setAttribute('id', superID);
            
            $(container).insertAfter($(this));
        } else {
            // just make sure the target div has superselect on it.
            $('#' + superID).addClass('superselect');
        }

        // for each of its options, create the corresponding item
        $(this).children().each(function(i) {


            var menuItem = document.createElement("div");
            menuItem.className = "superselect-item";

    
            var pid = $(this).parent(); // attr('parent-ID');

            if( pid.data('wide') ) 
              menuItem.className += " superselect-wide";

            menuItem.setAttribute('tabindex', 0);
            menuItem.innerHTML = $(this).html();
            menuItem.dataset.val = $(this).attr('value');
            menuItem.dataset.parentID = parentID;

            var subspacer = document.createElement("div");
            subspacer.className = "superselect-subspacer";

            // if the option is marked as selected, make it selected in the 
            // alternate view, too.
            if ($(this).attr('selected')) {
                menuItem.setAttribute('aria-selected', true);
                menuItem.className += " selected";
            }
            else {
              menuItem.setAttribute('aria-selected', false);
            }
            menuItem.setAttribute('role', 'tab');


            menuItem.addEventListener('keyup', function(event) {

                event.preventDefault();

                if (event.keyCode !== 13)
                    return;

                // whenever a menu item is selected, add / remove the 'selected' class
                $('#' + this.dataset.parentID).val(this.dataset.val).prop('selected', true);
                $(this).parent().children().removeClass("selected");
                $(this).addClass("selected");

                // we need to be aware that the select may have an onchange event.
                // so we need to trigger that. However, it's not always straightforward
                // to do so.

                var element = document.getElementById(this.dataset.parentID);
                if (typeof window.CustomEvent === "function") {
                    /* for early versions of IE */
                    var event = new CustomEvent('change');
                    element.dispatchEvent(event);
                } else {
                    var event = new Event('change');
                    element.dispatchEvent(event);
                }

            });


            menuItem.addEventListener('click', function(event) {
                // whenever a menu item is selected, add / remove the 'selected' class
                $('#' + this.dataset.parentID).val(this.dataset.val).prop('selected', true);
                $(this).parent().children().removeClass("selected");
                $(this).parent().children('.superselect-item').attr("aria-selected", false);
                $(this).addClass("selected");
                $(this).attr("aria-selected", true);

                // we need to be aware that the select may have an onchange event.
                // so we need to trigger that. However, it's not always straightforward
                // to do so.

                var element = document.getElementById(this.dataset.parentID);
                if (typeof window.CustomEvent === "function") {
                    /* for early versions of IE */
                    var event = new CustomEvent('change');
                    element.dispatchEvent(event);
                } else {
                    var event = new Event('change');
                    element.dispatchEvent(event);
                }

            });

            $('#' + superID).append(menuItem);
            $('#' + superID).append(subspacer);
        });

        var spacer = document.createElement("div");
        spacer.className = "superselect-spacer";
        spacer.dataset.parentID = parentID;

        // this non breaking space is very important -- without it
        // then there is no overflow detection.
        if ($('#' +  parentID).data('ignoreoverflow') != true) {
          spacer.innerHTML = "&nbsp;&nbsp;";
        }
        $('#' + superID).append(spacer);

        
    });

    superselectResizer();

    $(window).resize(function() {
        superselectResizer();
    });
    

}
