var profilePopOrigin;
var profilePop;
var profilePopOpen;
var profilePopClose;

function ESCprofilePop(e){
  if(e.keyCode === 27){
    $('#profile-popup').foundation('close');
  }
}

function profilePopClick(e){
    profilePopOrigin = e.currentTarget;

}

function profilePopDesktop(e){
  if(profilePop){
    //avatar is available
    //console.log('profilePopDesktop');
    nav1Area.appendChild(profilePop);
    profilePopClose.textContent = 'Close';
    if (siteWrapper.classList.contains('nav-narrow')){
      profilePopOrigin = navNarrow.querySelector('button.avatar');
    } else {
      profilePopOrigin = navWide.querySelector('button.avatar');
    }
  } else {
    //avatar is hidden
    //console.log('avatar is hidden.');
  }
}

function profilePopMobile(e){
  if(profilePop){
    //avatar is available
    //console.log('profilePopMobile');
    navMobile.appendChild(profilePop);
    profilePopOrigin = navMobile.querySelector('button.avatar');
    profilePopClose.textContent = 'Back';
    if(!navMobile.classList.contains('is-open')){
      $('#profile-popup').foundation('close');
    }
  } else {
    //avatar is hidden
    //console.log('avatar is hidden.');
  }
}

function profilePopInstall(){
if (isBaseTheme('pioneer')) {
  profilePop = document.querySelector('#profile-popup');

if (profilePop){

  profilePopClose = document.querySelector('#profile-popup-close');
  profilePopOpen = document.querySelectorAll('button.avatar');

  if(profilePopClose){
    profilePopClose.addEventListener('click', function(){
      $('#profile-popup').foundation('close');
    });
  }

  for(var i = 0; i < profilePopOpen.length; i++){
    profilePopOpen[i].addEventListener('click', profilePopClick);
    profilePopOpen[i].addEventListener('focus', function(e){
      if(e.currentTarget.getAttribute('tabindex') === '-1'){
        profilePopOrigin.focus();
      }
    });
  }

  $('#profile-popup').on('show.zf.dropdown', function () {
    var profileScrollArea = document.querySelector('#profile-popup [data-scrollarea]');
    profileScrollArea.scrollTop = 0;
    document.addEventListener('keydown', ESCprofilePop, true);

    profilePop.setAttribute('tabindex', '0');
    profilePop.addEventListener('keydown', function(e){
      if(e.target.classList.contains('dropdown-pane')){
        disableBackTab(e);
      }
    });

  
    //if mobile send focus to close button
    //else, send focus to popup area
    if (window.innerWidth < 1024) {
      setTimeout(function(){ profilePopClose.focus(); }, 100);
    } else {
      profilePopClose.focus();
    }
    var mobileMenu = document.getElementById('nav-mobile');
    mobileMenu.classList.add('innersteps');

  });

  $('#profile-popup').on('hide.zf.dropdown', function () {
    var mobileMenu = document.getElementById('nav-mobile');
    mobileMenu.classList.remove('innersteps');
    document.removeEventListener('keydown', ESCprofilePop, true);
    if (window.innerWidth < 1024) {
      setTimeout(function(){ profilePopOrigin.focus(); }, 100);
    } else {
      profilePopOrigin.focus();
    }
  });
}//Closing if Profile Pop
}//Closing if Pioneer
}


document.addEventListener('DOMContentLoaded', profilePopInstall);
