
function eventCalendar( container, subscriptions ) {

  this.calendarContainer = container + "-cal";
  this.categoryCounts = new Object();
  this.categoryList;
  this.categories = new Array();
  this.categoryWSE = '/asyncloader/getEntity/371';
  this.checkedCategories; // internal use only
  this.container = container;
  this.dateStartingOffset;
  this.dateEndingOffset;
  this.dt;
  this.events;
  this.listOfCategories = new Array();
  this.myTagSubscriptions = subscriptions;
  this.savedEvents;
  this.sidebar;
  this.wse = '/asyncloader/getEntity/801';
  this.engagewse = '/asyncloader/getentity/586';

  this.options = new Object();
  this.options.slim = false;
  

  this.clean = function( category  ) {
      // keep semicolons -- some tags have multiple values, comma separated
      return category.toLowerCase().replace(/[^a-zA-Z0-0;]/g, '_');
  }

  this.clearCategories = function() {
    $('.checkbox-category').prop('checked',false);
    $('#checkbox-caltype-stuorg').prop('checked', false);
    $('#checkbox-caltype-fp').prop('checked', false);
  }
  this.clearSearch = function() {
    document.getElementById("ec-cal_searchbox").value = "";
    this.dt._search = "";    
    this.dt.search();
  }
  this.initSidebar = function() {

    // first time setup for the sidebar.

    var buildSpacer = function() {
      var spacer = document.createElement("hr");
      return spacer;
    }

    // these could all be private, local functions encapsulated in this
    // method, but I'll keep them separate because of their size.
      
    $('#events-sidebar' ).append( this.buildSkeleton() );
    $('#events-sidebar' ).append( this.buildCalendarTypes() );
    $('#events-sidebar' ).append( buildSpacer() );
    $('#events-sidebar' ).append( this.buildCategories() );
    $('#events-sidebar' ).append( buildSpacer() );
    $('#events-sidebar' ).append( this.buildYouMayLike() );

  }

  this.buildSkeleton = function() {

    var sk = document.createElement("div");
    sk.innerHTML = "<hr><hr><hr><hr><hr>";
    sk.className = "events-sidebar-skeleton";
    sk.style.display = "none";
    return(sk);

  } 
  this.buildCategories = function() {
      
    var buildCategoryToggle = function() {
 
      var createUpArrow = function() {
        upArrow = document.createElement("i");
        upArrow.className = "dripicons-chevron-up";
        upArrow.setAttribute("aria-hidden", "true");

        var up = document.createElement("div");
        up.setAttribute("id", "categories-up");
        up.className = "small-2";
        up.style.display = "none";
        up.style.fontSize = "110%";
        up.appendChild( upArrow ); 

        return( up );
      }

      var createDownArrow = function() {
        downArrow = document.createElement("i");
        downArrow.className = "dripicons-chevron-down";
        downArrow.setAttribute("aria-hidden", "true");

        var down = document.createElement("div");
        down.setAttribute("id", "categories-down");
        down.className = "small-2";
        down.style.fontSize = "110%";
        down.appendChild( downArrow );

        return( down );
      }

      var createCategoryGroupTitle = function() {
        categoryAnchor = document.createElement("a");
        categoryAnchor.className = "nostyles event-category-line event-category-header-text";
        categoryAnchor.href = "#";
        categoryAnchor.innerHTML = "Category";

        var categoryTitle = document.createElement("div");
        categoryTitle.className = "cell small-10 events-sidebar-group";
        categoryTitle.appendChild( categoryAnchor );

        return( categoryTitle ); 
      }
 
      var categoryHeader = document.createElement("div");
      categoryHeader.className = "sidebar-subtitle events-sidebar-container grid-x grid-margin-x category-toggle";
      categoryHeader.onclick = function() { $('.events-category-list').toggle('slow');$('#categories-up').toggle();$('#categories-down').toggle();};
      

      categoryHeader.appendChild( createCategoryGroupTitle() );

      categoryHeader.appendChild( createUpArrow() );
      categoryHeader.appendChild( createDownArrow() );

      return categoryHeader;
    };

    var allCategories = document.createElement("div");
    allCategories.classList.add('event-sidebar-section');

    allCategories.appendChild( buildCategoryToggle() );


    var buildCategoryList = function() {

      // add title for this section
      var categorySubContainer = document.createElement("div");
      categorySubContainer.className = "event-category-line events-category-list  grid-x grid-margin-x";
      categorySubContainer.style.display = "none";

      // we need to loop over all the events and build a count for each category.
      for( var x = 0; x < this.events.length; x++) {
        // initialize if necessary
        this.categoryCounts[ this.events[x].Category]  == null ? this.categoryCounts[ this.events[x].Category ] = 1 : this.categoryCounts[ this.events[x].Category ]++;
        this.categories.push( this.events[x].Category );
      }

      // we built a list of categories in the for loop above. But, we
      // want to sort it and get rid of duplicates. Enter lodash:

      this.categories.sort();
      this.categories = _.uniq( this.categories, true );


      // so this is a little nasty...

      for( var x = 0; x < this.categories.length; x++) {

        // create a container for the category
        var container = document.createElement("div");
        container.className = "cell event-category-line small-12 medium-4 large-12";

        // create a hidden checkbox.  When it changes, re-filter the event list.
        var inp = document.createElement("input");
        inp.setAttribute("id", "category_filter_cb_" + x);
        inp.type = 'checkbox';
        inp.className = "checkbox-category fl-check";
        inp.onchange = function() { if( this.options.slim ) return; this.filter(); }.bind(this);
        inp.dataset.category = this.categories[x];

        // create a label, and add an event to it
        var label = document.createElement("label");
        label.className = "event-category-label";
        label.setAttribute("for", "category_filter_cb_" + x );
        //label.setAttribute('tabindex', '0');
        label.innerHTML = this.categories[x] + " <span class='event-category-count'>(" + this.categoryCounts[ this.categories[x] ] + ")</span>";

        label.onkeypress = function(event) {
          labelKeyPress(event, function() { 
            this.filter(); 
          }.bind(this));
        }.bind(this);



        container.appendChild( inp );
        container.appendChild(label);
      
        categorySubContainer.appendChild( container );
      }
      return( categorySubContainer );
    }.bind(this);
      
    allCategories.appendChild( buildCategoryList() );
    return( allCategories ); 
  }

  this.buildYouMayLike = function() {

    // this is for building the FlashPERKS / Stu Org radio buttons on the left
    // sidebar. If you want to add another type, you would add it to the bottom,
    // and you would have to also update the filter for calendar types (since it
    // only looks at stu org and flashperks today)  

    var allCalendarTypes = document.createElement("div");
    allCalendarTypes.className = "event-category-line events-sidebar-container grid-margin-x event-sidebar-section";

    var header = document.createElement("div");
    header.className = "cell sidebar-subtitle events-sidebar-group";

    var anchor = document.createElement("a");
    anchor.innerHTML = "You May Like";
    anchor.className = "nostyles event-category-header-text";
    header.appendChild( anchor);

    allCalendarTypes.appendChild(header);

    this.calendarType = function( text, id ) {  
      var container = document.createElement("div");
      container.className = "event-category-line cell small-6 medium-4 large-12";
      
      var label = document.createElement("label");
      label.className = "event-category-label";
      label.setAttribute("for", id);
      label.innerHTML = text;
      //label.setAttribute("tabindex", "0");
      label.onkeypress = function(event) { 
        labelKeyPress(event, function() { this.filter(); }.bind(this));
      }.bind(this);

      var inp = document.createElement("input");
      inp.setAttribute("id", id);
      //inp.setAttribute("tabindex", "-1");
      inp.type = 'checkbox';
      inp.classList.add('fl-check');
      inp.onchange = function() { if( this.options.slim ) return; this.filter(); }.bind(this);

      container.appendChild(inp);
      container.appendChild( label );

      return( container );
    }
    
    allCalendarTypes.appendChild( this.calendarType("Hosted by Student Orgs", "checkbox-caltype-stuorg") );
    // allCalendarTypes.appendChild( this.calendarType("FlashPERKS events", "checkbox-caltype-fp") );
     
    return( allCalendarTypes );

  }


  this.buildCalendarTypes = function() {

    var allButtons = document.createElement("div");
    allButtons.classList.add('event-sidebar-section');

    var buildRadio = function( labelText, mode, checked ) {

      var parentdiv = document.createElement("div");
      parentdiv.className = "event-category-line grid-x grid-margin-x";

      var subdiv= document.createElement("div");
      subdiv.className = "cell small-12";

      var label = document.createElement("label");
      label.setAttribute("for", "cal-type-" + mode);
      //label.setAttribute("tabindex", 0);
      label.className = "event-category-label";
      label.innerHTML = labelText;

      label.addEventListener('keypress', function(e) {

        // I suppose there is a difference here.  The regular
        // method -- click -- only fires on change. This will fire
        // on every key press. So, if you're on page 2, and you go back
        // and hit enter, it'll refresh this and send you back to page 1.
        if (e.key === "Enter" || e.charCode == 32) {

          $('.radio-active-hahaha').removeClass('radio-active-hahaha');

          var attr = this.getAttribute('for');
          $('#' + attr ).addClass('radio-active-hahaha');
       
        } 
      });

      label.addEventListener('keypress', function(event) {

        if (event.key === "Enter" || event.charCode == 32) {
          labelKeyPress(event, function() { this.dt.gotoPage(0,true); this.filter(); }.bind(this), true);
        }
      }.bind(this));

      var inp = document.createElement('input');
      inp.setAttribute('id', 'cal-type-' + mode );
      inp.type='radio';
      inp.classList.add('fl-radio');
      inp.setAttribute('name', 'event-cal-type');
      //inp.setAttribute('tabindex', '-1');
      inp.dataset.mode = mode;
      inp.addEventListener('change', function() {
        $('.radio-active-hahaha').removeClass('radio-active-hahaha');  
        this.className += " radio-active-hahaha"; 
      } );
      inp.addEventListener('change', function() {
        this.dt.gotoPage(0,true);
        this.filter();
      }.bind(this) );

      if( checked == true ) {
        inp.checked = true; 
        inp.className += " radio-active-hahaha";
      }
       


      
      subdiv.appendChild( inp );
      subdiv.appendChild( label );
      parentdiv.appendChild( subdiv );

      return( parentdiv );

    }.bind(this); 

    allButtons.appendChild( buildRadio("All Events", "all", true) );
    allButtons.appendChild( buildRadio("My Saved Events", "saved") );
    allButtons.appendChild( buildRadio("Events For You", "subscribed") );
    return( allButtons );

  }

  this.showDefaultComponents = function() {

      // main sections of the content
      $('#ec').show();
      $('#no-events').hide();                   // assume events
      $('#event-preferences').hide();
      $('#event-preferences-header').hide(); 
       
      // handle the headers:
      if( !this.options.slim) {
        $('#events-search-container').show();
        $('#report-range-container').show();
         $('.events-sidebar-container').show();
      } else {
         $('.events-body').removeClass('large-9').addClass('large-12');
         $('.events-sidebar-container').hide();

      }
      $('#remove-preferences-container').hide();
      $('#preferences-spacer').show();
      $('#preferences-button-container').hide();  
      $('#backtocalendar').hide();

  }


  this.filter = function() {
    if( this.options.slim == true ) {
      this.dt._tagFilter = function( line, first ) {

        if( line == "engageEvent" )
            return true;
        else
            return false;
      }.bind(this);
 
      this.dt.draw();
      return; 
    }


    var mode;

    mode =  $('.radio-active-hahaha').data('mode');

    // one exception:  if we are displaying a custom calendar
    // we just set mode to custom.
    if( this.custom != undefined && this.custom == true )
      mode = "custom";


    // whenever a radio button for the type of calendar is clicked,
    // the event adds the class to it. We do this so the change event
    // can add the class to the object, but it doesn't know about the
    // object.  There is a second event that then identifies what was
    // clicked. So, its fairly important that one of those elements 
    // always has the class.

    // filter is frequently called when a checkbox is ticked or 
    // a different calendar is selected.


    if( mode == "all") {

      // this is super easy.

      // 1.) make sure we show the proper elements
      
      this.showDefaultComponents();

      // 2.) set up the filters we want.  That is, 
      // check for flashperks / stuorg checkbox,
      // check the categories
      // and the date range.
      // search terms are handled automatically.

      this.dt._tagFilter = function( line, first ) {

        return( 
          this.filterCalendarTypes(line, first ) && 
          this.filterCategories( line, first ) && 
          this.filterDateRange( line, first )
        );
      }.bind(this);

      // 3.) draw the table itself.
      this.dt.draw();
    }


    if( mode == "custom" )
    {
      // custom is a little different. recall that custom
      // calendars are defined by url arguments. There's
      // no date range, there's no categories.

      this.dt._tagFilter = function( line, first ) {
        return( this.filterCustom( line, first ) )
  
      }.bind(this);
      this.dt.draw();

    }

    if( mode == "subscribed" ) {

      this.showDefaultComponents();


      var hasSubscriptions = false;

      for( var y in this.myTagSubscriptions) {
        if( this.myTagSubscriptions[y] == 1 ) {
          hasSubscriptions = true;
         
          $('#remove-preferences-container').show();
          $('#remove-preferences').show();
          break;
        }
      } 

      
      if( !hasSubscriptions ) {

        $('#ec').hide();
        $('#no-events').hide();
        $('#event-preferences').show();

        $('#event-preferences-header').show();

        $('#preferences-spacer').hide();
        $('#events-search-container').hide();
        $('#report-range-container').hide();
        $('#preferences-button-container').hide();
        $('#remove-preferences-container').show();
        $('#backtocalendar').show();

        this.buildEventsForYouOptions();
        this.buildCountOfCheckedCategories();

        return;
      }

      // user has at least one category they're watching
      $('#preferences-button-container').show();
      $('#preferences-spacer').hide();
      $('#remove-preferences-container').hide();

      this.dt._tagFilter = function( line, first) {

        return( 
          this.filterCategories( line, first) 
          && this.filterSubscribed( line, first )
          && this.filterCalendarTypes(line, first )
          && this.filterDateRange( line, first )
       );
      }.bind(this);
      this.dt.draw();
    }

    if( mode == "saved" ) {

      this.showDefaultComponents();

      this.dt._tagFilter = function( line, first ) {
        return( 
          this.filterSaved(line, first) 
          && this.filterCalendarTypes(line, first )
          && this.filterCategories( line, first) 
          && this.filterDateRange( line, first )
        );
      }.bind(this);
      this.dt.draw();
    }
  }




  // FILTERS BELOW:

  // Each filter takes two arguments -- tags and first.
  //
  // tags is the tag collection for the line, and first is either
  // true or false if it is the first line -- sometimes we need
  // to do something special on the first pass.

  this.filterDateRange = function( tags, first ) {
      // remember, the select is a comma separated pair, where 0,7 would be events from
      // today (today+0) until 7 days later (today+7days) for example.

      // so now we calculate the window for dates we want to examine

      var event_window_start = new moment().add(this.dateStartingOffset - 1, "days");
      var event_window_end = new moment().add(this.dateEndingOffset, "days");

      // line.year comes over as two digits, make it 4. we're creating the date with
      // yyyy-dayofyear but dayofyear needs to be 0 padded.
      var eventdate = new moment("20" + tags.year + "-" + ("000" + tags.dayofyear).slice(-3));

      // as long as the event date is within the range we specified, return true
      if (eventdate.format('x') >= event_window_start.format('x') && eventdate.format('x') <= event_window_end.format('x'))
          return true;
      return false; // implied else

  }


  this.filterSaved = function( tags, first ) {
    // when we loaded all the events in, we added the tag 'savedEvent' if it was
    // indeed saved.

    if( tags.savedEvent != undefined && tags.savedEvent == true ) 
      return true;

    return false;
  }

  this.filterSubscribed = function( tags, first ) {
    // likewise, if the category is one we subscribe to, we mark it
    // as subscribed. This is a good and bad thing.

    if( tags.subscribed != undefined && tags.subscribed == true ) 
      return true;
    return false;

  }
  this.filterCalendarTypes = function( tags, first ) {
    // check if calendar types are selected.

    // var fp =  $('#checkbox-caltype-fp').prop('checked');
    var fp = false;
    var stuorg = $('#checkbox-caltype-stuorg').prop('checked');


    // if they're both unchecked, we pass this test since we
    // don't care if it is a student org / fp event or not

    if( fp == false && stuorg == false )
      return true;

    if( fp == true && tags.flashperksEvent == true )
      return true;

    if( stuorg == true && tags.studentOrgEvent == true )
      return true;

    return false; 

  }

  this.filterCategories = function( tags, first ) {
  
    if( first == true ) {

      // first pass through, we need to see what categories
      // are checked. Then we can decide if the event matches.

      this.checkedCategories = new Array();

      var cats = document.getElementsByClassName( 'checkbox-category' );
      for( var x = 0; x < cats.length; x++) {
        if( cats[x].checked )
          this.checkedCategories.push( cats[x].dataset.category );
      }
    }

    // if no categories are checked, then interpret that as 
    // match all categories.

    if( this.checkedCategories.length == 0 )
      return true;

    // otherwise check to make sure the event matches.    
    for( var x = 0; x < this.checkedCategories.length; x++) {

      if( this.checkedCategories[x]  == tags.category ) 
        return true;
    
    } 
    return false;

  }

  this.filterCustom = function( tags, first ) {

    if( first == true ) {
     
        var params = window.location.search;
        if( params.length > 1 ) 
          params = params.substring(1).toLowerCase();

        params = params.split('&');

        for( var x = 0; x < params.length; x++) {

          var parts = params[x].split('=');

          if( parts[0] == "cat")
            this.options.customCategories = parts[1].split(","); 
         
          if( parts[0] == "campus")
            this.options.customCampus = parts[1]; 

          if( parts[0] == "div")
            this.options.customDivision = parts[1];
    
          if( parts[0] == "dept")
            this.options.customDepartment = parts[1];

        }

    }

    var pass = false;


    // find all categories that match, but then
    // filter out anything that is not in the department / division
    // or campus if specified.

    if( this.options.customCategories != undefined ) {
      for( var x = 0; x < this.options.customCategories.length; x++) {
        if( this.clean(tags.category).startsWith( this.options.customCategories[x] ) )
          pass = true; 
      }
    }


    if( this.options.customDepartment != undefined ) {
      if( this.clean(tags.department) == this.options.customDepartment )
        pass = true;
      else
        pass = false;
    }

    if( this.options.customDivision != undefined ) {
      if( this.clean(tags.division) == this.options.customDivision)
        pass = true;
      else
        pass = false;
    }

    if( this.options.customCampus != undefined ) {

        if( tags.campus.toLowerCase().substr(0,4) == this.options.customCampus.substr(0,4) )
          pass = true;
        else
          pass = false;
    }


    return( pass );


  }

  this.datepickerInit = function() {

        var rangeDiff;
        var start = new moment();
        var end = new moment().add(3650, 'days');
        var label = 'All Upcoming Events';

        moment.tz.setDefault("America/New_York");

        // now we need to set up the date picker

        var today = new moment();
        $('#reportrange').daterangepicker({
            minDate: new moment(),
            startDate: start,
            endDate: end,
            opens: 'left',
            parentEl: '#mycal',
            buttonClasses: 'button v2',
            cancelButtonClasses: 'button-ghost',
            ranges: {
                'All Upcoming Events': [today, new moment().add(3650, 'days')],
                'Today': [today, today],
                'Tomorrow': [new moment().add(1, 'days'), new moment().add(1, 'days')],
                'Next 3 Days': [today, new moment().add(3, 'days')],
                'Next 7 Days': [today, new moment().add(7, 'days')],
                'Next 14 Days': [today, new moment().add(14, 'days')],
                'Next 30 Days': [today, new moment().add(30, 'days')],
            }
        //}, this.datepickerSetDate);
        }, function(s,e,l) { this.datepickerSetDate(s,e,l) }.bind(this) );

        // and initialize it to the proper date / time
        this.datepickerSetDate(start, end, label);

        var pickerInput = document.getElementById('report-range-container');
        pickerInput.addEventListener('keydown', (e)=>{
  
          e.stopPropagation();
          /*
          var openKeys = ['Enter', 'Space', ' '];
          if(openKeys.indexOf(e.key) !== -1){
            if(e.key === 'Enter') e.preventDefault();

            swal2("Coming Soon", "This filter is not yet keyboard accessible.", "info");
          }
          */

          
        }, true);

  }

    this.datepickerSetDate = function(start, end, label) {

        //Current start and end dates sent to globals.
        //Need this rangeDiff to arrive at correct dateStartingOffset
        rangeDiff = end.diff(start, "days");
        this.dateStartingOffset = 0; // default to 0 unless otherwise noted.

        if (label == 'All Upcoming Events')
            this.dateEndingOffset = 3650;

        if (label == 'Today')
            this.dateEndingOffset = 0;

        if (label == 'Tomorrow')
            this.dateStartingOffset = this.dateEndingOffset = 1;

        if (label == 'Next 3 Days')
            this.dateEndingOffset = 3;

        if (label == 'Next 7 Days')
            this.dateEndingOffset = 7;

        if (label == 'Next 14 Days')
            this.dateEndingOffset = 14;

        if (label == 'Next 30 Days')
            this.dateEndingOffset = 30;

        if (label == 'Custom Range') {

            $('#reportrange span').html(start.format('MM/D/YYYY') + ' - ' + end.format('MM/D/YYYY'));

            //dateStartingOffset = start.diff(moment(), "days");

            // The above line should have worked to arrive at the dateStartingOffset, BUT this
            // kept returning incorrect values. For example, it returned zero for both
            // today AND tomorrow instead of zero for today and one for tomorrow. Subtracting
            // the overall rangeDiff from the dateEndingOffset works everytime though as expected.

            // yeah, I saw that too.  I was broken for the static date picker, which i solved
            // by subtracting one from the start range -- Jared

            this.dateEndingOffset = end.diff(new moment(), "days");
            this.dateStartingOffset = this.dateEndingOffset - rangeDiff;
        }
        else {
            $('#reportrange span').html(label); // had span before
        }

        // eventDivTable might be null if this is initialized before the actual content.
        // most likley a race condition with pulling down the JSON calendar feed.
        if ( this.dt != null) {
            // draw forces the re-application of the event filter, btw
            // which is what applies the new data range...
            //eventDivTable.draw();
            //this.dt.draw();
            this.dt.gotoPage(0,true);
            this.filter();
        }
    }





  this.init = function() {


    superselect('#viewmode');
    // check to see if this is a custom category.

    var params = window.location.search;

    if( params.length > 1 ) {
 
      params = params.substring(1).toLowerCase();
      params = params.split('&');

      for( var x = 0; x < params.length; x++) {
        var parts = params[x].split('=');
        if( parts[0] == "cust" && parts[1] == "1")
          this.custom = true; 
      }

    }


    // Get a list of categories the user subscribed to

    // i think originally i thought we would call this
    // multiple times, but in reality we really don't.
    if( this.categoryList == undefined )
    {
      // this will retry until it succeeds.  should be improved.
      $.getJSON( this.categoryWSE, function(data) {
        this.categoryList = data;
        this.init();
      }.bind(this)); 
      return false;
    }


    // get a lit of saved events

    if( this.savedEvents == undefined )
    {
      // i think there is room to clean this up. i think when we 
      // unsave an event, it is probably marking it as 0. we should
      // then just add to savedEvents the ones with a 1. but idk for sure

      $.getJSON('/user_profile/event/list', function (data) {
        this.savedEvents = new Array();
        if (data != null) {
            keys = Object.getOwnPropertyNames(data);

            for (x = 0; x < keys.length; x++) {
                if (keys[x] == "length") {
                    continue;
                }
                // keys in array are not necessarily sequential...  ugly AF
                this.savedEvents[x] = data[keys[x]];
            }
        }
        this.init();

      }.bind(this));
      return false;
    }


    // build events for buttons

    $('#viewmode').change( function(e ) {

      if( e.currentTarget.selectedIndex == 1 ) {

        this.options.slim = true;
   
         //$("#events-sidebar-container").children().hide();    
         $(".events-sidebar-container").hide();    
        ////$(".events-sidebar-skeleton").show();
   
        $("#events-sidebar").animate({width:'toggle'},350, "swing", function() { this.datepickerInit(); }.bind(this));
   
        $('#events-search-container').hide();
        $('#report-range-container').hide();
      

        $('.events-body').removeClass('large-9').addClass('large-12');
        //$('.events-sidebar-container').hide();
        $('.save-column').hide();
        //this.datepickerInit();

        this.clearSearch();
        this.clearCategories();
        $('#cal-type-all').prop("checked", true );
        // this.filter();
         // this.dt.draw();
      }
      else
      {

        this.options.slim = false;

        //$("#events-sidebar").children().show();    
        $(".events-sidebar-container").show();    
        ////$(".events-sidebar-skeleton").hide();

        $('#events-search-container').show();
        $('#report-range-container').show();
        $('.events-body').removeClass('large-12').addClass('large-9');
        $("#events-sidebar").animate({width:'toggle'},350);
        $('.save-column').show();
        this.filter();
        this.dt.draw();
      }


    }.bind(this) );


    $('#remove-preferences').click( function() {

        $('input[data-category]:checked').prop("checked", false);

        // improved. Used to loop over all categories and set to false.
        // no need.  Just wipe them. May actually be quicker to pull off
        // the :checked
        
        // cat_none is special -- it tells it to clear all categories instead
        // of just one category.
        this.toggle_my_categories("cat_none", 0);

    }.bind(this));


    $('#preferences-button').click( function() {
      this.buildEventsForYouOptions()
      this.buildCountOfCheckedCategories();

      $('#ec').hide();
      $('#events-search-container').hide();
      $('#preferences-button-container').hide();
      $('#report-range-container').hide();
      $('#event-preferences').show();
      $('#event-preferences-header').show();
      $('#backtocalendar').show();
      $('#remove-preferences-container').show();
      $('#no-events').hide();
      // $('#events-search-container').hide();
    }.bind(this));

    $('.back-to-calendar').click(  function() {
      $('#remove-preferences').hide();      
      var hasSubscriptions = false;
      for( var y in this.myTagSubscriptions) {
        if( this.myTagSubscriptions[y] == 1 ) {
          hasSubscriptions = true; 
          break;
        }
      } 
      if( !hasSubscriptions )
      {
        this.showDefaultComponents();
/*
        $('#preferences-button').hide();
        $('#preferences-button-container').show();
        $('#no-events').hide();
        $('#ec').show();
        // check the button...
        $('.radio-active-hahaha').removeClass('radio-active-hahaha');
*/
        $('#cal-type-all').addClass('radio-active-hahaha');
        $('#cal-type-all').prop('checked', true );
        this.filter();
      }
      else
      {
        
        this.showDefaultComponents();
        $('#cal-type-subscribed').addClass('radio-active-hahaha');
/*
        $('#no-events').hide();
        $('#events-search-container').show();
        $('#report-range-container').show();
        $('#preferences-button-container').show();
        $('#preferences-button').show();
        $('.radio-active-hahaha').removeClass('radio-active-hahaha');
        $('#cal-type-subscribed').addClass('radio-active-hahaha');
      */
        this.filter();
        
      }
    }.bind(this) );
  

    // initial setup of the containers 

    var calendarDiv = document.createElement('div');
    calendarDiv.setAttribute('id', this.calendarContainer);
    calendarDiv.className = "small-12";

    var dateRangeSelect = document.createElement("div");

    var searchContainer = document.createElement('div');
    searchContainer.setAttribute('id', 'events-search-container');
    searchContainer.className = "cell small-12";


    calendarDiv.appendChild( searchContainer );
    calendarDiv.appendChild( dateRangeSelect );
    
    $('#' + this.container).append( calendarDiv );

    this.datepickerInit();

    // if we already have event data, just use that.
    if( this.events == undefined ) {
      $.getJSON( this.engagewse, function(data ) {

        this.engageevents = data;
      
        $.getJSON( this.wse, function( data ) {
          // this allows us to use either the old endpoint, where 
          // data was under data[] , and the new one where the
          // data is under data.events
          if( typeof data.events !== 'undefined') {
              this.events = data.events; 

          } else {
            this.events = data;
          }

          // this.events = data;
          this.initSidebar(); 
          this.initDivTable();
          this.addEventsToDivTable();
          this.addEngageEventsToDivTable();
          this.filter(); // recall that filter handles drawing
                         // of the events table as necessary

          // a little bit of trickery to hide the sidebar if its
          // custom.        
          if( this.custom == true  )
            $('.events-body').removeClass('large-9').addClass('large-12');
          else
            $('.events-sidebar-container').show();

        }.bind( this ));
      }.bind(this));
    }
    else {
      this.initSidebar(); 
      this.initDivTable();
      this.addEngageEventsToDivTable();
      this.addEventsToDivTable();
      this.filter();
    }
  }  

  this.initDivTable = function() {
    this.dt = new divTable( this.calendarContainer );

    this.dt._searchable = true;
    this.dt._searchContainer = '#events-search-container';

    this.dt.addColumnDescriptors({classes: "cell event-item small-12  medium-12 large-10 bordered"});
    this.dt.addColumnDescriptors({classes: "cell event-item small-12  medium-12 large-2 bordered text-right save-column"});


    this.dt.onDraw = function () {

      $('.events-saved-button active').html( "<i class='icon-starfill' aria-hidden='true'></i>SAVED" );
      $('.events-save-button').html(  "<i class='icon-star' aria-hidden='true'></i>SAVE" );

    }


    // customize the search UI a bit
    this.dt._searchPlaceholder = "Search Events";

    // this handles what we do when a search fails.
    // in this case, we want to show and hide the no-results div

   this.dt.onSearchFails = function () {
        $('.events-no-results').show();
    }
    this.dt.onSearchSuccess = function () {
        $('.events-no-results').hide();
    }

  }

  this.addEngageEventsToDivTable = function() {

      var items;

      if( this.engageevents.channel == null ) {
        console.log("ERROR: Unable to retrieve Engage Events."); 
        return; // most likely an error condition
      }

      if( this.engageevents.channel.item == null ) 
        return;

      if( !Array.isArray( this.engageevents.channel.item ) ) {
          items = new Array();
          items[0] = this.engageevents.channel.item;        
      } 
      else {
        var items =  this.engageevents.channel.item;
      }

     
      if( items == null ) {
        return;

      } 
      for( var x = 0; x < items.length; x++) {
        
        
        items[x].description = items[x].description.replace( /<div class='p-name summary'>[^<]*<\/div>/,"");


        var e = new Object();
        e['Event Start Date'] =  items[x].start; 
        e['Event End Date'] = items[x].end;
        e.Description = items[x].description;
        e.Room = "";
        e.Building = items[x].location;
        e.KSUCampus = "Kent Campus";
        e.tags = new Object();
        e.tags.flashperksEvent = false;
        e.id = "engage" + x;
        e['Event Name'] = items[x].title;
        e['KSUStudentOrganization'] = items[x].host;
        e.MoreInformationURL = items[x].link;
        e['More Information Link Text'] = "Visit the KSU Engage website for more information."; 
       this.dt.addRow( [this.formatEventDescription(e), ''] , 'events-table-row', "engageEvent" );
      }

  }


  this.formatEventDescription = function( event ) {
    //var formatEventDescription = function( event ) 
    {

      var linkMarkdown = function(text) {
          return text.replace(/\[([^\]]*)\]\(([^)]*)\)/g, function (match, p1, p2) {
              return ("<a href='" + p2 + "' target='_new'>" + p1 + "</a>");
          });
      }

      var twitterMarkdown = function(text) {
          return text.replace(/ (@\w*)/,
              function (match, p1) {
                  return (" <a href='http://twitter.com/" + p1 + "' target='_blank'>" + p1 + "</a>");
              }
          );
      }

      var formatTime = function(datestring) {
          // helper to simply reformat the date of an event
          // this used to be much longer :D

          if (datestring == null)
              return "";
          return (new moment(Date.parse(datestring)).format('h:mm a'));
      }

      var buildSingleDateBox = function( eventMoment ) {
            // retval += "<div style='float:left; text-align: center;'>";
            retval = "<div class='event no-border is-active' style='margin-top: 0px;'>";
            retval += "<div class='event-date-holder'><div class='event-number'>" + eventMoment.format("DD") + "</div>"
                + "<div class='events-month'>" + eventMoment.format('MMM').toUpperCase() + "</div>";
            retval += "</div></div>";
            return retval;
      }


      var eventMoment = new moment(event['Event Start Date']);
      var eventEndMoment = new moment(event['Event End Date']);

      var clockIcon = '<span class="dripicons-clock"></span>';
      //  Build start / end time string with clock icon

      var startTime = formatTime(event['Event Start Date']);
      var endTime = formatTime(event['Event End Date']);

      var timeString = "<div class='events-icon-container'>" + clockIcon + "</div>" + startTime;

      // only append ending time to output if one is provided.
      if (endTime != "") {
          timeString += " - " + formatTime(event['Event End Date']);
      }

      descriptionString = event.Description;

      // convert link markdown to actual links
      // e.g. [google](http://www.google.com)

      descriptionString = linkMarkdown(descriptionString);

      // Replace @twitter references with links to twitter

      descriptionString = twitterMarkdown(descriptionString);


      // BUILD LOCATION STRING WITH MAP ICON

      var locationString = '<div class="events-icon-container"><span class="dripicons-location"></span></div><div class="events-location-string" style="display: inline-block">';

      // this should be cleaned up
      var locations = new Array();

      if( event.Building != "" && event.Building != null ) {
          //locationString +=  event.Building;
          locations.push( event.Building);
      }

      if (event.Room != "" && event.Room != null) {
          locations.push( event.Room );
          // locationString += " | " + event.Room;
      }
      if( event.KSUCampus != "" && event.KSUCampus != null) {
        locations.push( event.KSUCampus);
        //locationString += " | " + event.KSUCampus;
      }
      locationString += locations.join(" | ");
      locationString += "</div>";


      // Build flashperks string if points are available for this event

      var perksString = "";

      //if (event.KSUFlashPerksEventsPoints != null && event.KSUFlashPerksEvent == 'Show Only FLASHperks Events') {
      if (event.tags.flashperksEvent == true && event.tags.flashperksPoints > 0  ) {
          var perksString = "<div class='events-icon-container'><div class='event-icon-flashperks-tiny'> </div></div>" + event.tags.flashperksPoints + " FLASHperks pts";
      }


      // now we get into the nasty parts of actually building the html of the event description

      retval = "<div class='grid-x grid-margin-x'>";

      // add mini date
      retval += "<div class='cell'>";
      retval += "<div style='float:left; text-align: center;'>";
      if (eventMoment.format("DDD") == eventEndMoment.format("DDD")) {
          retval += buildSingleDateBox( eventMoment);
      }
      if (eventMoment.format("DDD") != eventEndMoment.format("DDD")) {
          retval += "<div class='event no-border is-active'>";
          retval += "<div class='event-date-holder'><div class='events-mini-date-day-multiple' style='font-size: 90%;'>";
          retval += eventMoment.format('MMM').toUpperCase() + " ";
          retval += eventMoment.format("DD");
          retval += "<div style='line-height: 150%'>-</div>";
          retval += eventEndMoment.format('MMM').toUpperCase() + " " +  eventEndMoment.format("DD");
          retval += "</div>";
          retval += "</div></div>";
      }
      retval += "</div>";

     // add event title
      retval += "<div class='grid-x'>";
      retval += "<div class='cell divTable-item-title'>";
      // add More Info link if necessary
      if( event.id && event.id.startsWith("engage") ) {
        // format title link differently if engage url
        retval += "<a href='" + event['MoreInformationURL'] + "' target='_new'>";
        retval += event['Event Name'];
        retval += "</a>";

      } else {
        retval += "<a href='https://www.kent.edu/ksu_events_loader/KSUEvent?id=" + event['Id'] + "' target='_new'>";
        retval += event['Event Name'];

        retval += "</a>";
      }

      if (event['KSUStudentOrganization'] != "" ) {
          retval += "<br><span class='badge'>" + event['KSUStudentOrganization'] + "</span>";
      }
      retval += "</div>";


      // add event description
      retval += "<div class='cell events-event-description'>" + descriptionString + "</div>";


      // append more info link if available
      if (event['MoreInformationURL'] != ""  && event['More Information Link Text'] != "") {
          retval += "<div class='cell'><a class='events-more-info-link' href='" + event['MoreInformationURL'] + "' target='_new'>" + event['More Information Link Text'] + "</a></div>";
      }

      // append timestring
      retval += "<div class='cell events-event-time'>" + timeString + "</div>";

      // append location string
      retval += "<div class='cell events-event-location'>" + locationString + "</div>";

      // append flashperks string
      if (perksString != "") {
          retval += "<div class='cell events-perks-container'>" + perksString + "</div>";
      }
      retval += "</div>";

      return retval;

    }


  }


  this.addEventsToDivTable = function( dataset ) {

    // this is super important.  It does two things.
    // it loads all the events, formatted nicely, into the divtable.

    // as it does that, it adds in the tags needed to filter the events,
    // such as, is it a flashperks event?



    // here we get into some really fun stuff...

    var subs = new Array();
    // maintain a list of my subscribed events.
    for( var y in this.myTagSubscriptions) {
      if ( this.myTagSubscriptions[ y ] == 1 )
        subs.push(y);
    } 


    for( var x = 0; x < this.events.length; x++) {

      var tags = new Object;
      tags.category = this.events[x].Category;

      if( this.savedEvents.indexOf( this.events[x].Id) != -1  ) {
        tags.savedEvent = true; 
      }

      if( this.events[x].EventKeywords != undefined )
      {

        var keywords = this.events[x].EventKeywords.split(";");

        // this is imporant.  Campuses are separate from keywords.
        // but, we treat them as keywords. So, we're going to add
        // them to the list of keywords.

        if( this.events[x].KSUCampus != undefined )
          keywords.push( this.events[x].KSUCampus );

        // clean each keyword (replace splaces with _, for example)
        for( var y = 0; y < keywords.length; y++)
          keywords[y] = this.clean(keywords[y]);

        // as long as one or more category is in our sub list
        if( _.intersection( keywords, subs).length ) {
          tags.subscribed = true;
        }

      }
      // these are needed as tags because we can filter them with
      // a custom calendar.
      tags.department = this.events[x].KSUDepartment;
      tags.division = this.events[x].Division;
      tags.campus = this.events[x].KSUCampus; 

      tags.title = this.events[x]['Event Name']; // not required but useful

      // also store some date information for filtering purposes.
      var m = new moment(new Date( this.events[x]['Event Start Date']));
        var year = m.format('YY');
        var dayofyear = m.format('DDD');
      tags.year = year;
      tags.dayofyear = dayofyear;

      // add tags if it is stu org or has flashperks.
      if( this.events[x].KSUStudentOrganization != undefined && this.events[x].KSUStudentOrganization != "" ) {
        tags.studentOrgEvent = true;
        tags.studentOrg = this.events[x].KSUStudentOrganization;
      }


      // might be issue if there are no points...
      if( this.events[x].KSUFlashPerksEvent == "True") {
        tags.flashperksEvent = true;
        tags.flashperksPoints = this.events[x].KSUFlashPerksEventsPoints;
      }
      else
        tags.flashperksEvent = false;


      // ok build out the save button
      var savebutton = document.createElement("a");

      //adding tabindex 0 does not allow keyboard users to click using the enter key. Setting the href to #0 solves this problem and avoids unwanted navigation since #0 is non-relevant.
      savebutton.setAttribute('href', '#0');
      savebutton.setAttribute('role', 'button');

      var saved = false;
      for( var y = 0; y < this.savedEvents.length; y++)
      {
        if( this.savedEvents[y] == this.events[x].Id ) {
          saved = true; break;
        } 
      }
        
      if( saved ) { //  && this.events[x].savedEvent == true )
        savebutton.className = "button events-saved-button has-icon-left active";
        savebutton.innerHTML = "<i class='icon-starfill' aria-hidden='true'></i>SAVED";
      }
      else {
        savebutton.className = "button events-save-button has-icon-left";
        savebutton.innerHTML = "<i class='icon-star' aria-hidden='true'></i>SAVE";
      }

      savebutton.dataset.eventid = this.events[x].Id;
      savebutton.setAttribute('id', 'event_' + this.events[x].Id );

      savebutton.addEventListener('click', function() { this.className += " active-save"; } );
      savebutton.addEventListener('click', function() { 
        var id =( $(".active-save").first().data("eventid") );
        $('.active-save').removeClass('active-save');
        this.saveEventToggle( id );
        
        if(document.documentElement.getAttribute('data-whatinput') === 'keyboard') {
          var clickedButton = document.getElementById('event_' + id);
          clickedButton.focus();
        }

      }.bind(this) );
      
      this.events[x].tags = tags;
      this.dt.addRow( [ this.formatEventDescription( this.events[x]), savebutton ], 'events-table-row', tags );
    
    } 

  }


    this.saveEventToggle = function(id) {

        var found = false;

        for( var x = 0; x < this.savedEvents.length; x++) {
          if( this.savedEvents[x] == id ) {
            this.savedEvents[x] = 'deleted';

            for( var y = 0; y < this.events.length; y++ ) {
                if( this.events[y].Id == id )
                  this.events[y].savedEvent = null;
            }
            found = true;
          }
        }

        if( found == false )
          this.savedEvents.push( id );

        this.dt._dataset = new Array();
        this.addEventsToDivTable();
        
        // called when a 'save' button is clicked

        // save it to the user's profile.

        $.get('/user_profile/event/' + id + '/toggle');

        this.dt.draw();
        return; 

    }

    this.buildCountOfCheckedCategories = function() {
      // ok, this is all for updating the number of categories that a
      // user has selected

      // if at least one category is checked, we shouldn't show
      // the message urging users to select categories

      var at_least_one_category_checked = false;

      // listOfCategories is an object with each category name

      for (var x in this.listOfCategories) {

        if (this.listOfCategories.hasOwnProperty(x)) { // ignore internal methods
          var category_count = $('input[data-category="' + x + '"]:checked').length;

          if (category_count == 0)
            $('[data-category-counter="' + x + '"]').hide(500);
          else
            $('[data-category-counter="' + x + '"]').show();

          $('[data-category-counter="' + x + '"]').html(category_count);

          if (category_count > 0)
            at_least_one_category_checked = true;
        }
      }

      if (at_least_one_category_checked) {
        // if at least one category is checked, allow users to
        // escape from this menu back to the calendar, instead
        // of the main calendar, and remove the message urging
        // users to select categories.
        $('.events-back-to-calendar').show();
        $('.events-back-to-calendar-all').hide();
      }
      else {
        // if no categories are checked, then when a user
        // escapes from the menu, take them back to the main
        // calendar and show the message urging users to select
        // categories.

        $('.events-back-to-calendar-all').show();
        $('.events-back-to-calendar').hide();
      }
    }


    this.toggle_my_categories = function(category, val) {
        category = category.substring(4);

        if (val == true)
            onoff = 1;
        else
            onoff = 0;

        $('#cat_' + category).attr('aria-checked', val);

        $.getJSON("/user_profile/eventtags/" + category + "/" + onoff);

        if (category == 'none')
            this.myTagSubscriptions = new Array();
        else
            this.myTagSubscriptions[category] = onoff;

        this.buildCountOfCheckedCategories();

    }


    this.buildEventsForYouOptions = function() {

        if( document.getElementById('event-preferences').children.length > 0 )
          return;

        data =  this.categoryList;


        // create the accordion-proper
        var category_accordion = document.createElement("ul");
        category_accordion.setAttribute('id', 'preferences-accordion');
        category_accordion.setAttribute('data-accordion', '');
        category_accordion.setAttribute('data-allow-all-closed', 'true');
        category_accordion.className = "accordion";
        category_accordion.style['width'] = "100%";

        // for each category
        for (var x = 0; x < data.length; x++) {
            var category_li = document.createElement("li");

            category_li.className = "accordion-item";

            // set first accordion element as active / open
            if (x == 0) {
                category_li.className = "accordion-item is-active";
                category_li.setAttribute('aria-expanded', "true");
            }
            category_li.setAttribute('data-accordion-item', "");

            // in error -- causes duplicate id category_li.setAttribute('id','preferences-item-' + x );

            var category_title = document.createElement("a");

            category_title.appendChild(document.createTextNode(data[x].title));

            // var linkText = document.createTextNode( data[x].title );// + "<span class='badge'>" + sub_category_count + "</span>" );
            //category_title.appendChild(linkText);

            var cat_count_span = document.createElement("span");
            cat_count_span.setAttribute('data-category-counter', this.clean(data[x].title));

            cat_count_span.className = "badge events-preferences-badge";
            // cat_count_span.innerHTML = sub_category_count;

            this.listOfCategories[this.clean(data[x].title)] = 1;
            category_title.appendChild(cat_count_span);

            category_title.href = "#";
            category_title.href = "#event-accordion-content-" + x;
            category_title.className = "accordion-title";

            var category_content = document.createElement("div");
            category_content.className = "accordion-content";
            category_content.setAttribute("data-tab-content", "");
            category_content.setAttribute("id", "event-accordion-content-" + x);

            var subcategory_container = document.createElement("div");
            subcategory_container.className = "grid-x";

            for (var y = 0; y < data[x].subcategories.length; y++) {

                var subcategory_checkbox_container = document.createElement("div");
                subcategory_checkbox_container.className = "cell small-12 large-4";
                subcategory_checkbox_container.style = "padding: .5em;";

                var subcategory_checkbox = document.createElement("input");
                subcategory_checkbox.type = 'checkbox';
		subcategory_checkbox.className = "fl-check";
                subcategory_checkbox.id = "cat_" + this.clean(data[x].subcategories[y].title);
                subcategory_checkbox.setAttribute("data-category", this.clean(data[x].title));

                subcategory_checkbox.addEventListener('change', function() {
                  this.className += " active-checkbox";
                });
                
                subcategory_checkbox.addEventListener( 'change', function () {

                  var box = document.getElementsByClassName('active-checkbox');
                  var id = box[0].getAttribute('id');
                  var checked = box[0].checked;
                 
                  $('.active-checkbox').removeClass('active-checkbox');
                 

                  for( var xx = 0; xx < this.events.length; xx++ )
                  {
                    if( this.events[xx].EventKeywords == null )
                      continue;
                    var cats = this.events[xx].EventKeywords.split(";");

                    cats.push( this.events[xx].KSUCampus );               
 
                    for( yy = 0; yy < cats.length; yy++)
                    {
                      if( "cat_" + this.clean(cats[yy]) == id ) 
                        this.events[xx].tags.subscribed = checked ;
                
                    }  
        
                  }
                    this.toggle_my_categories(id, checked);
                }.bind(this));



                if (this.myTagSubscriptions[this.clean(data[x].subcategories[y].title)] == "1") {
                    subcategory_checkbox.setAttribute("aria-checked", true);
                    subcategory_checkbox.checked = true;
                }
                else {
                    subcategory_checkbox.setAttribute("aria-checked", false);
                }

                subcategory_checkbox.setAttribute("aria-describedBy", "cat_" + this.clean(data[x].subcategories[y].title) + "_desc");
                subcategory_checkbox_container.appendChild(subcategory_checkbox);

                var subcategory_label_container = document.createElement("label");
                subcategory_label_container.setAttribute("id", "cat_" + this.clean(data[x].subcategories[y].title) + "_desc");
                subcategory_label_container.className = "cell small-12 large-5";
                //subcategory_label_container.style = "padding: .5em;";
		subcategory_label_container.setAttribute('for', "cat_" + this.clean(data[x].subcategories[y].title) );
                subcategory_label_container.innerHTML = data[x].subcategories[y].title;

		subcategory_checkbox_container.appendChild( subcategory_label_container);
                subcategory_container.appendChild(subcategory_checkbox_container);
                //subcategory_container.appendChild(subcategory_label_container);

            } /* */

            category_content.appendChild(subcategory_container);

            category_li.appendChild(category_title);

            category_li.appendChild(category_content);
            category_accordion.appendChild(category_li);
        }
        $('#event-preferences').append(category_accordion);

        $('.accordion').foundation();

    }

  }

