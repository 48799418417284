
// Flashline - mixins / functions for SweetAlert2

//Close icon/text in upper right corner. Invoked through mixin - onBeforeOpen.
function sweetAlertCustom() {
    var swalClose = document.querySelector('.swal2-popup button.swal2-close');
    var swal = document.querySelector('.swal2-popup');
    var swalHeader = document.querySelector('.swal2-popup .swal2-header');
    if (!swalClose.classList.contains('custom-close')) {
        swalClose.classList.add('custom-close');
        swalClose.innerHTML = "";
        var iconSpan = document.createElement('span');
        iconSpan.classList.add('dripicons-cross');
        iconSpan.setAttribute('aria-hidden', 'true');
        swalClose.appendChild(iconSpan);
        var text1 = document.createTextNode('Close');
        swalClose.appendChild(text1);
        swal.insertBefore(swalClose, swalHeader);
    }
}

//Sweet Alert with Cancel, Confirm, and Close icon/text in upper right corner
var swal2Btns = swal.mixin({
    showCloseButton: true,
    closeButtonAriaLabel: '',
    showCancelButton: true,
    cancelButtonText: 'Close',
    reverseButtons: true,
    stopKeydownPropagation: false,
    buttonsStyling: false,
    confirmButtonClass: 'button show-focus',
    cancelButtonClass: 'button',
    onBeforeOpen: function onBeforeOpen() {
        sweetAlertCustom();
    }
});

//Sweet Alert with Close icon/text in upper right corner
var swal2 = swal.mixin({
    showCloseButton: true,
    closeButtonAriaLabel: '',
    stopKeydownPropagation: false,
    buttonsStyling: false,
    confirmButtonClass: 'button show-focus',
    cancelButtonClass: 'button',
    onBeforeOpen: function onBeforeOpen() {
        sweetAlertCustom();
    }
});

//Sweet Alert 2 doesn't use Ball Fall.
//This lets us still use it when showLoaderOnConfirm is set to true
//Call this function from preConfirm function.
function sweetAlertUseBallFall() {
    var thisSwal = document.querySelector('.swal2-popup');
    thisSwal.classList.add('ball-fall');
    var swalConfirm = document.querySelector('.swal2-popup button.swal2-confirm');
    if (swalConfirm) {
        swalConfirm.classList.add('ball-fall');
        swalConfirm.innerHTML = '';
        var ballFall = document.createElement('div');
        ballFall.classList.add('la-ball-fall');
        ballFall.appendChild(document.createElement('div'));
        ballFall.appendChild(document.createElement('div'));
        ballFall.appendChild(document.createElement('div'));
        swalConfirm.appendChild(ballFall);

        var atUpdate = document.createElement('div');
        //Tabindex of -1 allows us to send focus to this element while leaving it OUT of the keyboard tabindex. 
        atUpdate.setAttribute('tabindex', '-1');
        atUpdate.textContent = 'processing...';
        atUpdate.classList.add('aria-only');
        swalConfirm.parentNode.appendChild(atUpdate);
        atUpdate.focus();//Send focus so screenreaders will announce

    }

}