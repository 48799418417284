var KSUTour = {
    addTourStep: function (options) {
        this.tourContent = true;

        if( !$( options.path ).length ) {
            return;
        }
        
        if( !$( options.path).is(":visible") )
        {
          if( options.mayBeInvisible != true )
            return;
        }

        var defaults = {
            'pathPosition': 'bottom',
            'nextText': 'Next',
            'lastStep': false
        };
        options = jQuery.extend(defaults, options);

        var buttonOptions;

        if( this.step == 1 && options.lastStep )
        {

          buttonOptions = [
            {
              text: 'Finish',
              action: this.shepherd.next,
              classes: 'shepherd-button-example-primary'
            }
          ];
          if( options.finishText != null ) { 
            buttonOptions[0].text = options.finishText;
          }

        } 
        else {
          buttonOptions = [
              {
                  text: 'Exit',
                  classes: 'shepherd-button-secondary',
                  action: this.shepherd.cancel
              }, {
                  text: 'Next',
                  action: this.shepherd.next,
                  classes: 'shepherd-button-example-primary'
              }
          ];

          if (this.step !== 1) {
              buttonOptions[0].text = 'Back';
              buttonOptions[0].action = this.shepherd.back;
          }

          if (options.lastStep) {
              buttonOptions[1].text = 'Finish';
              buttonOptions[1].action = this.shepherd.next;
          }
        }

        this.shepherd.addStep('intro-' + this.step, {
            text: options.text,
            attachTo: options.path + ' ' + options.pathPosition,
            classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',
            buttons: buttonOptions
        });

        this.step++;
    },

    init: function (data) {
        this.step = 1;

        // Don't show on mobile
        if (!Foundation.MediaQuery.atLeast('large')) {
            return;
        }

        this.shepherd = new Shepherd.Tour({
            defaults: {
                classes: 'shepherd-element shepherd-open shepherd-theme-arrows',
                showCancelLink: true
            }
        });

        // User's tour states
        this.states = $.parseJSON(data);
        this.employee = $('li > a:contains("Employee"), li > a:contains("Faculty & Advisors")').length;
        this.parent = $('li > a:contains("KSUView")').length;

        this.welcome();
        this.dashboards();
        this.myDashboard();


        if(this.tourContent){
          KSUMain_ES6.suppressModals = true;

          setTimeout(function () {
            this.shepherd.start();
          }.bind(this),500);

        }

        return;
    },

    postProgress: function (match) {
        $.post('/asyncloader/tour-progress', {'tour_finished': match});
    },

    extra: function() {

	KSUTour.addTourStep({
	'path': '.icon-library',
	'text': '<p>' + item.text[0] + '</p>',
	'lastStep': true
	});

	KSUTour.shepherd.on('inactive', function () {
	KSUTour.postProgress(item.match);
	});


    },

    welcome: function () {

        var match = 'welcome';

        if( typeof extraSteps == 'object' && Array.isArray( extraSteps ) ) {

          for( var x = 0; x < extraSteps.length; x++ ) {
            // note that finishText only applies when there is one and only one step. 
            if ((window.location.pathname).indexOf(match) !== -1 && _.includes(this.states, match) && !_.includes( this.states, extraSteps[x].name) && extraSteps[x].page == match ) { 
              this.addTourStep({ 
                'path': extraSteps[x].path,
                'text': extraSteps[x].text,
                'lastStep': true,
                'pathPosition': extraSteps[x].pathPosition,
                'finishText' : extraSteps[x].finishText
              });
              KSUTour.shepherd.on('inactive', function() {
                KSUTour.postProgress(extraSteps[x].name);
              });
              break;
            }
          }
        }



        if ((window.location.pathname).indexOf(match) !== -1 && !_.includes(this.states, match)) {

            this.addTourStep({
                'path': 'body',
                'text': '<h1>Hi, Welcome to the FlashLine!</h1><p>Before you get started, we would like to show you some features that we added to help you get where you need to go!</p>',
            });

            if (!this.parent) {
                this.addTourStep({
                    'path': '.side-nav',
                    'pathPosition': 'right',
                    'text': '<p>We’ve organized the information into sections that make sense so you can easily explore the site.</p>',
                });

                this.addTourStep({
                    'path': '#nav-wide',
                    'pathPosition': 'right',
                    'text': '<p>We’ve organized the information into sections that make sense so you can easily explore the site.</p>',
                });

                this.addTourStep({
                    'path': '#mobile-hamburger',
                    'pathPosition': 'right',
                    'text': '<p>We’ve organized the information into sections that make sense so you can easily explore the site.</p>',
                });

                this.addTourStep({
                    //'path': '.side-nav li a[href$="MyDashboard"]',
                    'path': '#nav-wide .block-menu-menu-nav1-featured a[href$="MyDashboard"]',
                    'pathPosition': 'right',
                    'text': '<p>My Dashboard is your personal dashboard where you can add and arrange your favorite content.</p>'
                });



            }

            if (this.parent) {
                this.addTourStep({
                    //'path': '.side-nav li a[href$="KSUView"]',
                    'path': 'a[href$="KSUView"]',
                    'text': '<p>KSU View provides access to valuable resources and most importantly, access to view the information your student has designated for you.</p>',
                    'pathPosition': 'right'
                });
                this.addTourStep({
                    //'path': '#navarea2 .checklist-tour',
                    'path': '#nav2-featured .checklist-tour',
                    'pathPosition': 'bottom',
                    'text': '<p>Use this icon to view your student’s checklist.</p>'
                });

                this.addTourStep({
                    //'path': '#navarea2 .checklist-tour',
                    'path': '#nav2 .checklist-tour',
                    'pathPosition': 'bottom',
                    'text': '<p>Use this icon to view your student’s checklist.</p>'
                });


            }

            this.addTourStep({
                //'path': '.search-form-nav input[name="search_block_form"]',
                'path': '.search-wrapper input[name="search_block_form"]',
                'text': '<p>Now you can search within FlashLine! Enter a keyword and FlashLine will quickly return the results you need.</p>',
                'lastStep': this.parent
            });

            if (!this.parent) {
                this.addTourStep({
                    //'path': '.top-bar-section',
                    'path': '#nav2',
                    'text': '<p>Use these icons to view your notifications, checklists, ' + (this.employee ? "worklist," : '') + ' and get to important resources like email and Blackboard.</p>',
                });
                /*
                this.addTourStep({
                    'path': 'body',
                    'text': '<p>Would you like to take a minute to set up your profile?</p>',
                    'lastStep': true
                });
                */
            }

            // When completed, or closed
            KSUTour.shepherd.on('inactive', function () {
                KSUTour.postProgress(match);

                swal2Btns({
                  title: 'Well done!',
                  text: 'Would you like to take a minute to set up your profile?',
                  type: 'success',
                  confirmButtonText: 'Set up my profile',
                  cancelButtonText: 'Not now',
                  showLoaderOnConfirm: true,
                  preConfirm: function() {
                    return new Promise(function(resolve) {
                        //use ball fall animation instead default
                        sweetAlertUseBallFall();
                        window.location.href = "/my-profile";
                        });
                    }
                });
            });
        }
    },

    dashboards: function () {
        var info = {
            student: {
                match: 'StudentDashboard',
                text: ['Welcome to your Student dashboard. This dashboard will provide at-a-glance information important to your student success at Kent State', 'Use the Resources to access other areas like links to registration, advising, and grades.']
            },
            employee: {
                match: 'EmployeeDashboard',
                text: ['This is your Employee dashboard. This dashboard gives you quick access to information and links that relate to your job.', 'Use the Resources to access more information about benefits, training, and wellness initiatives.']
            },
            faculty: {
                match: 'FacultyDashboard',
                text: ['This is your Faculty dashboard. This dashboard gives you quick access to your courses, rosters, and links you need for grading.', 'Use the Resources to access more information about policies, course catalogs, and faculty research.']
            },
            advisor: {
                match: 'AdvisorDashboard',
                text: ['Welcome to your Advisor dashboard. This dashboard gives you quick access to GPS as well as dates and links you need for advising activities.', 'Use the Resources to access more information about policies, course catalogs and additional advising links.']
            }
        };

        if( typeof extraSteps == 'object' && Array.isArray( extraSteps ) ) {
          for( var x = 0; x < extraSteps.length; x++ ) {
            // note that finishText only applies when there is one and only one step. 
            //if ((window.location.pathname).indexOf(match) !== -1 && _.includes(this.states, match) && !_.includes( this.states, extraSteps[x].name) && extraSteps[x].page == match ) {
            if ((window.location.pathname).indexOf(extraSteps[x].page) !== -1 /* && _.includes(this.states, extraSteps[x].page) */  && !_.includes( this.states, extraSteps[x].name) ) {
// && extraSteps[x].page == match ) {
              this.addTourStep({
                'path': extraSteps[x].path,
                'text': extraSteps[x].text,
                'lastStep': true,
                'pathPosition': extraSteps[x].pathPosition,
                'finishText' : extraSteps[x].finishText
              });
              KSUTour.shepherd.on('inactive', function() {
                KSUTour.postProgress(extraSteps[x].name);
              });
              break;
            }
          }
        }




        // Check if we've already shown one of these dashboard tours
        var dontShow = false;
        $.each(info, function (i, item) {
            if (_.includes(KSUTour.states, item.match)) {
                dontShow = true;
            }
        });

        $.each(info, function (i, item) {
            if ((window.location.pathname).indexOf(item.match) !== -1 && !_.includes(KSUTour.states, item.match)) {


                // Don't continue if they've seen one of these already
                if (dontShow) {
                    /*
                    KSUTour.addTourStep({
                    'path': '.main-content',
                    'text': '<p>' + item.text[0] + '</p>',
                    'lastStep': true
                    });

                    KSUTour.shepherd.on('inactive', function () {
                        KSUTour.postProgress(item.match);
                    });
                    */
                    KSUTour.postProgress(item.match);

                    return;

                } else {
                    KSUTour.addTourStep({
                    'path': '.main-content',
                    'text': '<p>' + item.text[0] + '</p>'
                    });
                }



                if (KSUTour.employee) {
                    KSUTour.addTourStep({
                        'path': '#nav2-featured .checklist-tour',
                        'text': '<p>You’ll find active worklist items here.</p>',
                        'pathPosition': 'bottom'
                    });
                }

                if (KSUTour.employee) {
                    KSUTour.addTourStep({
                        // 'path': '#nav2-featured .checklist-tour',
                         'path': '#nav2 .checklist-tour',
                        'text': '<p>You’ll find active worklist items here.</p>',
                        'pathPosition': 'bottom'
                    });
                }


                KSUTour.addTourStep({
                    'path': '[aria-expanded="true"] a.menu_link__menu_links_v3-Resources',
                    'pathPosition': 'right',
                    'text': '<p>' + item.text[1] + '</p>',
                    'mayBeInvisible': true
                });

                KSUTour.addTourStep({
                    'path': 'body',
                    'text': "<p>Click the pin icons <span class='dripicons-pin fl-icon inline-icon'></span> to place your favorite content onto the My Dashboard page.</p>",
                    'lastStep': true
                });

                KSUTour.shepherd.on('inactive', function () {
                    KSUTour.postProgress(item.match);
                });

                return false;
            }
        });
    },

    myDashboard: function () {
        var match = 'MyDashboard';
        if ((window.location.pathname).indexOf(match) !== -1 && !_.includes(this.states, match)) {
            /*
            this.addTourStep({
                'path': '.packery-grid-wrapper',
                'text': '<p>You can place your own content here by clicking the pin icon wherever you see it throughout FlashLine.</p>',
                'lastStep': true
            });
            */

            /* the button is not visible, so its not available on a new dashboard. 
            this.addTourStep({
                'path': '#edit-dash',
                'text': '<p>Once you’ve added content, you can move it and resize it so it appears how you’d like to see it.</p>',
                'lastStep': true
            });
            */

            KSUTour.shepherd.on('inactive', function () {
                KSUTour.postProgress(match);
            });
        }
    }

};

