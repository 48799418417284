//adds all the badges in the mobile menu and then
//displays a badge with total if greater than zero
function addBadges() {
    var mobileBadges = document.querySelectorAll('#navarea3 .badge');
    var badgeCount = 0;
    for (var i = 0; i < mobileBadges.length; i++) {
        let badgeNumEl = mobileBadges[i].querySelector('.badge-num');
        let currentBadgeNum = Number(badgeNumEl.textContent);
        badgeCount += currentBadgeNum;
    }

    var currentBadge = document.getElementById('mobile-total-badge');
    let ariaLabel = (badgeCount === 1) ? ' action item' : ' action items';

    //console.log(badgeCount);

    if (badgeCount > 0) {
        if(currentBadge){
            currentBadge.querySelector('.badge-num').textContent = badgeCount;
            currentBadge.querySelector('.aria-only').textContent = ariaLabel;

        } else {
          var mobileTotalBadge = document.createElement('span');
          mobileTotalBadge.id = 'mobile-total-badge';
          mobileTotalBadge.classList.add('badge');
          var badgeNumEl = document.createElement('span');
          badgeNumEl.classList.add('badge-num');
          badgeNumEl.textContent = badgeCount;
          var ariaLabelEl = document.createElement('span');
          ariaLabelEl.classList.add('aria-only');
          ariaLabelEl.textContent = ariaLabel; 
          mobileTotalBadge.appendChild(badgeNumEl);
          mobileTotalBadge.appendChild(ariaLabelEl);
          var mobileAuxButton = document.querySelector('#navarea3-toggle');
          mobileAuxButton.appendChild(mobileTotalBadge);
        }
    } else {
        if(currentBadge) {
          currentBadge.parentNode.removeChild(currentBadge);
          currentBadge = null;
        }
    }


} 